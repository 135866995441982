import React from "react";
import { useSelector } from "react-redux";
import Step4Commande from "../Step4/Step4Commande";
import Step4Facture from "../Step4/Step4Facture";
import Step4Particulier from "../Step4/Step4Particulier";
import Step4Reseller from "../Step4/Step4Reseller";
import moment from "moment";
import Step4MissingData from "../Step4/Step4MissingData";
import Step4MissingDataParticulier from "../Step4/Step4MissingDataParticulier";
import Step4Delivery from "../Step4/Step4Delivery";
import Step4Paiement from "../Step4/Step4Paiement";

const Step4Catalogue = () => {
  const { cart } = useSelector((state) => state.catalogueReducer);
  const { userRole } = useSelector((state) => state.userReducer);

  return (
    <div>
      <h2 className="bold text-softBlue text-center">
        Récapitulatif de la commande
      </h2>
      <h4 className="bold text-softBlue text-start">Commande #{cart.number}</h4>
      <h4 className="bold text-softBlue text-start f12">
        {moment(cart.date).format("dddd DD MMMM YYYY")}
      </h4>
      <h4 className="bold text-softBlue text-start f12">
        facture #{cart?.factureNumber}
      </h4>
      {cart.factureDate && (
        <h4 className="bold text-softBlue text-start f12">
          Date de facturation :{" "}
          {moment(cart.factureDate).format("dddd DD MMMM YYYY")}
        </h4>
      )}
      {cart.particulier ? (
        <Step4MissingDataParticulier />
      ) : (
        <Step4MissingData />
      )}
      {cart.particulier ? <Step4Particulier /> : <Step4Reseller />}
      <Step4Delivery />
      {userRole === "admin" && <Step4Commande />}
      {userRole === "admin" && <Step4Facture />}
      {/* {userRole === "admin" && <Step4BonDeCommande />} */}
      <Step4Paiement />
    </div>
  );
};

export default Step4Catalogue;
