import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../../../Common/FormElements/Button/Button";
import { openRightModal } from "../../../../../REDUX/actions/rightModal";
import { openCenteredModal } from "../../../../../REDUX/actions/centeredModal";
import ReserverSalon from "../ReserverSalon";
import Evenement from "../../../Modals/Evenement/Evenement";
import NewEvent from "../../../Modals/Evenement/NewEvent";

const SalonDropdown = () => {
  const { user } = useSelector((state) => state.userReducer);

  const dispatch = useDispatch();

  return (
    <div className="bg-white br5 p-4 calendarEventDrop position-absolute colAlignC gap-2">
      <span className="text-blue f15 sBold mb-2">Titre du salon</span>
      {user?.attributes?.userRole === 0 && (
        <Button
          className="bg-softBlue w100"
          onClick={() =>
            dispatch(openRightModal(<Evenement modalTitle="Salon" />))
          }
        >
          Consulter
        </Button>
      )}
      <Button
        className=" w100"
        onClick={() =>
          user?.attributes?.userRole === 0
            ? dispatch(openCenteredModal(<ReserverSalon />))
            : dispatch(openRightModal(<NewEvent />))
        }
      >
        {user?.attributes?.userRole === 0 ? "Réserver" : "Modifier"}
      </Button>
    </div>
  );
};

export default SalonDropdown;
