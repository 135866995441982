import React from "react";
import SavPreviewInfos from "./SavPreviewInfos";
import SavPreviewAttachments from "./SavPreviewAttachments";
import SavChatMessages from "../SavChatMessages";

function SavPreviewContent({ activeTab, ticketId }) {
  switch (activeTab) {
    case "Infos générales":
      return <SavPreviewInfos ticketId={ticketId} />;
    case "Pièces jointes":
      return <SavPreviewAttachments ticketId={ticketId} />;

    case "Commentaires":
      return (
        <div className="d-flex h-100 flex-column mx-5">
          <SavChatMessages />
        </div>
      );
    default:
      return <SavPreviewInfos ticketId={ticketId} />;
  }
}

export default SavPreviewContent;
