import _ from "lodash";

const initialState = {
  ordersLoading: false,
  ordersError: null,
  orders: [],
  ordersReload: false,
  orderLoading: false,
  orderError: null,
  order: null,
  orderReload: false,
  currentComments: [],
  currentCommentsLoading: false,
  commentsLoading: false,
  orderProducts: [],
  orderProductsLoading: false,
  orderProductsError: null,
  showOrder: null,
  ordersCount: null,
  orderResellerSAV: null,
  orderAffiliateSAV: null,
  savID: null,
  invoiceLoading: false,
};

const ordersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "ORDERS_LOADING": {
      return { ...state, ordersLoading: true };
    }
    case "ORDERS_ERROR": {
      return { ...state, ordersLoading: false, ordersError: payload };
    }
    case "ORDERS_FETCHED": {
      return {
        ...state,
        ordersLoading: false,
        orders: _.uniqBy(
          payload?.clear
            ? payload?.orders
            : [...(state?.orders || []), ...(payload?.orders || [])],
          function (item) {
            return item?.id;
          }
        ),
        ordersCount: payload?.ordersCount,
      };
    }
    case "ORDER_LOADING": {
      return { ...state, orderLoading: true };
    }
    case "ORDER_ERROR": {
      return { ...state, orderLoading: false, orderError: payload };
    }
    case "ORDER_FETCHED": {
      return { ...state, orderLoading: false, order: payload };
    }
    case "ORDER_RELOAD": {
      return { ...state, orderReload: !state.orderReload };
    }
    case "ORDER_CREATED": {
      return { ...state, ordersLoading: false, orders: payload };
    }
    case "ORDER_UPDATED": {
      return { ...state, ordersLoading: false, orderLoading: false };
    }
    case "ORDER_DELETED": {
      return { ...state, ordersLoading: false };
    }
    case "ORDERS_RELOAD": {
      return { ...state, ordersReload: !state.ordersReload };
    }
    case "CREATE_COMMENTS_ORDER_LOADING":
      return { ...state, currentCommentsLoading: true };
    case "CREATE_COMMENTS_ORDER_SUCCESS":
      return {
        ...state,
        currentCommentsLoading: false,
        currentComments: [payload, ...state.currentComments],
      };

    case "FETCH_COMMENTS_ORDER_LOADING":
      return { ...state, commentsLoading: true };

    case "FETCH_COMMENTS_ORDER_SUCCESS":
      return { ...state, currentComments: payload, commentsLoading: false };
    case "CLEAR_ORDER":
      return {
        ...state,
        orderLoading: false,
        orderError: null,
        order: null,
        orderReload: false,
        currentComments: [],
        commentsLoading: false,
      };

    case "GET_PRODUCTS_ORDER_LOAD":
      return { ...state, orderProductsLoading: true };
    case "GET_PRODUCTS_ORDER_SUCC":
      return { ...state, orderProductsLoading: false, orderProducts: payload };
    case "GET_PRODUCTS_ORDER_ERROR":
      return {
        ...state,
        orderProductsLoading: false,
        orderProductsError: payload,
      };
    case "SHOW_ORDER":
      return { ...state, showOrder: payload };
    case "CLOSE_ORDER":
      return { ...state, showOrder: null };
    case "CLEAR_ORDERS": {
      return { ...state, ordersLoading: false, orders: [], ordersCount: null };
    }
    case "GET_RESELLER_AFFILIATE_SUCC":
      return {
        ...state,
        orderResellerSAV: payload?.reseller,
        orderAffiliateSAV: payload?.affiliate,
        savID: payload?.savID,
      };

    default:
      return state;
  }
};

export default ordersReducer;
