import React, { useEffect, useState } from "react";

// import { attachmentsListData } from "../../../helpers/fakeData/Sav/attachments";
import Attachements from "../../Common/Forms/Attachements/Attachements";

const PhotoVideoProduct = ({ handleFormsChange, initialValues }) => {
  const [attachments, setAttachments] = useState(initialValues ?? []);
  const [activeSwitch, setActiveSwitch] = useState();

  useEffect(() => {
    handleFormsChange("media", attachments);
  }, [attachments]);

  return (
    <>
      <Attachements
        attachments={attachments}
        setAttachments={setAttachments}
        fromNewProduct
        acceptTypes={"image/*,video/*"}
        activeSwitch={activeSwitch}
        setActiveSwitch={setActiveSwitch}
      />
      {/* <div className="w-100 alignC gap-3 px-5 pt-3 mt-auto">
        <Button
          variant={"outline"}
          className="px-5"
          onClick={() => dispatch(closeRightModal())}
        >
          Annuler
        </Button>
        <Button
          className="px-5"
          disabled={disabled}
          loading={loading}
          onClick={loading ? undefined : handleClick}
        >
          Enregistrer
        </Button>
      </div> */}
    </>
  );
};

export default PhotoVideoProduct;
