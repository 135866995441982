import React from "react";

import Attachements from "../../../Common/Forms/Attachements/Attachements";
// import { attachmentsListData } from "../../../../helpers/fakeData/Sav/attachments";

function NewSAVAttachments({ attachments, setAttachments }) {
  return (
    <div className="pt-3">
      <Attachements attachments={attachments} setAttachments={setAttachments} />
    </div>
  );
}

export default NewSAVAttachments;
