import React from "react";
import OrderProductItem from "./OrderProductItem";
import getOrderDetailsLabel from "./OrderDetailsLabels";
import adjustPrice from "../../../Utils/AdjustPrice";

const OrderProductsList = ({ forRightModal, withoutStatus, orderData }) => {
  const orderDetailsLabels = getOrderDetailsLabel(forRightModal, withoutStatus);

  return (
    <div className="w-100">
      <div className={`row gx-0 px-5 bg-smoothBlue`}>
        {orderDetailsLabels?.map(({ label, className, alignement }, index) => (
          <p
            className={`${className} text-${alignement} d-block mb-0 f14 px-0 py-3 alignH text-brightGrey sBold textNoWrap`}
            key={index}
          >
            {label}
          </p>
        ))}
      </div>

      <div className={`bg-skyBlue`}>
        {orderData.groupedPackings?.map((details) => {
          return <OrderProductItem packing={details} key={details.id} />;
        })}
        <div className={`px-5 py-3 row gx-0`}>
          <span className={`col-11 f14 text-brightGrey sBold`}>
            Livraison HT
          </span>
          <span className={`col-1 f14 text-brightGrey textNoWrap`}>
            {adjustPrice(orderData?.totalDeliveryHT?.toFixed(2)) || 0} €
          </span>
        </div>
        <div className={`px-5 py-3 row gx-0`}>
          <span className={`col-11 f14 text-brightGrey sBold`}>Total HT</span>
          <span className={`col-1 f14 text-brightGrey textNoWrap`}>
            {adjustPrice((orderData?.totalHT).toFixed(2).toString())} €
          </span>
        </div>

        <div className={`px-5 py-3 row gx-0`}>
          <span className={`col-11 f14 text-brightGrey sBold`}>
            TVA :
            {orderData.addresseDeFacturation?.country?.name === "France"
              ? ` ${orderData.addresseDeFacturation?.country?.name || ""} (20%)`
              : ` ${orderData.addresseDeFacturation?.country?.name || ""} (0%)`}
          </span>
          <span className={`col-1 f14 text-brightGrey textNoWrap`}>
            {adjustPrice((orderData?.TVA).toFixed(2).toString())}€
          </span>
        </div>
        <div className={`px-5 py-3 row gx-0`}>
          <span className={`col-11 f14 text-brightGrey sBold`}>Total TTC</span>
          <span className={`col-1 f14 text-brightGrey textNoWrap`}>
            {adjustPrice((orderData?.totalTTC).toFixed(2).toString())} €
          </span>
        </div>

        {forRightModal ? (
          <hr
            style={{
              border: `1px solid white`,
              opacity: 0.8,
              width: "97%",
              margin: "auto",
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

export default OrderProductsList;
