import React from "react";
import Button from "../../../Common/FormElements/Button/Button";
import OrderProductsList from "../../../Common/OrderProductsList/OrderProductsList";

import "./Livraison.css";
import { useDispatch } from "react-redux";
import { openRightModal } from "../../../../REDUX/actions/rightModal";
import NewTicketSAV from "../../../Sav/NewTicketSav/NewTicketSAV";
import moment from "moment";

function Livraison({ order }) {
  const dispatch = useDispatch();
  const handleProblemReport = () => {
    dispatch(openRightModal(<NewTicketSAV />));
  };
  return (
    <div className="px-5 h100 d-flex flex-column justify-content-between">
      <div>
        <h1 className="quickSand rightModalTitle mb-5">Détail livraison</h1>
        <div className="px-4 py-3 bg-dimGrey mb-4">
          <h3 className="f20 bold text-blue">Adresse de livraison</h3>
          <div className="d-flex mt-4">
            {order?.delivery?.mode && (
              <p className="f16 text-brightGrey mb-0 w30">
                {order?.delivery?.mode}
              </p>
            )}
            <div className="w30 pe-3">
              {order?.delivery?.address?.street && (
                <p className="f15 text-brightGrey mb-0">
                  {order?.delivery?.address?.street}
                </p>
              )}
              {(order?.delivery?.address?.city?.data?.attributes?.name ||
                order?.delivery?.address?.zip) && (
                <p className="f14 text-brightGrey mb-0">
                  {(order?.delivery?.address?.city?.data?.attributes?.name ??
                    "") + " "}
                  {order?.delivery?.address?.zip ?? ""}
                </p>
              )}
              {order?.delivery?.address?.country?.data?.attributes?.name && (
                <p className="f14 text-brightGrey mb-0">
                  {order?.delivery?.address?.country?.data?.attributes?.name}
                </p>
              )}
            </div>

            <p className="f16 text-lightBlack mb-0 w30">{order?.phoneNumber}</p>
          </div>
          <div className="d-flex mt-4">
            <div className="w30">
              <p className="f15 sBold text-text-customBlack mb-0">
                N° de commande
              </p>
              <p className="f15 text-brightGrey mb-0">#{order?.slug}</p>
            </div>
            <div className="w30">
              <p className="f15 sBold text-text-customBlack mb-0">
                Livraison le
              </p>
              <p className="f15 text-brightGrey mb-0">
                {moment(order?.delivery?.date).format("DD/MM/YYYY")}
              </p>
            </div>
          </div>
        </div>
        <OrderProductsList orderData={order} forRightModal />
      </div>
      <div className="mt-5 d-flex justify-content-center">
        <Button variant="outline" className="me-3">
          Télécharger le bon de livraison
        </Button>
        <Button onClick={handleProblemReport}>Signaler un problème</Button>
      </div>
    </div>
  );
}

export default Livraison;
