import React, { useEffect, useState } from "react";
import AvailabilityVerifier from "../AvailabilityVerifier";
import { useDispatch } from "react-redux";
import {
  decrementQuantity,
  incrementQuantity,
  panierRemoveProduct,
} from "../../../REDUX/actions/panier";
import Select from "../../Common/FormElements/Select/Select";
import {
  cuveMapNames,
  jupeMapNames,
} from "../../../helpers/formLabels/cuveJupeOptions";
import TextInput from "../../Common/FormElements/TextInput/TextInput";
import adjustPrice from "../../../Utils/AdjustPrice";
import { getProductQuantity } from "../../../REDUX/actions/products";

function PanierItem({
  index,
  id,
  photo,
  replenishmentDate,
  availableQuantity,
  quantity,
  name,
  retailerPriceHT,
  showQuantity,
  panierID,
  productType,
  setSelectedPackings,
  setRefClientList,
  getInitialValues,
  panier,
  packings,
  productID,
  getPackingQuantity,
  getReplenishmentDate,
  getApprovQuantity,
}) {
  const [packingList, setPackingList] = useState();
  const [selectedPacking, setSelectedPacking] = useState();
  const [cuves, setCuves] = useState([]);
  const [panels, setPanels] = useState([]);
  const [formData, setFormData] = useState({});

  const dispatch = useDispatch();

  const addItem = () => {
    dispatch(
      incrementQuantity({
        panierItemID: id,
        oldQuantity: quantity,
        panierID: panierID,
      })
    );
  };

  const removeItem = () => {
    if (quantity > 1) {
      dispatch(
        decrementQuantity({
          panierItemID: id,
          oldQuantity: quantity,
          panierID: panierID,
        })
      );
    }
  };
  const handleDeleteItem = () => {
    if (productType !== 1) {
      dispatch(
        panierRemoveProduct({
          panierItemID: id,
          panierID: panierID,
        })
      );
    } else {
      setSelectedPacking();
      if (quantity > 1) {
        dispatch(
          decrementQuantity({
            panierItemID: id,
            oldQuantity: quantity,
            panierID: panierID,
          })
        );
      } else {
        dispatch(
          panierRemoveProduct({
            panierItemID: id,
            panierID: panierID,
          })
        );
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (!packingList?.length > 0) return;
    const list = packingList?.map((item) => ({
      name: item?.attributes?.cuve,
      code: item?.attributes?.cuve,
    }));
    setCuves(
      list?.filter(
        (item, index) =>
          !list?.find((v, i) => i < index && item?.code === v?.code)
      )
    );
  }, [packingList]);

  useEffect(() => {
    if (!packingList?.length > 0) return;
    const list = packingList
      ?.filter((item) => item?.attributes?.cuve === formData?.cuve?.name)
      .map((item) => {
        return {
          name: item?.attributes?.panel,
          code: item?.attributes?.panel,
        };
      });
    setPanels(
      list?.filter(
        (item, index) =>
          !list?.find((v, i) => i < index && item?.code === v?.code)
      )
    );
  }, [formData?.cuve?.name, packingList]);

  // useEffect(() => {
  //   setSelectedPacking(
  //     packingList?.find(
  //       (item) =>
  //         item?.attributes?.cuve === formData?.cuve?.name &&
  //         item?.attributes?.panel === formData?.panel?.name
  //     )
  //   );
  // }, [formData, packingList]);

  useEffect(() => {
    setPackingList(
      panier?.panierPackings?.filter((item) => {
        return (
          (+item?.id === +selectedPacking?.id ||
            Object.keys(packings)?.every(
              (key) => +packings[key][0]?.id !== +item?.id
            )) &&
          +item?.attributes?.model?.data?.id === +productID
        );
      })
    );
  }, [panier?.panierPackings, selectedPacking?.id, packings, productID]);

  useEffect(() => {
    setSelectedPackings((curr) => {
      const updatedList = [...curr];
      updatedList[index] = selectedPacking;
      return updatedList;
    });
    return () => {
      setSelectedPackings((curr) => {
        const updatedList = [...curr];
        updatedList[index] = undefined;
        return updatedList;
      });
    };
  }, [selectedPacking, index, setSelectedPackings]);

  useEffect(() => {
    const item = getInitialValues();

    setSelectedPacking(item);
    setFormData({
      cuve: { name: item?.attributes?.cuve, code: item?.attributes?.cuve },
      panel: { name: item?.attributes?.panel, code: item?.attributes?.panel },
      refClient: item?.refClient,
      serialNumber: item?.attributes?.serialNumber,
    });
  }, [getInitialValues, index]);

  useEffect(() => {
    setRefClientList((curr) => ({ ...curr, [id]: formData?.refClient }));
  }, [formData?.refClient, setRefClientList, id]);

  return (
    <div className="panierItem d-flex align-items-center justify-content-between my-3">
      {photo ? (
        <img
          src={photo}
          alt="produit"
          width={85}
          height={85}
          className="br5 me-3 panierItemImage"
        />
      ) : (
        <div className="w101px" />
      )}
      <div className="w95">
        <div className="d-flex w100 justify-content-between">
          <div className="w25">
            <p className="f15 bold text-customBlack mb-0">{name}</p>
            <AvailabilityVerifier
              availableQuantity={getPackingQuantity(selectedPacking)}
              replenishmentDate={
                selectedPacking?.attributes?.approvisionnement?.data?.attributes
                  ?.estimatedDate
                  ? selectedPacking?.attributes?.approvisionnement?.data
                      ?.attributes?.estimatedDate
                  : selectedPacking?.attributes?.approvisionnement?.data
                      ?.attributes?.arrivalDate
              }
              sold={selectedPacking?.attributes?.sold}
              totalUnarrived={getApprovQuantity(selectedPacking)}
              fromPanier={true}

              // fromProductDetails={true}
            />
            {/* <AvailabilityVerifier
              availableQuantity={getPackingQuantity(selectedPacking)}
              replenishmentDate={getReplenishmentDate(selectedPacking)}
            /> */}
          </div>

          <div className="w15 me-2">
            {productType === 1 && (
              <>
                <p className="f11 text-customBlack mb-0 text-center">Cuve</p>
                <Select
                  name="cuve"
                  itemsTemplate={cuveMapNames}
                  valueTemplate={cuveMapNames[formData?.cuve?.code]}
                  value={formData?.cuve}
                  options={cuves}
                  callback={handleChange}
                  placeholder={"Choisir"}
                  disabled
                />
              </>
            )}
          </div>
          <div className="w15 me-2">
            {productType === 1 && (
              <>
                <p className="f11 text-customBlack mb-0 text-center">Panneau</p>
                <Select
                  name="panel"
                  itemsTemplate={jupeMapNames}
                  valueTemplate={jupeMapNames[formData?.panel?.code]}
                  value={formData?.panel}
                  options={panels}
                  callback={handleChange}
                  placeholder={"Choisir"}
                  disabled
                />
              </>
            )}
          </div>
          {productType === 1 ? (
            <div className={`${productType !== 1 && "ms-2"} w15`}>
              <p className="f11 text-customBlack mb-0 text-center">
                Référence client
              </p>

              <TextInput
                name="refClient"
                className="w100"
                value={formData?.refClient}
                callback={handleChange}
                // onBlur={updateRefClient}
              />
            </div>
          ) : (
            <div className="ms-2 w15"></div>
          )}
          {!showQuantity ? (
            <div className="w15">
              <p className="f11 text-customBlack mb-0 text-center">
                Numéro de serie
              </p>
              <p className="f14 text-brightGrey mb-0 mt-2 text-center">
                {selectedPacking?.attributes?.serialNumber ?? "-"}
              </p>
            </div>
          ) : null}
          <div className="w15">
            <p className="f11 text-customBlack mb-0 text-center">Référence</p>
            <p className="f14 text-brightGrey mb-0 mt-2 text-center">
              {selectedPacking?.attributes?.reference ?? "-"}
            </p>
          </div>

          {showQuantity ? (
            <div className="w15 d-flex justify-content-center">
              {
                <i
                  className="bi bi-trash3 text-lameRed counterIcon me-3 pointer"
                  onClick={removeItem}
                />
              }
              <p className="f16">{quantity}</p>
              {/* <i
                className="bi bi-plus-lg counterIcon ms-2 pointer"
                onClick={addItem}
              ></i> */}
            </div>
          ) : null}
          <p className="mb-0 f16 sBold text-brightGrey w10 text-center">
            {adjustPrice((retailerPriceHT ?? 0).toFixed(2)?.toString())} €
          </p>
          <div
            className="trashIconContainer w15 br50 d-flex align-items-center justify-content-center pointer"
            role="button"
            onClick={handleDeleteItem}
          >
            <i className="bi bi-trash3 text-lameRed" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PanierItem;
