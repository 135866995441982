import React, { useState } from "react";
import TabsNavbar from "../../Common/TabsNavBar/TabsNavbar";
import LineChartHeader from "./LineChartHeader";
import CustomLineChart from "../../Common/CustomLineChart/CustomLineChart";
import "./Synteses.css";

const SyntheseRecettes = ({ stats }) => {
  const [activeTab, setActiveTab] = useState("CA Actuel");
  const [dateActiveTab, setDateActiveTab] = useState("Année");
  const currentYear = new Date().getFullYear();
  let years = [currentYear - 1, currentYear];

  const getCurrentStats = () => {
    switch (activeTab) {
      case "CA Actuel": {
        return stats?.totalTTCMap;
      }
      case "Marge net": {
        return stats?.margeMap;
      }
      case "Tx Marge": {
        return stats?.txMargeMap;
      }
      default: {
        return {};
      }
    }
  };

  return (
    <div className="syntheseRecettes bg-white br5 w60 shadow26 px-4 py-3 d-flex flex-column gap-3">
      <span className="text-blue bold f22 quickSand">Synthèse recettes</span>
      <TabsNavbar
        tabs={["CA Actuel", "Marge net", "Tx Marge"]}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        className="justify-content-between"
        tabClassName="f14 normal"
      />

      <LineChartHeader
        years={years}
        colors={["yellowOrange", "aquaGreen"]}
        setDateActiveTab={setDateActiveTab}
        dateActiveTab={dateActiveTab}
      />
      <CustomLineChart
        years={years}
        colors={["#FCB03E", "#1AD5AF"]}
        data={getCurrentStats()}
        unity={activeTab === "Tx Marge" ? "%" : "k"}
      />
    </div>
  );
};

export default SyntheseRecettes;
