import React, { useEffect } from "react";
import CalendarSlot from "./CalendarSlot/CalendarSlot";
import { useDispatch, useSelector } from "react-redux";
import getCalendarDays from "../../../../Utils/getCalendarDays";

import "./CalendarContent.css";
import { getEvents } from "../../../../REDUX/actions/events";
import { getOrders } from "../../../../REDUX/actions/orders";

function CalendarContent({ selectedOption }) {
  const date = useSelector((state) => state.calendarReducer.date);
  let dates = getCalendarDays(date);
  let indexOfOne = dates.findIndex(({ day }) => day === 1);
  const { events } = useSelector((state) => state.eventsReducer);
  const { orders } = useSelector((state) => state.ordersReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getEvents());
    // dispatch(getOrders({}));
  }, [dispatch]);

  return (
    <div className="calendarContent d-flex flex-wrap">
      {dates.map(({ date, day }, index) => {
        return (
          <CalendarSlot
            key={index}
            date={date}
            day={day}
            index={index}
            indexOfOne={indexOfOne}
            events={events?.data}
            orders={orders}
            selectedOption={selectedOption}
          />
        );
      })}
    </div>
  );
}

export default CalendarContent;
