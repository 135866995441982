export const revendeurInitialValues = {
  status: null,
  problem: null,
  refClient: null,
};

export const adminInitialValues = {
  createdAt: null,
  relaunch: null,
  revendeur: null,
  responsable: null,
  problem: null,
  intervention: false,
  sinceDate: null,
  untilDate: null,
};
