import React from "react";
import Button from "../Common/FormElements/Button/Button";
import CheckBox from "../Common/FormElements/CheckBox/CheckBox";
import Select from "../Common/FormElements/Select/Select";
import TextArea from "../Common/FormElements/TextArea/TextArea";
import TextInput from "../Common/FormElements/TextInput/TextInput";

const AskForQuote = () => {
  const formLabels = [
    [
      { id: 1, label: "Nom *", placeholder: "Nom" },
      { id: 2, label: "Prénom *", placeholder: "Prénom" },
      { id: 3, label: "Code postal *", placeholder: "Code postal" },
    ],
    [
      { id: 4, label: "E-mail *", placeholder: "mail@mail.com" },
      { id: 5, label: "Téléphone *", placeholder: "Téléphone" },
      {
        id: 6,
        label: "Période d’achat",
        placeholder: "Immédiatement",
        options: [
          { name: "Immédiatement", code: "Immédiatement" },
          // { name: "Option 1", code: "Option 1" },
          // { name: "Option 2", code: "Option 2" },
        ],
      },
    ],
    [
      { id: 7, label: "Adresse", placeholder: "Adresse" },
      { id: 8, label: "Ville", placeholder: "Ville" },
    ],
    [
      {
        id: 9,
        label: "Catégorie de Produit",
        placeholder: "Catégorie de Produit",
        options: [
          { name: "Spa", code: "Spa" },
          // { name: "Option 1", code: "Option 1" },
          // { name: "Option 2", code: "Option 2" },
        ],
      },
      {
        id: 10,
        label: "Modèle",
        placeholder: "Modèle",
        options: [
          { name: "Spa Lena", code: "Spa Lena" },
          // { name: "Option 1", code: "Option 1" },
          // { name: "Option 2", code: "Option 2" },
        ],
      },
    ],
  ];

  return (
    <div className="d-flex flex-column">
      <span className="sBold f23 text-lightBlack mb-4 px-2">
        Demande de devis
      </span>
      <div className="mt-4">
        {formLabels?.map((formRow, index) => (
          <div className="row m-0 mb-3" key={index}>
            {formRow?.map(({ id, label, placeholder, options }) => (
              <div
                className={`${
                  id === 7
                    ? "col-8"
                    : id === 8
                    ? "col-4"
                    : id === 9 || id === 10
                    ? "col-6"
                    : "col-4"
                } colAlignC p-0 px-2 align-items-start gap-2`}
                key={id}
              >
                <span className="text-softBlack f16 normal">{label}</span>
                {options ? (
                  <Select
                    options={options}
                    defaultValue={{
                      name: placeholder,
                      code: placeholder,
                    }}
                  />
                ) : (
                  <TextInput defaultValue={placeholder} width={"100%"} />
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="colAlignC align-items-start gap-2 px-2">
        <span className="text-softBlack f16 normal">Message</span>
        <TextArea
          className={"w-100 b-2-softGrey"}
          rows={4}
          placeholder={"Votre message"}
        />
      </div>
      <CheckBox
        text={
          "En soumettant ce formulaire, j'accepte que les informations saisies soient exploitées dans le cadre de la demande de devis."
        }
        textClassName="text-softBlack f12 normal"
        className="mt-3 px-2"
      />
      <div style={{ width: "250px", alignSelf: "center" }}>
        <Button className=" w-100 mt-5" width={250}>
          Envoyer
        </Button>
      </div>
    </div>
  );
};

export default AskForQuote;
