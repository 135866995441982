import { API_END_POINT } from "../../Api/EndPoints";
import API from "../../Api/Api";
import NEW_API from "../../Api/NewApi";
import QueryString from "qs";

export const getCountries = () => async (dispatch) => {
  dispatch({ type: "FETCH_COUNTRIES_LOADING" });
  try {
    const { data } = await API.get(
      `${API_END_POINT}api/countries?populate=cities`
    );
    const payload = data?.data?.map((item) => ({
      name: item?.attributes?.name,
      code: item?.id,
      cities: item?.attributes?.cities?.data?.map((city) => ({
        name: city?.attributes?.name,
        code: city?.id,
      })),
    }));

    dispatch({ type: "FETCH_COUNTRIES_SUCCESS", payload });
  } catch (error) {
    dispatch({ type: "FETCH_COUNTRIES_ERROR" });
  }
};
export const getCountriesNew = () => async (dispatch) => {
  try {
    const { data } = await NEW_API.get(`api/countries`);
    const payload = data?.data?.map((item) => ({
      name: item?.attributes?.name,
      code: item?.id,
    }));

    dispatch({ type: "FETCH_COUNTRIES_SUCCESS_NEW", payload });
  } catch (error) {
    dispatch({ type: "FETCH_COUNTRIES_ERROR" });
  }
};

export const getCountriesCitiesNew =
  (countryId, search) => async (dispatch) => {
    const query = QueryString.stringify({
      filters: {
        country: { id: { $eq: countryId } },
        name: { $containsi: search },
      },
      pagination: {
        pageSize: 10,
      },
    });
    dispatch({ type: "CITY_LOADING" });
    try {
      const { data } = await NEW_API.get(`api/cities?${query}`);
      // console.log(data);
      dispatch({
        type: "CITY_SUCCESS",
        payload: data.data.map((city) => {
          return {
            name: city.attributes?.name,
            code: city?.id,
            cityCode:
              city?.attributes?.code ||
              city?.attributes?.zipCode?.substring(0, 2),
            zipCode: city?.attributes?.zipCode,
          };
        }),
      });
    } catch (error) {
      dispatch({ type: "FETCH_COUNTRIES_ERROR" });
    }
  };
