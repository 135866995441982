import React from "react";
import ProductAttachment from "./ProductAttachment";
import { IMAGE_END_POINT } from "../../../Api/EndPoints";

function ProductAttachments({ documents }) {
  if (!documents?.length > 0) return null;
  return (
    <div className="px-3 mt-5">
      <h2 className="quickSand f30 text-blue bold mb-3">Tous les détails</h2>
      <div className="row">
        {documents?.map((item) => (
          <div key={item?.id} className="col-lg-4 col-xl-3 mt-3">
            <ProductAttachment
              name={item?.attributes?.title}
              icon={<i className="bi bi-file-earmark-text f34 text-white" />}
              link={`${IMAGE_END_POINT}${item?.attributes?.file?.data?.attributes?.url}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProductAttachments;
