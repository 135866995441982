import { MultiSelect } from "primereact/multiselect";

export default function CustomMultiSelect({
  name,
  value,
  callback,
  filter,
  options,
  placeholder = "",
  className = "",
}) {
  const handleChange = (e) => {
    callback && callback(e);
  };

  return (
    <MultiSelect
      appendTo={"self"}
      name={name}
      filter={filter}
      value={value}
      onChange={handleChange}
      options={options}
      placeholder={placeholder}
      emptyFilterMessage={"Aucun résultat trouvé"}
      className={className}
    />
  );
}
