import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useSelector } from "react-redux";
import { IMAGE_END_POINT } from "../../../Api/EndPoints";
import Thumbnail from "../../Common/Thumbnail/Thumbnail";
import avatarMenu from "../../../Images/Images/avatar.png";
import ProfileDropdown from "./ProfileDropdown";

const MenuAvatar = () => {
  const [isShowProfile, setIsShowProfile] = useState(false);
  const { user } = useSelector((state) => state.userReducer);
  const imageUrl = user?.attributes?.photo?.data?.attributes?.url
    ? `${IMAGE_END_POINT}${user.attributes.photo.data.attributes.url}`
    : avatarMenu;

  return (
    <div>
      <OutsideClickHandler
        onOutsideClick={() => {
          setIsShowProfile(false);
        }}
      >
        <div
          className="alignH gap-2 me-3 pointer "
          onClick={() => setIsShowProfile(!isShowProfile)}
        >
          <Thumbnail image={imageUrl} width={50} height={50} status="online" />
          <div className="colAlignC align-items-start ms-2">
            <span className="sBold f14 text-white">
              {user?.attributes?.firstName} {user?.attributes?.lastName}
            </span>
            <span className="normal f12 text-white">
              {user?.attributes?.occupation}
            </span>
          </div>
        </div>
        {isShowProfile && (
          <ProfileDropdown setIsShowProfile={setIsShowProfile} />
        )}
      </OutsideClickHandler>
    </div>
  );
};

export default MenuAvatar;
