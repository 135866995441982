import React from "react";
import { useDispatch } from "react-redux";
import Button from "../../../../Common/FormElements/Button/Button";
import Livraison from "../../../Modals/Livraison/Livraison";
import { openRightModal } from "../../../../../REDUX/actions/rightModal";

const LivraisonsDropdown = () => {
  const livraisonsData = [
    { id: 1, title: "Spa Pégase", price: 11890 },
    { id: 2, title: "Spa Pégase", price: 11890 },
  ];

  const dispatch = useDispatch();

  const getTotalPrices = (data) => {
    return data?.reduce((total, { price }) => total + price, 0);
  };

  return (
    <div className="bg-white br5 py-4 calendarEventDrop position-absolute colAlignC align-items-start gap-2 w100">
      <span className="text-lightBlack f12 normal px-4 ">#141147</span>
      <span className="text-softBlue f14 sBold  px-4">Nom du magasin</span>
      <span className="text-brightGrey f12 normal  px-4">
        Ref client : Jean Alfred
      </span>
      <div className="w100">
        {livraisonsData?.map(({ id, title, price }) => (
          <div
            className="py-2 mx-2 px-3 bg-dimGrey d-flex justify-content-between my-2 "
            key={id}
          >
            <span className="normal f12 text-lightBlack ">{title}</span>
            <span className="normal f12 text-lightBlack ">{`${price} €`}</span>
          </div>
        ))}
        <div className="py-2 px-4 d-flex justify-content-between mt-2 ">
          <span className="sBold f14 text-lightBlack ">Total</span>
          <span className="normal f12 text-lightBlack ">{`${getTotalPrices(
            livraisonsData
          )} €`}</span>
        </div>
      </div>
      <div className="px-4 w100">
        <Button
          className="bg-softBlue w100"
          onClick={() => dispatch(openRightModal(<Livraison />))}
        >
          Consulter
        </Button>
      </div>
    </div>
  );
};

export default LivraisonsDropdown;
