import React from "react";
import { v4 as uuidv4 } from "uuid";

import "./AddAttachment.css";

function AddAttachment({
  attachments,
  setAttachments,
  fromNewProduct,
  fromGuide,
}) {
  const handleClick = () => {
    setAttachments([...attachments, { id: uuidv4(), title: "", date: "" }]);
  };

  return (
    <button
      onClick={handleClick}
      className="addAttachment ms-3 mt-3 f12 text-softBlue border-0 bg-transparent underline"
    >
      {fromNewProduct
        ? "+ Ajouter une nouvelle photo ou vidéo"
        : fromGuide
        ? "+ Ajouter un nouveau fichier"
        : "+ Ajouter un nouveau document"}
    </button>
  );
}

export default AddAttachment;
