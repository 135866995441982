import React from "react";
import "./ListDeleteIcon.css";

function ListDeleteIcon({ callback, disabled, withoutMargin }) {
  return (
    <button
      className={`br50 p-1 border-0 bg-transparent ${
        withoutMargin ? "ms-0" : "ms-auto"
      } d-flex justify-content-center align-items-center listDeleteIcon`}
      onClick={callback}
    >
      <i className="bi bi-x-lg text-brightRed f18" />
    </button>
  );
}

export default ListDeleteIcon;
