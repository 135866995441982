import React, { useState } from "react";
import TextInput from "../../FormElements/TextInput/TextInput";
import "./PasswordInput.css";

function PassswordInput({
  text,
  className = "",
  withoutPlaceHolder,
  callback,
  name,
  value,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const eyeClass = showPassword ? "eye-slash" : "eye";
  const passwordFieldType = showPassword ? "text" : "password";
  const placeholder = withoutPlaceHolder ? "" : text;

  return (
    <div className={`passwordInput mb-3 position-relative ${className}`}>
      <p className="f16 text-ultraBlack mb-2">{text}</p>
      <TextInput
        placeholder={placeholder}
        type={passwordFieldType}
        callback={callback}
        autoComplete="current-password"
        name={name}
        value={value}
      />
      <i
        className={`bi bi-${eyeClass} f20 d-flex justify-content-center align-items-center passwordEye position-absolute pointer text-blueGrey`}
        onClick={() => setShowPassword(!showPassword)}
      />
    </div>
  );
}

export default PassswordInput;
