import React from "react";

function AttachmentInput({ name, handleChange }) {
  const handleFileChange = (e) => {
    if (e.target.files?.length > 0) {
      const files = e.target.files;
      const event = {
        target: { name, value: [...files] },
      };
      handleChange(event);
      e.target.value = [];
    }
  };
  return (
    <label className="attachmentInput pointer br50 d-flex justify-content-center align-items-center">
      <i className="attachmentInputIcon bi bi-paperclip text-softBlue f27 d-flex justify-content-center align-items-center" />
      <input
        name={name}
        hidden
        multiple
        accept="/*"
        type="file"
        onChange={handleFileChange}
      />
    </label>
  );
}

export default AttachmentInput;
