import React, { useState } from "react";
import Attachements from "../Common/Forms/Attachements/Attachements";
import { attachmentsListData } from "../../helpers/fakeData/Sav/attachments";
import { documentExtensions } from "../Produits/NewProductTabContents/data";

const NewGuideFichiers = ({ attachments, setAttachments = () => {} }) => {
  return (
    <div className="pt-3">
      <Attachements
        attachments={attachments}
        setAttachments={setAttachments}
        fromGuide
        acceptTypes={`image/*,video/*, ${documentExtensions.join(",")}`}
      />
    </div>
  );
};

export default NewGuideFichiers;
