const initialState = {
  organizations: [],
  loading: false,
  error: null,
};

const organizationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_ORGANIZATIONS_LOADING":
      return { ...state, loading: true, error: null };
    case "FETCH_ORGANIZATIONS_SUCCESS":
      return { ...state, loading: false, organizations: payload, error: null };
    case "FETCH_ORGANIZATIONS_ERROR":
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};

export default organizationReducer;
