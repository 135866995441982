import React, { useState } from "react";
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";
import {
  days,
  shortDays,
  months,
  shortMonths,
} from "../../../../helpers/fakeData/Calendar/Dates";
import moment from "moment";
import ArrowIcon from "../../icons/ArrowIcon";
import "./CalendarInput.css";

function CalendarInput({
  placeholder,
  width = "",
  className = "",
  customInputStyle,
  calendarIconSize,
  withMinDate = true,
  name,
  callback,
  value,
  view = "date",
  timeOnly = false,
  minDate = true,
  dateFormat = "dd/mm/yy",
  disabled,
}) {
  const [date, setDate] = useState(undefined);

  const handleChange = (e) => {
    setDate(e.value);
    callback && callback(e);
  };
  addLocale("fr", {
    dayNames: days,
    dayNamesShort: shortDays,
    dayNamesMin: shortDays,
    monthNames: months,
    monthNamesShort: shortMonths,
    firstDayOfWeek: 1,
  });
  const minDateProp = withMinDate && moment().toDate();

  return (
    <Calendar
      value={value ? value : date}
      onChange={handleChange}
      onSelect={(e) => setDate(e.value)}
      showIcon
      placeholder={placeholder}
      style={{
        width: width,
        "--calendarIconSize": calendarIconSize ? calendarIconSize : "20px",
      }}
      className={`calendarInput ${className}`}
      icon={
        timeOnly ? (
          <ArrowIcon />
        ) : (
          <i className="bi bi-calendar3 calendarInputIcon" />
        )
      }
      dateFormat={dateFormat}
      appendTo="self"
      locale="fr"
      minDate={minDate && minDateProp}
      inputStyle={customInputStyle ? customInputStyle : { fontSize: "16px" }}
      name={name}
      view={view}
      timeOnly={timeOnly}
      disabled={disabled}
    />
  );
}

export default CalendarInput;
