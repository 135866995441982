import API from "../../Api/Api";
import { API_END_POINT } from "../../Api/EndPoints";

export const getStats = () => async (dispatch) => {
  try {
    const currentYear = new Date().getFullYear();
    dispatch({ type: "STATS_LOADING" });
    const { data } = await API.get(
      `${API_END_POINT}api/product-stats?startYear=2023&endYear=${currentYear}`
    );
    dispatch({ type: "STATS_SUCCESS", payload: data });
  } catch (error) {
    dispatch({ type: "STATS_ERROR" });
  }
};
