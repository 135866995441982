const getProductsWithSerial = (product) => {
  let productsList = [];
  if (product?.quantity > 1) {
    for (let i = 0; i < product?.quantity; i++) {
      productsList.push({
        ...product,
        // serialNumber: (Math.random() * 100000000).toFixed(0),
        // reference: `${(Math.random() * 1000).toFixed(0)}G${(
        //   Math.random() * 10000
        // ).toFixed(0)}`,
      });
    }
  } else {
    productsList.push({
      ...product,
      // serialNumber: (Math.random() * 100000000).toFixed(0),
      // reference: `${(Math.random() * 1000).toFixed(0)}G${(
      //   Math.random() * 10000
      // ).toFixed(0)}`,
    });
  }
  return productsList;
};

export default getProductsWithSerial;
