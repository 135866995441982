import React, { useEffect, useState } from "react";
import SwitchButton from "../../../Common/FormElements/SwitchButton/SwitchButton";
import NewSAVCommande from "../../NewTicketSav/NewSAVCommande";
import SavPreviewInfos from "../../SavPreview/SavPreviewInfos";
import Select from "../../../Common/FormElements/Select/Select";
import { useDispatch, useSelector } from "react-redux";
import { getResponsables } from "../../../../REDUX/actions/user";
import ShadowLoader from "../../../../Utils/ViewWrapper/ShadowLoader/ShadowLoader";
import CalendarInput from "../../../Common/FormElements/CalendarInput/CalendarInput";
import { interventionSelectValues } from "./getInterventionType";
import moment from "moment";

const UpdateSAVInfos = ({ formData, setFormData }) => {
  const dispatch = useDispatch();
  const [showIntervention, setShowIntervention] = useState(false);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleInterventionChange = (e) => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      intervention: { ...formData.intervention, [name]: value },
    });
  };
  const { responsables, user } = useSelector((state) => state.userReducer);

  const getResponsablesOptions = () => {
    return responsables.map((responsable) => {
      return {
        name: responsable.firstName + " " + responsable.lastName,
        code: responsable.id,
      };
    });
  };

  useEffect(() => {
    dispatch(getResponsables());
  }, [dispatch]);

  useEffect(() => {
    if (formData.intervention) {
      setShowIntervention(true);
    }
  }, [formData]);

  return (
    <div className="updateTicketSAVinfo px-5 pt-5 d-flex flex-column">
      {/* First Form */}
      {/* <ShadowLoader loading={responsablesLoading} error={responsablesError}> */}
      <SavPreviewInfos />
      {user?.attributes?.userRole === 1 && (
        <>
          <hr
            style={{ borderColor: "#abb8b2", margin: 0 }}
            width={"100%"}
            className="mb-4"
          />
          <div className="d-flex gap-4">
            <div className="w30">
              <p className="f16 bold text-ultraBlack mb-2">Responsable:</p>
              <Select
                options={getResponsablesOptions()}
                name="responsable"
                callback={handleChange}
                value={formData.responsable}
              />
            </div>
            <div className="d-flex flex-column">
              <span className="f16 bold text-ultraBlack mb-2">
                Date de relance
              </span>
              <div className="w100 d-flex flex-column gap-2">
                <CalendarInput
                  name="relaunch"
                  callback={handleChange}
                  value={
                    formData?.relaunch
                      ? moment(formData?.relaunch).utc(true).toDate()
                      : null
                  }
                  minDate={false}
                />
              </div>
            </div>
          </div>
        </>
      )}
      {(user?.attributes?.userRole === 2 ||
        user?.attributes?.userRole === 1) && (
        <>
          <hr
            style={{ borderColor: "#abb8b2", margin: 0 }}
            width={"100%"}
            className="mt-5"
          />

          {/* </ShadowLoader> */}
          {/* Last Form */}
          <div className="d-flex flex-column gap-4 w100 mt-4">
            <div className="alignH">
              <span
                className="bold text-ultraBlack f16"
                style={{ marginRight: "20%" }}
              >
                Intervention
              </span>
              <SwitchButton
                isActive={showIntervention}
                onClick={() => setShowIntervention(!showIntervention)}
                small
              />
            </div>

            {showIntervention ? (
              <div className="alignH w100 gap-4 mb-5">
                <div className="w100 d-flex flex-column gap-2">
                  <span className="text-softBlack normal f16">
                    Type d'intervention
                  </span>
                  <div className="w100 d-flex flex-column gap-2">
                    <Select
                      placeholder="Type d'intervention"
                      options={interventionSelectValues}
                      name="type"
                      callback={handleInterventionChange}
                      value={
                        formData?.intervention?.type
                          ? formData?.intervention?.type
                          : null
                      }
                    />
                  </div>
                </div>
                <div className="w100 d-flex flex-column gap-2">
                  <span className="text-softBlack normal f16">
                    Date de l'intervention
                  </span>
                  <div className="w100 d-flex flex-column gap-2">
                    <CalendarInput
                      name="date"
                      callback={handleInterventionChange}
                      value={
                        formData?.intervention?.date
                          ? moment(formData?.intervention?.date)
                              .utc(true)
                              .toDate()
                          : null
                      }
                    />
                  </div>
                </div>

                {/* {formLabelsSecondtPart?.map(
              ({ label, type, placeholder }, index) => (
                <div className="w100 d-flex flex-column gap-2" key={index}>
                  <span className="text-softBlack normal f16">{label}</span>
                  {getFormEltType(label, type, undefined, placeholder)}
              )
            )} */}
              </div>
            ) : null}
          </div>
          <hr
            style={{ borderColor: "#abb8b2", margin: 0 }}
            width={"100%"}
            className="mt-5"
          />
        </>
      )}
      {formData?.commandes_from_sav?.data?.length > 0 && (
        <div className="my-4">
          <p className="f16 sBold text-softBlack mb-0">Commande SAV</p>
          <NewSAVCommande
            savOrders={formData?.commandes_from_sav?.data}
            problem={formData?.problem}
          />
        </div>
      )}
    </div>
  );
};

export default UpdateSAVInfos;
