import React, { useEffect, useState } from "react";
// import { Carousel } from "react-bootstrap";
import { eventPictures } from "../../../../helpers/fakeData/Calendar/eventPictures";
// import CarouselArrow from "./CarouselArrow";
import { useDispatch, useSelector } from "react-redux";
import "./Evenement.css";
import { deleteEvent, getSingleEvent } from "../../../../REDUX/actions/events";
import LoaderWrapper from "../../../../Utils/ViewWrapper/LoaderWrapper/LoaderWrapper";
import Button from "../../../Common/FormElements/Button/Button";
import DeleteBtn from "../../../Common/FormElements/DeleteBtn";
import EventDetails from "./EventDetails";
import UpdateEvent from "./UpdateEvent";
import { openCenteredModal } from "../../../../REDUX/actions/centeredModal";
import DeleteGuide from "../../../Faq/DeleteGuide";
import { eventTypeFr } from "../../../../Utils/fileUtils";
import ConfirmModal from "../../../Common/Modal/ConfirmModal";

function Evenement({ modalTitle = "événement", id }) {
  // consts
  const dispatch = useDispatch();
  const { event, eventLoading } = useSelector((state) => state.eventsReducer);
  const { user } = useSelector((state) => state.userReducer);
  useEffect(() => {
    dispatch(getSingleEvent(id));
  }, [dispatch, id]);
  /* ******************************** callBacks ******************************* */
  const handleDelete = (action, message, id) => {
    dispatch(
      openCenteredModal(
        <ConfirmModal onConfirm={action} label={message} id={id} />,
      ),
    );
  };
  const [showUpdateEvent, setShowUpdateEvent] = useState(false);

  /* ******************************** Rendering ******************************* */
  return (
    <LoaderWrapper loading={eventLoading}>
      <div className="evenementModal ">
        <h1 className="quickSand rightModalTitle mb-5 ps-5">
          Détail {modalTitle}
        </h1>
        {!showUpdateEvent ? (
          <EventDetails event={event} />
        ) : (
          <UpdateEvent event={event} eventId={id} />
        )}

        {[1, 2]?.includes(user?.attributes?.userRole) && !showUpdateEvent && (
          <div className="d-flex mt-3 gap-2 ps-5">
            <Button onClick={() => setShowUpdateEvent(true)}>Modifier</Button>
            <DeleteBtn
              onClick={() =>
                handleDelete(
                  () =>
                    dispatch(
                      deleteEvent(id, event?.data?.attributes?.eventType),
                    ),
                  `${eventTypeFr[event?.data?.attributes?.eventType]}`,
                  id,
                )
              }
            ></DeleteBtn>
          </div>
        )}
      </div>
    </LoaderWrapper>
  );
}

export default Evenement;
