import { toast } from "react-toastify";
import API from "../../Api/Api";
import { API_END_POINT } from "../../Api/EndPoints";
import moment from "moment";
import { prospectsQuery } from "../apiQueries/prospects";
import { prospectsExtractor } from "../../Utils/DataExtractors/prospectsExtractor";
import { closeRightModal } from "./rightModal";
import { deleteRecord } from "../../Utils/api";

export const createProspect = (payload) => async (dispatch, getState) => {
  dispatch({ type: "PROSPECTS_LOADING" });
  const { user } = getState().userReducer;

  const reseller =
    user?.attributes?.profil?.data?.attributes?.reseller?.data?.id;

  try {
    const prospectData = {
      ...payload,
      firstContactDate: moment(payload?.firstContactDate).format("YYYY-MM-DD"),
    };
    delete prospectData.callback;

    const { data: createdAddress } = await API.post("api/addresses", {
      data: {
        street: prospectData.adresse,
        country: prospectData?.country?.code,
        city: prospectData?.city?.code,
      },
    });
    delete prospectData.adresse;
    delete prospectData.city;
    delete prospectData.country;
    const {
      data: { data },
    } = await API.post(`api/prospects`, {
      data: { ...prospectData, address: createdAddress.data.id, reseller },
    });

    await API.post(`${API_END_POINT}api/comments?populate=*`, {
      data: {
        text: payload?.comment,
        prospect: data?.id,
        user: user?.id,
      },
    });

    dispatch({ type: "PROSPECT_CREATED" });
    toast.success("Prospect créé avec succès!");
    dispatch(getProspects({}));
    dispatch(closeRightModal());
  } catch (error) {
    console.log(error);
    dispatch({ type: "PROSPECTS_ERROR", payload: error });
    toast.error("une erreur est survenue!");
  }
};
export const getProspects =
  (payload = {}) =>
  async (dispatch, getState) => {
    dispatch({ type: "PROSPECTS_LOADING" });
    try {
      const { user } = getState().userReducer;
      const resellerId =
        user?.attributes?.profil?.data?.attributes?.reseller?.data?.id;
      const { searchInput } = payload;
      const urlFilters = payload.searchInput
        ? `filters[$or][0][firstName][$containsi]=${searchInput}&filters[$or][1][lastName][$containsi]=${searchInput}&filters[$or][2][refClient][$containsi]=${searchInput}&`
        : "";
      const fetchUrl = `${API_END_POINT}api/prospects?${urlFilters}${prospectsQuery()}&sort[0]=createdAt:desc`;

      const { data: allProspects } = await API.get(fetchUrl, {
        params: {
          filters: {
            reseller: { id: { $eq: resellerId } },
          },
        },
      });
      const prospects = prospectsExtractor(allProspects.data);
      dispatch({
        type: "PROSPECTS_FETCHED",
        payload: resellerId ? prospects : [],
      });
    } catch (error) {
      console.log(error);
      dispatch({ type: "PROSPECTS_ERROR", payload: error });
      toast.error("une erreur est survenue!");
    }
  };

export const prospectsReload = () => async (dispatch) => {
  dispatch({ type: "PROSPECTS_RELOAD" });
};
export const getOneProspect = (payload) => async (dispatch) => {
  dispatch({ type: "PROSPECT_LOADING" });
  try {
    const fetchUrl = `api/prospects/${payload}?${prospectsQuery()}`;
    const { data: fetchedProspect } = await API.get(fetchUrl);
    dispatch({ type: "PROSPECT_FETCHED", payload: fetchedProspect.data });
  } catch (error) {
    console.log(error);
    dispatch({ type: "PROSPECT_ERROR", payload: error });
    toast.error("An error occurred while fetching prospect details.");
  }
};

export const addComment = (payload) => async (dispatch, getState) => {
  try {
    const { user } = getState().userReducer;
    await API.post(`${API_END_POINT}api/comments?populate=*`, {
      data: {
        text: payload?.comment,
        prospect: payload?.id,
        user: user?.id,
      },
    });
    dispatch(getOneProspect(payload?.id));
  } catch (err) {
    console.log(err);
    toast.error("An error occurred while fetching prospect details.");
  }
};

export const deleteProspect = (payload) => async (dispatch) => {
  dispatch({ type: "PROSPECT_LOADING" });
  try {
    await API.delete(`${API_END_POINT}api/prospects/${payload?.id}`);
    if (payload?.comments?.length > 0) {
      for (const comment of payload?.comments) {
        await deleteRecord(comment?.id, "comments");
      }
    }
    dispatch({ type: "PROSPECT_DELETED" });
    dispatch(getProspects());
  } catch (error) {
    console.log(error);
    dispatch({ type: "PROSPECT_ERROR", payload: error });
  }
};
