import React, { useState } from "react";
import { useSelector } from "react-redux";
import FacturesView from "./FacturesView";
import TabsNavbar from "../Components/Common/TabsNavBar/TabsNavbar";
import FacturesViewTabsContent from "../Components/Factures/FacturesAdmin/FacturesViewTabsContent";

const MainFacturesView = () => {
  const [activeTab, setActiveTab] = useState("Dépenses");
  const { userRole } = useSelector((state) => state.userReducer);
  const isAdmin = userRole === "admin";
  return (
    <div className="w100 d-flex flex-column gap-4 h-100">
      {!isAdmin ? (
        <FacturesView />
      ) : (
        <>
          <TabsNavbar
            tabs={["Dépenses", "Factures"]}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            className="gap-5"
          />
          <FacturesViewTabsContent activeTab={activeTab} />
        </>
      )}
    </div>
  );
};

export default MainFacturesView;
