/* -------------------------------------------------------------------------- */
/*                                dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import moment from "moment";
import React, { useEffect, useState } from "react";
// local UI component :
import Button from "../../../Common/FormElements/Button/Button";
import CalendarInput from "../../../Common/FormElements/CalendarInput/CalendarInput";
import TextArea from "../../../Common/FormElements/TextArea/TextArea";
import TextInput from "../../../Common/FormElements/TextInput/TextInput";
import UploadButton from "../../../Common/FormElements/UploadButton/UploadButton";
// utils
import { formatTimeStringToDate } from "../../../../Utils/fileUtils";
// redux
import { useDispatch, useSelector } from "react-redux";
import { updateEvent } from "../../../../REDUX/actions/events";
import { closeRightModal } from "../../../../REDUX/actions/rightModal";
import { getOrganizations } from "../../../../REDUX/actions/organziation";
import CustomMultiSelect from "../../../Common/FormElements/MultiSelect";
/* -------------------------------------------------------------------------- */
/*                             Update-FC-Component                            */
/* -------------------------------------------------------------------------- */
const UpdateEvent = ({ event, eventId }) => {
  const { user } = useSelector((state) => state.userReducer);
  const { organizations } = useSelector((state) => state.organizationReducer);
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const { eventLoading } = useSelector((state) => state.eventsReducer);
  const eventData = event?.data?.attributes;
  const [eventFormData, setEventFormData] = useState({
    ...eventData,
    endTime: formatTimeStringToDate(eventData?.endTime),
    startTime: formatTimeStringToDate(eventData?.startTime),
  });
  const dispatch = useDispatch();
  const handleChange = (field, value) => {
    setEventFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };
  // handle change => calendar / time :
  const handleCalendarInput = (e, name) => {
    const { value } = e;
    let formattedValue = value;
    setEventFormData((prevFormData) => ({
      ...prevFormData,
      [name]: formattedValue,
    }));
  };

  const handleImageChange = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      setEventFormData((prevFormData) => ({
        ...prevFormData,
        newPhoto: imageFile,
      }));
    }
  };
  // data to update
  const updatedEvent = {
    ...eventFormData,
    endTime: moment(eventFormData.endTime).format("HH:mm:ss.SSS"),
    startTime: moment(eventFormData.startTime).format("HH:mm:ss.SSS"),
    startDate: moment(eventFormData.startDate)?.utc(true).toDate(),
    endDate: moment(eventFormData.endDate)?.utc(true).toDate(),
  };

  const handleSubmit = () => {
    dispatch(updateEvent({ updatedEvent, eventId }));
  };

  useEffect(() => {
    let extraFilters = {};
    if (user?.attributes?.userRole === 2) {
      extraFilters = {
        business_affiliate: {
          id: { $eq: user?.attributes?.business_affiliate?.data?.id },
        },
      };
    }
    dispatch(getOrganizations(extraFilters));
  }, [
    dispatch,
    user?.attributes?.userRole,
    user?.attributes?.business_affiliate?.data?.id,
  ]);

  useEffect(() => {
    setOrganizationOptions(
      organizations?.map((item) => ({ label: item?.name, value: item?.code }))
    );
  }, [organizations]);

  useEffect(() => {
    setEventFormData((state) => ({
      ...state,
      resellers: event?.data?.attributes?.resellers?.data?.map(
        (item) => item?.id
      ),
    }));
  }, [event]);
  /* ******************************** rendering ******************************* */
  return (
    <div className="evenementModal px-5 d-flex flex-column justify-content-between h100 ">
      <div>
        <div className="d-flex flex-column gap-4">
          <div className="d-flex flex-column gap-2">
            <span className="text-softBlack normal f16">Revendeur</span>
            <CustomMultiSelect
              filter
              name="resellers"
              placeholder=""
              value={
                Array.isArray(eventFormData?.resellers)
                  ? eventFormData.resellers
                  : []
              }
              options={organizationOptions}
              callback={(e) => handleChange("resellers", e.target.value)}
            />
          </div>
          <div className="d-flex flex-column gap-2">
            <span className="text-softBlack normal f16">Titre</span>
            <TextInput
              className="w100"
              value={eventFormData?.title || ""}
              callback={(e) => handleChange("title", e.target.value)}
            />
          </div>
          <div className="w100 alignH gap-4">
            <div className="w50 d-flex flex-column gap-2">
              <span className="text-softBlack normal f16">
                Date de début de l'événement
              </span>
              <CalendarInput
                className="w100"
                value={
                  moment(eventFormData?.startDate).utc(true).toDate() || null
                }
                callback={(event) => handleCalendarInput(event, "startDate")}
              />
            </div>
            <div className="w50 d-flex flex-column gap-2">
              <span className="text-softBlack normal f16">
                Date de fin de l'événement
              </span>
              <CalendarInput
                className="w100"
                value={
                  moment(eventFormData?.endDate).utc(true).toDate() || null
                }
                callback={(event) => handleCalendarInput(event, "endDate")}
              />
            </div>
          </div>

          <div className="w100 alignH gap-4">
            <div className="w50 d-flex flex-column gap-2">
              <span className="text-softBlack normal f16">Heure de début</span>
              <CalendarInput
                className="w100"
                callback={(event) => handleCalendarInput(event, "startTime")}
                value={eventFormData?.startTime}
                timeOnly
              />
            </div>
            <div className="w50 d-flex flex-column gap-2">
              <span className="text-softBlack normal f16">Heure de fin</span>
              <CalendarInput
                className="w100"
                callback={(event) => handleCalendarInput(event, "endTime")}
                timeOnly
                value={eventFormData?.endTime}
              />
            </div>
          </div>

          <div className="w100 gap-4 d-flex justify-content-between">
            <div
              className={`w100 d-${
                eventFormData.eventType === "salon" ? "none" : "flex"
              } justify-content-start flex-column`}
            >
              <span className="text-softBlack normal f16">
                Image couverture
              </span>
              <UploadButton
                type={"file"}
                text={`${
                  eventFormData?.newPhoto?.name ||
                  eventFormData?.Image?.data?.attributes?.name
                }`}
                callback={handleImageChange}
                className="coverImg mt-2"
              />
            </div>
            <div className="w100">
              <span className="text-softBlack normal f16 ">description</span>
              <TextArea
                className="w100 mt-2"
                value={eventFormData?.description || ""}
                callback={(e) => handleChange("description", e.target.value)}
              />
            </div>
          </div>
          <div className="w100 alignH gap-4">
            <div className="w100 d-flex flex-column gap-2">
              <span className="text-softBlack normal f16">
                Lien à consulter
              </span>
              <TextInput
                className="w100"
                value={eventFormData?.visitLink || ""}
                callback={(e) => handleChange("visitLink", e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Buttons */}
      <div className="align-self-center alignH gap-3 mt-5">
        <Button
          variant={"outline"}
          className="px-5"
          onClick={() => dispatch(closeRightModal())}
          loading={eventLoading}
        >
          Annuler
        </Button>
        <Button className="px-5" onClick={handleSubmit}>
          Enregistrer
        </Button>
      </div>
    </div>
  );
};

export default UpdateEvent;
