import React from "react";
import { MultiSelect } from "primereact/multiselect";

import "./Select/Select.css";
import "./multiselect.css";

export default function MultiSelectCheckbox({
  filter,
  name,
  options,
  placeholder,
  className = "",
  width = "w100",
  value,
  disabled,
  callback,
}) {
  const handleChange = (e) => {
    callback && callback(e);
  };

  return (
    <MultiSelect
      appendTo={"self"}
      filter={filter}
      filterInputAutoFocus
      name={name}
      value={value ?? ""}
      onChange={handleChange}
      options={options}
      placeholder={placeholder}
      className={`customSelect max-w420px ${className} ${width}`}
      disabled={disabled}
      showClear
      showSelectAll
      emptyFilterMessage={"Aucun résultat trouvé"}
    />
  );
}
