import gql from "graphql-tag";
import { ATTRIBUTES_PANIER } from "../Attributes/panier";

export const CREATE_PANIER = gql`
  mutation ($userID: ID, $reseller: ID) {
    createPanier(
      data: { total: 0, productsCount: 0, user: $userID, reseller: $reseller }
    ) {
      data {
        id
        attributes {
          total
          productsCount
          realRefClient
          user {
            data {
              id
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_PANIER = gql`
  mutation ($id: ID!, $total: Float, $productsCount: Int, $realRefClient :String, $reseller: ID) {
    updatePanier(
      id: $id
      data: { total: $total, productsCount: $productsCount, realRefClient : $realRefClient, reseller: $reseller }
    ) {
      ${ATTRIBUTES_PANIER}
    }
  }
`;

export const CREATE_PANIER_ITEM = gql`
  mutation ($product: ID, $panier: ID, $packings: [ID]) {
    createPanierItem(
      data: {
        product: $product
        panier: $panier
        packings: $packings
        quantity: 1
      }
    ) {
      data {
        id
        attributes {
          quantity
          product {
            data {
              id
              attributes {
                name
              }
            }
          }
          panier {
            data {
              id
            }
          }
        }
      }
    }
  }
`;
export const UPDATE_PANIER_ITEM = gql`
  mutation ($id: ID!, $quantity: Int, $refClient: String) {
    updatePanierItem(
      id: $id
      data: { quantity: $quantity, refClient: $refClient }
    ) {
      data {
        id
        attributes {
          quantity
          product {
            data {
              id
              attributes {
                name
              }
            }
          }
          panier {
            data {
              id
            }
          }
        }
      }
    }
  }
`;

export const DELETE_PANIER_ITEM = gql`
  mutation ($id: ID!) {
    deletePanierItem(id: $id) {
      data {
        id
      }
    }
  }
`;

export const CREATE_DELIVERY = gql`
  mutation ($panier: ID) {
    createDelivery(data: { panier: $panier, mode: "on site", price: 0 }) {
      data {
        id
      }
    }
  }
`;
export const UPDATE_DELIVERY = gql`
  mutation (
    $id: ID!
    $date: Date
    $deliveryAddress: String
    $billingAddress: String
    $deliveryMessage: String
    $mode: String
    $price: Float
    $addressId: ID
  ) {
    updateDelivery(
      id: $id
      data: {
        date: $date
        deliveryAddress: $deliveryAddress
        billingAddress: $billingAddress
        deliveryMessage: $deliveryMessage
        mode: $mode
        price: $price
        address: $addressId
      }
    ) {
      data {
        id
      }
    }
  }
`;
