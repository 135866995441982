import React, { useState } from "react";
import Select from "../../Common/FormElements/Select/Select";
import {
  getStatusSelectValue,
  orderPayStatus,
  orderStatus,
} from "../../../helpers/formLabels/orderStatus";
import Button from "../../Common/FormElements/Button/Button";
import { useDispatch } from "react-redux";
import { closeCenteredModal } from "../../../REDUX/actions/centeredModal";
import { updateCommande } from "../../../REDUX/actions/commandes";

function ModifyStatusDialog({ id, status, number, isPayment }) {
  const dispatch = useDispatch();
  const [selectedStatus, setSelectedStatus] = useState(
    getStatusSelectValue(status)
  );

  const handleSubmit = () => {
    dispatch(
      updateCommande(
        id,
        isPayment
          ? { payStatus: selectedStatus.code }
          : { status: selectedStatus.code }
      )
    );

    dispatch(closeCenteredModal());
  };

  return (
    <div>
      <p className="f15 sBold">Statut de la commande #{number} :</p>
      <Select
        options={isPayment ? orderPayStatus : orderStatus}
        placeholder="sélectionner"
        value={selectedStatus}
        callback={(e) => setSelectedStatus(e.value)}
        className="mb-4"
      />
      <div className="d-flex justify-content-center gap-3">
        <Button onClick={handleSubmit}>Enregistrer</Button>
        <Button onClick={() => dispatch(closeCenteredModal())}>Annuler</Button>
      </div>
    </div>
  );
}

export default ModifyStatusDialog;
