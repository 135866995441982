import bilingDeliveries from "../../../deliveries-paiement.json";

export const calculateTotalWheight = (accessories) => {
  let totalWeight = 0;

  accessories.forEach((product) => {
    totalWeight += product.accessoryWeight * 1000;
  });

  return totalWeight;
};

export const handleAccessoryDelivery = (accessories) => {
  const totalWeight = calculateTotalWheight(accessories);

  const colissimoPrice = [
    {
      min: 0,
      max: 250,
      prix: 4.99,
    },
    {
      min: 251,
      max: 500,
      prix: 6.99,
    },
    {
      min: 501,
      max: 750,
      prix: 8.1,
    },
    {
      min: 751,
      max: 1000,
      prix: 8.8,
    },
    {
      min: 1001,
      max: 2000,
      prix: 10.15,
    },
    {
      min: 2001,
      max: 5000,
      prix: 15.6,
    },
    {
      min: 5001,
      max: 10000,
      prix: 22.7,
    },
    {
      min: 10001,
      max: 15000,
      prix: 28.7,
    },
    {
      min: 15001,
      max: 30001,
      prix: 35.55,
    },
  ];

  const price = colissimoPrice.filter(
    (el) => el.min < totalWeight && el.max >= totalWeight
  )[0]?.prix;

  return price
    ? [
        {
          serviceName: "Livraison accessoire",
          Colissimo: price,
        },
      ]
    : [];
};

export const handleSpaDelivery = (panier, models) => {
  let deliveryDepartment =
    panier?.delivery?.address?.city?.data?.attributes?.code;
  const deliveryBillingOptions = bilingDeliveries?.find((belling) => {
    const bellingDptNumber = Number(belling.Dpt);
    return +bellingDptNumber === +deliveryDepartment;
  });

  return deliveryBillingOptions?.Services?.filter(
    (el) => el?.ServiceType >= models[0]?.product_size?.data?.attributes?.ordre
  );
};
