import React from "react";

const InProgressTag = () => (
  <span className="sBold d-inline-block w-100 f12 br8 py-2 px-3 text-center bg-lightOrange text-orange">
    En attente
  </span>
);
const DeliveredTag = () => (
  <span className="sBold d-inline-block w-100 f12 br8 py-2 px-3 text-center bg-lightBluee text-lightBluee">
    Livrée
  </span>
);
const PaidTag = () => (
  <span className="sBold d-inline-block w-100 f12 br8 py-2 px-3 text-center bg-green text-green">
    Payé
  </span>
);
const DepositTag = () => (
  <span className="sBold d-inline-block w-100 f12 br8 py-2 px-3 text-center bg-grey text-brightGrey">
    Acompte
  </span>
);

function CommandeStatusTag({ status }) {
  switch (status) {
    case 1:
      return <InProgressTag />;
    case 2:
      return <DeliveredTag />;
    case 3:
      return <PaidTag />;
    case 4:
      return <DepositTag />;
    default:
      return <span>-</span>;
  }
}

export default CommandeStatusTag;
