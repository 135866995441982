/* -------------------------------------------------------------------------- */
/*                                dependencies                                */
/* -------------------------------------------------------------------------- */
import React from "react";
// style
import "./Drawer.css";
// local UI components
import NavList from "./NavList";
/* -------------------------------------------------------------------------- */
/*                          Drawer Navlinks component                         */
/* -------------------------------------------------------------------------- */
const Drawer = () => {
  /* ******************************** Rendering ******************************* */
  return (
    <div className="drawerContainer d-flex flex-column transition02 bg-white">
      <div className="logoNavigations d-flex flex-column justify-content-center">
        <NavList />
      </div>
    </div>
  );
};

export default Drawer;
