import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation ($email: String!, $password: String!) {
    login(input: { identifier: $email, password: $password }) {
      jwt
      user {
        id
      }
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation (
    $id: ID!
    $firstName: String
    $lastName: String
    $occupation: String
    $phoneNumber: String
    $email: String
    $password: String
    $birthdate: Date
  ) {
    updateUsersPermissionsUser(
      id: $id
      data: {
        firstName: $firstName
        lastName: $lastName
        occupation: $occupation
        phoneNumber: $phoneNumber
        email: $email
        password: $password
        birthdate: $birthdate
      }
    ) {
      data {
        id
        attributes {
          firstName
          lastName
          occupation
          phoneNumber
          email
          birthdate
        }
      }
    }
  }
`;
