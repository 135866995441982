import React, { useEffect, useState } from "react";
import DepensesList from "./DepensesList";
import DepensesViewHeader from "./DepensesViewHeader";
import RightModal from "../../Modals/RightModal/RightModal";
import { useDispatch } from "react-redux";
import { getExpenseVendor, getExpenses } from "../../../REDUX/actions/expenses";

const DepensesTabContent = () => {
  const dispatch = useDispatch();

  const [showFitlers, setshowFitlers] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [filters, setFilters] = useState({});

  const handleShowFilter = () => {
    setshowFitlers(!showFitlers);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  useEffect(() => {
    dispatch(getExpenses());
    dispatch(getExpenseVendor());
  }, [dispatch]);

  return (
    <div className="d-flex flex-column flex-1">
      <DepensesViewHeader
        handleShowFilter={handleShowFilter}
        filters={filters}
        setFilters={setFilters}
        showFitlers={showFitlers}
        checkedItems={checkedItems}
        handleChange={handleChange}
      />
      <DepensesList
        checkedItems={checkedItems}
        setCheckedItems={setCheckedItems}
      />
      <RightModal width={"70"} />
    </div>
  );
};

export default DepensesTabContent;
