import { Modal } from "react-bootstrap";

const MyVerticallyCenteredModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName={"centeredModal"}
      centered
      scrollable
    >
      <Modal.Header closeButton className="centeredModalHeader"></Modal.Header>
      <Modal.Body className="px-5 pb-5 pt-3 customScroll">
        {props?.content}
      </Modal.Body>
    </Modal>
  );
};

export default MyVerticallyCenteredModal;
