import API from "../Api/Api";

export const uploadImageToStrapi = async (
  userID,
  imageFile,
  refImage = "plugin::users-permissions.user",
  altPhoto = "user Photo",
  field = "photo"
) => {
  try {
    console.log({ imageFile });

    const formData = new FormData();
    formData.append("files", imageFile);
    formData.append(
      "fileInfo",
      JSON.stringify({
        alternativeText: altPhoto,
      })
    );
    formData.append("refId", userID);
    formData.append("ref", refImage);
    formData.append("field", field);

    const response = await API.post("/api/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const imageId = response.data[0].url;

    return imageId;
  } catch (error) {
    console.log("error uploading image", error);
  }
};
