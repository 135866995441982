import React from "react";
import Drawer from "./Drawer.jsx/Drawer";
import Header from "./Header/Header";
import { useSelector } from "react-redux";
import LoginView from "../../Views/LoginView";
import { Navigate } from "react-router-dom";
import "./Layout.css";
import ViewWrapper from "../../Utils/ViewWrapper/ViewWrapper";

const Layout = ({ children }) => {
  const token = localStorage.getItem("token");
  const userData = useSelector((state) => state.userReducer);
  const auth = token && userData.user;
  return (
    <ViewWrapper loading={userData.userLoading && token}>
      {auth ? (
        <div>
          <Header />
          <div className="d-flex gap-4">
            <Drawer />
            <div className="layoutApp ps-4 pb-5">{children}</div>
          </div>
        </div>
      ) : (
        <>
          {!token ? <Navigate to="/" /> : null}
          <LoginView />
        </>
      )}
    </ViewWrapper>
  );
};

export default Layout;
