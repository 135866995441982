import React, { useState } from "react";
import Select from "../../Common/FormElements/Select/Select";
import TextInput from "../../Common/FormElements/TextInput/TextInput";
import ListDeleteIcon from "../../Common/DeleteIcon/ListDeleteIcon";
import {
  CuveOptionsApp,
  JupeOptionsApp,
} from "../../../helpers/formLabels/cuveJupeOptions";
import "./PackingList.css";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { formatFloat, isDefined } from "../../../Utils";
import API from "../../../Api/Api";
import { API_END_POINT } from "../../../Api/EndPoints";

function PackingListItem({
  packingItemData,
  taux,
  totalTransport,
  handleItemChange,
  handleDeleteItem,
}) {
  const { models, informations } = useSelector((state) => state.modelReducer);
  const { approv } = useSelector((state) => state.approvReducer);

  const [itemFormData, setItemFormData] = useState(packingItemData);
  const handleChange = (e) => {
    const { value, name } = e.target;
    setItemFormData({ ...itemFormData, [name]: value });
  };

  useEffect(() => {
    handleItemChange(packingItemData?.id, itemFormData);
  }, [itemFormData]);

  useEffect(() => {
    const currModel = informations?.find(
      (item) => +item?.id === +itemFormData?.model?.code
    )?.attributes;

    setItemFormData((formData) => ({
      ...formData,
      buyingPrice: currModel?.buyingPrice ?? 0,
      reference: currModel?.reference ?? "",
    }));
  }, [itemFormData?.model?.code, informations]);

  useEffect(() => {
    setItemFormData((formData) => ({
      ...formData,
      buyingCost: approv?.packingList?.find(
        (approvPacking) => +approvPacking?.id === +itemFormData?.id
      )?.buyingCost
        ? approv?.packingList?.find(
            (approvPacking) => +approvPacking?.id === +itemFormData?.id
          )?.buyingCost
        : (parseFloat(formData?.qc ?? 0) + parseFloat(formData?.buyingPrice)) *
          (parseFloat(taux) || 0),
    }));
  }, [itemFormData?.qc, itemFormData?.buyingPrice, taux]);

  useEffect(() => {
    if (isDefined(totalTransport) && isDefined(itemFormData)) {
      const currentProduct = informations?.find(
        (el) => +el?.id === +itemFormData?.model?.code
      );
      setItemFormData((formData) => ({
        ...formData,
        transport:
          parseFloat(totalTransport) /
            parseFloat(currentProduct?.attributes?.multipleContainer) || 0,
      }));
    }
  }, [totalTransport, itemFormData?.buyingCost]);

  useEffect(() => {
    setItemFormData((formData) => ({
      ...formData,
      deliveredCost: parseFloat(formData?.buyingCost) || 0,
    }));
  }, [itemFormData?.buyingCost, itemFormData?.transport]);

  useEffect(() => {
    if (isDefined(itemFormData?.id)) {
      async function checkProductType() {
        try {
          const { data } = await API.get(
            `${API_END_POINT}api/products?filters[id][$eq]=${itemFormData?.model?.code}&filters[productType][$ne]=1`
          );
          setItemFormData((formData) => ({
            ...formData,
            isProduct: !data?.data?.length > 0,
          }));
        } catch {}
      }
      checkProductType();
    }
  }, [itemFormData?.id, itemFormData?.model?.code]);

  // useEffect(() => {
  //   if (itemFormData?.quantity > 1) {
  //     for (let i = 0; i < itemFormData?.quantity; i++) {
  //       setFormData((formData) => {
  //         let updatedFormData = formData?.filter(
  //           (item) => item?.id !== itemFormData?.id,
  //         );
  //         for (let i = 0; i < itemFormData?.quantity; i++) {
  //           updatedFormData.push({ ...itemFormData, quantity: 1, hide: true });
  //         }
  //         return updatedFormData;
  //       });
  //     }
  //   }
  // }, [itemFormData?.quantity, setFormData]);

  if (itemFormData?.hide) return null;

  return (
    <div className="packingListItem alignH py-3 ps-4 justify-content-between w100 gap-2">
      <div className="w10">
        <Select
          filter
          appendToSelf
          placeholder={"Modèle"}
          options={models}
          name="model"
          callback={handleChange}
          value={itemFormData?.model}
        />
      </div>
      <div className="w10">
        <TextInput
          placeholder={"Référence"}
          name="reference"
          callback={handleChange}
          value={itemFormData?.reference ?? ""}
          disabled
        />
      </div>
      <div className="w5">
        <TextInput
          type="number"
          name="quantity"
          min="1"
          callback={handleChange}
          value={itemFormData?.quantity ?? 1}
          disabled={
            itemFormData?.isProduct || !isDefined(itemFormData?.model?.code)
          }
        />
      </div>
      <div className="w10">
        {itemFormData?.isProduct && (
          <Select
            className="cuveDropdown"
            options={CuveOptionsApp}
            name="cuve"
            callback={handleChange}
            value={itemFormData?.cuve}
            defaultValue={itemFormData?.cuve?.code}
            disabled={!itemFormData?.isProduct}
            placeholder={itemFormData?.cuve?.code ?? "Choisir"}
          />
        )}
      </div>
      <div className="w10">
        {itemFormData?.isProduct && (
          <Select
            className="cuveDropdown"
            options={JupeOptionsApp}
            name="panel"
            callback={handleChange}
            value={itemFormData?.panel}
            disabled={!itemFormData?.isProduct}
            defaultValue={itemFormData?.panel?.code}
            placeholder={itemFormData?.panel?.code ?? "Choisir"}
          />
        )}
      </div>
      <div className="w10">
        {itemFormData?.isProduct && (
          <TextInput
            placeholder={"N° de série"}
            name="serialNumber"
            callback={handleChange}
            value={itemFormData?.serialNumber}
          />
        )}
      </div>
      <div className="w10">
        <TextInput
          placeholder={"Prix d'achat"}
          name="buyingPrice"
          callback={handleChange}
          value={formatFloat(
            itemFormData?.buyingPrice * (itemFormData?.quantity ?? 1),
            "-"
          )}
          disabled
        />
      </div>
      <div className="w5">
        <TextInput
          placeholder={"QC"}
          name="qc"
          type="number"
          callback={handleChange}
          value={itemFormData?.qc}
        />
      </div>
      <div className="w10">
        <TextInput
          placeholder={"Coût d'achat"}
          name="buyingCost"
          type="number"
          callback={handleChange}
          value={formatFloat(
            itemFormData?.buyingCost * (itemFormData?.quantity ?? 1),
            "-"
          )}
          disabled
        />
      </div>
      <div className="w5">
        <TextInput
          placeholder={"Transport"}
          name="transport"
          callback={handleChange}
          value={formatFloat(
            itemFormData?.transport * (itemFormData?.quantity ?? 1),
            "-"
          )}
          disabled
        />
      </div>
      <div className="w10">
        <TextInput
          placeholder={"Coût rendu"}
          name="deliveredCost"
          type="number"
          callback={handleChange}
          value={formatFloat(
            (itemFormData?.deliveredCost + itemFormData?.transport) *
              (itemFormData?.quantity ?? 1),
            "-"
          )}
          disabled
        />
      </div>
      <span className="w5">
        <ListDeleteIcon
          callback={() =>
            handleDeleteItem(packingItemData?.id, packingItemData?.model?.code)
          }
          withoutMargin={true}
        />
      </span>
    </div>
  );
}

export default PackingListItem;
