import React, { useEffect, useState } from "react";

import TabsNavbar from "../../Common/TabsNavBar/TabsNavbar";
import SavPreviewContent from "./SavPreviewContent";
import { useDispatch, useSelector } from "react-redux";
import { getOneTicketSAV } from "../../../REDUX/actions/ticketSAV";

function SavPreview({ id }) {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("Infos générales");
  const { ticketSAVReload } = useSelector((state) => state.ticketSAVReducer);

  useEffect(() => {
    dispatch(getOneTicketSAV({ id }));
  }, [dispatch, id, ticketSAVReload]);
  return (
    <div className="newTicketSAV d-flex flex-column flex-1 justify-content-between">
      <div>
        <h2 className="rightModalTitle mb-4 ms-5">Ticket SAV #{id}</h2>
        <TabsNavbar
          tabs={["Infos générales", "Pièces jointes", "Commentaires"]}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabClassName="me-5"
        />
      </div>
      <div className="pt-4 d-flex flex-column flex-1">
        <SavPreviewContent activeTab={activeTab} ticketId={id} />
      </div>
    </div>
  );
}

export default SavPreview;
