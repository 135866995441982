const initialState = {
  models: [],
  informations: null,
  loading: false,
  error: null,
};

const modelReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_MODELS_LOADING":
      return { ...state, loading: true, error: null };
    case "FETCH_MODELS_SUCCESS":
      return {
        ...state,
        loading: false,
        models: payload?.modelsMap,
        informations: payload?.modelInfos,
        error: null,
      };
    case "FETCH_MODELS_ERROR":
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};

export default modelReducer;
