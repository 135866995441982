const adjustPrice = (price) => {
  if (price) {
    let result = price?.substring(price.length, price.length - 3);
    let count = 0;
    for (let i = price.length - 4; i >= 0; i--) {
      count++;
      if (count === 3) {
        result = " " + price[i] + result;
        count = 0;
      } else {
        result = price[i] + result;
      }
    }
    return result.trim();
  }
};

export default adjustPrice;
