import React, { Fragment, useEffect, useState } from "react";
import TextInput from "../../Common/FormElements/TextInput/TextInput";
import Select from "../../Common/FormElements/Select/Select";
import Separator from "../../Common/Separator/Separator";
import { affiliateInfosForm, affiliateInfosUpdateForm } from "./data";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

function Informations({
  handleFormsChange,
  initialValues,
  customErrors,
  setCustomErrors,
}) {
  const [formData, setFormData] = useState(initialValues);

  const { currentAffiliate } = useSelector((state) => state?.affiliateReducer);
  const errorsMap = {
    exist: "Cet e-mail est déjà utilisé.",
    phoneNumberError: "Veuillez entrer un numéro de téléphone valide.",
    emailError: "Veuillez entrer un email valide.",
    passwordError: "Veuillez entrer un mot de passe d'au moins 6 caractères",
  };
  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
    if (customErrors && customErrors[name]) {
      setCustomErrors((state) => {
        const updatedState = state;
        if (updatedState[name]) {
          delete updatedState[name];
        }
        return updatedState;
      });
    }
  };

  useEffect(() => {
    handleFormsChange("informations", formData);
  }, [formData]);

  return (
    <>
      <div className="modalContent px-5 pt-4 pb-5">
        <Row>
          {[
            ...(currentAffiliate
              ? affiliateInfosUpdateForm
              : affiliateInfosForm),
          ].map((el) => {
            if (el.type === "textarea") return null;
            if (el.type === "select") {
              return (
                <Fragment key={el?.name}>
                  <Col lg={6} key={el.name} className="mb-4">
                    <p className="f16 text-ultraBlack mb-2">{el.label}</p>
                    <Select
                      name={el.name}
                      placeholder={el.label}
                      value={formData[el.name]}
                      options={el?.options}
                      label={el.label}
                      callback={handleChange}
                    />
                  </Col>
                </Fragment>
              );
            }
            return (
              <Fragment key={el.name}>
                {el?.separator && (
                  <div className="mt-4">
                    <Separator />
                  </div>
                )}
                <Col
                  lg={el?.separator ? 12 : 6}
                  key={el.name}
                  className={`${
                    customErrors && customErrors[el?.name] ? "mb-2" : "mb-4"
                  }`}
                >
                  <p className="f16 text-ultraBlack mb-2">{el.label}</p>
                  <TextInput
                    className={"w100"}
                    type={el.type}
                    name={el.name}
                    value={formData[el.name]}
                    placeholder={el.placeholder}
                    euro={el.euro}
                    callback={handleChange}
                  />
                  {customErrors &&
                    errorsMap.hasOwnProperty(customErrors[el?.name]) && (
                      <div className="mt-2 text-redCalendar f14">
                        {errorsMap[customErrors[el?.name]]}
                      </div>
                    )}
                </Col>
              </Fragment>
            );
          })}
        </Row>
      </div>

      {/* <div className="buttonsContainer d-flex justify-content-center mt-5 pb-3 mt-auto">
        <Button
          variant="outline"
          className="me-4"
          onClick={() => dispatch(closeRightModal())}
        >
          Annuler
        </Button>
        <Button
          className="me-4"
          disabled={disabled}
          loading={currentAffiliateLoading}
          onClick={handleClick}
        >
          Enregistrer
        </Button>
        <Button variant="lightBlue">Envoyer les accés</Button>
      </div> */}
    </>
  );
}

export default Informations;
