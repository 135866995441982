import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../Components/Common/FormElements/Button/Button";
import { openCenteredModal } from "../../../REDUX/actions/centeredModal";
import DeliveryAdressModal from "./DeliveryAdressModal";

function AddressCard({
  name,
  address,
  phoneNumber,
  className = "",
  setSelectDelivery = () => {},
  selectDelivery,
  id,
  setDeliveryAddress,
}) {
  const dispatch = useDispatch();
  const { panier } = useSelector((state) => state.panierReducer);
  const editable = selectDelivery === id || panier?.delivery?.address?.id == id;
  const bgClassName = editable ? "bg-fadedBlue" : "bg-dimGrey";

  return (
    <div
      className={`br5 p-4 pt-4 ${bgClassName} ${className} w300 cursor-pointer `}
      onClick={() => setSelectDelivery(id)}
    >
      <p className="f16 text-brightGrey mb-2">{name}</p>

      {/* <p className="f16 text-brightGrey mb-2 ">{}</p> */}
      {!address?.street &&
      !address?.city?.data?.attributes?.name &&
      !address?.zip &&
      !address?.country?.data?.attributes?.name ? (
        <p className="f16 text-brightGrey mb-2">Adresse non mentionnée</p>
      ) : (
        <div>
          <p className="f16 text-brightGrey mb-1">
            {address?.street} {address?.city?.data?.attributes?.name}
          </p>
          <p className="f16 text-brightGrey mb-1">
            {address?.country?.data?.attributes?.name}
          </p>
          <p className="f16 text-brightGrey mb-2">{address?.zip}</p>
        </div>
      )}

      <p className="f16 text-brightGrey">{phoneNumber}</p>
      {editable ? (
        <>
          <Button
            className="mt-2"
            onClick={() =>
              dispatch(
                openCenteredModal(
                  <DeliveryAdressModal
                    deliveryAddress={selectDelivery}
                    setDeliveryAddress={setDeliveryAddress}
                  />,
                ),
              )
            }
          >
            Modifier
          </Button>
        </>
      ) : null}
    </div>
  );
}

export default AddressCard;
