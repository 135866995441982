import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { getApprovs } from "../../REDUX/actions/approvisionnement";
import { openRightModal } from "../../REDUX/actions/rightModal";
import FiltersContainer from "../Common/Calendar/FiltersContainer/FiltersContainer";
import Button from "../Common/FormElements/Button/Button";
import TextInput from "../Common/FormElements/TextInput/TextInput";
import AddContainer from "./Approvisionnement/AddContainer";
import ApprovFilters from "./Approvisionnement/ApprovFilters";

const CommandesApproViewHeader = ({
  activeTab,
  setOrderNumberSearch,
  setSearchInput,
  filtersInput,
  setFiltersInput,
  searchInput,
}) => {
  const [showFitlers, setshowFitlers] = useState(false);
  const [value, setValue] = useState("");

  const dispatch = useDispatch();

  const handleShowFilter = () => {
    setshowFitlers(!showFitlers);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setOrderNumberSearch(value);
  };

  const handleApprovSearch = (e) => {
    setSearchInput(e.target.value);
  };

  return activeTab === "Commandes" ? (
    <div className="adminCommandesViewHeader alignH w100 gap-5 justify-content-between">
      <h2 className="text-blue bold f30">{activeTab}</h2>
      <form onSubmit={handleSearch} className="w40">
        <TextInput
          withSearchBtn
          placeholder="Rechercher"
          className="w-100"
          onSearchBtnClick={() => setOrderNumberSearch(value)}
          callback={(e) => setValue(e.target.value)}
        />
      </form>
    </div>
  ) : (
    <div>
      <div className="alignH justify-content-between w100 gap-3">
        <h2 className="text-blue bold f30 flex-2">{activeTab}</h2>
        <TextInput
          withSearchBtn
          placeholder={"Rechercher"}
          className={"flex-2"}
          callback={handleApprovSearch}
          onSearchBtnClick={() => dispatch(getApprovs({ searchInput }))}
        />
        <Button
          Icon={() => <i className="bi bi-plus-lg" />}
          onClick={() => dispatch(openRightModal(<AddContainer />))}
        >
          Ajouter un container
        </Button>
        <Button
          Icon={() => <i className="bi bi-funnel fa-lg " />}
          onClick={handleShowFilter}
          variant={showFitlers && "outline"}
        >
          Filtres
        </Button>
      </div>
      {showFitlers ? (
        <FiltersContainer showFitlers={showFitlers} className="mt-4 ">
          <ApprovFilters searchInput={searchInput} />
        </FiltersContainer>
      ) : null}
    </div>
  );
};

export default CommandesApproViewHeader;
