import React from "react";

import Separator from "../../Common/Separator/Separator";
import NewTicketSAVInfosRevendeur from "./NewTicketSAVInfosRevendeur";

const NewSAVInfos = ({ formData, setFormData }) => {
  return (
    <div className="px-5 mt-5">
      <NewTicketSAVInfosRevendeur
        formData={formData}
        setFormData={setFormData}
      />
      <Separator />
      {/* <div className="mt-5">
        <p className="f16 sBold text-softBlack mb-0">Commande SAV</p>
        <NewSAVCommande />
      </div> */}
    </div>
  );
};

export default NewSAVInfos;
