import React, { useEffect, useState } from "react";
import Select from "../../Common/FormElements/Select/Select";
import TextInput from "../../Common/FormElements/TextInput/TextInput";
import ListDeleteIcon from "../../Common/DeleteIcon/ListDeleteIcon";
import { useDispatch, useSelector } from "react-redux";
import ShadowLoader from "../../../Utils/ViewWrapper/ShadowLoader/ShadowLoader";
import { getProductsList } from "../../../REDUX/actions/products";
import adjustPrice from "../../../Utils/AdjustPrice";
import { isDefined } from "../../../Utils";
import { getCuveValue } from "../Approvisionnement/getPackingSelectValue";
import {
  cuveMapNames,
  jupeMapNames,
} from "../../../helpers/formLabels/cuveJupeOptions";
import { OrderProductExtractor } from "../../../Utils/DataExtractors/productsExtractor";

function OrdreProduct({
  id,
  panierItemId,
  productID,
  refClient,
  discount,
  formData,
  setFormData,
  product,
  itemAmount,
  hasTax,
  setcommandesTabUpdated,
  quantity,
  handleDelete,
  packing,
  cuve,
  panel,
  newProduct,
  selectedProduct,
  setselectedProduct,
}) {
  // console.log({ quantity });
  // console.log({ selectedProduct });

  const { productsList, productsListLoading, productsListError } = useSelector(
    (state) => state.productsReducer
  );
  const [newRefClient, setNewRefClient] = useState();
  const [newQuantity, setNewQuantity] = useState();
  // yesss
  const [newCuve, setNewCuve] = useState();
  const [newPanel, setNewPanel] = useState();
  // const [cuves, setCuves] = useState([]);
  // const [panels, setPanels] = useState([]);

  const dispatch = useDispatch();

  // console.log({ newRefClient });

  const handleChange = async (e, name) => {
    if (name === "refClient") {
      setNewRefClient(e.target.value);
      setcommandesTabUpdated();
    }
    if (name === "quantity") {
      setNewQuantity(e.target.value);
    }
    name === "discount" && setcommandesTabUpdated(true);

    setFormData({
      ...formData,
      panierItems: {
        ...formData?.panierItems,
        data: formData?.panierItems?.data?.map((productt) => {
          return +panierItemId === +productt?.id
            ? {
                ...productt,
                attributes: { ...productt?.attributes, [name]: e.target.value },
              }
            : productt;
        }),
      },
    });
    // yes
    // if (newProduct) {
    //   setselectedProduct({
    //     ...selectedProduct,
    //     ...(await OrderProductExtractor(e.target.value.id)),
    //   });
    // }
    // yesss
    // setFormData({
    //   ...formData,
    //   panierItems: {
    //     ...formData?.panierItems,
    //     data: formData?.panierItems?.data?.map((panierItem) => {
    //       return +panierItemId === +panierItem?.id
    //         ? {
    //             ...panierItem,
    //             attributes: {
    //               ...panierItem?.attributes,
    //               packings: {
    //                 ...panierItem?.attributes?.packings,
    //                 data: [...panierItem?.attributes?.packings?.data],
    //               },
    //             },
    //           }
    //         : panierItem;
    //     }),
    //   },
    // });
  };

  useEffect(() => {
    setNewRefClient(refClient);
    setNewQuantity(quantity);
    setNewCuve(cuve);
    setNewPanel(panel);
  }, [refClient, quantity, cuve, panel]);

  useEffect(() => {
    dispatch(getProductsList());
  }, [dispatch]);

  // yesss
  // useEffect(() => {
  //   setFormData({
  //     ...formData,
  //     panierItems: {
  //       ...formData?.panierItems,
  //       data: formData?.panierItems?.data?.map((item) => {
  //         return panierItemId === item?.id
  //           ? {
  //               ...item,

  //               attributes: {
  //                 ...item?.attributes,
  //                 ...selectedProduct,
  //               },
  //             }
  //           : item;
  //       }),
  //     },
  //   });
  // }, [panierItemId, selectedProduct]);

  const getSelectProducts = () =>
    formData?.panierItems?.data?.map((panierItem) => {
      return {
        name: panierItem?.attributes?.product?.data?.attributes?.name,
        code: panierItem?.attributes?.product?.data?.id,
      };
    });

  const retailerPriceHTSum =
    (isDefined(itemAmount)
      ? formData?.createdAt < "2024-03-12" && formData?.oldId
        ? itemAmount / 1.2
        : itemAmount
      : product?.data?.attributes?.retailerPriceHT) * (quantity || 1) ?? 0;

  const priceTTCSum = (retailerPriceHTSum - discount ?? 0) * 1.2;

  const TVASum = priceTTCSum - (retailerPriceHTSum - discount ?? 0);

  // yesss
  // useEffect(() => {
  //   if (!selectedProduct?.packings?.data?.length > 0) return;
  //   const list = selectedProduct?.packings?.data?.map((item) => ({
  //     name: item?.attributes?.cuve,
  //     code: item?.attributes?.cuve,
  //   }));

  //   setCuves(
  //     list?.filter(
  //       (item, index) =>
  //         !list?.find((v, i) => i < index && item?.code === v?.code)
  //     )
  //   );
  // }, [selectedProduct?.packings?.data]);

  // useEffect(() => {
  //   if (!selectedProduct?.packings?.data?.length > 0) return;
  //   const list = selectedProduct?.packings?.data
  //     ?.filter((item) => item?.attributes?.cuve === newCuve)
  //     .map((item) => {
  //       return {
  //         name: item?.attributes?.panel,
  //         code: item?.attributes?.panel,
  //       };
  //     });
  //   setPanels(
  //     list?.filter(
  //       (item, index) =>
  //         !list?.find((v, i) => i < index && item?.code === v?.code)
  //     )
  //   );
  // }, [newCuve, selectedProduct?.packings?.data]);

  // console.log({ formData });
  // console.log({ cuve, panel });
  // console.log({ productID });

  return (
    <div className="ordreProduct px-4 py-3 alignH b-1-bottom-softGrey">
      <div className="w17 pe-3">
        <ShadowLoader loading={productsListLoading} error={productsListError}>
          <Select
            placeholder="Produit"
            name="Produit"
            valueCustomStyle="12px"
            options={newProduct ? productsList : getSelectProducts()}
            callback={(e) => handleChange(e, "product")}
            value={
              !newProduct &&
              getSelectProducts()?.find((item) => item?.code === productID)
            }
            optionDisabled={(option) =>
              formData?.products?.find(
                (existantProduct) => existantProduct.productID === option.code
              )
            }
            disabled={!newProduct}
            appendToSelf={true}
            filter={true}
          />
        </ShadowLoader>
      </div>

      <div className="w10 mb-0 pe-3">
        {product?.data?.attributes?.productType === 1 ? (
          <Select
            name="cuve"
            itemsTemplate={cuveMapNames}
            valueTemplate={cuveMapNames[cuve]}
            value={getCuveValue(newCuve)}
            // options={cuves}
            // callback={(e) => handleChange(e, cuve)}
            placeholder={"Choisir"}
            valueCustomStyle="12px"
            disabled={!newProduct}
          />
        ) : (
          <p className={"w10 mb-0 pe-3 text-brightGrey"}>-</p>
        )}
      </div>
      <div className="w10 mb-0 pe-3">
        {product?.data?.attributes?.productType === 1 ? (
          <Select
            name="panel"
            itemsTemplate={jupeMapNames}
            valueTemplate={jupeMapNames[panel]}
            value={getCuveValue(newPanel)}
            // options={panels}
            // callback={(e) => handleChange(e, panel)}
            placeholder={"Choisir"}
            valueCustomStyle="12px"
            disabled={!newProduct}
          />
        ) : (
          <p className={"w10 pe-3 mb-0 text-brightGrey"}>-</p>
        )}
      </div>

      {product?.data?.attributes?.productType === 1 ? (
        <TextInput
          className={"w10 pe-3"}
          inputClassName="f12"
          placeholderStyle="f12"
          value={newRefClient}
          callback={(e) => handleChange(e, "refClient")}
        />
      ) : (
        <p className={"w10 pe-3 mb-0 text-brightGrey"}>-</p>
      )}
      {product?.data?.attributes?.productType === 1 ? (
        <p className={"w10 pe-3 text-brightGrey mb-0 f12"}>
          {isDefined(quantity) ? quantity : 1}
        </p>
      ) : (
        <TextInput
          className={"w10 pe-3"}
          inputClassName="f12"
          placeholderStyle="f12"
          value={newQuantity}
          callback={(e) => handleChange(e, "quantity")}
          disabled
        />
      )}
      <p className={`f12 text-brightGrey ${hasTax ? "w10" : "w20"} mb-0`}>
        {adjustPrice(
          (retailerPriceHTSum - (discount || 0) || 0)?.toFixed(2).toString()
        )}{" "}
        €
      </p>
      {hasTax && (
        <p className="f12 text-brightGrey w10 mb-0">
          {adjustPrice((TVASum || 0)?.toFixed(2)?.toString())} €
        </p>
      )}
      {hasTax && (
        <p className="f12 text-brightGrey w10 mb-0">
          {adjustPrice((priceTTCSum || 0)?.toFixed(2)?.toString())} €
        </p>
      )}
      <div className="w9">
        <TextInput
          type="number"
          defaultValue={0}
          className="discountInput w90"
          inputClassName="f12"
          placeholderStyle="f12"
          euro
          value={discount}
          callback={(e) => handleChange(e, "discount")}
          disabled={formData?.paymentStatus || formData?.payments?.length > 0}
        />
      </div>
      <div className="w3">
        <ListDeleteIcon callback={() => handleDelete(id, packing)} />
      </div>
    </div>
  );
}

export default OrdreProduct;
