import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import CalendarCard from "../Components/Dashboard/Calendar/CalendarCard";
import CurrentOrders from "../Components/Dashboard/CurrentOrders/CurrentOrders";
import EventsAndNews from "../Components/Dashboard/EventsAndNews/EventsAndNews";
import ProductsStats from "../Components/Dashboard/ProductsStats/ProductsStats";
import ProspectsPreview from "../Components/Dashboard/ProspectsPreview/ProspectsPreview";
import SoldProducts from "../Components/Dashboard/SoldProducts/SoldProducts";
import TicketsSAV from "../Components/Dashboard/TicketsSAV/TicketsSAV";
import Synteses from "../Components/Dashboard/Syntheses/Synteses";
import QuestionPopup from "../Components/QuestionPopup/QuestionPopup";
import ViewScroller from "../Components/Common/ViewScroller/ViewScroller";
import RightModal from "../Components/Modals/RightModal/RightModal";
import { getStats } from "../REDUX/actions/stats";

const DashboardView = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userReducer);

  useEffect(() => {
    dispatch(getStats());
  }, [dispatch]);

  const resellerName =
    user?.attributes?.profil?.data?.attributes?.reseller?.data?.attributes
      ?.name;

  return (
    <ViewScroller>
      <div className="w100 d-flex flex-column gap-3">
        <h2 className="text-blue bold f30 mb-3 quickSand">
          {user?.attributes?.userRole === 0 && resellerName
            ? `Tableau de bord de la société ${resellerName}`
            : "Tableau de bord"}
        </h2>

        {user?.attributes?.userRole === 1 && <Synteses />}
        <div className="alignH gap-3 align-items-stretch w-100 ">
          <div className="d-flex flex-column gap-3 w100  justify-content-between">
            <ProductsStats />
            <CurrentOrders />
          </div>
          <div className="d-flex">
            <SoldProducts />
          </div>
        </div>
        <div className="alignH gap-3 align-items-stretch justify-content-between w-100">
          <div className="w35">
            <CalendarCard />
          </div>
          <div className="w65">
            <ProspectsPreview />
          </div>
        </div>
        <TicketsSAV />
        <EventsAndNews />
        {/* {user?.attributes?.userRole === 0 && <QuestionPopup />} */}
        <RightModal width={55} />
      </div>
    </ViewScroller>
  );
};

export default DashboardView;
