import React from "react";

function ViewHeader({ title, children, className }) {
  const customClassName = className ? className : "";
  return (
    <div className={`d-flex justify-content-between mb-4 ${customClassName}`}>
      <h1 className="quickSand f30 bold text-blue mb-0">{title}</h1>
      {children}
    </div>
  );
}

export default ViewHeader;
