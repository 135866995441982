import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateCart } from "../../../REDUX/actions/catalogue";
import { IMAGE_END_POINT } from "../../../Api/EndPoints";
import { saveAs } from "file-saver";

const Step4Commande = () => {
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => state.catalogueReducer);

  return (
    <div className="bold border my-4 p-4 br6 shadow">
      <h4 className="text-softBlue bold ">3- Détails de la commande</h4>
      <div className="mt-3 alignH gap-2">
        <div>Numéro de la commande : </div>
        <input
          className="refClientItem p-1"
          type="number"
          value={cart.number}
          onChange={(e) =>
            dispatch(
              updateCart(cart.id, {
                number: e.target.value,
                facture: null,
                bon_de_commande: null,
              })
            )
          }
        />
      </div>
      <div className="mt-3 alignH gap-2">
        <div>Date : </div>
        <input
          className="refClientItem p-1"
          type="date"
          defaultValue={moment(cart.date).format("yyyy-MM-DD")}
          onChange={(e) =>
            dispatch(
              updateCart(cart.id, {
                date: e.target.value,
                facture: null,
                bon_de_commande: null,
              })
            )
          }
        />
      </div>
      {cart.bon_de_commande ? (
        <div
          className="f12 underline cursor-pointer w100 m-auto"
          onClick={() =>
            saveAs(
              `${IMAGE_END_POINT}${cart.bon_de_commande.media.url}`,
              `Bon_de_commande-${cart.bon_de_commande?.number} commande-${cart.number}`
            )
          }
        >
          Télécharger le bon de commande
        </div>
      ) : null}

      {/* <div className="w60 m-auto">
        <PackingGroupList hideActions={true} />
      </div> */}
    </div>
  );
};

export default Step4Commande;
