import React, { useEffect, useMemo, useState } from "react";
import IntermediaryItem from "./IntermediaryItem";
import { useDispatch, useSelector } from "react-redux";
import { getAffiliates } from "../../../REDUX/actions/affiliate";
import Select from "../../Common/FormElements/Select/Select";

function Intermediary({ initialValues }) {
  let value = {};
  if (initialValues?.code) {
    if (Object.keys(initialValues)?.length > 0) {
      value = {
        name: initialValues?.name,
        code: initialValues?.name,
      };
    }
  }

  return (
    <div className="flex-1 d-flex flex-column">
      <div className="modalContent px-5 pt-4">
        {Object.keys(value)?.length > 0 ? (
          <>
            <div className="listHeader d-flex align-items-center bg-dimGrey p-3 mt-3">
              <p className="f12 text-brightGrey mb-0 w30 sBold">
                Apporteur d'affaire
              </p>
            </div>
            <div className="d-flex mt-3 w30">
              <Select
                name="name"
                options={[value]}
                defaultValue={value}
                disabled
              />
            </div>
          </>
        ) : (
          <p className="f12 text-brightGrey mb-0 sBold">
            Ce revendeur n'est pas affecté à un apporteur d'affaire !
          </p>
        )}
      </div>
      {/* <div className="buttonsContainer d-flex justify-content-center mt-auto">
        <Button variant="outline" className="me-4">
          Annuler
        </Button>
        <Button
          loading={loading}
          disabled={disabled}
          onClick={loading ? undefined : handleClick}
        >
          Enregistrer
        </Button>
      </div> */}
    </div>
  );
}

export default Intermediary;
