export const ticketSavsExtractor = (ticketSavsData) => {
  return ticketSavsData?.map((ticket) => {
    return {
      id: ticket?.id,
      deliveryDate: ticket?.attributes?.deliveryDate,
      refClient: ticket?.attributes?.refClient,
      status: ticket?.attributes?.status,
      serialNumber: ticket?.attributes?.serialNumber,
      warrantyEndDate: ticket?.attributes?.warrantyEndDate,
      problem: ticket?.attributes?.problem,
      date: ticket?.attributes?.createdAt,
      reseller: ticket?.attributes?.reseller?.data,
      order: ticket?.attributes?.order?.data,
      savOrders: ticket?.attributes?.savOrders?.data,
      relaunchDate: ticket?.attributes?.relaunch,
      intervention: ticket?.attributes?.intervention?.data,
      users: ticket?.attributes?.users_permissions_users?.data?.map((user) => {
        return {
          id: user.id,
          firstName: user?.attributes?.firstName,
          lastName: user?.attributes?.lastName,
          userRole: user?.attributes?.userRole,
        };
      }),
    };
  });
};
export const oneTicketSAVExtractor = (ticketData) => {
  return {
    ...ticketData?.attributes,
    id: ticketData?.id,
    deliveryDate: ticketData?.attributes?.deliveryDate,
    refClient: ticketData?.attributes?.refClient,
    status: ticketData?.attributes?.status,
    serialNumber: ticketData?.attributes?.serialNumber,
    warrantyEndDate: ticketData?.attributes?.warrantyEndDate,
    problem: ticketData?.attributes?.problem,
    date: ticketData?.attributes?.createdAt,
    product: ticketData?.attributes?.product?.data,
    reseller: ticketData?.attributes?.reseller?.data,
    commande: ticketData?.attributes?.commande?.data,
    savOrders: ticketData?.attributes?.savOrders?.data,
    intervention: ticketData?.attributes?.intervention?.data
      ? {
          id: ticketData?.attributes?.intervention?.data?.id,
          ...ticketData?.attributes?.intervention?.data?.attributes,
        }
      : null,
    users: ticketData?.attributes?.users_permissions_users?.data?.map(
      (user) => {
        return {
          id: user.id,
          firstName: user?.attributes?.firstName,
          lastName: user?.attributes?.lastName,
          userRole: user?.attributes?.userRole,
          photo: user?.attributes?.photo?.data?.attributes?.url,
        };
      }
    ),
    attachments: ticketData?.attributes?.attachments?.data?.map(
      (attachment) => {
        return {
          id: attachment?.id,
          createdAt: attachment?.attributes?.createdAt,
          title: attachment?.attributes?.title,
          file: {
            id: attachment?.attributes?.file?.data?.id,
            ...attachment?.attributes?.file?.data?.attributes,
          },
        };
      }
    ),
  };
};
