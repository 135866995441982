export const CuveJupeIcon = ({ src }) => (
  <img
    src={src}
    alt="CuveJupe"
    style={{ width: 12, height: 12, borderRadius: "50%" }}
  />
);

export const cuveMapNames = {
  "Midnight Opal": "Midnight Opal",
  "White Marble": "White Marble",
  "Mediterranean sunset": "Mediterranean sunset",
  "Ocean Wave": "Ocean Wave",
  Perle: "Perle",
};

export const jupeMapNames = {
  Noir: "Noir",
  Gris: "Gris",
  Marron: "Marron",
  "HPL Gris Sidéral #A2151": "HPL Gris Sidéral #A2151",

  "HPL Sable D'or #NW27": "HPL Sable D'or #NW27",

  "HPL Gris Lumière #NW24": "HPL Gris Lumière #NW24",

  "HPL Gris Anthracite #NW22": "HPL Gris Anthracite #NW22",

  "HPL Marron": "HPL Marron",

  "HPL Terre d'Ombre": "HPL Terre d'Ombre",

  "HPL Perle de Lin": "HPL Perle de Lin",

  "HPL Pierre d'Argent": "HPL Pierre d'Argent",

  "HPL Marbre Minéral": "HPL Marbre Minéral",
};

export const CuveOptionsApp = [
  { name: "White Marble", code: "White Marble" },
  { name: "Midnight Opal", code: "Midnight Opal" },
  { name: "Ocean Wave", code: "Ocean Wave" },
  { name: "Mediterranean sunset", code: "Mediterranean sunset" },
  { name: "Perle", code: "Perle" },
];

export const JupeOptionsApp = [
  { name: "Gris", code: "Gris" },
  { name: "Marron", code: "Marron" },
  { name: "Noir", code: "Noir" },
  { name: "HPL Gris Sidéral #A2151", code: "HPL Gris Sidéral #A2151" },

  { name: "HPL Sable D'or #NW27", code: "HPL Sable D'or #NW27" },

  { name: "HPL Gris Lumière #NW24", code: "HPL Gris Lumière #NW24" },

  { name: "HPL Gris Anthracite #NW22", code: "HPL Gris Anthracite #NW22" },

  { name: "HPL Marron", code: "HPL Marron" },

  { name: "HPL Terre d'Ombre", code: "HPL Terre d'Ombre" },

  { name: "HPL Perle de Lin", code: "HPL Perle de Lin" },

  { name: "HPL Pierre d'Argent", code: "HPL Pierre d'Argent" },

  { name: "HPL Marbre Minéral", code: "HPL Marbre Minéral" },
];
