import React, { useEffect, useState } from "react";
import TextInput from "../../Common/FormElements/TextInput/TextInput";
import Select from "../../Common/FormElements/Select/Select";
import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { addressForm } from "./data";

function Adresses({ handleFormsChange, initialValues }) {
  const { countries } = useSelector((state) => state.countryReducer);
  const [formData, setFormData] = useState(initialValues);
  const [fetchedData, setFetchedData] = useState({});

  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    handleFormsChange("address", formData);
  }, [formData]);

  useEffect(() => {
    setFetchedData((fetchedData) => ({ ...fetchedData, country: countries }));
  }, [countries]);

  return (
    <>
      <div className="modalContent px-5 pt-3">
        <Row>
          {addressForm?.map((el) => {
            if (el?.type === "select") {
              let options = [];
              if (el?.subOption && formData[el?.subOption]) {
                options = (fetchedData[el?.subOption]?.find(
                  (item) => item?.code === formData[el?.subOption]?.code
                ) || {})[el?.optionsName];
              } else {
                options = fetchedData[el?.name]?.map((item) => ({
                  name: item?.name,
                  code: item?.code,
                }));
              }
              return (
                <Col lg={el?.span ?? 6} key={el.name} className="my-3">
                  <p className="f16 text-ultraBlack mb-2">{el.label}</p>
                  <Select
                    name={el.name}
                    value={formData[el.name]}
                    options={options}
                    label={el.label}
                    disabled={el?.subOption && !options?.length > 0}
                    callback={handleChange}
                    appendToSelf={true}
                    filter={true}
                  />
                </Col>
              );
            }

            return (
              <Col lg={el?.span ?? 6} key={el.name} className="my-3">
                <p className="f16 text-ultraBlack mb-2">{el.label}</p>
                <TextInput
                  className={"w100"}
                  type={el.type}
                  name={el.name}
                  value={formData[el.name]}
                  callback={handleChange}
                />
              </Col>
            );
          })}
        </Row>
      </div>
      {/* <div className="buttonsContainer d-flex justify-content-center mt-5 pb-3 mt-auto">
        <Button
          variant="outline"
          className="me-4"
          onClick={() => dispatch(closeRightModal())}
        >
          Annuler
        </Button>
        <Button
          className="me-4"
          disabled={disabled}
          loading={currentAffiliateLoading}
          onClick={handleClick}
        >
          Enregistrer
        </Button>
        <Button variant="lightBlue">Envoyer les accés</Button>
      </div> */}
    </>
  );
}

export default Adresses;
