import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { currentUser } from "../../REDUX/actions/user";

const AuthCheck = ({ children }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(currentUser());
  }, [dispatch]);

  return <>{children}</>;
};

export default AuthCheck;
