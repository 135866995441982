import { getErrorMessage } from "../../Utils";

/* Handle Error */
export const handleError =
  (
    message = "Une erreur est survenue !",
    instruction = "Veuillez essayer plus tard. Si l'erreur persiste encore, veuillez contacter service client!"
  ) =>
  (dispatch) => {
    dispatch({
      type: "OPEN_ERROR",
      payload: { message: getErrorMessage(message), instruction: instruction },
    });
  };

/* Close Error */
export const closeError = () => async (dispatch) => {
  dispatch({ type: "CLOSE_ERROR" });
};

/* Handle Success */
export const handleSuccess = (message, instruction) => (dispatch) => {
  dispatch({
    type: "OPEN_SUCCESS",
    payload: { message: message, instruction: instruction },
  });
};
