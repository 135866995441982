import moment from "moment";
import qs from "qs";
import API from "../../Api/Api";
import { API_END_POINT, IMAGE_END_POINT } from "../../Api/EndPoints";
import { isDefined, selectValue, uploadFile } from "../../Utils";
import { deleteRecord } from "../../Utils/api";
import {
  GET_ONE_PRODUCT,
  GET_PRODUCTS,
  GET_PRODUCTS_ACCESSORIES,
} from "../Graphql/Queries/products";
import client from "./Client";
import { toast } from "react-toastify";
import { productsListExtractor } from "../../Utils/DataExtractors/productsExtractor";
import { productsListQuery } from "../apiQueries/products";
import _ from "lodash";

export const getProducts =
  (productType, searchInput = undefined, category, reset = false) =>
  async (dispatch, getState) => {
    if (reset) {
      dispatch({ type: "RESET_PRODUCTS" });
    }
    try {
      const { products: currentProducts, productsCount } =
        getState().productsReducer;
      const currentPage = Math.ceil(currentProducts?.length / 10);
      const page = currentPage + 1;
      if (
        (isDefined(productsCount) && currentPage * 10 > productsCount) ||
        productsCount === 0
      )
        return;
      dispatch({ type: "PRODUCTS_LOADING" });

      const { data } = await client.query({
        query: GET_PRODUCTS,
        variables: {
          productType,
          searchInput,
          category,
          page,
        },
      });
      const transformedProducts = data?.products?.data?.map((product) => {
        const packings = product?.attributes?.packings?.data;
        return {
          ...product,
          attributes: {
            ...product?.attributes,
            availableQuantity: getProductQuantity(packings),
          },
        };
      });

      dispatch({
        type: "PRODUCTS_FETCHED",
        payload:
          page === 1
            ? transformedProducts
            : _.uniqBy(
                [...currentProducts, ...transformedProducts],
                function (item) {
                  return item?.id;
                }
              ),
      });
      dispatch({
        type: "PRODUCTS_COUNT",
        payload: data?.products?.meta?.pagination?.total,
      });
    } catch (error) {
      console.log(error);
      dispatch({ type: "PRODUCTS_ERROR", payload: error });
    }
  };

export const getOneProduct = (id) => async (dispatch) => {
  dispatch({ type: "PRODUCT_DETAILS_LOADING" });
  try {
    const productDetails = await client.query({
      query: GET_ONE_PRODUCT,
      variables: { id },
    });

    const photos =
      productDetails?.data?.product?.data?.attributes?.attachments?.data
        .map((attach) => {
          if (
            attach?.attributes?.file?.data?.attributes?.mime?.includes("image")
          ) {
            return `${IMAGE_END_POINT}${attach.attributes?.file?.data?.attributes?.url}`;
          }
          return null;
        })
        .filter((item) => item !== null);
    let productDetailsPayload = {
      id: productDetails?.data?.product?.data?.id,
      ...productDetails?.data?.product?.data?.attributes,
      // getReplenishmentDate: (selected) =>
      //   getReplenishmentDate(
      //     productDetails?.data?.product?.data?.attributes?.packings?.data,
      //     selected
      //   ),

      photos,
    };
    dispatch({
      type: "PRODUCT_DETAILS_FETCHED",
      payload: productDetailsPayload,
    });
  } catch (error) {
    dispatch({ type: "PRODUCT_DETAILS_ERROR", payload: error });
    throw error;
  }
};

export const createProduct =
  (
    { product, caracteristiques, equipement, lightSensor, media, documents },
    callback
  ) =>
  async (dispatch) => {
    dispatch({ type: "CREATE_PRODUCT_LOADING" });
    try {
      const gamme = selectValue(product?.gamme);
      const category = selectValue(product?.category);
      const productType = selectValue(product?.productType);

      const product_size = selectValue(product?.product_size);
      const { data: createdProduct } = await API.post(
        `${API_END_POINT}api/products?populate=*`,
        {
          data: {
            ...product,
            kangourou: product?.kangourou?.code === 1,
            product_size,
            gamme,
            category,
            productType,
          },
        }
      );
      const { data } = await API.get(
        `${API_END_POINT}api/products/${createdProduct?.data?.id}?populate=*`
      );
      await dispatch(createCharacteristics(caracteristiques));
      await dispatch(createEquipement(equipement));
      await dispatch(createLightSensor(lightSensor));
      await dispatch(addProductMedia(media));
      await dispatch(addProductDocument(documents));
      dispatch({
        type: "CREATE_PRODUCT_SUCCESS",
        payload: data?.data,
      });
      callback();
      toast.success("Produit créé avec succès !");
    } catch (error) {
      toast.error("Une erreur s'est produite lors de la création du produit");
      dispatch({ type: "CREATE_PRODUCT_ERROR" });
      console.log({ error });
    }
  };

export const updateCurrentProduct =
  (
    { product, caracteristiques, equipement, lightSensor, media, documents },
    id,
    callback
  ) =>
  async (dispatch) => {
    dispatch({ type: "UPDATE_CREATE_PRODUCT_LOADING" });
    try {
      console.log({ product, id });
      const gamme = selectValue(product?.gamme);
      const category = selectValue(product?.category);
      const productType = selectValue(product?.productType);
      const product_size = selectValue(product?.product_size);

      await API.put(`${API_END_POINT}api/products/${id}?populate=*`, {
        data: {
          ...product,
          kangourou: product?.kangourou?.code === 1,
          product_size,
          gamme,
          category,
          productType,
        },
      });

      const query = qs.stringify({
        populate: ["packings.approvisionnement", "category", "gamme"],
      });

      const { data } = await API.get(
        `${API_END_POINT}api/products/${id}?populate=*&${query}`
      );

      const packings = data?.data?.attributes?.packings?.data;
      const updatedProduct = {
        ...data?.data,
        attributes: {
          ...data?.data?.attributes,
          availableQuantity: getProductQuantity(packings),
        },
      };

      await dispatch(updateCreatedCharacteristics(caracteristiques));
      await dispatch(updateCurrentEquipement(equipement));
      await dispatch(updateCurrentLightSensor(lightSensor));
      await dispatch(addProductMedia(media));
      await dispatch(addProductDocument(documents));
      // dispatch({
      //   type: "UPDATE_CREATE_PRODUCT_SUCCESS",
      //   payload: updatedProduct,
      // });
      callback();
      toast.success("Produit a été mis à jour avec succès !");
    } catch (error) {
      toast.error(
        "Une erreur s'est produite lors de la mise à jour du produit"
      );
      dispatch("UPDATE_CREATE_PRODUCT_ERROR");
    }
  };

export const createCharacteristics =
  (characteristics) => async (dispatch, getState) => {
    const { currentProduct } = getState().productsReducer;
    dispatch({ type: "CREATE_CHARS_LOADING" });
    const { extrafields } = characteristics;
    try {
      const { data } = await API.post(`${API_END_POINT}api/caracteristiques`, {
        data: {
          ...characteristics,
          extraFields: extrafields, // Update the extraFields property
          product: currentProduct?.id,
        },
      });

      // dispatch({
      //   type: "CREATE_CHARS_SUCCESS",
      //   payload: data?.data,
      // });
    } catch (error) {
      dispatch("CREATE_CHARS_ERROR");
    }
  };

export const updateCreatedCharacteristics =
  (characteristics) => async (dispatch, getState) => {
    const { currentProduct, currentChars } = getState().productsReducer;
    dispatch({ type: "UPDATE_CREATE_CHARS_LOADING" });
    const { extrafields } = characteristics;

    try {
      if (currentChars?.id) {
        const { data } = await API.put(
          `${API_END_POINT}api/caracteristiques/${currentChars?.id}`,
          {
            data: {
              ...characteristics,
              extraFields: extrafields,
              product: currentProduct?.id,
            },
          }
        );
      } else {
        dispatch(createCharacteristics(characteristics));
      } // dispatch({
      //   type: "UPDATE_CREATE_CHARS_SUCCESS",
      //   payload: data?.data,
      // });
    } catch (error) {
      console.log(error);
      dispatch("UPDATE_CREATE_CHARS_ERROR");
    }
  };

export const createEquipement = (equipement) => async (dispatch, getState) => {
  const { currentProduct } = getState().productsReducer;
  const filtrationCartouche = selectValue(equipement?.filtrationCartouche);
  const acrylicsARISTECH = selectValue(equipement?.acrylicsARISTECH);
  const systemeBiolok = selectValue(equipement?.systemeBiolok);
  const filtrationBasseConsommation = selectValue(
    equipement?.filtrationBasseConsommation
  );
  const cascade = selectValue(equipement?.cascade);
  const easyDrain = selectValue(equipement?.easyDrain);
  const winterCover = selectValue(equipement?.winterCover);
  const baseABS = selectValue(equipement?.baseABS);
  dispatch({ type: "CREATE_EQUIPEMENT_LOADING" });
  try {
    const { data } = await API.post(`${API_END_POINT}api/equipements`, {
      data: {
        ...equipement,
        filtrationBasseConsommation,
        filtrationCartouche,
        acrylicsARISTECH,
        systemeBiolok,
        baseABS,
        cascade,
        easyDrain,
        winterCover,
        product: currentProduct?.id,
      },
    });
    // dispatch({
    //   type: "CREATE_EQUIPEMENT_SUCCESS",
    //   payload: data?.data,
    // });
  } catch (error) {
    console.log(error);
    dispatch("CREATE_EQUIPEMENT_ERROR");
  }
};

export const updateCurrentEquipement =
  (equipement) => async (dispatch, getState) => {
    const { currentProduct, currentEquipement } = getState().productsReducer;
    if (!currentEquipement) {
      dispatch(createEquipement(equipement));
      return;
    }
    const filtrationCartouche = selectValue(equipement?.filtrationCartouche);
    const systemeBiolok = selectValue(equipement?.systemeBiolok);
    const acrylicsARISTECH = selectValue(equipement?.acrylicsARISTECH);
    const filtrationBasseConsommation = selectValue(
      equipement?.filtrationBasseConsommation
    );
    const cascade = selectValue(equipement?.cascade);
    const easyDrain = selectValue(equipement?.easyDrain);
    const winterCover = selectValue(equipement?.winterCover);
    const baseABS = selectValue(equipement?.baseABS);
    dispatch({ type: "UPDATE_CREATE_EQUIPEMENT_LOADING" });
    try {
      const { data } = await API.put(
        `${API_END_POINT}api/equipements/${currentEquipement?.id}`,
        {
          data: {
            ...equipement,
            filtrationBasseConsommation,
            filtrationCartouche,
            acrylicsARISTECH,
            systemeBiolok,
            baseABS,
            cascade,
            easyDrain,
            winterCover,
            product: currentProduct?.id,
          },
        }
      );
      // dispatch({
      //   type: "UPDATE_CREATE_EQUIPEMENT_SUCCESS",
      //   payload: data?.data,
      // });
    } catch (error) {
      console.log(error);
      dispatch("UPDATE_CREATE_EQUIPEMENT_ERROR");
    }
  };

export const createLightSensor =
  (lightsensor) => async (dispatch, getState) => {
    const { currentProduct } = getState().productsReducer;
    dispatch({ type: "CREATE_LIGHTSENSOR_LOADING" });
    try {
      const { data } = await API.post(
        `${API_END_POINT}api/light-sensor-series`,
        {
          data: {
            ...lightsensor,
            product: currentProduct?.id,
          },
        }
      );
      // dispatch({
      //   type: "CREATE_LIGHTSENSOR_SUCCESS",
      //   payload: data?.data,
      // });
    } catch (error) {
      console.log(error);
      dispatch("CREATE_LIGHTSENSOR_ERROR");
    }
  };

export const updateCurrentLightSensor =
  (lightsensor) => async (dispatch, getState) => {
    const { currentProduct, currentLightSensor } = getState().productsReducer;
    dispatch({ type: "UPDATE_CREATE_LIGHTSENSOR_LOADING" });
    try {
      if (currentLightSensor?.id) {
        const { data } = await API.put(
          `${API_END_POINT}api/light-sensor-series/${currentLightSensor?.id}`,
          {
            data: {
              ...lightsensor,
              product: currentProduct?.id,
            },
          }
        );
      }
      // dispatch({
      //   type: "UPDATE_CREATE_LIGHTSENSOR_SUCCESS",
      //   payload: data?.data,
      // });
    } catch (error) {
      console.log(error);
      dispatch("UPDATE_CREATE_LIGHTSENSOR_ERROR");
    }
  };

export const addProductMedia = (attachements) => async (dispatch, getState) => {
  const { currentProduct } = getState().productsReducer;
  dispatch({ type: "CREATE_ATTACHEMENTS_LOADING" });
  try {
    let dataList = [];
    for (const attachement of attachements) {
      if (attachement?.fileId && attachement?.attachmentId) {
        const { data } = await API.put(
          `${API_END_POINT}api/attachments/${attachement?.attachmentId}?populate=*`,
          {
            data: {
              title: attachement?.title,
            },
          }
        );
        dataList.push(data?.data);
      } else {
        const fileId = await uploadFile(attachement?.file);
        const { data } = await API.post(
          `${API_END_POINT}api/attachments?populate=*`,
          {
            data: {
              title: attachement?.title,
              date: moment(attachement?.date),
              file: fileId,
              product: currentProduct?.id,
            },
          }
        );
        dataList.push(data?.data);
      }
    }
    // dispatch({
    //   type: "CREATE_ATTACHEMENTS_SUCCESS",
    //   payload: dataList,
    // });
  } catch (error) {
    console.log(error);
    dispatch("CREATE_ATTACHEMENTS_ERROR");
  }
};

export const deleteAttachements =
  (attachements, callback) => async (dispatch) => {
    try {
      dispatch({ type: "DELETE_ATTACHEMENT_LOADING" });
      const deletedAttachments = [];
      for (const attachement of attachements) {
        if (attachement?.fileId && attachement?.attachmentId) {
          await API.delete(
            `${API_END_POINT}api/attachments/${attachement?.attachmentId}`
          );
          await API.delete(
            `${API_END_POINT}api/upload/files/${attachement?.fileId}`
          );
          deletedAttachments.push(attachement);
        }
      }

      dispatch({
        type: "DELETE_ATTACHEMENT_SUCCESS",
        payload: deletedAttachments,
      });
      callback();
    } catch (error) {}
  };

export const addProductDocument =
  (attachements) => async (dispatch, getState) => {
    const { currentProduct } = getState().productsReducer;

    dispatch({ type: "CREATE_DOCUMENTS_LOADING" });
    try {
      let dataList = [];
      for (const attachement of attachements) {
        if (
          isDefined(attachement?.fileId) &&
          isDefined(attachement?.attachmentId)
        ) {
          const { data } = await API.put(
            `${API_END_POINT}api/attachments/${attachement?.attachmentId}?populate=*`,
            {
              data: {
                title: attachement?.title,
                icon: attachement?.icon?.code ?? 0,
                type: attachement?.docType?.code,
              },
            }
          );
          dataList.push(data?.data);
        } else {
          const fileId = await uploadFile(attachement?.file);

          const { data } = await API.post(
            `${API_END_POINT}api/attachments?populate=*`,
            {
              data: {
                title: attachement?.title,
                date: new Date(attachement?.date),
                file: fileId,
                product: currentProduct?.id,
                icon: attachement?.icon?.code ?? 0,
                type: attachement?.docType?.code,
              },
            }
          );
          dataList.push(data?.data);
        }
      }

      // console.log({ dataList });
      // dispatch({
      //   type: "CREATE_DOCUMENTS_SUCCESS",
      //   payload: dataList,
      // });
    } catch (error) {
      dispatch({ type: "CREATE_DOCUMENTS_ERROR" });
    }
  };

export const deleteDocumentAttachements =
  (attachements, callback) => async (dispatch) => {
    try {
      dispatch({ type: "DELETE_DOCUMENT_LOADING", payload: attachements });
      const deletedAttachments = [];
      for (const attachement of attachements) {
        if (attachement?.fileId && attachement?.attachmentId) {
          await API.delete(
            `${API_END_POINT}api/attachments/${attachement?.attachmentId}`
          );
          await API.delete(
            `${API_END_POINT}api/upload/files/${attachement?.fileId}`
          );
          deletedAttachments.push(attachement);
        }
      }

      dispatch({ type: "DELETE_DOCUMENT_SUCCESS", payload: attachements });
      callback();
    } catch (error) {}
  };

export const getCurrentProduct = (id) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_CURRENT_PRODUCT_LOADING" });
    const { data } = await client.query({
      query: GET_ONE_PRODUCT,
      variables: {
        id,
      },
    });

    dispatch({
      type: "FETCH_CURRENT_PRODUCT_SUCCESS",
      payload: data?.product?.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: "FETCH_CURRENT_PRODUCT_ERROR" });
  }
};

export const deleteProduct =
  (id, equipementId, lightSensorId, charId, attachments) =>
  async (dispatch) => {
    dispatch({ type: "DELETE_PRODUCT_LOADING" });
    try {
      await deleteRecord(id, "products");
      await deleteRecord(equipementId, "equipements");
      await deleteRecord(lightSensorId, "light-sensor-series");
      await deleteRecord(charId, "caracteristiques");
      for (const attach of attachments) {
        await deleteRecord(attach?.id, "attachments");
        await deleteRecord(attach?.attributes?.file?.data?.id, "upload/files");
      }

      dispatch({ type: "DELETE_PRODUCT_SUCCESS", payload: id });
      toast.success("Produit supprimé avec succès !");
    } catch (error) {
      toast.error(
        "Une erreur s'est produite lors de la suppression du produit"
      );
      dispatch({ type: "DELETE_PRODUCT_LOADING", payload: error });
    }
  };

export const clearCurrentProduct = () => (dispatch) => {
  dispatch({ type: "CLEAR_CREATED_PRODUCT" });
};

// update product Quantities for catalogue
// export const updateProductQuantity =
//   ({ availableQuantity }, id) =>
//   async (dispatch) => {
//     dispatch({ type: "UPDATE_CREATE_PRODUCT_LOADING" });
//     try {
//       await API.put(`${API_END_POINT}api/products/${id}?populate=*`, {
//         data: {
//           availableQuantity,
//         },
//       });
//     } catch (error) {
//       console.log(error);
//       dispatch({ type: "UPDATE_CREATE_PRODUCT_ERROR" });
//     }
//   };

//  get products list for dropdown
export const getProductsList = () => async (dispatch) => {
  dispatch({ type: "PRODUCTS_LIST_LOADING" });
  try {
    const { data: allProducts } = await API.get(
      `${API_END_POINT}api/products?filters${productsListQuery()}
      `
    );
    const products = productsListExtractor(allProducts?.data);
    dispatch({
      type: "PRODUCTS_LIST_FETCHED",
      payload: products,
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: "PRODUCTS_LIST_ERROR", payload: error });
  }
};

export const getProductQuantity = (packings) => {
  return packings?.reduce((acc, packing) => {
    const hasArrived =
      moment(
        packing?.attributes?.approvisionnement?.data?.attributes?.arrivalDate ||
          packing?.attributes?.approvisionnement?.data?.attributes
            ?.estimatedDate
      ).diff(moment(), "day") < 0;

    if (!packing?.attributes?.sold && hasArrived) {
      return acc + 1;
    }
    return acc;
  }, 0);
};
export const getApprovQuantity = (packings, selectedPacking) => {
  const availablePackings = packings?.filter(
    (packing) =>
      packing?.attributes?.cuve === selectedPacking?.attributes?.cuve &&
      packing?.attributes?.panel === selectedPacking?.attributes?.panel &&
      !packing?.attributes?.sold
  );
  return availablePackings.reduce((acc, packing) => {
    const willArrived =
      moment(
        packing?.attributes?.approvisionnement?.data?.attributes?.arrivalDate ||
          packing?.attributes?.approvisionnement?.data?.attributes
            ?.estimatedDate
      ).diff(moment(), "day") >= 0;

    if (!packing?.attributes?.sold && willArrived) {
      return acc + 1;
    }
    return acc;
  }, 0);
};

export const getReplenishmentDate = (packings = [], selectedPacking) => {
  const availablePacking = packings?.find((packing) => {
    return (
      packing?.id === selectedPacking?.id &&
      moment(
        packing?.attributes?.approvisionnement?.data?.attributes?.arrivalDate ||
          packing?.attributes?.approvisionnement?.data?.attributes
            ?.estimatedDate
      ).diff(moment(), "day") >= 0 &&
      !packing?.attributes?.sold
    );
  });

  if (availablePacking) {
    return availablePacking?.attributes?.approvisionnement?.data?.attributes
      ?.arrivalDate;
  }

  return undefined;
};

export const getPackingQuantity = (packings, selectedPacking) => {
  const availablePackings = packings?.filter(
    (packing) =>
      packing?.attributes?.cuve === selectedPacking?.attributes?.cuve &&
      packing?.attributes?.panel === selectedPacking?.attributes?.panel &&
      !packing?.attributes?.sold
  );
  return availablePackings.reduce((acc, packing) => {
    const hasArrived =
      moment(
        packing?.attributes?.approvisionnement?.data?.attributes?.arrivalDate ||
          packing?.attributes?.approvisionnement?.data?.attributes
            ?.estimatedDate
      ).diff(moment(), "day") < 0;

    if (!packing?.attributes?.sold && hasArrived) {
      return acc + 1;
    }
    return acc;
  }, 0);
};

export const getAccessoryRelatedProducts = () => async (dispatch) => {
  try {
    const { data } = await API.get(
      `${API_END_POINT}api/products?populate=*&filters[productType][$eq]=1`
    );

    dispatch({
      type: "GET_RELATED_PRODUCTS",
      payload: data?.data?.map((el) => ({
        label: el?.attributes?.name,
        value: el?.id,
      })),
    });
  } catch (error) {
    console.log(error);
  }
};

export const getProductAccessories = (id) => async (dispatch) => {
  try {
    dispatch({ type: "PRODUCTS_ACCESSORIES_LOADING" });

    const { data } = await client.query({
      query: GET_PRODUCTS_ACCESSORIES,
      variables: {
        id,
      },
    });
    const transformedProducts = data?.products?.data?.map((product) => {
      const packings = product?.attributes?.packings?.data;
      return {
        ...product,
        attributes: {
          ...product?.attributes,
          availableQuantity: getProductQuantity(packings),
        },
      };
    });

    dispatch({
      type: "PRODUCTS_ACCESSORIES_FETCHED",
      payload: transformedProducts,
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: "PRODUCTS_ACCESSORIES_ERROR", payload: error });
  }
};

export const updateMainImage =
  ({ productId, fileId }) =>
  async (dispatch) => {
    dispatch({ type: "UPDATE_USER_LOADING" });
    try {
      await API.put(`/api/products/${productId}`, {
        data: { mainImage: fileId },
      });

      toast.success("Image principale mise à jour avec succès !");
    } catch (error) {
      // dispatch({ type: "UPDATE_USER_FAIL" });
      toast.error(
        "Une erreur s'est produite lors de la mise à jour de l'image principale."
      );
    }
  };
