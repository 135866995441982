import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import NewAdressCard from "../../NewComponents/Step3/NewAdressCard";
import { getCurrentReseller } from "../../../REDUX/actions/reseller";
import AddAdress from "../../Catalogue/DeliveryDetails/AddAdress";
// import TextInput from "../../Common/FormElements/TextInput/TextInput";
// import Select from "../../Common/FormElements/Select/Select";
// import Separator from "../../Common/Separator/Separator";
// import { billingAddressForm, deliveryAddressForm } from "./data";
// import { getCountries } from "../../../REDUX/actions/country";

function Adresses({ initialValues, handleFormChange }) {
  const dispatch = useDispatch();

  // const { countries } = useSelector((state) => state.countryReducer);
  // const [formData, setFormData] = useState(initialValues?.delivery);
  // const [secondFormData, setSecondFormData] = useState(initialValues?.billing);
  // const [fetchedData, setFetchedData] = useState({});

  const { currentReseller } = useSelector((state) => state?.resellerReducer);

  // console.log({ currentReseller });

  // const handleChange = (e) => {
  //   const { value, name } = e.target;
  //   setFormData({ ...formData, [name]: value });
  // };

  // const handleChangeSecond = (e) => {
  //   const { value, name } = e.target;
  //   setSecondFormData({ ...secondFormData, [name]: value });
  // };

  // useEffect(() => {
  //   setFetchedData((fetchedData) => ({ ...fetchedData, country: countries }));
  // }, [countries]);

  // useEffect(() => {
  //   dispatch(getCountries());
  // }, [dispatch]);

  // useEffect(() => {
  //   handleFormChange("addresses", {
  //     delivery: formData,
  //     billing: secondFormData,
  //   });
  // }, [formData, secondFormData]);

  return (
    <div className="flex-1 d-flex flex-column p-5">
      <div className="flex-1 d-flex flex-wrap gap-5">
        {currentReseller?.attributes?.delivery_addresses?.data?.map(
          (address) => (
            <NewAdressCard
              key={address.id}
              address={address}
              type="addresseDeLivraison"
              resellerId={currentReseller?.id}
              withSelectOption={false}
              callBack={() => dispatch(getCurrentReseller(currentReseller?.id))}
            />
          )
        )}
        <AddAdress
          resellerId={currentReseller?.id}
          type="addresseDeLivraison"
          callBack={() => dispatch(getCurrentReseller(currentReseller?.id))}
        />
      </div>
      <hr style={{ borderColor: "#abb8b2", margin: 0 }} className={"my-5"} />

      <div className="flex-1 d-flex flex-wrap gap-5">
        {currentReseller?.attributes?.billingAddress?.data?.map((address) => (
          <NewAdressCard
            key={address.id}
            address={address}
            type="addresseDeFacturation"
            resellerId={currentReseller?.id}
            withSelectOption={false}
            callBack={() => dispatch(getCurrentReseller(currentReseller?.id))}
          />
        ))}
        <AddAdress
          resellerId={currentReseller?.id}
          type="addresseDeFacturation"
          callBack={() => dispatch(getCurrentReseller(currentReseller?.id))}
        />
      </div>
      {/* <div className="modalContent px-5 pt-4">
        <div className="row mb-2">
          {deliveryAddressForm.map((el) => {
            if (el?.type === "select") {
              let options = [];
              if (el?.subOption && formData[el?.subOption]) {
                options = (fetchedData[el?.subOption]?.find(
                  (item) => item?.code === formData[el?.subOption]?.code
                ) || {})[el?.optionsName];
              } else {
                options = fetchedData[el?.name]?.map((item) => ({
                  name: item?.name,
                  code: item?.code,
                }));
              }
              return (
                <div className="col-6 mb-3" key={el?.name}>
                  <p className="f16 text-ultraBlack mb-2">{el?.label}</p>
                  <Select
                    {...el}
                    options={options}
                    value={formData[el?.name]}
                    disabled={el?.subOption && !options?.length > 0}
                    callback={handleChange}
                    appendToSelf={true}
                    filter={true}
                  />
                </div>
              );
            }
            return (
              <div className="col-6 mb-3" key={el?.name}>
                <p className="f16 text-ultraBlack mb-2">{el?.label}</p>
                <TextInput
                  {...el}
                  value={formData[el?.name]}
                  callback={handleChange}
                />
              </div>
            );
          })}
        </div>
        <Separator />
        <div className="row">
          {billingAddressForm.map((el) => {
            if (el?.type === "select") {
              let options = [];
              if (el?.subOption && secondFormData[el?.subOption]) {
                options = (fetchedData[el?.subOption]?.find(
                  (item) => item?.code === secondFormData[el?.subOption]?.code
                ) || {})[el?.optionsName];
              } else {
                options = fetchedData[el?.name]?.map((item) => ({
                  name: item?.name,
                  code: item?.code,
                }));
              }
              return (
                <div className="col-6 mt-3" key={el?.name}>
                  <p className="f16 text-ultraBlack mb-2">{el?.label}</p>
                  <Select
                    {...el}
                    options={options}
                    value={secondFormData[el?.name]}
                    disabled={el?.subOption && !options?.length > 0}
                    callback={handleChangeSecond}
                    appendToSelf={true}
                    filter={true}
                  />
                </div>
              );
            }
            return (
              <div className="col-6 mt-3" key={el?.name}>
                <p className="f16 text-ultraBlack mb-2">{el?.label}</p>
                <TextInput
                  {...el}
                  value={secondFormData[el?.name]}
                  callback={handleChangeSecond}
                />
              </div>
            );
          })}
        </div>
      </div> */}
    </div>
  );
}

export default Adresses;
