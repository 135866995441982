import React, { useEffect, useState } from "react";
import FaqViewHeader from "../Components/Faq/FaqViewHeader";
import FaqViewContent from "../Components/Faq/FaqViewContent";
import RightModal from "../Components/Modals/RightModal/RightModal";
import ViewScroller from "../Components/Common/ViewScroller/ViewScroller";
import { useDispatch, useSelector } from "react-redux";
import { getGuides } from "../REDUX/actions/guides";
import LoaderWrapper from "../Utils/ViewWrapper/LoaderWrapper/LoaderWrapper";

const FaqView = () => {
  const dispatch = useDispatch();
  const { guides, guidesLoading } = useSelector((state) => state.guidesReducer);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    dispatch(getGuides());
  }, [dispatch]);
  return (
    <ViewScroller>
      <div className="w100 d-flex flex-column gap-4 h-100">
        <FaqViewHeader
          setSearchQuery={setSearchQuery}
          searchQuery={searchQuery}
        />
        <LoaderWrapper loading={guidesLoading}>
          <FaqViewContent guides={guides} searchQuery={searchQuery} />
        </LoaderWrapper>
        <RightModal width={70} />
      </div>
    </ViewScroller>
  );
};

export default FaqView;
