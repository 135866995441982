import React from "react";

function RevendeurNotFound() {
  return (
    <div className="m-5">
      <p>⚠️ Ce ticket a été créé par un admin!</p>
    </div>
  );
}

export default RevendeurNotFound;
