import { toast } from "react-toastify";
import API from "../Api/Api";

export const uploadAttachmentToStrapi = async (
  attachment,
  altText,
  refId,
  ref,
  field
) => {
  const formData = new FormData();
  formData.append("files", attachment);
  formData.append(
    "fileInfo",
    JSON.stringify({
      alternativeText: altText,
    })
  );
  formData.append("refId", refId);
  formData.append("ref", ref);
  formData.append("field", field);

  return API.post("/api/upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
    .then(() => {
      console.log("upload successful!");
    })
    .catch((error) => {
      console.log(error);
      console.log("upload error");
    });
};
// catch (error) {
//   console.log("error uploading image", error);
//   toast.error("Erreur dans l'upload!");
//   return error;
// }
// };
