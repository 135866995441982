import React from "react";

function AvailabilityVerifier({
  availableQuantity,
  replenishmentDate,
  packings,
  fromProductDetails,
  sold,
  totalUnarrived,
  fromPanier,
}) {
  // console.log({
  //   replenishmentDate,
  //   availableQuantity,
  //   sold,
  //   totalUnarrived,
  // });

  const panierItemCondition =
    totalUnarrived === undefined && availableQuantity !== 0;
  return (
    <>
      <div className="position-relative d-flex justify-content-between align-items-center">
        {availableQuantity !== 0 && (
          <div className="d-flex align-items-center mb-3">
            <p className="text-greenPist mb-0 f14">
              {availableQuantity} en stock
            </p>
          </div>
        )}

        {!fromPanier &&
          (!replenishmentDate && !availableQuantity && sold ? (
            <p className="text-red f14">Hors stock</p>
          ) : (
            totalUnarrived !== 0 &&
            !panierItemCondition && (
              <p className="text-brown f14 me-3">
                {`${
                  totalUnarrived ? `${totalUnarrived} en` : "En"
                } réapprovisionnement`}
              </p>
            )
          ))}
      </div>
    </>
  );
}

export default AvailabilityVerifier;
