import {
  FAQSvg,
  SAVSvg,
  FactureSvg,
  CatalogueSvg,
  ProspectsSvg,
  DashboardSvg,
  CalendrierSvg,
  CommandesSvg,
  MonEquipeSVG,
} from "../../Svgs";

export const onglets = [
  {
    name: "Dashboard",
    icon: (color) => <DashboardSvg color={color} />,
    to: "/",
    showOnlyToAdmin: false,
    showOnlyToClient: false,
    showOnlyToBroker: true,
  },
  {
    name: "Catalogue",
    icon: (color) => <CatalogueSvg color={color} />,
    to: "/catalogue",
    showOnlyToAdmin: false,
    showOnlyToClient: true,
    showOnlyToBroker: true,
  },
  {
    name: "Produits",
    icon: (color) => <CatalogueSvg color={color} />,
    to: "/produits",
    showOnlyToAdmin: true,
    showOnlyToClient: false,
    showOnlyToBroker: false,
  },
  {
    name: "Catalogue",
    icon: (color) => <CatalogueSvg color={color} />,
    to: "/catalogue",
    showOnlyToAdmin: true,
    showOnlyToClient: false,
    showOnlyToBroker: false,
  },
  {
    name: "Commandes",
    icon: (color) => <CommandesSvg color={color} />,
    to: "/commandes",
    showOnlyToAdmin: false,
    showOnlyToClient: false,
    showOnlyToBroker: true,
  },
  {
    name: "Revendeur",
    icon: (color) => <ProspectsSvg color={color} />,
    to: "/revendeur",
    showOnlyToAdmin: true,
    showOnlyToClient: false,
    showOnlyToBroker: true,
  },
  {
    name: "Prospects",
    icon: (color) => <ProspectsSvg color={color} />,
    to: "/prospects",
    showOnlyToAdmin: false,
    showOnlyToClient: true,
    showOnlyToBroker: false,
  },
  {
    name: "Apporteur d'affaires",
    icon: (color) => <MonEquipeSVG color={color} />,
    to: "/apporteur-affaires",
    showOnlyToAdmin: true,
    showOnlyToClient: false,
    showOnlyToBroker: false,
  },

  {
    name: "Calendrier",
    icon: (color) => <CalendrierSvg color={color} />,
    to: "/calendrier",
    showOnlyToAdmin: false,
    showOnlyToClient: false,
    showOnlyToBroker: true,
  },
  {
    name: "Guides",
    icon: (color) => <FAQSvg color={color} />,
    to: "/guide",
    showOnlyToAdmin: false,
    showOnlyToClient: false,
    showOnlyToBroker: true,
  },
  {
    name: "SAV",
    icon: (color) => <SAVSvg color={color} />,
    to: "/sav",
    showOnlyToAdmin: false,
    showOnlyToClient: false,
    showOnlyToBroker: true,
  },
  {
    name: "Factures",
    icon: (color) => <FactureSvg color={color} />,
    to: "/facture",
    showOnlyToAdmin: false,
    showOnlyToClient: false,
    showOnlyToBroker: true,
  },
  {
    name: "Mon Équipe",
    icon: (color) => <MonEquipeSVG color={color} />,
    to: "/equipe",
    showOnlyToAdmin: false,
    showOnlyToClient: false,
    showOnlyToBroker: false,
  },
];
