import API from "../../Api/Api";
import { API_END_POINT } from "../../Api/EndPoints";

export const getProductSizes = () => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_PRODUCT_SIZES_LOADING" });

    const { data } = await API.get(`${API_END_POINT}api/product-sizes`);

    const payload = data?.data?.map((item) => ({
      name: item?.attributes?.name,
      code: item?.id,
    }));

    dispatch({ type: "FETCH_PRODUCT_SIZES_SUCCESS", payload });
  } catch (err) {
    console.log(err);
    dispatch({ type: "FETCH_PRODUCT_SIZES_ERROR" });
  }
};
