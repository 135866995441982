const approvCore = (approv) => {
  return {
    id: approv?.id,
    containerName: approv?.attributes?.containerName,
    factory: approv?.attributes?.factory,
    orderDate: approv?.attributes?.orderDate,
    estimatedDate: approv?.attributes?.estimatedDate,
    arrivalDate: approv?.attributes?.arrivalDate,
    taux: approv?.attributes?.taux,
    deliveryPrice: approv?.attributes?.deliveryPrice,
    QC: approv?.attributes?.QC ?? 0,
    returnCost: approv?.attributes?.returnCost,
    amountTTC: approv?.attributes?.amountTTC,
    TVA: approv?.attributes?.TVA,
    amountHT: approv?.attributes?.amountHT,
    entrepot: approv?.attributes?.entrepot,
    packingList: approv?.attributes?.packings?.data?.map((packing) => {
      const isProduct =
        packing?.attributes?.model?.data?.attributes?.productType === 1;
      const cuve = isProduct ? packing?.attributes?.cuve : null;
      const panel = isProduct ? packing?.attributes?.panel : null;

      return {
        id: packing?.id,
        ...packing?.attributes,
        isProduct,
        cuve,
        panel,
      };
    }),
    attachments: approv?.attributes?.attachments?.data,
  };
};

export const approvsExtractor = (approvsData) => {
  return approvsData?.map((approv) => {
    return approvCore(approv);
  });
};

export const oneApprovExtractor = (approvData) => {
  return {
    ...approvCore(approvData),
    payments: approvData?.attributes?.payments?.data?.map((payment) => {
      return {
        id: payment.id,
        amount: payment.attributes.amount,
        date: payment.attributes.date,
      };
    }),
    attachments: approvData?.attributes?.attachments
      ? approvData?.attributes?.attachments?.data?.map((attachment) => {
          return {
            id: attachment?.id,
            createdAt: attachment?.attributes?.createdAt,
            title: attachment?.attributes?.title,
            file: {
              id: attachment?.attributes?.file?.data?.id,
              ...attachment?.attributes?.file?.data?.attributes,
            },
          };
        })
      : [],
  };
};
