import React from "react";
import CommandeDetails from "./CommandeDetails";

const GeneralInfoNew = ({ formData }) => {
  return (
    <div className="modalContent px-5 py-4">
      <CommandeDetails commande={formData} />
    </div>
  );
};

export default GeneralInfoNew;
