const initialState = {
  countries: [],
  loading: false,
  error: null,
  cityLoading: false,
  cities: [],
};

const countryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_COUNTRIES_LOADING":
      return { ...state, loading: true, error: null };
    case "CITY_LOADING":
      return { ...state, cityLoading: true, error: null };
    case "CITY_SUCCESS":
      return { ...state, cityLoading: false, error: null, cities: payload };
    case "FETCH_COUNTRIES_SUCCESS":
      return { ...state, loading: false, countries: payload, error: null };
    case "FETCH_COUNTRIES_SUCCESS_NEW":
      return { ...state, loading: false, countries: payload, error: null };
    case "FETCH_COUNTRIES_ERROR":
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};

export default countryReducer;
