import { useState } from "react";
import Button from "./FormElements/Button/Button";
import "./ImageModal.css";
import { saveAs } from "file-saver";
import { Spinner } from "react-bootstrap";

const ImageModal = ({ image }) => {
  const [loading, setLoading] = useState(false);

  console.log(loading);
  const downloadImage = () => {
    setLoading(true);
    saveAs(image);
    setLoading(false);
  };

  return (
    <div className="d-flex justify-content-start flex-column align-items-center gap-3 px-3 image-modal">
      <img src={image} alt="" className="w-auto h-auto" />

      <Button
        className={`mb-3 ${loading ? "loading" : ""}`}
        onClick={loading ? undefined : downloadImage}
      >
        <p className="mb-0">Télécharger</p>

        {loading && (
          <div className="small-spinner-container d-flex align-items-center">
            <Spinner
              animation="border"
              variant="light"
              className="small-spinner"
            />
          </div>
        )}
      </Button>
    </div>
  );
};

export default ImageModal;
