export const handleMultipleDelivery = (models) => {
  const numberOfProducts = models.length;
  switch (numberOfProducts) {
    case 1:
      return 1;

    case 2:
      return 1.7;
    case 3:
      return 2.4;
    case 4:
      return 3;
    case 5:
      return 3.3;
    case 6:
      return 3.8;
    case 7:
      return 4;
    case 8:
      return 4.2;
    case 9:
      return 4.4;
    case 10:
      return 4.6;
    default:
      return 1;
  }
};

export const handleAccessoryMultiplier = (models) => {
  const numberOfProducts = models.length;
  if (!numberOfProducts) {
    return 0;
  }
  return {
    1: 1,
    2: 1.6,
    3: 2,
    4: 2.4,
    5: 2.6,
    6: 2.8,
    7: 3,
    8: 3.1,
    9: 3.2,
    10: 3.3,
  }[numberOfProducts];
};
