import React, { useState } from "react";

import SavViewHeader from "../Components/Sav/SavViewHeader";
import SavViewContent from "../Components/Sav/SavViewContent";
import RightModal from "../Components/Modals/RightModal/RightModal";
import ViewScroller from "../Components/Common/ViewScroller/ViewScroller";
import { useSelector } from "react-redux";
import {
  adminInitialValues,
  revendeurInitialValues,
} from "../helpers/formLabels/savFilters";

const SavView = () => {
  const { userRole } = useSelector(
    (state) => state.userReducer.user.attributes
  );
  const filtersInitialValues =
    userRole === 0 ? revendeurInitialValues : adminInitialValues;
  const [searchInput, setSearchInput] = useState("");
  const [filtersInput, setFiltersInput] = useState(filtersInitialValues);

  return (
    <ViewScroller>
      <div className="w100 d-flex flex-column h-100">
        <SavViewHeader
          setSearchInput={setSearchInput}
          setFiltersInput={setFiltersInput}
          filtersInput={filtersInput}
        />
        <SavViewContent searchInput={searchInput} filtersInput={filtersInput} />
        <RightModal noEdges width={70} className="pb-4" id="savModal" />
      </div>
    </ViewScroller>
  );
};

export default SavView;
