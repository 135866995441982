import React, { useState } from "react";
import GeneralInfo from "../../../Common/Forms/GeneralInfo/GeneralInfo";
import "./NewMember.css";

function NewMember() {
  return (
    <div className="newMemberModal  d-flex flex-column justify-content-between h-100">
      <h1 className="quickSand rightModalTitle px-5 ">Nouveau membre</h1>
      <div className=" d-flex flex-column justify-content-between h-100">
        <GeneralInfo />
      </div>
    </div>
  );
}

export default NewMember;
