import React from "react";
import SavChatMessages from "../../SavChatMessages";

const UpdateTicketSAVEchanges = ({ activeTab }) => {
  switch (activeTab) {
    case "Commentaires":
      return <SavChatMessages />;

    case "Notes admin":
      return <SavChatMessages isAdmin />;

    default:
      <SavChatMessages />;
  }
};

export default UpdateTicketSAVEchanges;
