const initialState = {
  categories: [],
  loading: false,
  error: null,
};

const categoryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_CATEGORIES_LOADING":
      return { ...state, loading: true, error: null };
    case "FETCH_CATEGORIES_SUCCESS":
      return { ...state, loading: false, categories: payload, error: null };
    case "FETCH_CATEGORIES_ERROR":
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};

export default categoryReducer;
