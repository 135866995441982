import React from "react";
import { useSelector } from "react-redux";

const Step4Reseller = () => {
  const { cart } = useSelector((state) => state.catalogueReducer);
  const { userRole } = useSelector((state) => state.userReducer);
  return (
    <div className="bold border my-4 p-4 br6 shadow">
      <div className="alignH gap-5">
        <h4 className="mt-4 text-softBlue bold">1- Détails du client</h4>
      </div>
      <div className="mt-3 alignH gap-2">
        <div>Magasin : </div>
        <div>
          {cart.reseller ? (
            cart.reseller.name
          ) : (
            <div className="text-red">Manquant</div>
          )}{" "}
        </div>
      </div>
      {userRole !== "reseller" && (
        <div className="mt-3 alignH gap-2">
          <div>Apporteur d'affaire : </div>
          <div>
            {cart.business_affiliate ? (
              cart.business_affiliate.company
            ) : (
              <div className="text-red">Manquant</div>
            )}{" "}
          </div>
        </div>
      )}
      {userRole !== "reseller" && (
        <div className="alignH gap-2">
          <div>Commission : </div>
          <div>
            {cart.business_affiliate ? (
              cart.comission + "€"
            ) : (
              <div className="text-red">Manquant</div>
            )}{" "}
          </div>
        </div>
      )}
    </div>
  );
};

export default Step4Reseller;
