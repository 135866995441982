import React from "react";
import {
  LivraisonSVG,
  PanierSVG,
  PaymentCardSVG,
} from "../../../Components/Svgs";
import "./PaymentSteps.css";
import { useNavigate } from "react-router-dom";

function PaymentSteps({ activeStep = "panier", disabled }) {
  const navigate = useNavigate();
  const checkActive = (stepName) => (activeStep === stepName ? "active" : "");
  const pickColor = (stepName) =>
    activeStep === stepName ? "white" : "#666666";

  return (
    <div className="paymentSteps">
      <div className="d-flex align-items-center justify-content-center">
        <div className="stepContainer d-flex flex-column justify-content-center align-items-center pt-3">
          <button
            onClick={() => navigate("/catalogue/panier")}
            className={`border-0 bg-transparent iconContainer bg-dimGrey br50 d-flex align-items-center justify-content-center ${checkActive(
              "panier"
            )}`}
          >
            <PanierSVG color={pickColor("panier")} />
          </button>
          <p className="mt-3 mb-0 f13 sBold">Panier</p>
        </div>
        <div className="panierLine bg-brightGrey mx-4"></div>
        <div className="stepContainer d-flex flex-column justify-content-center align-items-center pt-3">
          <button
            onClick={() => navigate("/catalogue/livraison")}
            className={`border-0 bg-transparent iconContainer bg-dimGrey br50 d-flex align-items-center justify-content-center ${checkActive(
              "livraison"
            )}`}
            disabled={disabled}
          >
            <LivraisonSVG color={pickColor("livraison")} />
          </button>
          <p className="mt-3 mb-0 f13 sBold">Livraison</p>
        </div>
        <div className="panierLine bg-brightGrey mx-4"></div>
        <div className="stepContainer d-flex flex-column justify-content-center align-items-center pt-3">
          <button
            onClick={() => navigate("/catalogue/paiement")}
            className={`border-0 bg-transparent iconContainer bg-dimGrey br50 d-flex align-items-center justify-content-center ${checkActive(
              "payment"
            )}`}
            disabled={disabled}
          >
            <PaymentCardSVG color={pickColor("payment")} />
          </button>
          <p className="mt-3 mb-0 f13 sBold">Paiement</p>
        </div>
      </div>
    </div>
  );
}

export default PaymentSteps;
