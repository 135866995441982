import React, { useEffect, useState } from "react";

import "./SwitchButton.css";

function SwitchButton({ isActive, filterSwitch, small = false, onClick }) {
  const [active, setActive] = useState();
  const activeClassName = active ? `on` : "off";

  const handleSwitch = () => {
    onClick && onClick();
    setActive(!active);
  };

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  return (
    <button
      className={`switchButton ${activeClassName} ${
        filterSwitch ? " filterSwitchButton" : "switchButton"
      }`}
      style={{
        "--filterSwitchBtnColor": `${small ? "#59a8c4" : "white"}`,
        minWidth: "31px",
      }}
      onClick={handleSwitch}
    >
      <div
        className={`${filterSwitch ? " filterSwitchCircle" : "switchCircle"} `}
        style={{
          "--filterSwitchCircleColor": `${small ? "white" : "#59a8c4"}`,
        }}
      ></div>
    </button>
  );
}

export default SwitchButton;
