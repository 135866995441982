import React from "react";
import PassswordInput from "./PassswordInput";
import { useDispatch, useSelector } from "react-redux";
import { closeError } from "../../../../REDUX/actions/error";

function ResetPassword({
  currPassword,
  newPassword,
  newConfirmPassword,
  setCurrPassword,
  setNewPassword,
  setNewConfirmPassword,
  matchPassword,
  identicPassword,
  invalidFields,
}) {
  const { errorMessage } = useSelector((state) => state.errorReducer);

  const dispatch = useDispatch();

  const handleChangeCurrPassword = (e) => {
    dispatch(closeError());
    setCurrPassword(e.target.value);
  };
  const handleChangeNewPassword = (e) => {
    dispatch(closeError());
    setNewPassword(e.target.value);
  };
  const handleChangeConfirmPassword = (e) => {
    dispatch(closeError());
    setNewConfirmPassword(e.target.value);
  };

  console.log({ currPassword });

  return (
    <div className="p-5 ">
      <PassswordInput
        text="Ancien mot de passe"
        withoutPlaceHolder
        value={currPassword}
        callback={handleChangeCurrPassword}
      />
      <PassswordInput
        text="Nouveau mot de passe"
        withoutPlaceHolder
        value={newPassword}
        callback={handleChangeNewPassword}
      />
      <PassswordInput
        text="Confirmation du mot de passe"
        withoutPlaceHolder
        value={newConfirmPassword}
        callback={handleChangeConfirmPassword}
      />
      {invalidFields.password === "passwordError" && (
        <span className="text-red f12 ">
          Veuillez entrer un mot de passe d'au moins 6 caractères !
        </span>
      )}
      {matchPassword === false && (
        <p className="text-red f12 mb-0">
          Le nouveau mot de passe et la confirmation mot de passe ne
          correspondent pas !
        </p>
      )}
      {identicPassword === true && (
        <p className="text-red f12 mb-0">
          Le nouveau mot de passe doit être différent du mot de passe actuel !
        </p>
      )}
      <p className="text-red f12 mb-0">{errorMessage}</p>
    </div>
  );
}

export default ResetPassword;
