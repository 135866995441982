import React, { useState } from "react";
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";
import {
  days,
  months,
  shortDays,
} from "../../../../helpers/fakeData/Calendar/Dates";
import "./DeliveryCalendar.css";

import "./DeliveryCalendar.css";
import moment from "moment";
import { getOrderStatus } from "../../../Commandes/ModifyOrder/orderUtils";

function DeliveryCalendar({ setSelectedDate, selectedDate, orderStatus }) {
  addLocale("fr", {
    dayNames: days,
    dayNamesShort: shortDays,
    dayNamesMin: shortDays,
    monthNames: months,
    monthNamesShort: months,
    firstDayOfWeek: 1,
  });
  const handleDateChange = (e) => {
    setSelectedDate(moment(e.value).format("YYYY-MM-DD"));
  };

  return (
    <div className="calendarContainer">
      <Calendar
        inline
        value={moment(selectedDate).utc(true).toDate()}
        onChange={handleDateChange}
        locale="fr"
        className="deliveryCalendar"
        dateFormat="dd/mm/yy"
        minDate={false}
        // minDate={
        //   orderStatus === "Livrée"
        //     ? false
        //     : moment().add(5, "days").utc(true).toDate()
        // }
      />
    </div>
  );
}

export default DeliveryCalendar;
