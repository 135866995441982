import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TabsNavbar from "../Common/TabsNavBar/TabsNavbar";
import Button from "../Common/FormElements/Button/Button";
import { closeRightModal } from "../../REDUX/actions/rightModal";
import TabContent from "../MonEquipe/Modals/NewMember/TabContent";
import { getSelectedGuide, updateGuide } from "../../REDUX/actions/guides";
import { transformSavedAttachGuide } from "../Sav/SavPreview/transformSavedAttachments";

const getProductType = (type) => {
  switch (type) {
    case 1:
      return "Produits";
    case 2:
      return "Accessoires";
    case 3:
      return "Communication";
    case 4:
      return "SAV";
    default:
      break;
  }
};

const UpdateGuide = ({ id }) => {
  const dispatch = useDispatch();
  const { categories } = useSelector((state) => state.categoryReducer);
  const { ranges } = useSelector((state) => state.rangeReducer);
  const { guideLoading: loading } = useSelector((state) => state.guidesReducer);

  const [activeTab, setActiveTab] = useState("Question");
  const [formData, setFormData] = useState({});
  const [attachments, setAttachments] = useState([]);

  const handleSubmit = () => {
    if (loading) return;
    const updatedGuide = {
      ...formData,
      category: formData?.category?.code,
      gamme: formData?.range?.code,
      model: formData?.model?.code,
    };
    dispatch(updateGuide({ id, updatedGuide, attachments }));
  };
  const { guide } = useSelector((state) => state.guidesReducer);

  const getSelectValue = (name, value) => {
    return {
      name: name,
      code: value,
    };
  };
  useEffect(() => {
    dispatch(getSelectedGuide({ id }));
  }, [dispatch, id]);

  useEffect(() => {
    if (guide?.data) {
      setFormData({
        ...guide?.data?.attributes,
        range: getSelectValue(
          guide?.data?.attributes?.gamme?.data?.attributes?.name,
          guide?.data?.attributes?.gamme?.data?.id,
        ),
        type: getSelectValue(
          getProductType(
            guide?.data?.attributes?.model?.data?.attributes?.productType,
          ),
          guide?.data?.attributes?.model?.data?.attributes?.productType,
        ),
        model: getSelectValue(
          guide?.data?.attributes?.model?.data?.attributes?.name,
          guide?.data?.attributes?.model?.data?.id,
        ),
        category: categories?.find(
          (category) =>
            guide?.data?.attributes?.category?.data?.id === category?.code,
        ),
      });
      setAttachments(
        transformSavedAttachGuide(guide?.data?.attributes?.attachments?.data),
      );
    } else {
      setFormData({});
    }
  }, [guide, ranges, categories]);
  return (
    <div className="d-flex flex-column h100 justify-content-between">
      <div>
        <h1 className="quickSand f25 sBold text-blue mb-5 px-5">
          Modifier un guide
        </h1>
        <TabsNavbar
          tabs={["Question", "Fichiers"]}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <TabContent
          activeTab={activeTab}
          formData={formData}
          setFormData={setFormData}
          attachments={attachments}
          setAttachments={setAttachments}
        />
      </div>
      <div className="align-self-center alignH gap-3">
        <Button
          variant={"outline"}
          className="px-5"
          onClick={() => dispatch(closeRightModal())}
        >
          Annuler
        </Button>
        <Button className="px-5" onClick={handleSubmit} loading={loading}>
          Enregistrer
        </Button>
      </div>
    </div>
  );
};

export default UpdateGuide;
