const initialState = {
  open: false,
  content: null,
  fromProfile: false,
};

const rightModalReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "OPEN_FROM_PROFILE":
      return { ...state, fromProfile: true };
    case "OPEN_MODAL":
      return { ...state, open: true, content: payload };
    case "CLOSE_MODAL":
      return { ...state, open: false, fromProfile: false, content: null };

    default:
      return state;
  }
};

export default rightModalReducer;
