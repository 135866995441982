import React from "react";

import SynthesesRecettesSection from "./SynthesesRecettesSection";
import SynthesesDepensesSection from "./SynthesesDepensesSection";

const SynthesesTabContent = () => {
  return (
    <div className="d-flex flex-column gap-3 w100">
      <h2 className="text-blue bold f30  mb-4">Synthèse recettes</h2>
      <SynthesesRecettesSection />
      <h2 className="text-blue bold f30 mt-5 mb-4">Synthèse dépenses</h2>
      <SynthesesDepensesSection />
    </div>
  );
};

export default SynthesesTabContent;
