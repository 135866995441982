const initialState = {
  stats: {},
  loading: false,
  error: null,
};

const statsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "STATS_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };

    case "STATS_SUCCESS":
      return {
        ...state,
        loading: false,
        stats: payload,
      };

    case "STATS_ERROR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export default statsReducer;
