const initialState = {
  productSizes: [],
  loading: false,
  error: null,
};

const productSizeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_PRODUCT_SIZES_LOADING":
      return { ...state, loading: true, error: null };
    case "FETCH_PRODUCT_SIZES_SUCCESS":
      return { ...state, loading: false, productSizes: payload, error: null };
    case "FETCH_PRODUCT_SIZES_ERROR":
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};

export default productSizeReducer;
