import React from "react";
import { useDispatch } from "react-redux";

import { deleteProspect } from "../../REDUX/actions/prospects";
import { closeCenteredModal } from "../../REDUX/actions/centeredModal";
import DeleteBtn from "../Common/FormElements/DeleteBtn";
import Button from "../Common/FormElements/Button/Button";

const DeleteProspect = ({ payload }) => {
  console.log(payload);
  const dispatch = useDispatch();
  const handleDelete = () => {
    dispatch(deleteProspect(payload));
    dispatch(closeCenteredModal());
  };
  const closeModal = () => {
    dispatch(closeCenteredModal());
  };
  return (
    <div className="d-flex flex-column align-items-center gap-3">
      <p>Etes vous sûr de vouloir supprimer le prospect #{payload?.id} ? </p>
      <div className="d-flex gap-3">
        <DeleteBtn onClick={handleDelete} />
        <Button onClick={closeModal}>Annuler</Button>
      </div>
    </div>
  );
};

export default DeleteProspect;
