import moment from "moment";
import React from "react";
import momentLoadFR from "../../../../Utils/momentFR";
import "./DateSlider.css";
import { useDispatch, useSelector } from "react-redux";
import { updateDate } from "../../../../REDUX/actions/calendar";

function DateSlider() {
  momentLoadFR();
  const dispatch = useDispatch();
  const date = useSelector((state) => state.calendarReducer.date);

  const handleNextMonth = () => {
    dispatch(updateDate(moment(date).add(1, "M").format("YYYY-MM-DD")));
  };
  const handlePreviousMonth = () => {
    dispatch(updateDate(moment(date).subtract(1, "M").format("YYYY-MM-DD")));
  };
  return (
    <div className="dateSlider d-flex align-items-center">
      <i
        onClick={handlePreviousMonth}
        className="bi bi-chevron-left me-2 text-blue arrow d-flex align-items-center justify-content-center pointer"
      />
      <p className="f18 bold text-customBlack mb-0">
        {`${moment(date).startOf("month").format("ll")} - ${moment(date)
          .endOf("month")
          .format("ll")}`}
      </p>

      <i
        role="button"
        onClick={handleNextMonth}
        className="bi bi-chevron-right ms-2 text-blue arrow d-flex align-items-center justify-content-center pointer"
      />
    </div>
  );
}

export default DateSlider;
