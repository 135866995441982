import React from "react";
import "./Separator.css";

function Separator({ width, height, hideInMobile, color, style, className }) {
  const hideClassName = hideInMobile ? "hide-m" : "";
  const borderColorClassName = color ? `1px solid ${color}` : "";
  return (
    <hr
      className={`separator ${hideClassName} ${className}`}
      style={{ width, height, borderTop: borderColorClassName, ...style }}
    />
  );
}

export default Separator;
