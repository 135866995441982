import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import IntermediaryCard from "./IntermediaryCard";
import LoaderWrapper from "../../Utils/ViewWrapper/LoaderWrapper/LoaderWrapper";
import { formatDate } from "../../Utils/fileUtils";

function ApporteurAffairesContent() {
  const { affiliates, loading } = useSelector(
    (state) => state.affiliateReducer,
  );
  return (
    <div className="flex-1 d-flex flex-column">
      <div className="row bg-dimGrey py-3 px-4 mx-0 w-100 d-flex justify-content-between align-items-center">
        <span className="col-1 text-brightGrey sBold f14">Nom</span>
        <span className="col-1 text-brightGrey sBold f14">Téléphone</span>
        <span className="col-2 text-brightGrey sBold f14">E-mail</span>
        <span className="col-2 text-brightGrey sBold f14">Adresse</span>
        <span className="col-2 text-brightGrey sBold f14">
          Nom de la société
        </span>
        <span className="col-2 text-brightGrey sBold f14">
          Dernière commande
        </span>
        <span className="col-1"></span>
      </div>

      <div className="flex-1">
        <LoaderWrapper loading={loading}>
          {affiliates?.map((affiliate) => {
            const affiliateOrders = affiliate?.attributes?.orders?.data;
            const affiliateLastOrder =
              affiliateOrders[affiliateOrders.length - 1];

            return (
              <div key={affiliate?.id}>
                <IntermediaryCard
                  company={affiliate?.attributes?.company}
                  id={affiliate?.id}
                  userId={affiliate?.attributes?.user?.data?.id}
                  comissions={affiliate?.attributes?.comissions?.data?.map(
                    (item) => item?.id,
                  )}
                  name={`${affiliate?.attributes?.user?.data?.attributes.firstName} ${affiliate?.attributes?.user?.data?.attributes.lastName}`}
                  tel={
                    affiliate?.attributes?.user?.data?.attributes.phoneNumber
                  }
                  email={affiliate?.attributes?.user?.data?.attributes.email}
                  address={affiliate?.attributes?.billingAddress}
                  lastOrderDate={
                    affiliateLastOrder?.attributes?.createdAt
                      ? formatDate(
                          affiliateLastOrder?.attributes?.createdAt,
                          "DD-MM-YYYY",
                        )
                      : "-"
                  }
                />
                <hr
                  style={{ borderColor: "#abb8b2", margin: 0 }}
                  width={"100%"}
                />
              </div>
            );
          })}
        </LoaderWrapper>
      </div>
    </div>
  );
}

export default ApporteurAffairesContent;
