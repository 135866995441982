import moment from "moment";

const getCalendarDays = (date) => {
  let days = [];
  // representing the week day coresponding to the first day of the month starting from monday as 1..7 sunday
  let firstDayOfMonth = moment(date).startOf("month").day();

  // dealing with sunday as first day of the month
  if (firstDayOfMonth === 0) {
    firstDayOfMonth = 7;
  }
  let previousMonthDays = moment(date).subtract(1, "M").daysInMonth();

  // representing the day to display in the first slot of the calendar
  let slotDay = previousMonthDays - firstDayOfMonth + 2;
  let currentMonthDays = moment(date).daysInMonth();

  let slotMonth;
  let slotYear;
  // looping through last month's days
  for (let i = 0; i < firstDayOfMonth - 1; i++) {
    let slotMonth = moment(date).subtract(1, "M").month() + 1;
    let slotYear = moment(date).subtract(1, "M").year();
    let slotDate = `${slotYear}/${slotMonth}/${slotDay}`;
    days.push({ date: slotDate, day: slotDay });

    slotDay++;
  }

  // looping through current month's days
  slotDay = 1;
  for (let i = 0; i < currentMonthDays && days.length < 35; i++) {
    slotMonth = moment(date).month() + 1;
    slotYear = moment(date).year();
    let slotDate = `${slotYear}/${slotMonth}/${slotDay}`;
    days.push({ date: slotDate, day: slotDay });

    slotDay++;
  }

  // looping through next month's days
  slotDay = 1;
  for (let i = 0; days.length < 35; i++) {
    slotMonth = moment(date).add(1, "M").month() + 1;
    slotYear = moment(date).add(1, "M").year();
    let slotDate = `${slotYear}/${slotMonth}/${slotDay}`;
    days.push({ date: slotDate, day: slotDay });
    slotDay++;
  }
  return days;
};

export default getCalendarDays;
