import API from "../../Api/Api";
import { API_END_POINT } from "../../Api/EndPoints";

const emailValidation = (email) => {
  let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

export const emailExists = async (email) => {
  const { data } = await API.get(
    `${API_END_POINT}api/users?filters[email][$eqi]=${email}`
  );

  return data?.length > 0;
};

export default emailValidation;
