import React, { useState } from "react";
import ProduitsViewHeader from "../Components/Produits/ProduitsViewHeader";
import ProduitsViewContent from "../Components/Produits/ProduitsViewContent";
import RightModal from "../Components/Modals/RightModal/RightModal";
import ViewScroller from "../Components/Common/ViewScroller/ViewScroller";

const ProduitsView = () => {
  const [search, setSearch] = useState();
  const [isSearching, setIsSearching] = useState(false);
  return (
    <ViewScroller>
      <div className="w100 d-flex flex-column gap-4 h-100">
        <ProduitsViewHeader
          search={search}
          isSearching={isSearching}
          setSearch={setSearch}
          setIsSearching={setIsSearching}
        />
        <ProduitsViewContent search={search} isSearching={isSearching} />
        <RightModal width={70} />
      </div>
    </ViewScroller>
  );
};

export default ProduitsView;
