import React, { useEffect, useState } from "react";
import TextArea from "../Common/FormElements/TextArea/TextArea";
import Select from "../Common/FormElements/Select/Select";
import TextInput from "../Common/FormElements/TextInput/TextInput";
import { useSelector } from "react-redux";
import { isDefined } from "../../Utils";
import { API_END_POINT } from "../../Api/EndPoints";
import API from "../../Api/Api";

const productTypes = [
  {
    name: "Produits",
    code: 1,
  },
  {
    name: "Accessoires",
    code: 2,
  },
  {
    name: "Communication",
    code: 3,
  },
  {
    name: "SAV",
    code: 4,
  },
];

const NewGuideQuestions = ({ formData, setFormData }) => {
  const { categories } = useSelector((state) => state.categoryReducer);
  const { ranges } = useSelector((state) => state.rangeReducer);

  const [optionData, setOptionData] = useState({
    type: productTypes,
    category: [],
    range: [],
    model: [],
  });

  const getModels = async () => {
    try {
      if (isDefined(formData?.type)) {
        const { data } = await API.get(
          `${API_END_POINT}api/products?filters[productType][$eq]=${formData?.type?.code}`,
        );

        setOptionData((currentData) => ({
          ...currentData,
          category: [],
          range: [],
          model: data?.data?.map((item) => ({
            name: item?.attributes?.name,
            code: item?.id,
          })),
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  // handle change
  const handleChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
    if (field === "category") {
      setFormData((formData) => ({
        ...formData,
        range: undefined,
        model: undefined,
      }));
    } else if (field === "range") {
      setFormData((formData) => ({
        ...formData,
        model: undefined,
      }));
    } else if (field === "type") {
      setFormData((formData) => ({ type: formData?.type }));
    }
  };

  useEffect(() => {
    setOptionData((currData) => ({
      ...currData,
      category: categories,
    }));
  }, [formData?.type, categories]);

  useEffect(() => {
    setOptionData((data) => ({
      ...data,
      range: formData?.category?.ranges,
    }));
  }, [formData?.category?.ranges]);

  useEffect(() => {
    setOptionData((data) => ({
      ...data,
      model: ranges?.find((range) => +range?.code === +formData?.range?.code)
        ?.models,
    }));
  }, [formData?.range, ranges]);

  useEffect(() => {
    if ([2, 4, 3]?.includes(formData?.type?.code)) {
      getModels();
    }
  }, [formData?.type?.code]);

  return (
    <div className="d-flex flex-column px-5 pt-4 gap-4">
      <div className="w100 d-flex flex-column gap-2">
        <span className="text-softBlack normal f16">Titre</span>
        <TextInput
          className="w100"
          value={formData?.title || ""}
          callback={(e) => handleChange("title", e.target.value)}
        />
      </div>
      <div className="row alignH">
        {selectList.map(({ label, name, types }, id) => {
          if (types?.includes(formData?.type?.code) || types === undefined)
            return (
              <div className="col-4 d-flex flex-column gap-2" key={id}>
                <span className="text-softBlack normal f16">{label}</span>
                <Select
                  resetable
                  showClear
                  className="w100"
                  placeholder={label}
                  options={optionData[name]}
                  value={formData[name]}
                  callback={(selectedOption) => {
                    handleChange(name, selectedOption.value);
                  }}
                />
              </div>
            );
          return null;
        })}
      </div>
      <div className="w100 d-flex flex-column gap-2">
        <span className="text-softBlack normal f16">Description</span>
        <TextArea
          className={"w-100 b-2-softGrey h100"}
          value={formData.description || ""}
          callback={(e) => handleChange("description", e.target.value)}
        />
      </div>
    </div>
  );
};

export default NewGuideQuestions;

const selectList = [
  {
    label: "Type",
    name: "type",
    placeholder: "Type",
  },
  {
    label: "Catégories",
    name: "category",
    placeholder: "Catégory",
    types: [1],
  },
  {
    label: "Gamme",
    name: "range",
    placeholder: "Gamme",
    types: [1],
  },
  {
    label: "Modèle",
    name: "model",
    placeholder: "Modèle",
    types: [1, 2, 3, 4],
  },
];
