import React from "react";

import "./FiltersContainer.css";

function FiltersContainer({ children, resultsCount, className = "" }) {
  return (
    <div className={className}>
      {resultsCount && (
        <p className="f12 text-brightGrey mb-0 mt-3">
          {/* {resultsCount} résultat(s) */}
        </p>
      )}
      <div className="filtersContainer py-4 px-5 bg-dimGrey">{children}</div>
    </div>
  );
}

export default FiltersContainer;
