import React from "react";
import LoginForm from "../Components/LoginForm/LoginForm";

function LoginView() {
  return (
    <div className="bg-blue vh100 d-flex justify-content-center align-items-center">
      <LoginForm />
    </div>
  );
}

export default LoginView;
