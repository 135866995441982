import React, { useEffect, useState } from "react";
import NewTicketSAVContent from "./NewTicketSAVContent";
import "./NewTicketSAV.css";
import TabsNavbar from "../../Common/TabsNavBar/TabsNavbar";
import Button from "../../Common/FormElements/Button/Button";
import moment from "moment";
import checkFormDataValidity from "../../../Utils/checkFormDataValidity";
import { useDispatch, useSelector } from "react-redux";
import { createTicketSAV } from "../../../REDUX/actions/ticketSAV";
import { savResellersExtractor } from "../../../Utils/DataExtractors/savExtractor";

const NewTicketSAV = () => {
  const { user } = useSelector((state) => state.userReducer);
  const [activeTab, setActiveTab] = useState("Infos générales");
  const [formData, setFormData] = useState({
    reseller:
      user?.attributes?.userRole === 0
        ? savResellersExtractor({
            data: [user?.attributes?.profil?.data?.attributes?.reseller?.data],
          })[0]
        : undefined,
    // order: undefined,
    product: undefined,
    deliveryDate: undefined,
    serialNumber: undefined,
    warrantyEndDate: undefined,
    problem: undefined,
    refClient: undefined,
    commande: undefined,
  });
  const [attachments, setAttachments] = useState([]);

  const dispatch = useDispatch();

  const handleSubmit = () => {
    dispatch(createTicketSAV({ ...formData, attachments: attachments }));
  };
  const { ticketSAVsLoading } = useSelector((state) => state.ticketSAVReducer);

  useEffect(() => {
    setFormData((prevState) => {
      return {
        ...prevState,
        warrantyEndDate: formData.deliveryDate
          ? moment(formData.deliveryDate).add(2, "y").format("DD/MM/YYYY")
          : undefined,
      };
    });
  }, [formData.deliveryDate]);

  useEffect(() => {
    setFormData((prevState) => {
      return {
        ...prevState,
        deliveryDate: moment(formData?.commande?.deliveryDate)
          ?.utc(true)
          .toDate(),
      };
    });
  }, [formData?.commande?.deliveryDate]);

  return (
    <div className="newTicketSAV d-flex flex-column justify-content-between h100">
      <div>
        <h2 className="rightModalTitle px-5 mb-5">Ticket SAV</h2>
        <TabsNavbar
          tabs={["Infos générales", "Pièces jointes"]}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabClassName="me-5"
        />
        <NewTicketSAVContent
          activeTab={activeTab}
          formData={formData}
          setFormData={setFormData}
          attachments={attachments}
          setAttachments={setAttachments}
        />
      </div>
      {activeTab !== "Commentaire" && activeTab !== "Résumé" && (
        <div className="d-flex justify-content-center mt-5 pb-3">
          {/* <Button>Annuler la demande de SAV</Button> */}
          <Button
            disabled={!checkFormDataValidity(formData)}
            onClick={handleSubmit}
            loading={ticketSAVsLoading}
          >
            Enregistrer
          </Button>
        </div>
      )}
    </div>
  );
};

export default NewTicketSAV;
