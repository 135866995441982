import React, { useEffect, useState } from "react";

import TabsNavbar from "../Common/TabsNavBar/TabsNavbar";
import TabContentNewProduct from "./TabContentNewProduct";
import "./NewProductAdmin.css";
import { getCategories } from "../../REDUX/actions/category";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCurrentProduct,
  createProduct,
} from "../../REDUX/actions/products";
import Button from "../Common/FormElements/Button/Button";
import { closeRightModal } from "../../REDUX/actions/rightModal";
import { getRanges } from "../../REDUX/actions/range";
import { getProductSizes } from "../../REDUX/actions/productSize";
import { getDeliveryTypes } from "../../REDUX/actions/deliveryTypes";

const NewProductAdmin = () => {
  const dispatch = useDispatch();
  const { currentProductLoading: loading } = useSelector(
    (state) => state.productsReducer,
  );
  const [activeTab, setActiveTab] = useState("Infos générales");
  const [forms, setForms] = useState({
    product: {},
    caracteristiques: {},
    equipement: {},
    lightSensor: {},
    media: [],
    documents: [],
  });
  const initialTabs = [
    "Infos générales",
    "Caractéristiques",
    "Equipements",
    "LightSensor Serie",
    "Photos/Vidéos",
    "Documents",
  ];
  const [tabs, setTabs] = useState(initialTabs);
  const [disabled, setDisabled] = useState(true);

  const handleClick = () => {
    dispatch(createProduct(forms, () => dispatch(closeRightModal())));
  };

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getRanges());
    dispatch(getProductSizes());
    dispatch(getDeliveryTypes());
  }, [dispatch]);

  useEffect(() => {
    return () => dispatch(clearCurrentProduct());
  }, [dispatch]);

  useEffect(() => {
    if (forms.product.productType?.code !== 1) {
      setTabs((prevTabs) =>
        prevTabs.filter(
          (tab) => tab !== "Equipements" && tab !== "LightSensor Serie",
        ),
      );
    } else {
      setTabs(initialTabs);
    }
    return () => setTabs(initialTabs);
  }, [forms?.product?.productType?.code]);

  return (
    <div className="d-flex flex-column justify-content-between h100">
      <span className="sBold f25 quickSand text-blue mb-4 px-5">
        Ajouter un nouveau produit
      </span>
      <div className="mt-4 d-flex flex-column flex-1">
        <TabsNavbar
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          className="justify-content-between"
        />
        <div className="px-5 mt-4 d-flex flex-column h-100">
          <TabContentNewProduct
            activeTab={activeTab}
            setDisabled={setDisabled}
            forms={forms}
            setForms={setForms}
          />
          <div className="w-100 alignC gap-3 px-5 pt-3 mt-auto">
            <Button
              variant={"outline"}
              className="px-5"
              onClick={() => dispatch(closeRightModal())}
            >
              Annuler
            </Button>
            <Button
              className="px-5"
              disabled={disabled}
              loading={loading}
              onClick={loading ? undefined : handleClick}
            >
              Enregistrer
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewProductAdmin;
