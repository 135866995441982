import React, { useEffect } from "react";
import ProspectCard from "./ProspectCard";
import { getProspects } from "../../REDUX/actions/prospects";
import { useDispatch, useSelector } from "react-redux";
import LoaderWrapper from "../../Utils/ViewWrapper/LoaderWrapper/LoaderWrapper";

const ProspectsViewContent = ({ searchInput }) => {
  const dispatch = useDispatch();
  const { prospects, prospectsError, prospectsLoading } = useSelector(
    (state) => state.prospectsReducer
  );
  useEffect(() => {
    dispatch(getProspects());
  }, [dispatch]);

  return (
    <div className="flex-1">
      <div className="row bg-dimGrey py-3 px-4 mx-0 w-100 d-flex align-items-center">
        <span className="col-2 text-brightGrey sBold f14">Nom</span>
        <span className="col-2 text-brightGrey sBold f14">Téléphone</span>
        <span className="col-2 text-brightGrey sBold f14">E-mail</span>
        <span className="col-2 text-brightGrey sBold f14">Adresse</span>
        <span className="col-3 text-brightGrey sBold f14">
          Référence client
        </span>
      </div>
      <LoaderWrapper error={prospectsError} loading={prospectsLoading}>
        {prospects?.map((prospect) => {
          console.log({ prospect });
          return (
            <div key={prospect?.id}>
              <ProspectCard key={prospect?.id} {...prospect} />
              <hr
                style={{ borderColor: "#abb8b2", margin: 0 }}
                width={"100%"}
              />
            </div>
          );
        })}
      </LoaderWrapper>
    </div>
  );
};

export default ProspectsViewContent;
