import React from "react";
import { useSelector } from "react-redux";
import SavFiltersClient from "./SavFiltersClient";
import SavFiltersAdmin from "./SavFiltersAdmin";

const SavFilters = ({ filtersInput, setFiltersInput }) => {
  const { userRole } = useSelector((state) => state.userReducer);

  return userRole === "reseller" || userRole === "BusinessBroker" ? (
    <SavFiltersClient
      filtersInput={filtersInput}
      setFiltersInput={setFiltersInput}
    />
  ) : userRole === "admin" ? (
    <SavFiltersAdmin
      filtersInput={filtersInput}
      setFiltersInput={setFiltersInput}
    />
  ) : null;
};

export default SavFilters;
