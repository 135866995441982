import React from "react";
import { useSelector } from "react-redux";

const Step4MissingDataParticulier = () => {
  const { cart } = useSelector((state) => state.catalogueReducer);

  return !cart.addresseDeLivraison ||
    !cart.addresseDeFacturation ||
    !cart.particulierName ||
    !cart.deliveryOptionName ||
    !cart.particulierPhone ||
    !cart.particulierEmail ||
    !cart.facture ||
    !cart.bon_de_commande ? (
    <div className="mt-3 bold">
      <div className="f12 text-red">Détails manquants</div>
      {!cart.addresseDeLivraison ? (
        <div className="text-red f10">Adresse de livraison (obligatoire)</div>
      ) : null}
      {!cart.addresseDeFacturation ? (
        <div className="text-red f10">Adresse de facturation (obligatoire)</div>
      ) : null}
      {!cart.deliveryOptionName ? (
        <div className="text-red f10">Mode de livraison (obligatoire)</div>
      ) : null}
      {!cart.particulierName ? (
        <div className="text-red f10">Nom & prénom du client (obligatoire)</div>
      ) : null}
      {!cart.particulierPhone ? (
        <div className="text-red f10">Téléphone du client</div>
      ) : null}
      {!cart.particulierEmail ? (
        <div className="text-red f10">Email du client</div>
      ) : null}

      {!cart.facture ? <div className="text-red f10">Facture</div> : null}
      {!cart.bon_de_commande ? (
        <div className="text-red f10">Bon de commande</div>
      ) : null}
    </div>
  ) : null;
};

export default Step4MissingDataParticulier;
