import React from "react";

import "./TextArea.css";

function TextArea({
  name,
  placeholder,
  rows = 5,
  className,
  callback,
  defaultValue = "",
  value = "",
}) {
  return (
    <textarea
      name={name}
      className={`textArea br6 p-3 w100 b-1-darkGrey ${className}`}
      rows={rows}
      placeholder={placeholder}
      onChange={callback}
      value={value}
      defaultValue={defaultValue}
    />
  );
}

export default TextArea;
