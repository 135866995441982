import React from "react";
import InvoiceTabComments from "./InvoiceTabComments";

const InvoiceComments = ({ id, slug }) => {
  return (
    <div className="newApporteurAffaire d-flex flex-column justify-content-between h100">
      <div className="h-100 d-flex flex-column">
        <h2 className="sBold f25 quickSand text-blue px-5 mb-5">
          {`La facture #${slug}`}
        </h2>
        <InvoiceTabComments id={id} />
      </div>
    </div>
  );
};

export default InvoiceComments;
