// initial state
const initialState = {
  eventsLoading: false,
  eventsError: null,
  events: null,
  event: null,
  eventLoading: false,
  eventError: null,
  createdEventLoading: false,
  createdEventSuccess: null,
  createdEventError: null,
};

// Action Types
export const EVENTS_LOADING = "EVENTS_LOADING";
export const EVENTS_ERROR = "EVENTS_ERROR";
export const EVENTS_FETCHED = "EVENTS_FETCHED";
export const EVENT_LOADING = "EVENT_LOADING";
export const EVENT_FETCHED = "EVENT_FETCHED";
export const EVENT_ERROR = "EVENT_ERROR";
export const EVENT_UPDATED = "EVENT_UPDATED";
export const CREATED_EVENT_LOADING = "CREATED_EVENT_LOADING";
export const CREATED_EVENT_SUCCESS = "CREATED_EVENT_SUCCESS";
export const CREATED_EVENT_ERROR = "CREATED_EVENT_ERROR";
export const DELETE_EVENT = "DELETE_EVENT";
// Reducer
const eventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case EVENTS_LOADING:
      return { ...state, eventsLoading: true };
    case EVENT_ERROR:
      return { ...state, eventLoading: false, eventError: action.payload };
    case EVENTS_ERROR:
      return { ...state, eventsLoading: false, eventsError: action.payload };
    case EVENTS_FETCHED:
      return { ...state, eventsLoading: false, events: action.payload };
    case EVENT_LOADING:
      return { ...state, eventLoading: true };
    case EVENT_FETCHED:
      return { ...state, eventLoading: false, event: action.payload };
    case CREATED_EVENT_LOADING:
      return {
        ...state,
        createdEventLoading: true,
        createdEventSuccess: null,
        createdEventError: null,
      };
    case CREATED_EVENT_SUCCESS:
      return {
        ...state,
        createdEventLoading: false,
        createdEventSuccess: action.payload,
        events: {
          ...state.events,
          data: [...state.events.data, action.payload],
        },
        createdEventError: null,
      };
    case CREATED_EVENT_ERROR:
      return {
        ...state,
        createdEventLoading: false,
        createdEventSuccess: null,
        createdEventError: action.payload,
      };
    case DELETE_EVENT:
      const eventIdToDelete = action.payload;
      // Filter out the event with the specified ID from the events.data array
      const updatedEventsData = state.events.data.filter(
        (event) => event.id !== eventIdToDelete
      );
      return {
        ...state,
        events: {
          ...state.events,
          data: updatedEventsData,
        },
      };
    default:
      return state;
  }
};

export default eventsReducer;
