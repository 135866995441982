import SavPreview from "../Components/Sav/SavPreview/SavPreview";
import UpdateTicketSav from "../Components/Sav/UpdateTicketSavAdmin/UpdateTicketSav";
import { openRightModal } from "../REDUX/actions/rightModal";
import { updateTicketSAVStatus } from "../REDUX/actions/ticketSAV";

export const extractRevendeurOfSav = (users) => {
  return users.data?.find((user) => user?.attributes?.userRole === 0);
};
export const extractResponsableOfSav = (users) => {
  return users.data?.find((user) => user?.attributes?.userRole === 1);
};
export const generateOptionsBasedOnRole = (userRole, id, dispatch) => {
  const roleOptionsMap = {
    admin: [
      // {
      //   text: "Modifier le ticket",
      //   callback: () => dispatch(openRightModal(<UpdateTicketSav id={id} />)),
      // },
      {
        text: "Voir le ticket",
        callback: () => dispatch(openRightModal(<UpdateTicketSav id={id} />)),
      },
      {
        text: "Résoudre le ticket",
        callback: () =>
          dispatch(updateTicketSAVStatus({ id: id, data: { status: 2 } })),
      },
      {
        text: "Annuler le ticket",
        red: true,
        callback: () =>
          dispatch(updateTicketSAVStatus({ id: id, data: { status: 3 } })),
      },
    ],
    reseller: [
      {
        text: "Voir le ticket",
        callback: () => dispatch(openRightModal(<SavPreview id={id} />)),
      },
      // {
      //   text: "Modifier le ticket",
      //   callback: () => dispatch(openRightModal(<UpdateTicketSav id={id} />)),
      // },
      // {
      //   text: "Annuler le ticket",
      //   red: true,
      //   callback: () =>
      //     dispatch(updateTicketSAVStatus({ id: id, data: { status: 3 } })),
      // },
    ],
    BusinessBroker: [
      {
        text: "Voir le ticket",
        callback: () => dispatch(openRightModal(<UpdateTicketSav id={id} />)),
      },
    ],
  };
  const options = roleOptionsMap[userRole] || [];

  return options;
};
