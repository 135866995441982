import _ from "lodash";

const initialState = {
  invoicesLoading: false,
  invoicesError: null,
  invoices: [],
  done: false,
  currentComments: [],
  currentCommentsLoading: false,
  commentsLoading: false,
};

const invoicesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "INVOICES_RESET": {
      return {
        ...state,
        invoices: [],
        done: false,
        invoicesLoading: false,
        invoicesError: null,
        total: null,
      };
    }
    case "INVOICES_LOADING": {
      return { ...state, invoicesLoading: true };
    }
    case "INVOICES_ERROR": {
      return { ...state, invoicesLoading: false, invoicesError: payload };
    }
    case "INVOICE_CREATED": {
      return { ...state, invoicesLoading: false };
    }
    case "INVOICE_UPDATED": {
      return { ...state, invoicesLoading: false };
    }
    case "INVOICE_DELETED": {
      return {
        ...state,
        invoicesLoading: false,
        invoices: state?.invoices?.filter(
          (invoice) => !payload?.includes(invoice?.id)
        ),
      };
    }
    case "INVOICES_FETCHED": {
      return {
        ...state,
        invoicesLoading: false,
        invoices: _.uniqBy([...state.invoices, ...payload], function (item) {
          return item?.id;
        }),
      };
    }
    case "INVOICE_DONE": {
      return { ...state, done: true };
    }
    case "CREATE_COMMENTS_INVOICE_LOADING":
      return { ...state, currentCommentsLoading: true };
    case "CREATE_COMMENTS_INVOICE_SUCCESS":
      return {
        ...state,
        currentCommentsLoading: false,
        currentComments: [payload, ...state.currentComments],
      };

    case "FETCH_COMMENTS_INVOICE_LOADING":
      return { ...state, commentsLoading: true };

    case "FETCH_COMMENTS_INVOICE_SUCCESS":
      return { ...state, currentComments: payload, commentsLoading: false };

    default:
      return state;
  }
};

export default invoicesReducer;
