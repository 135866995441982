/* -------------------------------------------------------------------------- */
/*                                dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages:
import React, { useEffect, useState } from "react";
// redux
import { useDispatch, useSelector } from "react-redux";
import { getSelectedReselerAdress } from "../../../REDUX/actions/reseller";
// local ui component
import LoaderWrapper from "../../../Utils/ViewWrapper/LoaderWrapper/LoaderWrapper";
import AddressCard from "./AddressCard";
import AddAdress from "./AddAdress";

/* -------------------------------------------------------------------------- */
/*                              FC-LIST-COMPONENT                             */
/* -------------------------------------------------------------------------- */
const DeliveryAdresses = () => {
  /* ********************************** consts & Hooks ********************************* */
  const { panier } = useSelector((state) => state.panierReducer);
  const [selectDelivery, setSelectDelivery] = useState();
  const resellerData = panier?.resellerData;
  const phoneNum = resellerData?.attributes?.phoneNumber;
  const deliveryAdresses = resellerData?.attributes?.delivery_addresses?.data;
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectDelivery !== undefined) {
      dispatch(
        getSelectedReselerAdress({
          id: selectDelivery,
        })
      );
    }
  }, [selectDelivery, dispatch, panier]);
  // const isBusinessBroker = userRole === "BusinessBroker";
  /* ******************************** Rendering ******************************* */
  return (
    <LoaderWrapper>
      <div className="py-4 d-flex gap-4 scroll-snap-Xmandatory pe-3 minh300">
        {deliveryAdresses?.map((deliveryEntry) => {
          const name = deliveryEntry?.attributes?.name ?? "";
          return (
            <AddressCard
              name={name}
              phoneNumber={phoneNum}
              address={deliveryEntry?.attributes}
              setSelectDelivery={setSelectDelivery}
              selectDelivery={selectDelivery}
              id={deliveryEntry?.id}
              key={deliveryEntry?.id}
            />
          );
        })}
        <AddAdress resellerId={resellerData?.id} />
      </div>
    </LoaderWrapper>
  );
};

export default DeliveryAdresses;
