import { API_END_POINT } from "../../Api/EndPoints";
import API from "../../Api/Api";

export const getModels = () => async (dispatch) => {
  dispatch({ type: "FETCH_MODELS_LOADING" });
  try {
    const { data } = await API.get(`${API_END_POINT}api/products`);
    const modelsMap = data?.data?.map((item) => ({
      name: item?.attributes?.name,
      code: item?.id,
    }));

    dispatch({
      type: "FETCH_MODELS_SUCCESS",
      payload: { modelInfos: data?.data, modelsMap },
    });
  } catch (error) {
    dispatch({ type: "FETCH_MODELS_ERROR" });
  }
};
