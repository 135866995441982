import React from "react";

const ListProductsTitles = () => {
  return (
    <div className="row bg-dimGrey py-3 ps-4 mx-0 w-100 d-flex justify-content-between align-items-center">
      <span className="col-1 text-brightGrey sBold sBold f14"></span>
      <span className="col-2 text-brightGrey sBold sBold f14">Nom</span>
      <span className="col-1 text-brightGrey sBold sBold f14">Catégorie</span>
      <span className="col-1 text-brightGrey sBold sBold f14">Gamme</span>
      <span className="col-1 text-brightGrey sBold sBold f14">Prix Achat</span>
      <span className="col-1 text-brightGrey sBold sBold f14">TX Marge PO</span>
      <span className="col-1 text-brightGrey sBold sBold f14">
        Prix Rev. HT
      </span>
      <span className="col-1 text-brightGrey sBold sBold f14">
        TX Marge Rev.
      </span>

      <span className="col-1 text-brightGrey sBold sBold f14">
        Prix Public TTC
      </span>
      <span className="col-1 text-brightGrey sBold sBold f14">Statut</span>
      <span className="col-1 text-brightGrey sBold sBold f14"></span>
    </div>
  );
};

export default ListProductsTitles;
