export const openRightModal = (payload) => {
  return { type: "OPEN_MODAL", payload };
};

export const closeRightModal = () => {
  return { type: "CLOSE_MODAL" };
};

export const openFromProfile = () => {
  return { type: "OPEN_FROM_PROFILE" };
};
