const checkFormDataValidity = (formData) => {
  for (const key in formData) {
    if (!formData[key] && key !== "refClient" && key !== "serialNumber") {
      return false;
    }
  }
  return true;
};

export default checkFormDataValidity;
