const initialState = {
  open: false,
  content: null,
};

const centeredModalReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "OPEN_CENTERED_MODAL":
      return { ...state, open: true, content: payload };
    case "CLOSE_CENTERED_MODAL":
      return { ...state, open: false, content: null };

    default:
      return state;
  }
};

export default centeredModalReducer;
