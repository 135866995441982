import API from "../../Api/Api";
import { API_END_POINT } from "../../Api/EndPoints";

export const getDeliveryTypes = () => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_DELIVERY_TYPES_LOADING" });

    const { data } = await API.get(`${API_END_POINT}api/delivery-types`);

    const payload = data?.data?.map((item) => ({
      label: item?.attributes?.name,
      value: item?.id,
      multipeContainer: item?.attributes?.multipeContainer,
    }));

    dispatch({ type: "FETCH_DELIVERY_TYPES_SUCCESS", payload });
  } catch (err) {
    console.log(err);
    dispatch({ type: "FETCH_DELIVERY_TYPES_ERROR" });
  }
};
