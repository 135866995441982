export const refClientOptions = [
  { name: "Ref client 1", code: "Ref client 1" },
  { name: "Ref client 2", code: "Ref client 2" },
  { name: "Ref client 3", code: "Ref client 3" },
];

export const problemOptions = [
  { name: "Panne", code: 1 },
  { name: "Électricité", code: 2 },
];
