import React from "react";
import { useDispatch } from "react-redux";
import DeleteBtn from "../Common/FormElements/DeleteBtn";
import Button from "../Common/FormElements/Button/Button";
import { closeCenteredModal } from "../../REDUX/actions/centeredModal";

const DeleteGuide = ({ message, handleDelete }) => {
  const dispatch = useDispatch();
  return (
    <div className="d-flex align-items-center flex-column gap-2">
      <p>{message}</p>
      <div className="d-flex gap-3">
        <DeleteBtn onClick={handleDelete} />
        <Button onClick={() => dispatch(closeCenteredModal())}>Annuler</Button>
      </div>
    </div>
  );
};

export default DeleteGuide;
