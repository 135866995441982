import React, { useEffect, useState } from "react";
import SocieteItem from "./SocieteItem";
import { useDispatch, useSelector } from "react-redux";
import { deleteAffiliateReseller } from "../../../REDUX/actions/affiliate";
import { omit } from "lodash";
import { isDefined } from "../../../Utils";

function Societe({ handleFormsChange, initialValues }) {
  const dispatch = useDispatch();
  const { currentResellers, currentAffiliate } = useSelector(
    (state) => state?.affiliateReducer
  );
  const { organizations } = useSelector((state) => state.organizationReducer);
  const [formData, setFormData] = useState(initialValues);

  const getRemainingOptions = (index) =>
    organizations
      ?.map((item) => omit(item, "businessAffiliate"))
      ?.filter(
        (organization) =>
          !formData?.find(
            (item, i) => +index !== +i && +item?.code === +organization?.code
          )
      );

  const addField = () => {
    setFormData([...formData, {}]);
  };

  const removeField = (index) => () => {
    const code = formData[index]?.code;
    if (code !== undefined && code !== null) {
      dispatch(
        deleteAffiliateReseller(
          formData[index]?.code,
          currentResellers,
          currentAffiliate?.informations?.id
        )
      );
    }
    setFormData((currState) => {
      const updatedFormData = [
        ...(currState?.filter((_, i) => +i !== +index) ?? []),
      ];
      return updatedFormData;
    });
  };

  // const checkFormValidation = () => {
  //   setDisabled(
  //     !formData?.length > 0 ||
  //       !formData?.every(
  //         (item) => item?.code !== undefined && item?.code !== null
  //       )
  //   );
  // };

  const handleChange = (index) => (e) => {
    const { value } = e.target;
    setFormData((currState) => {
      const updatedFormData = [...currState];
      updatedFormData[index] = value;
      return updatedFormData;
    });
  };

  // const handleClick = () => {
  //   if (!disabled) {
  //     dispatch(
  //       createAffiliateReseller(formData, currentAffiliate?.informations.id)
  //     );
  //   }
  // };

  useEffect(() => {
    handleFormsChange("revendeurs", formData);
  }, [formData]);

  // useEffect(() => {
  //   if (currentResellers?.length > 0) {
  //     setFormData(
  //       currentResellers?.map((item) => ({
  //         name: item?.attributes?.name,
  //         code: item?.id,
  //       }))
  //     );
  //   }
  // }, [currentResellers]);
  //

  return (
    <>
      <div className="modalContent px-5 pt-4">
        {formData?.map((item, index) => {
          const options = getRemainingOptions(index);
          if (
            options?.every((option) => +option?.code !== +item?.code) &&
            !isDefined(item)
          ) {
            return null;
          }
          return (
            <SocieteItem
              name="organization"
              value={formData[index]}
              key={item?.organization?.code}
              options={getRemainingOptions(index)}
              onChange={handleChange(index)}
              removeField={removeField(index)}
            />
          );
        })}
        <button
          onClick={addField}
          className="addAttachment underline ms-3 mt-3 f12 text-softBlue border-0 bg-transparent"
        >
          + Ajouter un revendeur
        </button>
      </div>
      {/* <div className="buttonsContainer d-flex justify-content-center mt-auto pb-3">
        <Button
          variant="outline"
          className="me-4"
          onClick={() => dispatch(closeRightModal())}
        >
          Annuler
        </Button>
        <Button
          className="me-4"
          disabled={disabled}
          loading={currentAffiliateLoading}
          onClick={handleClick}
        >
          Enregistrer
        </Button>
        <Button variant="lightBlue">Envoyer les accés</Button>
      </div> */}
    </>
  );
}

export default Societe;
