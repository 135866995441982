import React from "react";
import Select from "../../../Common/FormElements/Select/Select";
import TextInput from "../../../Common/FormElements/TextInput/TextInput";
import Button from "../../../Common/FormElements/Button/Button";
import TextArea from "../../../Common/FormElements/TextArea/TextArea";

const ReserverSalon = () => {
  const formLabels = [
    [
      { id: 1, label: "Nom" },
      { id: 2, label: "Prénom" },
    ],
    [
      {
        id: 3,
        label: "Société",
        placeholder: "Sélectionner",
        options: [
          { name: "Sélectionner", code: "Sélectionner" },
          { name: "Option 1", code: "Option 1" },
          { name: "Option 2", code: "Option 2" },
        ],
      },
      { id: 4, label: "Téléphone" },
    ],
    [{ id: 5, label: "Adresse" }],
    [
      {
        id: 6,
        label: "Ville",
        placeholder: "Sélectionner",
        options: [
          { name: "Sélectionner", code: "Sélectionner" },
          { name: "Option 1", code: "Option 1" },
          { name: "Option 2", code: "Option 2" },
        ],
      },

      {
        id: 7,
        label: "Code postal",
      },
    ],
    [
      {
        id: 8,
        label: "Pays",
        placeholder: "Sélectionner",
        options: [
          { name: "Sélectionner", code: "Sélectionner" },
          { name: "Option 1", code: "Option 1" },
          { name: "Option 2", code: "Option 2" },
        ],
      },
    ],
  ];

  return (
    <div className="d-flex flex-column">
      <span className="sBold f23 text-lightBlack">Réservation au salon</span>

      <div className="mt-4 alignH justify-content-between align-items-stretch">
        <div className="w60 pe-3" style={{ borderRight: "1px solid #efefef" }}>
          {formLabels?.map((item, index) => (
            <div className="row w100 my-3" key={index}>
              <div
                className={`d-flex flex-column ${
                  item?.length === 1 && item[0]?.label !== "Pays"
                    ? "col-12"
                    : "col-6"
                } gap-2`}
              >
                <span className="text-softBlack normalf16">
                  {item[0]?.label}
                </span>
                {item[0]?.options ? (
                  <Select
                    placeholder="Sélectionner"
                    options={item[0]?.options}
                  />
                ) : (
                  <TextInput />
                )}
              </div>
              {item[1] && (
                <div className="d-flex flex-column col-6 gap-2">
                  <span className="text-softBlack normal f16">
                    {item[1]?.label}
                  </span>
                  {item[1]?.options ? (
                    <Select
                      placeholder="Sélectionner"
                      options={item[1]?.options}
                    />
                  ) : (
                    <TextInput />
                  )}
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="colAlignC h100 align-items-start gap-2 w35">
          <span className="text-softBlack f16 normal">Message</span>
          <TextArea
            className={"w-100 b-2-softGrey h100"}
            rows={16}
            placeholder={
              "Important contacter le client la veille afin de confirmer les horaires de livraison."
            }
          />
        </div>
      </div>

      <div style={{ alignSelf: "center" }}>
        <Button className=" w-100 mt-5">Envoyer</Button>
      </div>
    </div>
  );
};

export default ReserverSalon;
