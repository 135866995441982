import gql from "graphql-tag";
import { ATTRIBUTES_PANIER } from "../Attributes/panier";

export const GET_PANIER = gql`
  query ($id: ID!) {
    panier(id: $id) {
      ${ATTRIBUTES_PANIER}
    }
  }
`;
