import React, { useEffect, useState } from "react";
import RadioInput from "../../Common/FormElements/RadioInput/RadioInput";
import { useDispatch, useSelector } from "react-redux";
import { openCenteredModal } from "../../../REDUX/actions/centeredModal";
import DeliveryDialog from "./DeliveryDialog";
import TextInput from "../../Common/FormElements/TextInput/TextInput";
import { updateDelivery } from "../../../REDUX/actions/panier";
import CustomTooltip from "../../Common/CustomTooltip/CustomTooltip";

function DeliveryOption({
  option,
  address,
  price,
  storeIcon,
  checkedOption,
  setCheckedOption,
  optionName,
  detailCamion,
  manual,
  stringPrice,
  basePrice,
}) {
  const dispatch = useDispatch();
  const { panier } = useSelector((state) => state.panierReducer);
  const [manualPrice, setManualPrice] = useState(price);
  const handleClick = () => {
    if (
      manual &&
      panier?.delivery?.mode !== "Frais de livraison manuel" &&
      checkedOption
    ) {
      setManualPrice();
      dispatch(
        updateDelivery({
          panierID: panier.id,
          deliveryID: panier.delivery.id,
          mode: `${optionName} ${detailCamion ?? ""}`,
          price: null,
        })
      );
    } else {
      dispatch(
        updateDelivery({
          panierID: panier.id,
          deliveryID: panier.delivery.id,
          mode: `${optionName} ${detailCamion ?? ""}`,
          price: manual ? manualPrice : price,
        })
      );
    }
    dispatch(
      openCenteredModal(
        <DeliveryDialog
          mode={`${optionName} ${detailCamion ?? ""}`}
          price={price}
          submitText="choisir"
        />
      )
    );
    setCheckedOption(`${optionName} ${detailCamion ?? ""}`);
  };

  const handleBlur = (e) => {
    dispatch(
      updateDelivery({
        panierID: panier.id,
        deliveryID: panier.delivery.id,
        mode: `${optionName} ${detailCamion ?? ""}`,
        price: Number(e.target.value),
      })
    );
  };
  const selectedClassname =
    `${optionName} ${detailCamion ?? ""}` === checkedOption?.trim()
      ? "selected"
      : "";

  useEffect(() => {
    if (manual && !checkedOption?.trim() !== optionName?.trim()) {
      setManualPrice();
    }
  }, [checkedOption, manual, optionName, price]);

  return (
    <div
      onClick={handleClick}
      className={`deliveryOption px-3 mb-3 d-flex align-items-center justify-content-between b-1-darkGrey br5 pointer ${selectedClassname}`}
    >
      <div className="d-flex align-items-center">
        <RadioInput
          name="delivery"
          checked={
            `(${optionName} ${detailCamion ?? ""})` === checkedOption?.trim()
          }
        />
        {storeIcon ? (
          <i className="bi bi-shop-window f44 text-brightGrey mx-5" />
        ) : (
          <i className="bi bi-truck f44 text-brightGrey mx-5" />
        )}
        <div>
          <p className="f16 sBold text-customBlack mb-0">
            {option} &nbsp; <span className="text-blue">{detailCamion}</span>
          </p>
          {address ? (
            <p className="f16 italic text-brightGrey mb-0">{address}</p>
          ) : null}
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-end gap-2">
        {manual && checkedOption?.trim() === option?.trim() && (
          <TextInput
            inputClassName="text-end pe-2 w-50 ms-auto"
            type="number"
            onBlur={handleBlur}
            stopPropagation
            value={manualPrice}
            callback={(e) => setManualPrice(e.target.value)}
            defaultValue={manualPrice}
          />
        )}
        {(checkedOption?.trim() === option.trim() || !manual) && (
          <CustomTooltip
            delivery
            title={
              <div
                className="f18 bold text-start text-customBlack mb-0"
                // style={{ width: "400px", backgroundColor: "red" }}
              >
                {manual ? (
                  ""
                ) : (
                  <div>
                    <span className="f8 mt-0 pt-0 bold">
                      prix de base : {basePrice}€
                    </span>
                    <p
                      className="f8 m-0"
                      dangerouslySetInnerHTML={{ __html: stringPrice }}
                    ></p>
                    <p className="f14 pt-2">prix final : {price} €</p>
                  </div>
                )}
              </div>
            }
          >
            <div className="bold">{price} €</div>
          </CustomTooltip>
        )}
      </div>
    </div>
  );
}

export default DeliveryOption;
