import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import CurrentOrdersList from "./CurrentOrdersList";
import Button from "../../Common/FormElements/Button/Button";
import CurrentOrderItem from "./CurrentOrderItem";
import { currentOrdersData } from "../../../helpers/fakeData/Dashboard";
import { CommandesSvg } from "../../Svgs";

import "./CurrentOrders.css";
import { getOrders } from "../../../REDUX/actions/orders";
import { fetchCommandes } from "../../../REDUX/actions/commandes";

const CurrentOrders = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { commandes } = useSelector((state) => state.commandeReducer);

  useEffect(() => {
    dispatch(fetchCommandes());
  }, [dispatch]);

  const displayedOrders = commandes;

  return (
    <div className="bg-white br5 shadow14 px-4 pt-4 gap-3 min-h-70">
      <div className="alignC justify-content-between w-100 mb-3">
        <div className="alignH gap-2 ">
          <CommandesSvg color={"#14748e"} width={18} />
          <span className="quickSand f22 bold text-blue">
            Commandes en cours
          </span>
        </div>
        <Button onClick={() => navigate("/commandes")}>Voir plus</Button>
      </div>
      <div className="colAlignC"></div>
      <CurrentOrdersList />
      {displayedOrders?.slice(0, 5)?.map((order, index) => (
        <div className="colAlignC" key={order?.id}>
          <CurrentOrderItem
            id={order?.id}
            number={order?.number}
            total={order?.totalTTC}
            date={order?.createdAt}
            status={order?.status}
            reseller={order?.reseller?.name}
          />
          {index + 1 !== displayedOrders?.slice(0, 5).length && (
            <hr style={{ borderColor: "#abb8b2", margin: 0 }} width={"100%"} />
          )}
        </div>
      ))}
    </div>
  );
};

export default CurrentOrders;
