import React from "react";
import "./Button.css";
import { Spinner } from "react-bootstrap";

const Button = ({
  disabled,
  variant,
  onClick = () => {},
  children,
  Icon,
  RightIcon,
  className = "",
  style,
  isSubmit,
  loading,
  formDataIncomplete, // New prop to indicate if formData is incomplete
}) => {
  const isDisabled = disabled || formDataIncomplete;

  return (
    <button
      type={isSubmit ? "submit" : "button"}
      onClick={isDisabled || loading ? undefined : onClick}
      disabled={isDisabled}
      style={style}
      className={`custom-btn ${
        variant === "outline" && "outline-btn"
      } flex-nowrap ${
        variant === "outline" ? "border-blue border-2" : "border-0"
      } ${RightIcon ? "ps-4 pe-3" : "px-4"} br5 ${
        variant === "outline"
          ? "text-blue"
          : isDisabled
          ? "text-grey"
          : "text-white"
      } ${
        variant === "outline"
          ? "bg-white"
          : variant === "lightBlue"
          ? "bg-lightBlue"
          : isDisabled
          ? "bg-lightGrey"
          : "bg-blue"
      } f15 normal ${className} `}
    >
      <div className="alignC">
        <div>
          {Icon && (
            <div className="alignC me-2">
              <Icon />
            </div>
          )}
        </div>
        {loading ? (
          <Spinner
            animation="border"
            variant={`${variant === "outline" ? "blue" : "light"}`}
            className="small-spinner"
          />
        ) : (
          children
        )}
        <div>
          {RightIcon && (
            <div className="alignC ms-4 ">
              <RightIcon />
            </div>
          )}
        </div>
      </div>
    </button>
  );
};

export default Button;
