import React from "react";
import "./CalendarHeader.css";

function CalendarHeader() {
  return (
    <div className="calendarHeader d-flex">
      <div className="slot firstSlot d-flex justify-content-center align-items-center">
        <p className="mb-0 f15 sBold text-customBlack">Lundi</p>
      </div>
      <div className="slot d-flex justify-content-center align-items-center">
        <p className="mb-0 f15 sBold text-customBlack">Mardi</p>
      </div>
      <div className="slot d-flex justify-content-center align-items-center">
        <p className="mb-0 f15 sBold text-customBlack">Mercredi</p>
      </div>
      <div className="slot d-flex justify-content-center align-items-center">
        <p className="mb-0 f15 sBold text-customBlack">Jeudi</p>
      </div>
      <div className="slot d-flex justify-content-center align-items-center">
        <p className="mb-0 f15 sBold text-customBlack">Vendredi</p>
      </div>
      <div className="slot d-flex justify-content-center align-items-center">
        <p className="mb-0 f15 sBold text-customBlack">Samedi</p>
      </div>
      <div className="slot lastSlot d-flex justify-content-center align-items-center">
        <p className="mb-0 f15 sBold text-customBlack">Dimanche</p>
      </div>
    </div>
  );
}

export default CalendarHeader;
