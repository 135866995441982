export const TicketProblemPicker = (problem) => {
  switch (problem) {
    case 1:
      return "Panne";
    case 2:
      return "Électricité";
    default:
      return "Panne";
  }
};
