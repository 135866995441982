import React from "react";
import RadioInput from "../../../Components/Common/FormElements/RadioInput/RadioInput";

function PaymentOption({
  title,
  children,
  paymenMethod,
  setPaymenMethod,
  methodName,
}) {
  const handleClick = () => {
    setPaymenMethod(methodName);
  };
  const selectedClassname = methodName === paymenMethod ? "selected" : "";

  return (
    <div
      onClick={handleClick}
      className={`paymentOption b-1-darkGrey p-3 br5 pointer ${selectedClassname}`}
    >
      <div className="d-flex align-items-center mb-2">
        <RadioInput name="payment" checked={paymenMethod === methodName} />
        <p className="f18 bold text-customBlack mb-0 ms-2">{title}</p>
      </div>
      <div>{children}</div>
    </div>
  );
}

export default PaymentOption;
