import React from "react";

import CustomCheckBox from "../../Common/CustomCheckBox/CustomCheckBox";
import VerticalPoints from "../../Common/VerticalPoints/VerticalPoints";
import { openRightModal } from "../../../REDUX/actions/rightModal";
import { useDispatch } from "react-redux";
import NewDepense from "./NewDepense";
import { IMAGE_END_POINT } from "../../../Api/EndPoints";
import { saveAs } from "file-saver";
import { deleteExpensesItem } from "../../../REDUX/actions/expenses";
import { openCenteredModal } from "../../../REDUX/actions/centeredModal";
import ConfirmModal from "../../Common/Modal/ConfirmModal";
import { getFileName } from "../../../Utils/fileUtils";
import adjustPrice from "../../../Utils/AdjustPrice";
import moment from "moment";

const DepenseItem = ({
  id,
  checked,
  vendor,
  TVA,
  amountHT,
  amountTTC,
  date,
  title,
  type,
  payment_1,
  payment_2,
  payment_3,
  fileUrl,
  comments,
  attachmentId,
  fileId,
  fileName,
  setCheckedItems,
}) => {
  const dispatch = useDispatch();

  // const paidClassName = status === "Soldé" ? "paid" : "";
  const checkedClassName = checked ? "checked" : "";
  const detailsOptions = [
    {
      text: "Modifier la dépense",
      callback: () => dispatch(openRightModal(<NewDepense id={id} />)),
    },
    {
      text: "Télécharger la facture",
      callback: () =>
        saveAs(`${IMAGE_END_POINT}${fileUrl}`, getFileName(fileUrl)),
      hidden: !fileUrl,
    },
    {
      text: "Ajouter un commentaire",
      callback: () =>
        dispatch(
          openRightModal(<NewDepense id={id} initialTab="Commentaire" />)
        ),
    },
    {
      text: "Supprimer la dépense",
      red: true,
      callback: () =>
        dispatch(
          openCenteredModal(
            <ConfirmModal
              label="la dépense"
              id={id}
              onConfirm={() =>
                dispatch(deleteExpensesItem(id, attachmentId, fileId, comments))
              }
            />
          )
        ),
    },
  ];

  const depenseColStyle = `f14 text-brightGrey mb-0 col-1`;

  const handleClick = () => {
    // setChecked(!checked);
    if (checked) {
      setCheckedItems((currItems) =>
        currItems?.filter((item) => +item?.id !== +id)
      );
    } else {
      setCheckedItems((currItems) => [
        ...currItems,
        { id, attachmentId, fileId, comments, fileName, fileUrl },
      ]);
    }
  };

  return (
    <div
      className={`factureItem alignH b-1-bottom-softGrey justify-content-between p-3 pointer ${checkedClassName}`}
      role="button"
      onClick={handleClick}
    >
      <CustomCheckBox
        checked={checked}
        className="me-3"
        // callback={handleClick}
      />
      <p className={depenseColStyle}>{moment(date)?.format("DD-MM-YYYY")}</p>
      <p className={depenseColStyle}>{title}</p>
      <p className={depenseColStyle}>{vendor}</p>

      <p className={depenseColStyle}>{type}</p>
      <p className={depenseColStyle}>
        {amountTTC ? adjustPrice(amountTTC.toFixed(2).toString()) : "0.00"} €
      </p>
      <p className={depenseColStyle}>
        {TVA ? adjustPrice(TVA.toFixed(2).toString()) : "0.00"} €
      </p>
      <p className={depenseColStyle}>
        {amountHT ? adjustPrice(amountHT.toFixed(2).toString()) : "0.00"} €
      </p>
      <p className={depenseColStyle}>
        {payment_1 ? adjustPrice(payment_1.toFixed(2).toString()) : "0.00"} €
      </p>
      <p className={depenseColStyle}>
        {payment_2 ? adjustPrice(payment_2.toFixed(2).toString()) : "0.00"} €
      </p>
      <p className={depenseColStyle}>
        {payment_3 ? adjustPrice(payment_3.toFixed(2).toString()) : "0.00"} €
      </p>
      <div className="col-1">
        <VerticalPoints options={detailsOptions} showLeft className="ms-auto" />
      </div>
    </div>
  );
};

export default DepenseItem;
