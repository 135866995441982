import React from "react";
import productImage from "../../../Images/Images/Catalaogue/productImage.jpg";
import { getStatusStyle } from "../../../Utils";

function NewSAVSummary() {
  const statusStyle = getStatusStyle("En cours");
  return (
    <div className="newSavSummary p-5 d-flex justify-content-between">
      <div className="detailsColumn">
        <div className="firstRow">
          <p className="f14 text-softBlack bold mb-1">N° de ticket</p>
          <p className="f14 text-softBlack mb-1">15221</p>
        </div>
        <div className="secondRow">
          <p className="f14 text-softBlack bold mb-1">Responsable</p>
          <p className="f14 text-softBlack mb-1">Lorem ipsum</p>
        </div>
        <div className="thridRow">
          <p className="f14 text-softBlack bold mb-1">Statut</p>
          <div
            className={`br8 p-2 statusContainer colAlignC ms-1 ${statusStyle.statusBgStyle}`}
          >
            <span
              className={`w-100 textCenter f12 medium ${statusStyle.statusTextStyle}`}
            >
              En Cours
            </span>
          </div>
        </div>
        <div>
          <p className="f14 text-softBlack bold mb-1 ">Technicien</p>
          <i className="bi bi-check-circle checkIcon f20 ms-1" />
        </div>
      </div>
      <div className="detailsColumn">
        <div className="firstRow">
          <p className="f14 text-softBlack bold mb-1">Date du ticket</p>
          <p className="f14 text-softBlack mb-1">21/06/2018</p>
        </div>
        <div className="secondRow">
          <p className="f14 text-softBlack bold mb-1">Date de livraison</p>
          <p className="f14 text-softBlack mb-1">21/06/2018</p>
        </div>
        <div className="thridRow">
          <p className="f14 text-softBlack bold mb-1">Problème</p>
          <p className="f14 text-softBlack mb-1">12334 - Dupont Jacques</p>
        </div>
      </div>
      <div className="detailsColumn">
        <div className="firstRow">
          <p className="f14 text-softBlack bold mb-1">Revendeur</p>
          <p className="f14 text-softBlue mb-1">Jacques</p>
          <p className="f14 text-softBlue mb-1">Ipsum SPA</p>
        </div>
        <div className="secondRow">
          <p className="f14 text-softBlack bold mb-1">Ref client</p>
          <p className="f14 text-softBlack mb-1">Dupont Jacques</p>
        </div>
        <div className="thridRow">
          <p className="f14 text-softBlack bold mb-1">Date de relance</p>
          <p className="f14 text-softBlack mb-1">21/06/2018</p>
        </div>
      </div>
      <div className="detailsColumn w35">
        <div className="descriptionContainer">
          <p className="f14 text-softBlack bold mb-1">Description</p>
          <p className="f14 text-softBlack mb-1">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry s standard dummy text
            ever since the1500s,
          </p>
        </div>
        <div>
          <p className="f14 text-softBlack bold mb-3">Pièces jointes</p>
          <img
            src={productImage}
            alt="piece jointe"
            className="br6 pointer attachments me-3 mb-3"
            width={106}
            height={67}
          />
          <img
            src={productImage}
            alt="piece jointe"
            className="br6 pointer attachments me-3 mb-3"
            width={106}
            height={67}
          />
          <img
            src={productImage}
            alt="piece jointe"
            className="br6 pointer attachments me-3 mb-3"
            width={106}
            height={67}
          />
          <img
            src={productImage}
            alt="piece jointe"
            className="br6 pointer attachments me-3 mb-3"
            width={106}
            height={67}
          />
        </div>
      </div>
    </div>
  );
}

export default NewSAVSummary;
