import React, { useState } from "react";

import "./VerticalPoints.css";
import OutsideClickHandler from "react-outside-click-handler";
import { Spinner } from "react-bootstrap";

const ButtonCore = ({
  red,
  index,
  callback,
  options = [],
  text,
  loading,
  setActive,
}) => {
  const colorClassName = red ? "text-brightRed" : "text-brightGrey";
  return (
    <button
      key={index}
      onClick={(e) => {
        e.stopPropagation();
        callback && callback();
        setActive(false);
      }}
      className={`optionBtn border-0 w100 py-2 text-nowrap ${
        index === 0 ? "pt-2" : index === options?.length - 1 && "pb-2"
      } px-3 d-flex f15 normal bg-transparent ${colorClassName}`}
    >
      {loading ? (
        <div className="alignC w100">
          <Spinner
            animation="border"
            variant={"blue"}
            className="small-spinner"
          />
        </div>
      ) : (
        text
      )}
    </button>
  );
};

function VerticalPoints({ options = [], showLeft, className = "" }) {
  const [active, setActive] = useState(false);

  const activeClassName = active ? "active" : "";
  const detailsShowClassName = active ? "d-block" : "d-none";
  const showOnLeftClassName = showLeft ? "showLeft" : "";

  const handleClick = (e) => {
    setActive(!active);
    e.stopPropagation();
  };

  return (
    <div className={`verticalPointsContainer position-relative ${className}`}>
      <OutsideClickHandler onOutsideClick={() => setActive(false)}>
        <div
          className="iconContainer br50 d-flex justify-content-center align-items-center pointer"
          onClick={handleClick}
        >
          <i
            className={`bi bi-three-dots-vertical verticalPoints f25 text-brightGrey ${activeClassName}`}
          />
        </div>

        <div
          className={`detailsContainer alignH position-absolute zIndex-3 br5 bg-white ${detailsShowClassName} ${showOnLeftClassName}`}
        >
          {options?.map((option, index) =>
            option?.hidden ? null : option.downloadUrl ? (
              <a
                key={index}
                href={option.downloadUrl}
                download={"pdf-sample"}
                target="_blank"
                rel="noreferrer"
              >
                <ButtonCore
                  {...option}
                  options={options}
                  setActive={setActive}
                />
              </a>
            ) : (
              <ButtonCore
                key={index}
                {...option}
                options={options}
                setActive={setActive}
              />
            )
          )}
        </div>
      </OutsideClickHandler>
    </div>
  );
}

export default VerticalPoints;
