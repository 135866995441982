import React from "react";
import "./CustomCheckBox.css";
import { CheckBoxSVG } from "../../Svgs";

function CustomCheckBox({ className, checked, callback }) {
  return (
    <div
      className={`customCheckBoxContainer pointer d-flex justify-content-center align-items-center ${className}`}
      role="button"
      onClick={callback}
    >
      {checked ? (
        <CheckBoxSVG className="checkedCustomCheckBox" />
      ) : (
        <div className="customCheckBox"></div>
      )}
    </div>
  );
}

export default CustomCheckBox;
