import React from "react";
import Button from "../Common/FormElements/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/fontawesome-free-solid";
import { useDispatch } from "react-redux";
import { openRightModal } from "../../REDUX/actions/rightModal";
import NewApporteurAffaire from "./NewApporteurAffaire/NewApporteurAffaire";

function ApporteurAffairesHeader() {
  const dispatch = useDispatch();

  const handleOpenModal = () => {
    dispatch(openRightModal(<NewApporteurAffaire />));
  };
  return (
    <div className="alignH justify-content-between gap-3">
      <div className="alignH">
        <h2 className="text-blue bold f30 mb-0 ms-4">Apporteur d'affaires</h2>
      </div>
      <div className="alignH gap-3 ">
        <Button
          Icon={() => <FontAwesomeIcon icon={faPlus} size={"xs"} />}
          onClick={handleOpenModal}
        >
          Ajouter un apporteur d'affaires
        </Button>
      </div>
    </div>
  );
}

export default ApporteurAffairesHeader;
