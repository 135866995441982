/* -------------------------------------------------------------------------- */
/*                                dependencies                                */
/* -------------------------------------------------------------------------- */
// packages
import React from "react";
import { Routes as Switch, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
// Local UI components
import DashboardView from "../Views/DashboardView";
import MonEquipeView from "../Views/MonEquipeView";
import ProduitsView from "../Views/ProduitsView";
import ProductDetails from "../Components/Catalogue/ProductDetails/ProductDetails";
import PanierDetails from "../Components/Catalogue/PanierDetails/PanierDetails";
import DeliveryDetails from "../Components/Catalogue/DeliveryDetails/DeliveryDetails";
import PaymentDetails from "../Components/Catalogue/PaymentDetails/PaymentDetails";
import CommandesView from "../Views/CommandesView";
import ApporteurAffaires from "../Views/ApporteurAffaires";
import RevendeurView from "../Views/RevendeurView";
import ProspectsView from "../Views/ProspectsView";
import IntermediaresProfiles from "../Components/Revendeur/IntermediaresProfiles/IntermediaresProfiles";
import CalendarView from "../Views/CalendarView";
import FaqView from "../Views/FaqView";
import MainFacturesView from "../Views/MainFacturesView";
import SavView from "../Views/SavView";
import CatalogueNewView from "../Views/CatalogueNewView";

const Routes = () => {
  /* ****************************** CONSTS & VARS ***************************** */
  const { userRole } = useSelector((state) => state.userReducer);
  /* ******************************** Rendering ******************************* */
  return (
    <Switch>
      <Route path="/" exact element={<DashboardView />} />
      {userRole === "admin" && (
        <>
          <Route path="/catalogue" element={<CatalogueNewView />} exact />
          <Route path="/produits" element={<ProduitsView />} exact />
        </>
      )}
      {userRole !== "businessBroker" && (
        <Route path="/equipe" exact element={<MonEquipeView />}></Route>
      )}
      <Route path="/catalogue" element={<CatalogueNewView />} exact />
      {/* <Route path="/cataloguenew" element={<CatalogueNewView />} exact /> */}
      <Route path="/catalogue/produit/:id" element={<ProductDetails />} />
      <Route path="/catalogue/panier" element={<PanierDetails />} />
      <Route path="/catalogue/livraison" element={<DeliveryDetails />} />
      <Route path="/catalogue/paiement" element={<PaymentDetails />} />
      <Route path="/commandes" element={<CommandesView />} />
      <Route path="/apporteur-affaires" element={<ApporteurAffaires />} />
      <Route path="/revendeur" element={<RevendeurView />} />
      <Route path="/prospects" element={<ProspectsView />} />
      <Route
        path="/revendeur/:id/profils"
        element={<IntermediaresProfiles />}
      />
      <Route path="/calendrier" element={<CalendarView />} />
      <Route path="/guide" element={<FaqView />} />
      <Route path="/facture" element={<MainFacturesView />} />
      <Route path="/SAV" element={<SavView />} />
      <Route path="*" exact element={<Navigate to="/" />} />
    </Switch>
  );
};
export default Routes;
