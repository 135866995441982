import React from "react";
import ShadowLayer from "./ShadowLayer";

function ShadowLoader({ loading, error, children }) {
  return !loading ? (
    !error ? (
      <>{children}</>
    ) : (
      <p className="m-5">Erreur!</p>
    )
  ) : (
    <ShadowLayer>{children}</ShadowLayer>
  );
}

export default ShadowLoader;
