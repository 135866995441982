import React from "react";
import LongSwitch from "../../Common/LongSwitch/LongSwitch";
import DateSlider from "./DateSlider/DateSlider";
// import FiltersContainer from "../../Common/Calendar/FiltersContainer/FiltersContainer";
// import CalendarFilters from "./FiltersContainer/CalendarFilters";

import "./CalendarToolbar.css";

function CalendarToolbar() {
  // const [showFitlers, setshowFitlers] = useState(false);
  // const activeFilterClassname = showFitlers ? "active" : "";

  // const handleShowFilter = () => {
  //   setshowFitlers(!showFitlers);
  // };
  return (
    <div className="calendarToolbar d-flex flex-column">
      <div className="alignH w100 justify-content-between">
        {/* <div className="w55 alignH  justify-content-between"> */}
        <div className="w100 alignH  justify-content-center">
          {/* <LongSwitch /> */}
          <DateSlider />
        </div>
        <div></div>
        {/* <Button
          className={`toolbarFilterBtn ${activeFilterClassname}`}
          Icon={() => <i className="bi bi-funnel " />}
          onClick={handleShowFilter}
        >
          Filtres
        </Button> */}
      </div>
      {/* {showFitlers ? (
        <FiltersContainer showFitlers={showFitlers}>
          <CalendarFilters />
        </FiltersContainer>
      ) : null} */}
    </div>
  );
}

export default CalendarToolbar;
