import React from "react";

import FacturesView from "../../../Views/FacturesView";
import SynthesesTabContent from "./SynthesesTabContent";
import DepensesTabContent from "./DepensesTabContent";

const FacturesViewTabsContent = ({ activeTab }) => {
  switch (activeTab) {
    case "Synthèses":
      return <SynthesesTabContent activeTab={activeTab} />;
    case "Dépenses":
      return <DepensesTabContent activeTab={activeTab} />;
    case "Factures":
      return <FacturesView />;
    default:
      return <SynthesesTabContent />;
  }
};

export default FacturesViewTabsContent;
