import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import MessageItem from "../../Sav/NewTicketSav/ChatContainer/MessageItem";
import MessageInput from "../../Sav/NewTicketSav/ChatContainer/MessageInput";
import {
  createOrderComment,
  getOrderComments,
} from "../../../REDUX/actions/commandes";

function Commentaires() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userReducer);
  const {
    commande: order,
    currentComments,
    currentCommentsLoading: loading,
  } = useSelector((state) => state.commandeReducer);
  const [newMessage, setNewMessage] = useState({ text: "", attachments: [] });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "attachments") {
      setNewMessage({
        ...newMessage,
        [name]:
          newMessage?.attachments?.length > 0
            ? [...newMessage?.attachments, ...value]
            : value,
      });
    } else {
      setNewMessage({ ...newMessage, [name]: value });
    }
  };

  const handleSubmit = (e, callback) => {
    e.preventDefault();
    if (
      newMessage?.text?.trim()?.length > 0 ||
      newMessage?.attachments?.length > 0
    ) {
      dispatch(
        createOrderComment(newMessage, order?.id, user?.id, () => {
          setNewMessage({ text: "", attachments: [] });
          callback();
        })
      );
    }
  };

  useEffect(() => {
    if (order?.id) {
      dispatch(getOrderComments(order?.id));
    }
  }, [dispatch, order?.id]);

  return (
    <div className="ps-5 pe-3 pt-4 newSavComment d-flex flex-column justify-content-end ">
      <div className="d-flex pe-4 flex-column overflow-auto flex-column-reverse scroll-snap-Ymandatory">
        {currentComments.map((comment, index) => (
          <MessageItem
            key={comment?.id}
            index={index}
            message={comment}
            isOwner={+user?.id === +comment?.attributes?.user?.data?.id}
          />
        ))}
      </div>
      <div className={"mt-5 pe-4"}>
        <MessageInput
          loading={loading}
          handleChange={handleChange}
          newMessage={newMessage}
          setNewMessage={setNewMessage}
          handleSubmit={handleSubmit}
        />
      </div>
    </div>
  );
}

export default Commentaires;
