const approvDetailsLabels = [
  { label: "Model", className: "col-3" },
  { label: "Référence", className: "col-1" },
  { label: "Cuve", className: "col-1" },
  { label: "Panneau", className: "col-1" },
  { label: "N° série", className: "col-1" },
  { label: "Prix achat", className: "col-1" },
  { label: "QC", className: "col-1" },
  { label: "Coût achat", className: "col-1" },
  { label: "Transport", className: "col-1" },
  { label: "Coût rendu", className: "col-1" },
];

export default approvDetailsLabels;
