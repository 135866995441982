import moment from "moment";
import React, { useEffect, useState } from "react";
// redux
import { useDispatch, useSelector } from "react-redux";
import {
  getApprovs,
  getApprovsSelect,
} from "../../../REDUX/actions/approvisionnement";
import Button from "../../Common/FormElements/Button/Button";
import CalendarInput from "../../Common/FormElements/CalendarInput/CalendarInput";
import Select from "../../Common/FormElements/Select/Select";

const ApprovFilters = ({ searchInput }) => {
  /* ********************************** Hooks ********************************* */
  // state
  const [filters, setFilters] = useState({});
  const dispatch = useDispatch();
  const { approvSelect } = useSelector((state) => state.approvReducer);
  // effect
  useEffect(() => {
    dispatch(getApprovsSelect({}));
  }, []);
  /* ********************************* methods ******************************** */
  const formatFactoriesOptions = (approvs = []) => {
    const uniqueFactories = new Set();
    const formattedOptions = [];
    approvs?.forEach((entry) => {
      const factory = entry.factory;
      if (!uniqueFactories.has(factory)) {
        uniqueFactories.add(factory);
        formattedOptions.push({
          code: factory,
          name: factory,
        });
      }
    });
    return formattedOptions;
  };

  /* ******************************** callBacks ******************************* */
  const handleCalendarInput = (e, name) => {
    const { value } = e;
    const selectedValue = moment(value);
    // define map property
    const propertyMap = {
      year: selectedValue.year(),
      month: selectedValue.month() + 1,
    };
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: propertyMap[name],
    }));
  };
  const handleSelectChange = (e) => {
    const { value, name } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value.code,
    }));
  };
  const handleSubmitFilter = () => {
    dispatch(getApprovs({ ...filters, searchInput }));
  };
  // let filtersFormIncomplete =
  //   !filters.year || !filters.month || !filters.factory;
  /* ******************************** Rendering ******************************* */
  return (
    <div className="d-flex justify-content-between">
      <div className="w20">
        <label className="f16 text-softBlack mb-2 sBold">Année</label>
        <CalendarInput
          placeholder="Année"
          className="w100"
          view={"year"}
          dateFormat="yy"
          minDate={false}
          callback={(e) => handleCalendarInput(e, "year")}
        />
      </div>
      <div className="w20">
        <label className="f16 text-softBlack mb-2 sBold d-flex flex-column w100">
          Mois
        </label>
        <CalendarInput
          placeholder="Mois"
          className="w100"
          view="month"
          dateFormat="mm"
          callback={(e) => handleCalendarInput(e, "month")}
          minDate={false}
        />
      </div>
      <div className="w20">
        <label className="f16 text-softBlack mb-2 sBold">Usine</label>
        <Select
          options={formatFactoriesOptions(approvSelect)}
          placeholder={"Usine"}
          name="factory"
          callback={handleSelectChange}
        />
      </div>
      <div className="w20 d-flex justify-content-center align-items-end">
        <Button
          className="filterSubmitBtn"
          onClick={handleSubmitFilter}
          // formDataIncomplete={filtersFormIncomplete}
        >
          Filtrer
        </Button>
      </div>
    </div>
  );
};

export default ApprovFilters;
