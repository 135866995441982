import React, { useEffect, useState } from "react";
import DeliveryOption from "./DeliveryOption";
import { useSelector } from "react-redux";
import {
  handleAccessoryMultiplier,
  handleMultipleDelivery,
} from "./multipleDelivery";
import {
  calculateTotalWheight,
  handleAccessoryDelivery,
  handleSpaDelivery,
} from "./accessoryDelivery";

function DeliveryOptions({ setShowDialog, callback }) {
  const { panier } = useSelector((state) => state.panierReducer);
  const [multiplier, setmultiplier] = useState(1);
  const [accessoryMultiplier, setaccessoryMultiplier] = useState(1);
  const [deliveryDescription, setdeliveryDescription] = useState("");
  const [filteredDeliveryOptions, setfilteredDeliveryOptions] = useState([]);
  const [haveModels, sethaveModels] = useState(false);

  const [checkedOption, setCheckedOption] = useState(panier?.delivery?.mode);

  useEffect(() => {
    const handleDeliveryTypes = () => {
      setdeliveryDescription("");
      let models = [];
      let accessories = [];
      let accessoriesWithKangourou = [];
      let accessoriesWithoutKangourou = [];
      if (panier?.panierItems) {
        panier?.panierItems.forEach((item) => {
          for (let i = 0; i < item.quantity; i++) {
            if (item.packings.length) {
              const model =
                item.packings[0]?.attributes?.model?.data?.attributes;

              if (model.productType === 1) {
                models.push(model);
              } else {
                accessories.push(model);
                if (model.kangourou) {
                  accessoriesWithKangourou.push(model);
                } else {
                  accessoriesWithoutKangourou.push(model);
                }
              }
            }
          }
        });
        models?.sort(
          (a, b) =>
            b?.product_size?.data?.attributes?.ordre -
            a?.product_size?.data?.attributes?.ordre
        );

        if (models?.length === 0) {
          sethaveModels(false);
          if (accessoriesWithKangourou.length === 0) {
            setaccessoryMultiplier(
              handleAccessoryMultiplier(accessoriesWithoutKangourou)
            );
            setfilteredDeliveryOptions([
              {
                serviceName: "Livraison accessoire",
                Messagerie: 100,
              },
            ]);
            setdeliveryDescription(
              ` + ${accessoriesWithoutKangourou.length} produits en livraison messagerie : 100€ x ${accessoryMultiplier} </br>`
            );
          } else if (accessoriesWithoutKangourou.length === 0) {
            const totalWhight = calculateTotalWheight(accessoriesWithKangourou);
            setfilteredDeliveryOptions(
              handleAccessoryDelivery(accessoriesWithKangourou)
            );
            setdeliveryDescription(`Poid total ${totalWhight / 1000} kg </br>`);
          } else {
            setfilteredDeliveryOptions([]);
          }
        } else {
          sethaveModels(true);
          setfilteredDeliveryOptions(handleSpaDelivery(panier, models));
          setmultiplier(handleMultipleDelivery(models));
          setaccessoryMultiplier(
            handleAccessoryMultiplier(accessoriesWithoutKangourou)
          );

          setdeliveryDescription(
            `${models.length} produit - multiplicateur x ${multiplier} </br>`
          );

          if (accessoriesWithoutKangourou?.length) {
            setdeliveryDescription(
              (prevState) =>
                `${prevState} + ${accessoriesWithoutKangourou?.length} produits en livraison messagerie : 100€ x ${accessoryMultiplier} </br>`
            );
          }
          if (accessoriesWithKangourou?.length) {
            setdeliveryDescription(
              (prevState) =>
                `${prevState} + ${accessoriesWithKangourou?.length} produits dans les poches kangourou : gratuit </br>`
            );
          }
          setdeliveryDescription(
            (prevState) =>
              `${prevState} Calcul : prix de base * ${multiplier}${
                accessoryMultiplier ? ` + ${accessoryMultiplier} *100€` : ``
              }`
          );
        }
      }
    };
    handleDeliveryTypes();
  }, [accessoryMultiplier, multiplier, panier, panier?.panierItems]);

  return (
    <div>
      <DeliveryOption
        key={33}
        option="Récupération au dépôt de Poseidon"
        optionName="Récupération au dépôt de Poseidon"
        checkedOption={checkedOption}
        setCheckedOption={setCheckedOption}
        setShowDialog={setShowDialog}
        price={0}
        stringPrice={`Gratuit`}
        storeIcon
        callback={callback}
      />
      <DeliveryOption
        key={34}
        option="Frais de livraison manuel"
        optionName="Frais de livraison manuel"
        checkedOption={checkedOption}
        setCheckedOption={setCheckedOption}
        setShowDialog={setShowDialog}
        price={panier?.delivery?.price}
        callback={callback}
        manual
      />

      {filteredDeliveryOptions?.map((delivery, index) =>
        Object.keys(delivery)?.map(
          (el, z) =>
            el !== "ServiceType" &&
            el !== "serviceName" && (
              <DeliveryOption
                key={index + z}
                option={delivery.serviceName}
                checkedOption={checkedOption}
                setCheckedOption={setCheckedOption}
                setShowDialog={setShowDialog}
                optionName={delivery?.serviceName}
                detailCamion={el}
                stringPrice={`${deliveryDescription} `}
                basePrice={delivery[el]}
                price={
                  haveModels
                    ? accessoryMultiplier === 0
                      ? delivery[el] * multiplier
                      : delivery[el] * multiplier + 100 * accessoryMultiplier
                    : accessoryMultiplier <= 1
                    ? delivery[el]
                    : delivery[el] * accessoryMultiplier
                }
                callback={callback}
              />
            )
        )
      )}
    </div>
  );
}

export default DeliveryOptions;
