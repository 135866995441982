import React from "react";

import "./RadioInput.css";

function RadioInput({ id, name, text, checked }) {
  return (
    <div className="d-flex align-items-center pointer">
      <input
        name={name}
        type="radio"
        className="radioInput pointer"
        id={id}
        checked={checked}
        // just to remvoe console warning
        onChange={() => {}}
      />
      {text ? (
        <label className="ms-2 mb-0 pointer" htmlFor={id}>
          {text}
        </label>
      ) : null}
    </div>
  );
}

export default RadioInput;
