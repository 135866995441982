const initialState = {
  deliveryTypes: [],
  loading: false,
  error: null,
};

const deliveryTypeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_DELIVERY_TYPES_LOADING":
      return { ...state, loading: true, error: null };
    case "FETCH_DELIVERY_TYPES_SUCCESS":
      return { ...state, loading: false, deliveryTypes: payload, error: null };
    case "FETCH_DELIVERY_TYPES_ERROR":
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};

export default deliveryTypeReducer;
