import React from "react";

import Select from "../../Common/FormElements/Select/Select";
import TextInput from "../../Common/FormElements/TextInput/TextInput";
import Button from "../../Common/FormElements/Button/Button";
import { useSelector } from "react-redux";

const DepensesFilters = ({ filters, handleChange, handleSearch }) => {
  const { vendors } = useSelector((state) => state.expensesReducer);

  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-between gap-4">
        <div className="w25 d-flex flex-column">
          <label className="f16 text-softBlack mb-2 sBold">Année</label>
          <TextInput
            placeholder="Année"
            type="number"
            name="year"
            value={filters?.year ?? ""}
            callback={handleChange}
          />
        </div>
        <div className="w25 d-flex flex-column">
          <label className="f16 text-softBlack mb-2 sBold">Mois</label>
          <TextInput
            placeholder="Mois"
            type="number"
            name="month"
            value={filters?.month ?? ""}
            callback={handleChange}
          />
        </div>
        <div className="w25">
          <label className="f16 text-softBlack mb-2 sBold">Type</label>
          <TextInput
            type="text"
            placeholder="Type"
            name="type"
            value={filters?.type ?? ""}
            callback={handleChange}
          />
        </div>
        <div className="w25">
          <label className="f16 text-softBlack mb-2 sBold">Fournisseur</label>
          <Select
            name="vendor"
            options={vendors}
            placeholder="Fournisseur"
            value={filters?.vendors}
            callback={handleChange}
          />
        </div>
      </div>
      <div className="d-flex justify-content-between mt-4 gap-4">
        <div className="w25">
          <label className="f16 text-softBlack mb-2 sBold">Paiement</label>
          <TextInput
            type="number"
            euro
            name="payment"
            value={filters?.payment}
            callback={handleChange}
          />
        </div>
        <div className="w25"></div>
        <div className="w25"></div>
        <div className="w25 d-flex justify-content-end align-items-end">
          <div className="w100">
            <Button className="w100" onClick={handleSearch}>
              Filtrer
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DepensesFilters;
