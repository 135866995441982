import React from "react";
import CatalogCard from "./CatalogCard";
import { useSelector } from "react-redux";

const NewCatalogueList = ({ activeTab, searchInput, productsactiveTab }) => {
  const { catalogue } = useSelector((state) => state.catalogueReducer);

  return catalogue.map((product) => (
    <CatalogCard
      product={product}
      key={product.id}
      activeTab={activeTab}
      searchInput={searchInput}
      productsactiveTab={productsactiveTab}
    />
  ));
};

export default NewCatalogueList;
