import React from "react";
import "./ListHeaderButtons.css";

function DownloadButton({ callback, disabled = true }) {
  return (
    <button
      className="downloadButton br5 d-flex justify-content-center align-items-center border-0 me-2"
      disabled={disabled}
      onClick={callback}
    >
      <i className="bi bi-download " />
    </button>
  );
}

export default DownloadButton;
