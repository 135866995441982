import { ProgressSpinner } from "primereact/progressspinner";
import React from "react";
import "./ShadowLayer.css";

function ShadowLayer({ children }) {
  return (
    <div className="shadowLayerContainer position-relative">
      <div className="shadowSpinnerContainer">
        <ProgressSpinner
          style={{
            display: "flex",
            alignItems: "center",
            width: "50px",
            height: "50px",
          }}
          strokeWidth={3}
        />
      </div>
      <div className="shadowLayerContent">{children}</div>
    </div>
  );
}

export default ShadowLayer;
