import React, { useState } from "react";
import RightModal from "../Components/Modals/RightModal/RightModal";
import RevendeurViewHeader from "../Components/Revendeur/RevendeurViewHeader";
import RevendeurViewContent from "../Components/Revendeur/RevendeurViewContent";
import ViewScroller from "../Components/Common/ViewScroller/ViewScroller";

const RevendeurView = () => {
  const [search, setSearch] = useState("");
  const [isArchived, setIsArchived] = useState(false);

  return (
    <ViewScroller>
      <div className="w100 d-flex flex-column gap-4 h-100">
        <RevendeurViewHeader
          search={search}
          setSearch={setSearch}
          isArchived={isArchived}
          setIsArchived={setIsArchived}
        />
        <RevendeurViewContent
          isArchived={isArchived}
          search={search}
          setIsArchived={setIsArchived}
        />
        <RightModal width={60} />
      </div>
    </ViewScroller>
  );
};

export default RevendeurView;
