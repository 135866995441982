import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import TextInput from "../../Common/FormElements/TextInput/TextInput";
import Button from "../../Common/FormElements/Button/Button";
import { useDispatch } from "react-redux";
import { closeCenteredModal } from "../../../REDUX/actions/centeredModal";
const ExtraFields = ({ addAnExtraField }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };
  const SubmitAddExtraField = () => {
    const { inputName, valueName } = formData;
    console.log(inputName, valueName);
    if (inputName && valueName) {
      const newExtraField = { inputName, valueName };
      addAnExtraField(newExtraField);
      setFormData({});
      dispatch(closeCenteredModal());
    }
  };

  // console.log(extraFields);
  return (
    <div>
      <Row>
        <Col lg={6} className="mb-3 d-flex flex-column gap-2">
          <span className="text-softBlack normalf16">Nom du champ</span>
          <TextInput
            className={"w100"}
            type="string"
            name="inputName"
            value={formData.inputName}
            placeholder={"Nom du champ"}
            callback={handleChange}
          />
        </Col>
        <Col lg={6} className="mb-3 d-flex flex-column gap-2">
          <span className="text-softBlack normalf16">Valeur</span>
          <TextInput
            className={"w100"}
            type="string"
            name="valueName"
            value={formData.valueName}
            placeholder={"valeur"}
            callback={handleChange}
          />
        </Col>
        <Button onClick={SubmitAddExtraField}>Ajouter</Button>
      </Row>
    </div>
  );
};

export default ExtraFields;
