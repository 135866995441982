import React from "react";
import { useDispatch, useSelector } from "react-redux";
import MyVerticallyCenteredModal from "./MyVerticallyCenteredModal";
import { closeCenteredModal } from "../../../REDUX/actions/centeredModal";

import "./CenteredModal.css";

const CenteredModal = () => {
  const { open, content } = useSelector((state) => state.centeredModalReducer);

  const dispatch = useDispatch();
  return (
    <div className="centeredVerticalModal">
      <MyVerticallyCenteredModal
        show={open}
        onHide={() => dispatch(closeCenteredModal())}
        content={content}
      />
    </div>
  );
};

export default CenteredModal;
