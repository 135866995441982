import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import SavCard from "./SavCard";
import "./SavViewContent";
import LoaderWrapper from "../../Utils/ViewWrapper/LoaderWrapper/LoaderWrapper";
import { getTicketSAVs } from "../../REDUX/actions/ticketSAV";
import { getCurrentAffiliate } from "../../REDUX/actions/affiliate";

const SavViewContent = ({ searchInput, filtersInput }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userReducer);
  const { ticketSAVs, ticketSAVsLoading, ticketSAVsError, ticketSAVsReload } =
    useSelector((state) => state.ticketSAVReducer);
  const { currentAffiliate } = useSelector((state) => state.affiliateReducer);

  useEffect(() => {
    dispatch(getTicketSAVs({ searchInput, filtersInput }));
  }, [dispatch, searchInput, filtersInput, ticketSAVsReload]);

  useEffect(() => {
    dispatch(
      getCurrentAffiliate(user?.attributes?.business_affiliate?.data?.id)
    );
  }, [dispatch, user?.attributes?.business_affiliate?.data?.id]);

  return (
    <div className="flex-1 d-flex flex-column">
      <div className="row bg-dimGrey py-3 px-4 mx-0 w-100 d-flex justify-content-between align-items-center">
        <span className="col-1 text-brightGrey sBold f14">#</span>
        <span className="col-1 text-brightGrey sBold f14">Date du ticket</span>
        {user?.attributes?.userRole !== 0 && (
          <span className="col-1 text-brightGrey sBold f14">Revendeur</span>
        )}
        {user?.attributes?.userRole === 1 && (
          <span className="col-1 text-brightGrey sBold f14">Responsable</span>
        )}

        <span className="col-1 text-brightGrey sBold f14">
          Date de livraison
        </span>
        {user?.attributes?.userRole === 0 && (
          <span className="col-1 text-brightGrey sBold f14">
            Référence client
          </span>
        )}
        <span className="col-1 text-brightGrey sBold f14">Statut</span>
        <span className="col-1 text-brightGrey sBold f14">Problème</span>
        {user?.attributes?.userRole === 1 && (
          <span className="col-1 text-brightGrey sBold f14">
            Date de relance
          </span>
        )}
        <span className="col-1 text-brightGrey sBold f14">Technicien</span>
        <span className="col-1 text-brightGrey sBold f14"></span>
      </div>
      <div className="flex-1">
        <LoaderWrapper loading={ticketSAVsLoading} error={ticketSAVsError}>
          {(user?.attributes?.userRole === 2
            ? ticketSAVs?.filter((ticketSAV) =>
                currentAffiliate?.informations?.attributes?.organizations?.data
                  .map((affReseller) => affReseller.id)
                  .includes(ticketSAV?.reseller?.id)
              )
            : ticketSAVs
          )?.map((sav) => {
            return (
              <div key={sav?.id}>
                <SavCard {...sav} />
                <hr
                  style={{ borderColor: "#abb8b2", margin: 0 }}
                  width={"100%"}
                />
              </div>
            );
          })}
        </LoaderWrapper>
      </div>
    </div>
  );
};

export default SavViewContent;
