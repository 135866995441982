import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getStatusStyle, handleValue } from "../../../Utils";
import VerticalPoints from "../../Common/VerticalPoints/VerticalPoints";
import moment from "moment";
import { TicketProblemPicker } from "./TicketProblemPicker";
import {
  closeRightModal,
  openRightModal,
} from "../../../REDUX/actions/rightModal";
import ModifyOrder from "../../Commandes/ModifyOrder/ModifyOrder";

const NewSAVCommande = ({ savOrders, problem }) => {
  const { user } = useSelector((state) => state.userReducer);

  const navigate = useNavigate();

  const handleLinkToOrder = (id) => {
    dispatch(closeRightModal());
    navigate(`/commandes`);
    user?.attributes?.userRole !== 0 &&
      dispatch(openRightModal(<ModifyOrder id={id} />));
  };
  const handleLinkToProduct = (id) => {
    dispatch(closeRightModal());
    navigate(`/catalogue/produit/${id}`);
  };

  const dispatch = useDispatch();

  return (
    <div className="mt-2">
      <div className="d-flex bg-dimGrey p-3">
        <span className="sBold f14 text-lightBlack w10">#</span>
        <span className="sBold f14 text-lightBlack w20">Date</span>
        <span className="sBold f14 text-lightBlack w30">Produit</span>
        <span className="sBold f14 text-lightBlack w20">Statut</span>
        <span className="sBold f14 text-lightBlack w15">Problème</span>
        <span className="sBold f14 text-lightBlack w5"></span>
      </div>

      {savOrders?.map(
        ({ id, attributes: { status, createdAt, packings, number } }) => {
          const statusStyle = getStatusStyle(status);
          const statusClassName = `${statusStyle?.statusTextStyle} ${statusStyle?.statusBgStyle}`;

          return (
            <div key={id}>
              <div className="d-flex alignH p-3">
                <span
                  className="text-lightBlue f15 normal w10 pointer"
                  onClick={() => handleLinkToOrder(id)}
                >
                  {number}
                </span>
                <span className="text-lightBlack f15 normal w20">
                  {moment(createdAt).format("DD-MM-YYYY")}
                </span>
                <div className="d-flex flex-column w30">
                  {packings?.data?.map((packing) => {
                    return (
                      <span
                        className="text-lightBlue f15 normal pointer"
                        onClick={() =>
                          handleLinkToProduct(
                            packing?.attributes?.model?.data?.id
                          )
                        }
                      >
                        {packing?.attributes?.name}
                      </span>
                    );
                  })}
                </div>
                <div className="w20 ">
                  <span
                    className={`text-left br8 p-2 d-block ${statusClassName}
                    text-lightBlack f15 normal
                    `}
                  >
                    {status}
                  </span>
                </div>
                <span className="text-lightBlack f15 normal w15">
                  {handleValue(TicketProblemPicker(problem))}
                </span>
                <VerticalPoints
                  showLeft
                  options={[
                    {
                      text: "Voir la commande",
                      callback: () => {
                        handleLinkToOrder(id);
                      },
                    },
                    {
                      text: "Consulter le produit",
                      callback: () => {
                        handleLinkToProduct(
                          packings?.data[0]?.attributes?.model?.data?.id
                        );
                      },
                    },
                  ]}
                  className="ms-auto w5"
                />
              </div>
              <hr
                style={{ borderColor: "#abb8b2", margin: 0 }}
                width={"100%"}
              />
            </div>
          );
        }
      )}
    </div>
  );
};

export default NewSAVCommande;
