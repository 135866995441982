import React from "react";
import ViewHeader from "./ViewHeader";
import RightModal from "../../Modals/RightModal/RightModal";
import ViewContent from "./ViewContent";

function IntermediaresProfiles() {
  return (
    <div className="w100 d-flex flex-column gap-4 h-100">
      <ViewHeader />
      <ViewContent />
      <RightModal width={60} />
    </div>
  );
}

export default IntermediaresProfiles;
