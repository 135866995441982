import React, { useEffect, useState } from "react";
import ViewHeader from "../Components/Common/ViewHeader/ViewHeader";
import TextInput from "../Components/Common/FormElements/TextInput/TextInput";
import DownloadButton from "../Components/Common/ListHeaderButtons/DownloadButton";
import DeleteButton from "../Components/Common/ListHeaderButtons/DeleteButton";
import FacturesList from "../Components/Factures/FacturesList/FacturesList";
import ViewScroller from "../Components/Common/ViewScroller/ViewScroller";
import { useDispatch, useSelector } from "react-redux";
import { deleteInvoices, getInvoices } from "../REDUX/actions/invoices";
import DeleteInvoicesModal from "../Components/Factures/Modal/DeleteInvoicesModal";
import {
  closeCenteredModal,
  openCenteredModal,
} from "../REDUX/actions/centeredModal";
import { areFilesInList, handleDownload } from "../Utils/fileUtils";
import useOnScreen from "../hooks/useOnScreen";
import RightModal from "../Components/Modals/RightModal/RightModal";

const FacturesView = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userReducer);
  const [searchInput, setSearchInput] = useState();
  const [checkedInvoices, setCheckedInvoices] = useState([]);
  const { measureRef, isIntersecting } = useOnScreen();

  const canDelete = user?.attributes?.userRole === 1;

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSearch = (e) => {
    if (e) {
      e.preventDefault();
    }
    dispatch({ type: "INVOICES_RESET" });
    dispatch(getInvoices({ searchInput }));
  };

  const clearSearch = () => {
    dispatch({ type: "INVOICES_RESET" });
    setSearchInput("");
    dispatch(getInvoices({ searchInput: "" }));
  };

  const handleDeleteMany = () => {
    dispatch(
      deleteInvoices({ toDeleteInvoices: checkedInvoices, searchInput })
    );
    setCheckedInvoices([]);
    dispatch(closeCenteredModal());
  };
  const openDeleteModal = (action, message) => {
    dispatch(
      openCenteredModal(
        <DeleteInvoicesModal handleDelete={action} message={message} />
      )
    );
  };
  useEffect(() => {
    if (isIntersecting) {
      dispatch(getInvoices({ searchInput }));
    }
  }, [dispatch, isIntersecting]);

  useEffect(() => {
    return () => dispatch({ type: "INVOICES_RESET" });
  }, [dispatch]);

  return (
    <ViewScroller>
      <div className="flex-1 d-flex flex-column">
        <form onSubmit={handleSearch}>
          <ViewHeader title="Factures" className="facturesViewHeader">
            <TextInput
              withSearchBtn
              placeholder="Rechercher"
              className="w50"
              value={searchInput}
              callback={handleSearchChange}
              onSearchBtnClick={handleSearch}
              searchButtonType="submit"
              clearSearch={clearSearch}
            />
            <div className="listHeaderButtons d-flex">
              <DownloadButton
                disabled={!areFilesInList(checkedInvoices, "uploads")}
                callback={() => handleDownload(checkedInvoices)}
              />
              {canDelete && (
                <DeleteButton
                  callback={() =>
                    openDeleteModal(
                      handleDeleteMany,
                      "Êtes-vous sûr(e) de vouloir supprimer ces factures ?"
                    )
                  }
                  disabled={checkedInvoices.length <= 0}
                />
              )}
            </div>
          </ViewHeader>
        </form>
        <FacturesList
          measureRef={measureRef}
          searchInput={searchInput}
          checkedInvoices={checkedInvoices}
          setCheckedInvoices={setCheckedInvoices}
          openDeleteModal={openDeleteModal}
        />
        <RightModal width={70} className="pb-4" />
      </div>
    </ViewScroller>
  );
};

export default FacturesView;
