import React, { useEffect, useState } from "react";
import SideBar from "../../../Components/Layout/Sidebar/SideBar";
import Panier from "../Panier/Panier";
import ProductSummary from "./ProductSummary";
import ProductVideo from "./ProductVideo";
import OtherProducts from "./OtherProducts";
import ViewScroller from "../../Common/ViewScroller/ViewScroller";
import LoaderWrapper from "../../../Utils/ViewWrapper/LoaderWrapper/LoaderWrapper";
import AvailabilityVerifier from "../AvailabilityVerifier";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getOneProduct,
  getProductAccessories,
  getProductQuantity,
} from "../../../REDUX/actions/products";
import "./ProductDetails.css";
import moment from "moment";
import ProductStockItem from "../ProductStockItem";
import ProductAttachments from "./ProductAttachments";
import { documentExtensions } from "../../Produits/NewProductTabContents/data";

function ProductDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { productDetails, productDetailsLoading, productDetailsError } =
    useSelector((state) => state.productsReducer);

  const [unsoldPackings, setUnsoldPackings] = useState([]);

  useEffect(() => {
    dispatch(getOneProduct(id));
    dispatch(getProductAccessories(id));
  }, [dispatch, id]);

  useEffect(() => {
    const availablePackings = productDetails?.packings?.data?.filter(
      (packing) => !packing?.attributes?.sold
    );

    const filteredPackings = [];
    const arrivedPackings = [];
    availablePackings?.forEach((packing) => {
      const conditionalDate =
        packing?.attributes?.approvisionnement?.data?.attributes?.arrivalDate ||
        packing?.attributes?.approvisionnement?.data?.attributes?.estimatedDate;

      const arrived = moment(conditionalDate)?.diff(moment(), "day") < 0;

      const index = arrivedPackings?.findIndex(
        (p) =>
          p?.attributes?.cuve === packing?.attributes?.cuve &&
          p?.attributes?.panel === packing?.attributes?.panel
      );
      // console.log({ index });

      const unarrivedIndex = filteredPackings?.findIndex(
        (p) =>
          p?.attributes?.cuve === packing?.attributes?.cuve &&
          p?.attributes?.panel === packing?.attributes?.panel
      );

      // console.log({ unarrivedIndex });

      if (arrived) {
        if (index === -1) {
          const arrivalDate =
            packing?.attributes?.approvisionnement?.data?.attributes
              ?.arrivalDate;
          arrivedPackings?.unshift({
            ...packing,
            count: 1,
            ids: [{ id: packing.id, arrivalDate }],
          });
        } else if (index > -1) {
          const arrivalDate =
            packing?.attributes?.approvisionnement?.data?.attributes
              ?.arrivalDate;
          arrivedPackings[index].ids.push({ id: packing.id, arrivalDate });
          arrivedPackings[index].count =
            (arrivedPackings[index].count ?? 0) + 1;
        }
      } else if (!arrived) {
        const arrivalDate =
          packing?.attributes?.approvisionnement?.data?.attributes?.arrivalDate;
        const estimatedDate =
          packing?.attributes?.approvisionnement?.data?.attributes
            ?.estimatedDate;
        const serialNumber = packing?.attributes?.serialNumber;

        if (unarrivedIndex === -1) {
          const arrivalDate =
            packing?.attributes?.approvisionnement?.data?.attributes
              ?.arrivalDate;

          filteredPackings?.push({
            ...packing,
            unarrivedCount: 1,
            ids: [{ id: packing.id, arrivalDate, estimatedDate }],
            arrivals: [
              {
                arrivalDate,
                serialNumber,
                estimatedDate,
              },
            ],
          });
        } else if (unarrivedIndex > -1) {
          filteredPackings[unarrivedIndex].ids.push({
            id: packing.id,
            arrivalDate,
            estimatedDate,
          });
          filteredPackings[unarrivedIndex].unarrivedCount =
            (filteredPackings[unarrivedIndex]?.unarrivedCount ?? 0) + 1;
          filteredPackings[unarrivedIndex].arrivals = [
            ...(filteredPackings[unarrivedIndex]?.arrivals ?? []),
            {
              arrivalDate,
              serialNumber,
              estimatedDate,
            },
          ];
        }
      }
    });
    // console.log(count);
    setUnsoldPackings([
      ...filteredPackings,
      ...arrivedPackings.map((packing) => {
        const sortedIds = packing?.ids?.sort(
          (a, b) => new Date(a?.arrivalDate) - new Date(b?.arrivalDate)
        );
        return {
          ...packing,
          ids: sortedIds,
        };
      }),
    ]);
  }, [productDetails.packings]);
  /* ******************************** rendering ******************************* */

  // console.log({ unsoldPackings });

  const totalUnarrived = unsoldPackings?.reduce(
    (acc, { unarrivedCount }) => acc + (+unarrivedCount || 0),
    0
  );
  return (
    <ViewScroller>
      <LoaderWrapper
        loading={productDetailsLoading}
        error={productDetailsError}
      >
        <div className="productDetails d-flex justify-content-between">
          <div className={`mainWithSideBar`}>
            <div className="ps-3 pe-4">
              <Link
                className="f15 text-customBlack d-block mb-4"
                to="/cataloguenew"
              >
                ← Retour au catalogue
              </Link>
            </div>
            {/* <div className="ps-3 pe-4">
              <Link
                className="f15 text-customBlack d-block mb-4"
                to="/catalogue"
              >
                ← Retour au catalogue
              </Link>
              <div className="col-3">
                <AvailabilityVerifier
                  availableQuantity={getProductQuantity(
                    productDetails?.packings?.data
                  )}
                  // replenishmentDate={productDetails?.replenishmentDate}
                  replenishmentDate={
                    productDetails?.packings?.data[0]?.attributes
                      ?.approvisionnement?.data?.attributes?.estimatedDate
                      ? productDetails?.packings?.data[0]?.attributes
                          ?.approvisionnement?.data?.attributes?.estimatedDate
                      : productDetails?.packings?.data[0]?.attributes
                          ?.approvisionnement?.data?.attributes?.arrivalDate
                  }
                  packings={productDetails?.packings?.data}
                  fromProductDetails={true}
                  sold={
                    productDetails?.packings?.data?.filter(
                      ({ attributes }) => attributes?.sold === false
                    )?.length > 0
                      ? false
                      : true
                  }
                  totalUnarrived={totalUnarrived}
                />
              </div>
              <div className="d-flex align-items-center">
                <div>
                  <p className="mb-0 quickSand f24 bold text-blue me-5">
                    {productDetails.name}
                  </p>
                </div>
                <div className="d-flex align-items-center mx-5">
                  <p className="f16 sBold text-customBlack mb-0">
                    Prix Partenaire HT :&nbsp;
                  </p>
                  <p className="f16 sBold text-darkBlue mb-0">
                    {`${productDetails.retailerPriceHT ?? 0}`} €
                  </p>
                </div>
                <div className="d-flex align-items-center mx-5">
                  <p className="f16 sBold text-customBlack mb-0">
                    Prix Public TTC :&nbsp;
                  </p>
                  <p className="f16 sBold text-brown mb-0">
                    {`${productDetails.publicPriceTTC}`} €
                  </p>
                </div>
              </div>
              <p className="quickSand f26 text-blue">
                {productDetails.category?.data?.attributes?.name}
              </p>
              <div className="mb-5 row">
                <div className="col-6 max-w420px">
                  <ProductStockItem
                    id={id}
                    hasLoader
                    unsoldPackings={unsoldPackings?.slice(
                      unsoldPackings?.length / 2
                    )}
                    productName={productDetails?.name}
                    productType={productDetails?.productType}
                  />
                </div>
                <div className="col-6 max-w420px">
                  <ProductStockItem
                    id={id}
                    hasLoader
                    unsoldPackings={unsoldPackings?.slice(
                      0,
                      unsoldPackings?.length / 2
                    )}
                    productName={productDetails?.name}
                    productType={productDetails?.productType}
                  />
                </div>
              </div>
            </div> */}
            <ProductSummary productDetails={productDetails} />
            <ProductVideo
              links={productDetails.attachments?.data
                ?.filter((item) =>
                  item?.attributes?.file?.data?.attributes?.mime.includes(
                    "video"
                  )
                )
                .map((item) => item?.attributes?.file?.data?.attributes?.url)}
            />
            <ProductAttachments
              documents={productDetails?.attachments?.data?.filter((item) =>
                documentExtensions.find((ext) =>
                  item?.attributes?.file?.data?.attributes?.name?.includes(ext)
                )
              )}
            />
            {/* <OtherProducts /> */}
          </div>
          {/* {showSidebar && ( */}
          {/* <SideBar>
            <Panier buttonText="Passer la commande" />
          </SideBar> */}
          {/* )} */}
        </div>
      </LoaderWrapper>
    </ViewScroller>
  );
}

export default ProductDetails;
