import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getCatalogue } from "../REDUX/actions/catalogue";
import Cart from "../Components/NewComponents/Cart";
import Step1Catalogue from "../Components/NewComponents/CatalogueSteps/Step1Catalogue";
import Step2Catalogue from "../Components/NewComponents/CatalogueSteps/Step2Catalogue";
import Step3Catalogue from "../Components/NewComponents/CatalogueSteps/Step3Catalogue";
import Step4Catalogue from "../Components/NewComponents/CatalogueSteps/Step4Catalogue";
import CartSelector from "../Components/NewComponents/CartSelector";

const CatalogueNewView = () => {
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState();
  const [activeTab, setActiveTab] = useState("Produits");
  const [productsactiveTab, setproductsActiveTab] = useState("SPA");
  const [step, setStep] = useState(1);

  useEffect(() => {
    dispatch(getCatalogue(activeTab, productsactiveTab, searchInput));
  }, [activeTab, searchInput, productsactiveTab, dispatch]);

  return (
    <div className="position-relative">
      <div style={{ width: "65vw" }}>
        <CartSelector
          searchInput={searchInput}
          activeTab={activeTab}
          productsactiveTab={productsactiveTab}
        />
        {step === 1 && (
          <Step1Catalogue
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            productsactiveTab={productsactiveTab}
            setproductsActiveTab={setproductsActiveTab}
          />
        )}
        {step === 2 && (
          <Step2Catalogue
            searchInput={searchInput}
            activeTab={activeTab}
            productsactiveTab={productsactiveTab}
          />
        )}
        {step === 3 && (
          <Step3Catalogue
            searchInput={searchInput}
            activeTab={activeTab}
            productsactiveTab={productsactiveTab}
          />
        )}
        {step === 4 && (
          <Step4Catalogue
            searchInput={searchInput}
            activeTab={activeTab}
            productsactiveTab={productsactiveTab}
          />
        )}
      </div>
      <Cart
        step={step}
        setStep={setStep}
        activeTab={activeTab}
        searchInput={searchInput}
        productsactiveTab={productsactiveTab}
      />
    </div>
  );
};

export default CatalogueNewView;
