import React, { useState } from "react";
import ViewHeader from "../Components/Common/ViewHeader/ViewHeader";
import CalendarToolbar from "../Components/Calendar/CalendarToolbar/CalendarToolbar";
import CalendarMain from "../Components/Calendar/CalendarMain/CalendarMain";
import RightModal from "../Components/Modals/RightModal/RightModal";
import EventsAndNews from "../Components/Dashboard/EventsAndNews/EventsAndNews";
import CenteredModal from "../Components/Modals/CenteredModal/CenteredModal";
import ViewScroller from "../Components/Common/ViewScroller/ViewScroller";
import Select from "../Components/Common/FormElements/Select/Select";

const CalendarView = () => {
  const selectOptions = [
    { name: "Tous", code: "Tous" },
    { name: "Salons", code: "Salons" },
    { name: "Événements", code: "Événements" },
    { name: "Livraisons", code: "Livraisons" },
  ];
  const [selectedOption, setSelectedOption] = useState({
    name: "Tous",
    code: "Tous",
  });
  return (
    <ViewScroller>
      <div>
        <div className="row justify-content-between">
          <div className="col-3">
            <ViewHeader title="Calendrier" />
          </div>
          <div className="col-2">
            <Select
              options={selectOptions}
              placeholder="Select Option"
              value={selectedOption}
              callback={(e) => setSelectedOption(e.value)}
            />
          </div>
        </div>
        <CalendarToolbar />
        <CalendarMain selectedOption={selectedOption} />
        <RightModal width={55} />
        {/* <CenteredModal /> */}
        <div className="mt-5">
          <EventsAndNews />
        </div>
      </div>
    </ViewScroller>
  );
};

export default CalendarView;
