import React from "react";
import { useDispatch, useSelector } from "react-redux";

import adjustPrice from "../../../Utils/AdjustPrice";
import {
  decrementQuantity,
  incrementQuantity,
} from "../../../REDUX/actions/panier";
import defaultImage from "../../../Images/Images/default-image.webp";

import "./ProductItem.css";
import { useLocation } from "react-router-dom";

function ProductItem({
  id,
  photo,
  name,
  description,
  retailerPriceHT: price,
  category,
  quantity,
  panierID,
  packings,
}) {
  const location = useLocation();
  const canChangeQuantity = location?.pathname !== "/catalogue/paiement";
  const currentPacking = packings[0];
  const dispatch = useDispatch();
  const { panier, canOrderWithoutPay } = useSelector(
    (state) => state.panierReducer
  );
  const addItem = () => {
    if (
      !!panier?.panierItems?.find(
        (item) => +item?.id === +id && item?.availableQuantity <= item?.quantity
      )
    ) {
      return;
    }
    dispatch(
      incrementQuantity({
        panierItemID: id,
        oldQuantity: quantity,
        panierID: panierID,
      })
    );
  };
  const removeItem = () => {
    dispatch(
      decrementQuantity({
        panierItemID: id,
        oldQuantity: quantity,
        panierID: panierID,
      })
    );
  };
  const calculateTotal = (price, quantity) => {
    return (price * quantity).toFixed(2).toString();
  };
  return (
    <div className="productItem alignH mb-3 w-100">
      <img
        src={photo?.length > 0 ? photo : defaultImage}
        alt=""
        width={74}
        className={`br5 me-3 ${photo?.length > 0 ? "" : "object-cover"}`}
      />
      <div className="w100 d-flex flex-column justify-content-between">
        <p className="mb-1 f14 sBold">{name}</p>
        {/* {description ? (
          <p className="f10 text-brightGrey">{description}</p>
        ) : (
          <p className="f14  sBold">{category?.data?.attributes?.name}</p>
        )} */}
        {currentPacking?.attributes?.cuve && (
          <p className="f12 text-customBlack mb-0">
            Cuve : {currentPacking?.attributes?.cuve}
          </p>
        )}
        {currentPacking?.attributes?.panel && (
          <p className="f12 text-customBlack">
            Jupe : {currentPacking?.attributes?.panel}
          </p>
        )}

        <div className="d-flex justify-content-between">
          <div className="d-flex">
            {canChangeQuantity && (
              <i
                className="bi bi-trash3 text-lameRed counterIcon me-3 pointer"
                onClick={removeItem}
              />
            )}
            <p className="f16 mb-0">{quantity}</p>
            {/* {canChangeQuantity && (
              <i
                className="bi bi-plus-lg counterIcon ms-2 pointer"
                onClick={addItem}
              ></i>
            )} */}
          </div>
          <p className="mb-0 text-customBlack">
            {canOrderWithoutPay
              ? 0
              : adjustPrice(calculateTotal(price, quantity))}
            €
          </p>
        </div>
      </div>
    </div>
  );
}

export default ProductItem;
