/* -------------------------------------------------------------------------- */
/*                                dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// Local UI components
import TabsNavbar from "../Components/Common/TabsNavBar/TabsNavbar";
import CommandesViewTabsContent from "../Components/Commandes/CommandesViewTabsContent";
import CommandesTabContent from "../Components/Commandes/CommandesTabContent";
import { fetchCommandes } from "../REDUX/actions/commandes";

const CommandesView = () => {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState("Commandes");
  const { userRole, user } = useSelector((state) => state.userReducer);
  const canSeeAprrov = userRole === "admin";

  useEffect(() => {
    // console.log(user);
    if (user) {
      dispatch(fetchCommandes());
    }
  }, [dispatch, user.id]);

  return (
    <div className="w100 d-flex flex-column gap-4 h-100">
      {!canSeeAprrov ? (
        <CommandesTabContent activeTab={activeTab} />
      ) : (
        <>
          <TabsNavbar
            tabs={["Commandes", "Approvisionnements"]}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            className="gap-5"
          />
          <CommandesViewTabsContent activeTab={activeTab} />
        </>
      )}
    </div>
  );
};

export default CommandesView;
