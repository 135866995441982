import React, { useState, useEffect } from "react";
import VerticalPoints from "../../Common/VerticalPoints/VerticalPoints";
import { getStatusStyle } from "../../../Utils";
import TicketStatusTag from "../../Sav/NewTicketSav/TicketStatusTag";
import moment from "moment";
import { useDispatch } from "react-redux";
import { openRightModal } from "../../../REDUX/actions/rightModal";
import ModifyOrder from "../../Commandes/ModifyOrder/ModifyOrder";
import adjustPrice from "../../../Utils/AdjustPrice";

const CurrentOrderItem = ({ id, number, total, date, status, reseller }) => {
  const columnStyle = "col-2 textStart text-brightGrey f14 normal py-4";
  const [statusStyle, setStatusStyle] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    setStatusStyle(getStatusStyle(status));
  }, [status]);

  return (
    <div className="row w-100 ps-5 alignH justify-content-between">
      <span className={columnStyle}>{`#${number}`}</span>
      <span className={columnStyle}>{reseller}</span>

      <span className={`${columnStyle} text-brightGrey`}>{`${adjustPrice(
        (total ?? 0).toFixed(2).toString()
      )} €`}</span>
      <span className={columnStyle}>{moment(date).format("DD-MM-YYYY")}</span>
      <div className={`alignH justify-content-between col-2 pe-0`}>
        <div className={`br8 statusContainer ${statusStyle?.statusBgStyle}`}>
          <TicketStatusTag status={status} />
        </div>
      </div>
      <div className="col-1 alignH justify-content-end">
        <VerticalPoints
          showLeft
          options={[
            {
              text: "Commentaire",
              callback: () =>
                dispatch(
                  openRightModal(
                    <ModifyOrder id={id} initialTab="Commentaires" />
                  )
                ),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default CurrentOrderItem;
