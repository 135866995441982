import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Offcanvas } from "react-bootstrap";
import { closeRightModal } from "../../../REDUX/actions/rightModal";
import "./RightModal.css";

const RightModal = ({ width = 50, className = "", id = "" }) => {
  const { open, content } = useSelector((state) => state.rightModalReducer);
  const dispatch = useDispatch();
  const handleClose = () => dispatch(closeRightModal());
  const contentClassName = "mt-3";

  return (
    <div className="rightModalContainer">
      <Offcanvas
        show={open}
        onHide={handleClose}
        placement="end"
        className={`rightModalContent h100 pb-5 ${className}`}
        style={{ width: `${width}vw` }}
        id={id}
      >
        <Offcanvas.Header
          closeButton
          className="rightModalHeader"
        ></Offcanvas.Header>
        <Offcanvas.Body
          className={`rightModalBody ${contentClassName} customScroll`}
        >
          {content}
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default RightModal;
