import React from "react";
import ProductCarousel from "./ProductCarousel";
import ProductTable from "./ProductTable";

function ProductSummary({ productDetails }) {
  return (
    <div className="d-flex">
      <ProductCarousel photos={productDetails.photos} />
      <ProductTable productDetails={productDetails} />
    </div>
  );
}

export default ProductSummary;
