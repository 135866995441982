import React, { useState } from "react";
import ProductTableHeader from "./ProductTableHeader";
import ProductTableBody from "./ProductTableBody";
import ProductTableFooter from "./ProductTableFooter";

function ProductTable({ productDetails }) {
  const [activeTab, setActiveTab] = useState("Caractéristiques");
  const TABS = {
    SPA: ["Caractéristiques", "Equipement", "LightSensor Serie"],
    "SPA DE NAGE": ["Caractéristiques", "Equipement", "LightSensor Serie"],
    SAUNA: ["Caractéristiques", "Equipement"],
  };
  /* ******************************** RENDERING ******************************* */
  return (
    <div className="productTable w60">
      <ProductTableHeader
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabs={TABS[productDetails?.category?.data?.attributes?.name]}
      />
      <ProductTableBody productDetails={productDetails} activeTab={activeTab} />
      <ProductTableFooter productDetails={productDetails} />
    </div>
  );
}

export default ProductTable;
