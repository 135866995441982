export const formLabelsNewDep = [
  [
    {
      label: "Titre de la dépense",
      type: "text",
      placeholder: "Titre",
      name: "title",
      required: true,
    },
    {
      label: "Fournisseur",
      type: "text",
      placeholder: "Fournisseur",
      name: "vendor",
      required: true,
    },
    {
      label: "Type",
      type: "input",
      placeholder: "Type",
      name: "type",
      required: true,
    },
  ],
  [
    {
      label: "Date",
      type: "calendar",
      placeholder: "Date",
      name: "date",
      required: true,
    },
    {
      label: "Montant TTC",
      type: "number",
      placeholder: "Montant TTC",
      euro: true,
      name: "amountTTC",
      required: false,
    },
    {
      label: "TVA 20%",
      type: "number",
      placeholder: "TVA",
      euro: true,
      name: "TVA",
      required: false,
      disabled: false,
    },
  ],
  [
    {
      label: "Montant HT",
      type: "number",
      placeholder: "Montant HT",
      euro: true,
      name: "amountHT",
      required: true,
    },
    {
      label: "Date de paiement",
      type: "calendar",
      placeholder: "Date",
      name: "paymentDate",
      required: false,
    },
  ],
  [
    {
      label: "Paiement 1",
      type: "number",
      placeholder: "Paiement",
      euro: true,
      name: "payment_1",
      required: false,
    },
    {
      label: "Paiement 2",
      type: "number",
      placeholder: "Paiement",
      euro: true,
      name: "payment_2",
      required: false,
    },
    {
      label: "Paiement 3",
      type: "number",
      placeholder: "Paiement",
      euro: true,
      name: "payment_3",
      required: false,
    },
  ],
];
