import React from "react";
import GeneralInfo from "../../../Common/Forms/GeneralInfo/GeneralInfo";
import AddressInfo from "../../../Common/Forms/AddressInfo/AddressInfo";
import CompanyInfo from "../../../Common/Forms/CompanyInfo/CompanyInfo";
import NewGuideQuestions from "../../../Faq/NewGuideQuestions";
import NewGuideFichiers from "../../../Faq/NewGuideFichiers";

function TabContent({
  activeTab,
  formData,
  setFormData = () => {},
  attachments,
  setAttachments = () => {},
}) {
  switch (activeTab) {
    case "Infos générales":
      return <GeneralInfo />;
    case "Adresse":
      return <AddressInfo />;
    case "Société":
      return <CompanyInfo />;
    case "Question":
      return (
        <NewGuideQuestions formData={formData} setFormData={setFormData} />
      );
    case "Fichiers":
      return (
        <NewGuideFichiers
          attachments={attachments}
          setAttachments={setAttachments}
        />
      );
    default:
      <GeneralInfo />;
  }
}

export default TabContent;
