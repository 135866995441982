import React, { useEffect } from "react";
import { Fragment } from "react";
import { useLocation } from "react-router-dom";

function ViewScroller({ children }) {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <Fragment>{children}</Fragment>;
}

export default ViewScroller;
