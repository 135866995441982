import React from "react";
import RadioInput from "../../Common/FormElements/RadioInput/RadioInput";
import CustomTooltip from "../../Common/CustomTooltip/CustomTooltip";
import { useDispatch, useSelector } from "react-redux";
import { updateCart } from "../../../REDUX/actions/catalogue";

const DeliveryOptionNew = ({
  deliveryOption,
  manual,
  storeIcon,
  option = "",
  checkedOption = "",
  setSelectedDelivery,
  selectedDelivery,
}) => {
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => state.catalogueReducer);

  return (
    <div
      onClick={() => {
        setSelectedDelivery(deliveryOption);
        dispatch(
          updateCart(cart.id, {
            delivery_type: deliveryOption.id,
            deliveryPrice: deliveryOption.price,
            deliveryOptionName: deliveryOption.name,
            facture: null,
            bon_de_commande: null,
          })
        );
      }}
    >
      <div
        className={`deliveryOption px-3 mb-3 d-flex align-items-center justify-content-between b-1-darkGrey br5 pointer ${
          deliveryOption.name === selectedDelivery.name ? "selected" : ""
        }`}
      >
        <div className="d-flex align-items-center">
          <RadioInput
            name="delivery"
            checked={deliveryOption.name === selectedDelivery.name}
          />
          {storeIcon ? (
            <i className="bi bi-shop-window f44 text-brightGrey mx-5" />
          ) : (
            <i className="bi bi-truck f44 text-brightGrey mx-5" />
          )}
          <div>
            <p className="f16 sBold text-customBlack mb-0">
              {option} &nbsp;{" "}
              <span className="text-blue">{deliveryOption.name}</span>
            </p>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-end gap-2">
          {manual && deliveryOption.name === selectedDelivery.name && (
            <input
              style={{ outline: "none" }}
              className="text-end p-2 pe-2 w-50 ms-auto textInput br6 normal border"
              type="number"
              defaultValue={cart.deliveryPrice}
              onChange={(e) =>
                dispatch(
                  updateCart(cart.id, {
                    deliveryPrice: e.target.value,
                    facture: null,
                    bon_de_commande: null,
                  })
                )
              }
            />
          )}
          {(checkedOption?.trim() === option.trim() || !manual) && (
            <CustomTooltip
              delivery
              title={
                <div className="f18 bold text-start text-customBlack mb-0">
                  {manual ? (
                    ""
                  ) : (
                    <div>
                      <p
                        className="f12 m-0"
                        dangerouslySetInnerHTML={{
                          __html: deliveryOption.explanation,
                        }}
                      ></p>
                    </div>
                  )}
                </div>
              }
            >
              <div className="bold">
                {deliveryOption.price} €{" "}
                <span className="f10">{cart.particulier ? "TTC" : "HT"}</span>
              </div>
            </CustomTooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeliveryOptionNew;
