import qs from "qs";

export const buildGuidesQuery = (fields, fileFields, filters) => {
  const queryObject = {
    populate: {
      attachments: {
        fields,
        populate: { file: { fields: fileFields } },
      },
      category: true,
      gamme: true,
      model: true,
      business_affiliate: true,
    },
    filters: filters,
    sort: "createdAt:desc",
  };
  const queryOptions = { encodeValuesOnly: true };
  return qs.stringify(queryObject, queryOptions);
};
