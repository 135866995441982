import React from "react";

import "./ProductStats.css";
import adjustPrice from "../../../Utils/AdjustPrice";

const ProductStats = ({ title, statValue }) => {
  const formattedValue = adjustPrice(statValue.toFixed(2).toString());
  return (
    <div className="bg-white br5 colAlignC justify-content-between align-items-start shadow26 w-100 gap-3 productStatsContainer">
      <span className="text-blue bold f22 quickSand">{title}</span>
      <span className="black f44 bold">
        {formattedValue.substring(
          0,
          formattedValue.split("")?.findIndex((el) => el === "."),
        )}
      </span>
    </div>
  );
};

export default ProductStats;
