export const formLabels = [
  [
    { label: "Nom", placeholder: "Nom", name: "lastName", type: "text" },
    { label: "Prénom", placeholder: "Prénom", name: "firstName", type: "text" },
  ],
  [
    {
      type: "tel",
      label: "Téléphone",
      placeholder: "Téléphone",
      name: "phoneNumber",
    },
    { type: "email", label: "E-mail", placeholder: "E-mail", name: "email" },
  ],
  [
    {
      type: "text",
      label: "Référence client",
      placeholder: "",
      name: "refClient",
    },
    {
      type: "calender",
      label: "Date création 1er contact",
      placeholder: "",
      name: "firstContactDate",
      separator: true,
    },
  ],
  [
    {
      label: "Adresse",
      type: "text",
      placeholder: "Adresse",
      name: "adresse",
      span: 12,
    },
  ],
  [
    {
      type: "select",
      label: "Pays",
      name: "country",
    },
    {
      type: "select",
      label: "Ville",
      name: "city",
      subOption: "country",
      optionsName: "cities",
    },
  ],
];
export const formDataInitialValues = {
  lastName: "",
  firstName: "",
  phoneNumber: "",
  email: "",
  // refClient: "",
  firstContactDate: null,
  adresse: "",
  city: "",
  country: "",
};
