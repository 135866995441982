const initialState = {
  approvsLoading: false,
  approvsError: null,
  approvs: [],
  approvsReload: false,
  approvLoading: false,
  approvError: null,
  approv: null,
  approvReload: false,
  approvSelect: [],
  approvSelectLoading: false,
  currentComments: [],
  currentCommentsLoading: false,
  commentsLoading: false,
  approvsCount: null,
};

const approvReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "APPROVS_LOADING": {
      return { ...state, approvsLoading: true };
    }

    case "APPROVS_ERROR": {
      return { ...state, approvsLoading: false, approvsError: payload };
    }
    case "APPROVS_FETCHED": {
      return { ...state, approvsLoading: false, approvs: payload };
    }
    case "APPROVS_SELECT_LOADING": {
      return { ...state, approvSelectLoading: true };
    }
    case "APPROV_SELECT_FETCHED": {
      return { ...state, approvSelectLoading: false, approvSelect: payload };
    }
    case "APPROVS_RELOAD": {
      return { ...state, approvsReload: !state.approvsReload };
    }
    case "APPROV_LOADING": {
      return { ...state, approvLoading: true };
    }
    case "APPROV_ERROR": {
      return { ...state, approvLoading: false, approvError: payload };
    }
    case "APPROV_FETCHED": {
      return { ...state, approvLoading: false, approv: payload };
    }
    case "APPROV_RELOAD": {
      return { ...state, approvReload: !state.approvReload };
    }
    case "APPROV_CREATED": {
      return {
        ...state,
        approvLoading: false,
      };
    }
    case "APPROV_UPDATED": {
      return { ...state, approvsLoading: false, approvLoading: false };
    }
    case "APPROV_DELETED": {
      return { ...state, approvsLoading: false };
    }

    case "CREATE_COMMENTS_CONTAINER_LOADING":
      return { ...state, currentCommentsLoading: true };
    case "CREATE_COMMENTS_CONTAINER_SUCCESS":
      return {
        ...state,
        currentCommentsLoading: false,
        currentComments: [payload, ...state.currentComments],
      };

    case "FETCH_COMMENTS_CONTAINER_LOADING":
      return { ...state, commentsLoading: true };

    case "FETCH_COMMENTS_CONTAINER_SUCCESS":
      return { ...state, currentComments: payload, commentsLoading: false };

    case "CLEAR_CURR_APPROV":
      return {
        ...state,
        approv: null,
        currentComments: [],
        commentsLoading: false,
      };
    case "RESET_APPROVS":
      return {
        ...state,
        approvs: [],
        approvsCount: null,
        approvsLoading: false,
      };

    default:
      return state;
  }
};

export default approvReducer;
