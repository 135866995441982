import React from "react";

import TicketSAVPreview from "./TicketSAVPreview";

const TicketsSAVValues = ({ values }) => {
  return (
    <div>
      {values?.slice(0, 5)?.map((ticketSAV) => (
        <div className="colAlignC" key={ticketSAV?.id}>
          <TicketSAVPreview {...ticketSAV} ticketSAV={ticketSAV} />
          <hr style={{ borderColor: "#abb8b2", margin: 0 }} width={"100%"} />
        </div>
      ))}
    </div>
  );
};

export default TicketsSAVValues;
