import React from "react";
import moment from "moment";
import { formatDate } from "../../../Utils/fileUtils";

const DateTemplate = ({ date, eventsDates }) => {
  // Format the day number
  const formattedDay = moment(date).format("DD");
  return (
    <div className="d-flex flex-column align-items-center justify-content-center customEventDay">
      <span className={`text-brightGrey f16 normal position-relative`}>
        {formattedDay}
      </span>
      <div className="d-flex gap-1">
        {eventsDates?.map((entry) => {
          if (entry?.startDate === formatDate(date)) {
            return (
              <span
                className={`br50  eventDayIndicator ${entry?.backgroundColor}`}
                style={{
                  width: "6px",
                  height: "6px",
                }}
              ></span>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default DateTemplate;
