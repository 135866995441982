import QueryString from "qs";

export const prospectsQuery = () =>
  QueryString.stringify(
    {
      fields: [
        "id",
        "lastName",
        "firstName",
        "phoneNumber",
        "email",
        "refClient",
        "firstContactDate",
      ],
      populate: {
        comments: {
          populate: {
            user: {
              populate: true,
            },
          },
        },
        address: {
          populate: {
            country: true,
            city: true,
          },
        },
      },
    },
    { encodeValuesOnly: true }
  );
