import React, { useState } from "react";
import { HeaderLogoSvg } from "../Svgs";
import TextInput from "../Common/FormElements/TextInput/TextInput";
import PassswordInput from "../Common/Forms/ResetPassword/PassswordInput";
import Button from "../Common/FormElements/Button/Button";
import { Alert } from "react-bootstrap";
import emailValidation from "./emailValidation";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../REDUX/actions/user";
import "./LoginForm.css";

function LoginForm() {
  const userData = useSelector((state) => state.userReducer);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);

  const dispatch = useDispatch();
  const handleEmailChange = (e) => {
    if (!emailValidation(e.target.value) && e.target.value !== "") {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };
  return (
    <form className="loginForm bg-white br5 d-flex flex-column justify-content-center align-items-center">
      <HeaderLogoSvg textColor="blue" imageURL="poseidon-login-logo.svg" />
      <div className="mt-4 w60">
        <p className="f16 text-ultraBlack mb-2">E-mail</p>
        <TextInput callback={handleEmailChange} autoComplete="username" />
        <div className="errorMessageContainer">
          {emailError ? (
            <span className="f12 text-red">Votre e-mail est invalide!</span>
          ) : null}
        </div>
      </div>
      <PassswordInput
        text="Mot de passe"
        className="w60 loginPasswordInput"
        withoutPlaceHolder
        callback={handlePasswordChange}
      />
      <a
        href="/login"
        className="forgotPasswordLink f12 d-block w60 text-end text-blue"
      >
        Mot de passe oublié ?
      </a>
      {userData.error ? (
        <Alert
          variant="danger"
          className="alignH justify-content-center w60 errorAlert mt-3"
        >
          Email ou mot de passe incorrect!
        </Alert>
      ) : null}
      <Button
        loading={userData.userLoading}
        className="mt-3 w20"
        onClick={handleSubmit}
        isSubmit={true}
        disabled={!email || !password || emailError}
      >
        Connexion
      </Button>
    </form>
  );
}

export default LoginForm;
