import QueryString from "qs";

export const ordersQuery = () => {
  return QueryString.stringify(
    {
      fields: [
        "id",
        "oldId",
        "productsCount",
        "refClient",
        "status",
        "total",
        "createdAt",
        "oldCreatedAt",
        "status",
        "commission",
        "margeNet",
        "txMarge",
        "montantHT",
        "TVA",
        "buyer",
        "paymentStatus",
        "phoneNumber",
        "realRefClient",
        "slug",
      ],
      populate: {
        reseller: {
          populate: {
            billingAddress: {
              populate: {
                country: true,
              },
            },
          },
        },
        invoice: {
          fields: ["slug", "slugDate"],
          populate: {
            attachment: {
              populate: { file: true },
            },
          },
        },
        delivery: {
          populate: {
            address: {
              city: { populate: true },
              country: { populate: true },
            },
          },
        },
        payments: {
          fields: ["id", "amount", "date"],
        },
        panier_items: {
          fields: ["id", "quantity", "refClient", "itemAmount"],
          populate: {
            product: {
              fields: ["id", "name", "productType", "publicPriceTTC"],
              populate: {
                attachments: {
                  fields: ["title"],
                  populate: {
                    file: { fields: ["url"] },
                  },
                },
                category: { fields: ["name"] },
              },
            },
            packings: {
              fields: ["id", "buyingCost", "deliveredCost", "cuve", "panel"],
              populate: {
                model: {
                  fields: [
                    "id",
                    "name",
                    "productType",
                    "publicPriceTTC",
                    "buyingPrice",
                    "retailerPriceHT",
                    "TVA",
                    "reference",
                  ],
                  populate: {
                    gamme: true,
                    attachments: {
                      fields: ["title"],
                      populate: {
                        file: { fields: ["url"] },
                      },
                    },
                    category: { fields: ["name"] },
                    caracteristique: {
                      fields: ["weight", "palletizedDimensions"],
                    },
                  },
                },
              },
            },
          },
        },
        business_affiliate: {
          fields: ["id"],
          populate: {
            user: { fields: ["firstName", "lastName"] },
            comissions: {
              populate: {
                range: true,
              },
            },
          },
        },
      },
      // sort: ["slug:desc"],
    },
    { encodeValuesOnly: true }
  );
};

export const adminOrdersQuery = () =>
  QueryString.stringify(
    {
      fields: [
        "id",
        "oldId",
        "productsCount",
        "refClient",
        "status",
        "total",
        "createdAt",
        "oldCreatedAt",
        "status",
        "commission",
        "margeNet",
        "txMarge",
        "montantHT",
        "TVA",
        "buyer",
        "paymentStatus",
        "phoneNumber",
        "realRefClient",
        "slug",
      ],
      populate: {
        reseller: {
          populate: {
            business_affiliate: {
              fields: ["id"],
              populate: {
                user: { fields: ["firstName", "lastName"] },
                comissions: {
                  populate: {
                    range: true,
                  },
                },
                billingAddress: {
                  populate: {
                    country: true,
                  },
                },
              },
            },
          },
        },
        invoice: {
          fields: ["slug", "slugDate"],
          populate: {
            attachment: {
              populate: { file: true },
            },
          },
        },
        delivery: {
          populate: {
            address: {
              populate: {
                city: true,
                country: true,
              },
            },
          },
        },
        payments: {
          fields: ["id", "amount", "date"],
        },
        panier_items: {
          fields: ["id", "quantity", "discount", "refClient", "itemAmount"],
          populate: {
            product: {
              populate: {
                caracteristique: true,
              },
            },
            packings: {
              fields: [
                "id",
                "buyingCost",
                "deliveredCost",
                "cuve",
                "panel",
                "serialNumber",
              ],
              populate: {
                model: {
                  fields: [
                    "id",
                    "name",
                    "productType",
                    "publicPriceTTC",
                    "buyingPrice",
                    "retailerPriceHT",
                    "TVA",
                    "reference",
                  ],
                  populate: {
                    gamme: true,
                    attachments: {
                      fields: ["title"],
                      populate: {
                        file: { fields: ["url"] },
                      },
                    },
                    category: { fields: ["name"] },
                    caracteristique: {
                      fields: ["weight", "palletizedDimensions"],
                    },
                  },
                },
              },
            },
          },
        },
        business_affiliate: {
          fields: ["id"],
          populate: {
            user: { fields: ["firstName", "lastName"] },
            comissions: {
              populate: {
                range: true,
              },
            },
          },
        },
      },
      // sort: ["slug:desc"],
    },
    { encodeValuesOnly: true }
  );
