const initialState = {
  open: false,
  type: "danger",
  errorMessage: "",
  successMessage: "",
  instruction: "",
};

const errorReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "OPEN_ERROR":
      return {
        ...state,
        open: true,
        errorMessage: payload.message,
        instruction: payload.instruction,
        successMessage: "",
        type: "danger",
      };
    case "OPEN_SUCCESS":
      return {
        ...state,
        open: true,
        successMessage: payload.message,
        errorMessage: "",
        type: "success",
        instruction: payload.instruction,
      };
    case "CLOSE_ERROR":
      return {
        ...state,
        open: false,
        successMessage: "",
        errorMessage: "",
        type: 0,
        instruction: "",
      };
    default:
      return state;
  }
};

export default errorReducer;
