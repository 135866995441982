import React, { useState } from "react";
import Select from "../Common/FormElements/Select/Select";
import Button from "../Common/FormElements/Button/Button";
import TextInput from "../Common/FormElements/TextInput/TextInput";
import {
  problemOptions,
  statusOptions,
} from "../../helpers/formLabels/savRevendeurFilters";

const SavFiltersClient = ({ filtersInput, setFiltersInput }) => {
  const initialFiltersValues = {
    status: null,
    problem: null,
    refClient: "",
  };
  const [formData, setFormData] = useState(initialFiltersValues);
  const handleResetFilters = () => {
    setFormData(initialFiltersValues);
    setFiltersInput({ status: undefined, problem: undefined, refClient: "" });
  };
  const handleSubmit = () =>
    setFiltersInput({
      ...filtersInput,
      refClient: formData.refClient,
      status: formData?.status?.code,
      problem: formData?.problem?.code,
    });

  return (
    <div className="d-flex justify-content-evenly">
      <div className="w30">
        <label className="f16 text-softBlack mb-2 sBold">Statut</label>
        <Select
          options={statusOptions}
          placeholder="Sélectionner"
          value={formData?.status}
          callback={(e) => setFormData({ ...formData, status: e.value })}
          resetable
        />
      </div>
      <div className="w30">
        <label className="f16 text-softBlack mb-2 sBold">Problème</label>
        <Select
          options={problemOptions}
          placeholder="Sélectionner"
          value={formData?.problem}
          callback={(e) => setFormData({ ...formData, problem: e.value })}
          resetable
        />
      </div>
      {/* <div className="w20">
        <label className="f16 text-softBlack mb-2 sBold">
          Référence client
        </label>
        <TextInput
          placeholder="Référence client"
          callback={(e) => {
            setFormData({ ...formData, refClient: e.target.value });
          }}
          value={formData.refClient}
        />
      </div> */}
      <div className="w30 d-flex justify-content-center align-items-end gap-2">
        <Button className="filterSubmitBtn" onClick={handleSubmit}>
          Filtrer
        </Button>
        <Button
          Icon={() => <i className="bi bi-x-square-fill" />}
          onClick={handleResetFilters}
        >
          Réinitialiser Filtres
        </Button>
      </div>
    </div>
  );
};

export default SavFiltersClient;
