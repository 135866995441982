import React from "react";
import GeneralInfo from "./GeneralInfo";
import Commandes from "./Commandes";
import Commentaires from "./Commentaires";
import Paiements from "./Paiements";
import GeneralInfoNew from "./GeneralInfoNew";

function ModifyOrderContent({
  activeTab,
  formData,
  setFormData,
  setcommandesTabUpdated,
  commandesTabUpdated,
  deletedPackings,
  setdeletedPackings,
  order,
}) {
  switch (activeTab) {
    case "Infos générales":
      return (
        <GeneralInfoNew formData={formData} setFormData={setFormData} />
        // <GeneralInfo
        //   formData={formData}
        //   setFormData={setFormData}
        //   order={order}
        // />
      );
    case "Commandes":
      return (
        <Commandes
          formData={formData}
          setFormData={setFormData}
          withRef={true}
          setcommandesTabUpdated={setcommandesTabUpdated}
          commandesTabUpdated={commandesTabUpdated}
          deletedPackings={deletedPackings}
          setdeletedPackings={setdeletedPackings}
        />
      );
    case "Commentaires":
      return <Commentaires />;
    case "Paiements":
      return <Paiements formData={formData} setFormData={setFormData} />;
    default:
      <GeneralInfo />;
  }
}

export default ModifyOrderContent;
