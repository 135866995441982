export const prospectsExtractor = (prospectsData) => {
  return prospectsData?.map((prospect) => {
    return {
      id: prospect?.id,
      email: prospect?.attributes?.email,
      firstName: prospect?.attributes?.firstName,
      lastName: prospect?.attributes?.lastName,
      phoneNumber: prospect?.attributes?.phoneNumber,
      refClient: prospect?.attributes?.refClient,
      comments: prospect?.attributes?.comments,
      adresse: {
        street: prospect?.attributes?.address?.data?.attributes?.street,
        city: prospect?.attributes?.address?.data?.attributes?.city,
        country: prospect?.attributes?.address?.data?.attributes?.country,
      },
    };
  });
};
