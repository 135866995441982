import React from "react";
import adjustPrice from "../../Utils/AdjustPrice";
import { useDispatch, useSelector } from "react-redux";
import { removeFromCart, updatePacking } from "../../REDUX/actions/catalogue";

const CartPackingItem = ({
  packingGroup,
  activeTab,
  searchInput,
  productsactiveTab,
  showFields = false,
  hideActions,
}) => {
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => state.catalogueReducer);
  const { userRole } = useSelector((state) => state.userReducer);
  return packingGroup.productType === 1 ? (
    <div>
      {packingGroup.packings.map((packing) => (
        <div
          className="border text-start br10 p-2  my-1 d-flex justify-content-between w100 pb-3"
          key={packing.id}
        >
          <div>
            <div className="f12 bold">SN : {packing.serialNumber}</div>
            <div className="f12 bold">Cuve : {packing.cuve}</div>
            <div className="f12 bold">Panneau : {packing.panel}</div>
            <div className="f14 bold ps">
              Prix {cart.particulier ? "TTC" : "HT"}:{" "}
              {cart.particulier
                ? adjustPrice(
                    (packing?.publicPriceTTC
                      ? packing?.publicPriceTTC
                      : packing.model.publicPriceTTC
                    )
                      ?.toFixed(2)
                      .toString()
                  )
                : adjustPrice(
                    (packing?.retailerPriceHT
                      ? packing?.retailerPriceHT
                      : packing.model.retailerPriceHT
                    )
                      ?.toFixed(2)
                      .toString()
                  )}
              €
            </div>
            {showFields ? (
              <>
                {userRole === "admin" && (
                  <div className="my-1 alignH gap-2">
                    <label htmlFor="remise">Remise (€) : </label>{" "}
                    {!hideActions ? (
                      <input
                        id="remise"
                        placeholder="Remise"
                        className="refClientItem p-1"
                        type="number"
                        defaultValue={parseFloat(packing.discount) || 0}
                        onChange={(e) =>
                          dispatch(
                            updatePacking(
                              packing.id,
                              { discount: parseFloat(e.target.value) || 0 },
                              {
                                activeTab,
                                searchInput,
                                productsactiveTab,
                              }
                            )
                          )
                        }
                      />
                    ) : (
                      <div>{parseFloat(packing.discount) || 0}€</div>
                    )}
                  </div>
                )}
                <div className="alignH gap-2">
                  <label htmlFor="elRefClient">Référence client : </label>{" "}
                  {!hideActions ? (
                    <input
                      id="elRefClient"
                      placeholder="reference client"
                      className="refClientItem p-1"
                      defaultValue={packing.refClient}
                      onChange={(e) =>
                        dispatch(
                          updatePacking(
                            packing.id,
                            { refClient: e.target.value },
                            {
                              activeTab,
                              searchInput,
                              productsactiveTab,
                            }
                          )
                        )
                      }
                    />
                  ) : (
                    <div> {packing.refClient || " -"} </div>
                  )}
                </div>
              </>
            ) : null}
          </div>
          {!hideActions && (
            <div className="alignH">
              <i
                className="bi bi-trash3 text-lameRed counterIcon me-3 pointer"
                onClick={() =>
                  dispatch(
                    removeFromCart(packing.id, {
                      activeTab,
                      searchInput,
                      productsactiveTab,
                    })
                  )
                }
              />
            </div>
          )}
        </div>
      ))}
    </div>
  ) : (
    <div className="border br10 p-2  my-1 alignH justify-content-between w100 pb-3">
      {cart.particulier ? (
        <div>
          Quantité : {packingGroup.packings.length}
          <div className="f12 bold ps">
            Prix unitaire TTC :{" "}
            {adjustPrice(
              packingGroup.packings[0].model.publicPriceTTC
                ?.toFixed(2)
                ?.toString()
            )}
            €
          </div>
          <div className="f12 bold ps">
            Prix total TTC :{" "}
            {adjustPrice(
              (
                packingGroup.packings[0].model.publicPriceTTC *
                packingGroup.packings.length
              )
                ?.toFixed(2)
                ?.toString()
            )}
            €
          </div>
        </div>
      ) : (
        <div>
          Quantité : {packingGroup.packings.length}
          <div className="f12 bold ps">
            Prix unitaire HT :{" "}
            {adjustPrice(
              packingGroup.packings[0].model.retailerPriceHT
                ?.toFixed(2)
                ?.toString()
            )}
            €
          </div>
          <div className="f12 bold ps">
            Prix total HT :{" "}
            {adjustPrice(
              (
                packingGroup.packings[0].model.retailerPriceHT *
                packingGroup.packings.length
              )
                ?.toFixed(2)
                ?.toString()
            )}
            €
          </div>
        </div>
      )}
      {!hideActions && (
        <button
          className="bold alignC qteButton f16 br6"
          onClick={() =>
            dispatch(
              removeFromCart(packingGroup.packings[0].id, {
                activeTab,
                searchInput,
                productsactiveTab,
              })
            )
          }
        >
          -
        </button>
      )}
    </div>
  );
};

export default CartPackingItem;
