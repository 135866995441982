import React, { useState } from "react";
import { useDispatch } from "react-redux";

import TabsNavbar from "../Common/TabsNavBar/TabsNavbar";
import Button from "../Common/FormElements/Button/Button";
import { closeRightModal } from "../../REDUX/actions/rightModal";
import TabContent from "../MonEquipe/Modals/NewMember/TabContent";
import { addGuide } from "../../REDUX/actions/guides";

const NewGuide = () => {
  const [activeTab, setActiveTab] = useState("Question");
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [attachments, setAttachments] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    const dataTosend = {
      ...formData,
      category: formData?.category?.code,
      model: formData?.model?.code,
      gamme: formData?.range?.code,
    };
    // closeRightModal();
    try {
      dispatch(addGuide(dataTosend, attachments));
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const formDataIncomplete = !(
    formData.title &&
    formData.description &&
    formData.type
  );

  return (
    <div className="d-flex flex-column h100 justify-content-between">
      <div>
        <h1 className="quickSand f25 sBold text-blue mb-5 px-5">
          Ajouter un guide
        </h1>
        <TabsNavbar
          tabs={["Question", "Fichiers"]}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <TabContent
          activeTab={activeTab}
          formData={formData}
          setFormData={setFormData}
          attachments={attachments}
          setAttachments={setAttachments}
        />
      </div>
      <div className="align-self-center alignH gap-3">
        <Button
          variant={"outline"}
          className="px-5"
          onClick={() => dispatch(closeRightModal())}
        >
          Annuler
        </Button>
        <Button
          className="px-5"
          onClick={handleSubmit}
          formDataIncomplete={formDataIncomplete}
          loading={isSubmitting} // Use the isSubmitting state for loading
        >
          Enregistrer
        </Button>
      </div>
    </div>
  );
};

export default NewGuide;
