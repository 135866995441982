const initialState = {
  panierLoading: false,
  panierError: null,
  panier: null,
  panierSoftLoading: false,
  canOrderWithoutPay: false,
  cartAffiliate: null,
};

const panierReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "PANIER_LOADING": {
      return { ...state, panierLoading: true };
    }
    case "PANIER_SOFT_LOADING": {
      return { ...state, panierSoftLoading: true };
    }
    case "PANIER_CREATED": {
      return { ...state, panierLoading: false, panierSoftLoading: false };
    }
    case "PANIER_UPDATED": {
      return { ...state, panierLoading: false, panierSoftLoading: false };
    }
    case "PANIER_ERROR": {
      return {
        ...state,
        panierLoading: false,
        panierSoftLoading: false,
        panierError: payload,
      };
    }
    case "PANIER_FETCHED": {
      return {
        ...state,
        panierLoading: false,
        panierSoftLoading: false,
        panier: payload,
      };
    }
    case "CLEAR_PANIER":
      return { ...state, panier: null, cartAffiliate: null };

    case "TOGGLE_ORDER_WO_PAY":
      return { ...state, canOrderWithoutPay: payload };
    case "CART_AFFILIATE":
      return { ...state, cartAffiliate: payload };
    default:
      return state;
  }
};

export default panierReducer;
