export const productForm = [
  {
    type: "text",
    label: "Nom",
    placeholder: "Nom",
    name: "name",
    required: true,
  },
  {
    type: "select",
    name: "productType",
    label: "Type de produit",
    required: true,
  },
  {
    type: "select",
    name: "category",
    label: "Catégories",
    // required: true,
    disabledByOptions: true,
  },
  {
    type: "select",
    name: "gamme",
    label: "Gamme",
    // required: true,
    disabledByOptions: true,
  },
  {
    type: "text",
    placeholder: "Référence",
    required: true,
    label: "Référence",
    name: "reference",
  },
  {
    type: "multiSelectCheckbox",
    name: "related_products",
    label: "Produit(s) associé(s)",
    filter: true,
  },
  {
    type: "number",
    placeholder: "Prix d'achat",
    required: true,
    name: "buyingPrice",
    label: "Prix d'achat",
    euro: true,
  },
  {
    type: "number",
    placeholder: "Prix revendeur HT",
    required: true,
    name: "retailerPriceHT",
    label: "Prix revendeur HT",
    euro: true,
  },
  {
    type: "number",
    placeholder: "Prix public TTC",
    required: true,
    name: "publicPriceTTC",
    label: "Prix public TTC",
    euro: true,
  },
  {
    type: "select",
    name: "product_size",
    label: "Taille Palettisation",
    // required: true,
  },
  {
    type: "multiSelectCheckbox",
    name: "delivery_types",
    label: "Type Livraison Possible",
    multiple: true,
    // required: true,
  },
  {
    type: "number",
    placeholder: "0",
    name: "multipleContainer",
    label: "Multiple Container",
    // required: true,
  },
  {
    type: "select",
    placeholder: "0",
    name: "kangourou",
    label: "Poche kangourou spa",
  },
  {
    type: "text",
    placeholder: "0",
    name: "accessoryDimension",
    label: "Dimension Colisage mm",
  },
  {
    type: "number",
    placeholder: "0",
    name: "accessoryWeight",
    label: "Poids en kg",
  },
  {
    type: "textarea",
    placeholder: "description",
    label: "Description",
    name: "description",
  },
];

export const caractProductForm = [
  {
    type: "number",
    placeholder: "Capacité",
    required: false,
    name: "capacity",
    label: "Capacité",
  },
  {
    type: "text",
    placeholder: "Dimensions produit",
    required: false,
    name: "productDimensions",
    label: "Dimensions produit",
  },
  {
    type: "text",
    placeholder: "Dimensions palettisée",
    required: false,
    name: "palletizedDimensions",
    label: "Dimensions palettisée",
  },
  {
    type: "number",
    placeholder: "Poids",
    required: false,
    name: "weight",
    label: "Poids",
  },
  {
    type: "text",
    placeholder: "Jet hydrothérapie",
    required: false,
    name: "hydrotherapyJet",
    label: "Jet hydrothérapie",
  },
  {
    type: "text",
    placeholder: "Pompe à eau",
    required: false,
    name: "waterPump",
    label: "Pompe à eau",
  },
  {
    type: "text",
    placeholder: "Pompe de filtration",
    required: false,
    name: "filtrationPump",
    label: "Pompe de filtration",
  },
  {
    type: "text",
    placeholder: "Ordinateur de bord",
    required: false,
    name: "dashboardComputer",
    label: "Ordinateur de bord",
  },
  {
    type: "text",
    placeholder: "Boitier Eléctrique Balboa",
    required: false,
    name: "balboaElectricBox",
    label: "Boitier Eléctrique Balboa",
  },
  {
    type: "text",
    placeholder: "Chauffage Balboa",
    required: false,
    name: "balboaHeater",
    label: "Chauffage Balboa",
  },
  {
    type: "text",
    placeholder: "Alimentation électrique",
    required: false,
    name: "electricalPowerSource",
    label: "Alimentation électrique",
  },
  {
    type: "text",
    placeholder: "Pompe de nage",
    required: false,
    name: "swimJetPump",
    label: "Pompe de nage",
  },
  {
    type: "number",
    placeholder: "Volume d’eau",
    required: false,
    name: "waterVolume",
    label: "Volume d’eau",
  },
];

export const equipementForm = [
  {
    type: "select",
    placeholder: "Acrylics ARISTECH",
    required: true,
    name: "acrylicsARISTECH",
    label: "Acrylics ARISTECH",
    options: [
      {
        name: "Oui",
        code: true,
      },
      {
        name: "Non",
        code: false,
      },
    ],
  },
  {
    type: "select",
    placeholder: "Système BioLok",
    required: true,
    name: "systemeBiolok",
    label: "Système BioLok",
    options: [
      {
        name: "Oui",
        code: true,
      },
      {
        name: "Non",
        code: false,
      },
    ],
  },
  {
    type: "text",
    placeholder: "Désinfection",
    required: true,
    name: "desinfection",
    label: "Désinfection",
  },
  {
    type: "select",
    placeholder: "Filtration à cartouche",
    required: true,
    name: "filtrationCartouche",
    label: "Filtration à cartouche",
    options: [
      {
        name: "Oui",
        code: true,
      },
      {
        name: "Non",
        code: false,
      },
    ],
  },
  {
    type: "select",
    placeholder: "Filtration basse consommation",
    required: true,
    name: "filtrationBasseConsommation",
    label: "Filtration basse consommation",
    options: [
      {
        name: "Oui",
        code: true,
      },
      {
        name: "Non",
        code: false,
      },
    ],
  },
  {
    type: "text",
    placeholder: "Isolation",
    required: true,
    name: "isolation",
    label: "Isolation",
  },
  {
    type: "select",
    placeholder: "Base ABS",
    required: true,
    name: "baseABS",
    label: "Base ABS",
    options: [
      {
        name: "Oui",
        code: true,
      },
      {
        name: "Non",
        code: false,
      },
    ],
  },
  {
    type: "text",
    placeholder: "Régulateur de puissance",
    required: true,
    name: "powerController",
    label: "Régulateur de puissance",
  },
  {
    type: "select",
    placeholder: "Cascade",
    required: true,
    name: "cascade",
    label: "Cascade",
    options: [
      {
        name: "Oui",
        code: true,
      },
      {
        name: "Non",
        code: false,
      },
    ],
  },
  {
    type: "select",
    placeholder: "Easy Drain",
    required: true,
    name: "easyDrain",
    label: "Easy Drain",
    options: [
      {
        name: "Oui",
        code: true,
      },
      {
        name: "Non",
        code: false,
      },
    ],
  },
  {
    type: "select",
    placeholder: "Bâche hivernation",
    required: true,
    name: "winterCover",
    label: "Bâche hivernation",
    options: [
      {
        name: "Oui",
        code: true,
      },
      {
        name: "Non",
        code: false,
      },
    ],
  },
  // {
  //   type: "text",
  //   placeholder: "Niveaux de cale pieds",
  //   required: true,
  //   name: "",
  //   label: "",
  // },
  // {
  //   type: "text",
  //   placeholder: "Régulateur de puissance",
  //   required: true,
  //   name: "",
  //   label: "",
  // },
  // {
  //   type: "text",
  //   placeholder: "Repose verre",
  //   required: true,
  //   name: "",
  //   label: "",
  // },
  // {
  //   type: "text",
  //   placeholder: "Cascade",
  //   required: true,
  //   name: "",
  //   label: "",
  // },
  // {
  //   type: "text",
  //   placeholder: "Easy Drain",
  //   required: true,
  //   name: "",
  //   label: "",
  // },
  // {
  //   type: "text",
  //   placeholder: "Bâche hivernation",
  //   required: true,
  //   name: "",
  //   label: "",
  // },
];

export const lightSensorForm = [
  {
    type: "number",
    placeholder: "Spot de chromothérapie",
    required: true,
    name: "sportChromotherapie",
    label: "Spot de chromothérapie",
  },
  {
    type: "number",
    placeholder: "LEDs ligne d'eau",
    required: true,
    name: "LEDsLigneEau",
    label: "LEDs ligne d'eau",
  },
  {
    type: "number",
    placeholder: "Jets Rétro-éclairés",
    required: true,
    name: "jetsRetroEclaires",
    label: "Jets Rétro-éclairés",
  },
  {
    type: "text",
    placeholder: "Cascade rétro-éclairée",
    required: true,
    name: "backlitWaterfall",
    label: "Cascade rétro-éclairée",
  },
  {
    type: "text",
    placeholder: "Rivière rétro-éclairée",
    required: true,
    name: "backlitRiver",
    label: "Rivière rétro-éclairée",
  },
  {
    type: "text",
    placeholder: "Appui-tête rétro-éclairé",
    required: true,
    name: "backlitNeckSupport",
    label: "Appui-tête rétro-éclairé",
  },
  {
    type: "switch",
    name: "backlitCorners",
    label: "Angles rétro-éclairés",
  },
  {
    type: "switch",
    name: "backlitPanels",
    label: "Panneaux rétro-éclairés",
  },
  {
    type: "switch",
    name: "backlitSkimmer",
    label: "Skimmer rétro-éclairé",
  },
  {
    type: "switch",
    name: "backlitToggleSwitch",
    label: "ON/OFF rétroéclairés",
  },
  {
    type: "switch",
    name: "backlitRegulators",
    label: "Régulateurs rétroéclairés",
  },
];

export const imageExtensions = [
  ".jpg",
  ".jpeg",
  ".png",
  ".gif",
  ".bmp",
  ".webp",
  ".svg",
  ".tiff",
  ".tif",
  ".ico",
  ".heic",
  ".heif",
  ".raw",
];

export const videoExtensions = [
  ".mp4",
  ".mov",
  ".avi",
  ".mkv",
  ".wmv",
  ".flv",
  ".webm",
  ".m4v",
  ".3gp",
  ".3g2",
  ".mpg",
  ".mpeg",
];

export const documentExtensions = [
  ".pdf",
  ".doc",
  ".docx",
  ".ppt",
  ".pptx",
  ".xls",
  ".xlsx",
  ".txt",
  ".rtf",
  ".csv",
  ".odt",
  ".ods",
  ".odp",
];

export const joinExtensions = (extensions) => extensions.join(" ");
