import React, { useState } from "react";
import { Dropdown } from "primereact/dropdown";

import "./Select.css";
import { isDefined } from "../../../../Utils";

export default function Select({
  filter,
  name,
  options,
  placeholder,
  valueTemplate,
  itemsTemplate,
  className = "",
  panelClassName = "",
  defaultValue = null,
  width = "w100",
  appendToSelf,
  valueCustomStyle,
  value,
  disabled,
  callback,
  resetable,
  showClear,
  optionDisabled,
  multiple = false,
}) {
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const handleChange = (e) => {
    setSelectedValue(e.value);
    callback && callback(e);
  };
  const dropdownCustomStyle = {
    "--valueCustomStyle": valueCustomStyle ? valueCustomStyle : "16px",
  };

  const optionTemplate = (option) => itemsTemplate[option?.code];

  return (
    <Dropdown
      filter={filter}
      multiple={multiple}
      name={name}
      value={resetable ? value : value || selectedValue}
      valueTemplate={valueTemplate && valueTemplate}
      itemTemplate={itemsTemplate ? optionTemplate : undefined}
      onChange={handleChange}
      options={options}
      optionLabel="name"
      placeholder={placeholder}
      className={`customSelect text-red ${className} ${width}`}
      panelStyle={dropdownCustomStyle}
      style={dropdownCustomStyle}
      appendTo={appendToSelf ? "self" : ""}
      panelClassName={panelClassName}
      disabled={disabled}
      showClear={showClear}
      optionDisabled={optionDisabled}
      emptyMessage={"Aucun résultat trouvé"}
      emptyFilterMessage="Aucun résultat trouvé"
    />
  );
}
