import React from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/fontawesome-free-solid";
import Button from "../Common/FormElements/Button/Button";
// import TextInput from "../Common/FormElements/TextInput/TextInput";
// import { SearchSVG } from "../Svgs";
import { openRightModal } from "../../REDUX/actions/rightModal";
import NewProspect from "./NewProspect";
import TextInput from "../Common/FormElements/TextInput/TextInput";
import { getProspects } from "../../REDUX/actions/prospects";

const ProspectsViewHeader = ({ setSearchInput, searchInput }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  // const navigate = useNavigate();

  // const toggleProspects = () => {
  //   location.pathname === "/prospects"
  //     ? navigate("/prospects/client")
  //     : navigate("/prospects");
  // };
  const handleSearch = (e) => {
    if (e) {
      e.preventDefault();
    }
    dispatch(getProspects({ searchInput }));
  };
  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const clearSearch = () => {
    setSearchInput("");
    dispatch(getProspects({ searchInput: "" }));
  };
  return (
    <div className="alignH justify-content-between gap-3">
      <h2 className="text-blue bold f30">
        {location.pathname === "/prospects" ? "Prospects" : "Prospect / Client"}
      </h2>
      <form onSubmit={handleSearch}>
        <TextInput
          withSearchBtn
          placeholder="Rechercher"
          width={500}
          value={searchInput}
          callback={handleSearchChange}
          onSearchBtnClick={handleSearch}
          searchButtonType="submit"
          clearSearch={clearSearch}
        />
      </form>

      <div className="alignH gap-3 ">
        {/* <TextInput placeholder={"Nom"} />
        <Button Icon={() => <SearchSVG color="white" />}>Rechercher</Button>
        <Button variant="outline" onClick={toggleProspects}>
          {location.pathname === "/prospects"
            ? "Prospect / Client"
            : "Prospect"}
        </Button> */}
        <Button
          Icon={() => <FontAwesomeIcon icon={faPlus} size={"xs"} />}
          onClick={() => dispatch(openRightModal(<NewProspect />))}
        >
          Nouveau prospect
        </Button>
      </div>
    </div>
  );
};

export default ProspectsViewHeader;
