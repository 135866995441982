import React from "react";
import { Tooltip } from "antd";

import "./CustomTooltip.css";

const CustomTooltip = ({ title, placement, children, delivery = false }) => {
  return (
    <Tooltip
      title={title}
      arrow={false}
      color="white"
      overlayClassName={`customTooltip ${delivery ? "deliveryTooltip" : ""}`}
      placement={placement ?? undefined}
    >
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;
