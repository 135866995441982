export const profileForm = [
  {
    type: "text",
    label: "Nom",
    name: "lastName",
    required: true,
  },
  {
    type: "text",
    label: "Prénom",
    name: "firstName",
    required: true,
  },
  {
    type: "text",
    label: "Téléphone",
    name: "phoneNumber",
    required: true,
  },
  {
    type: "email",
    label: "E-mail",
    name: "email",
    required: true,
  },
  // {
  //   type: "select",
  //   label: "Poste",
  //   placeholder: "Sélectionner",
  //   name: "occupation",
  //   required: true,
  //   options: [
  //     { name: "Option 1", code: "Option 1" },
  //     { name: "Option 2", code: "Option 2" },
  //   ],
  // },
  {
    type: "password",
    name: "password",
    required: true,
    span: 12,
    // separator: true,
  },
];

export const updateProfileForm = [
  {
    type: "text",
    label: "Nom",
    name: "lastName",
    required: true,
  },
  {
    type: "text",
    label: "Prénom",
    name: "firstName",
    required: true,
  },
  {
    type: "text",
    label: "Téléphone",
    name: "phoneNumber",
    required: true,
  },
  {
    type: "email",
    label: "E-mail",
    name: "email",
    required: true,
  },
  {
    type: "password",
    name: "password",
    required: true,
    span: 12,
    // separator: true,
  },
  // {
  //   type: "select",
  //   label: "Poste",
  //   placeholder: "Sélectionner",
  //   name: "occupation",
  //   required: true,
  //   options: [
  //     { name: "Option 1", code: "Option 1" },
  //     { name: "Option 2", code: "Option 2" },
  //   ],
  // },
];
