export const orderStatus = [
  { name: "Brouillon", code: "Brouillon" },
  { name: "En Cours", code: "En Cours" },
  { name: "Livrée", code: "Livrée" },
  // { name: "Payée/en attente de livraison", code: 5 },
];
export const orderPayStatus = [
  { name: "En attente", code: "En attente" },
  { name: "Acompte payé", code: "Acompte payé" },
  { name: "Payée", code: "Payée" },
];

export const getStatusSelectValue = (status) => {
  // const statusOptions = {
  //   1: "En attente",
  //   2: "Livrée",
  //   4: "Acompte",
  //   5: "Payée/en attente de livraison",
  // };
  return { name: status, code: status };
};
export const getPayStatusSelectValue = (payStatus) => {
  return { name: payStatus, code: payStatus };
};
