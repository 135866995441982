import React from "react";
import TabsNavbar from "../../Common/TabsNavBar/TabsNavbar";

function ProductTableHeader({
  tabs = ["Caractéristiques"],
  activeTab,
  setActiveTab,
}) {
  return (
    <TabsNavbar
      tabs={tabs}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      className="productTableHeader justify-content-between"
      bottom="-16px"
      secondary
    />
  );
}

export default ProductTableHeader;
