import React from "react";
import { useDispatch } from "react-redux";
import VerticalPoints from "../Common/VerticalPoints/VerticalPoints";
import { openRightModal } from "../../REDUX/actions/rightModal";
import UpdateApporteurAffaire from "./NewApporteurAffaire/UpdateApporteurAffaire";
import { openCenteredModal } from "../../REDUX/actions/centeredModal";
import ConfirmModal from "../Common/Modal/ConfirmModal";
import { deleteAffiliate } from "../../REDUX/actions/affiliate";

const IntermediaryCard = ({
  id,
  userId,
  comissions,
  name,
  tel,
  email,
  address,
  company,
  lastOrderDate,
}) => {
  const dispatch = useDispatch();
  const handleUpdate = () => {
    dispatch(openRightModal(<UpdateApporteurAffaire id={id} />));
  };
  return (
    <div>
      <div
        className={`row px-4 py-4 d-flex justify-content-between align-items-center w-100 orderCardContainer pointer `}
        onClick={handleUpdate}
      >
        <span className={`f14 normal col-1 text-black`}>{name ?? "-"}</span>
        <span className={`f14 normal col-1 text-lightBlack`}>{tel ?? "-"}</span>
        <span className={`f14 normal col-2 text-lightBlack`}>
          {email ?? "-"}
        </span>
        <span className={`f14 normal col-2 text-lightBlack`}>
          {address ?? "-"}
        </span>
        <span className={`f14 normal col-2 text-blueCalendar`}>
          {company ?? "-"}
        </span>
        <span className={`f14 normal col-2 text-lightBlack`}>
          {lastOrderDate ?? "-"}{" "}
        </span>
        <span className="col-1">
          <VerticalPoints
            showLeft
            options={[
              // {
              //   text: "Modifier",
              //   callback: () =>
              //     dispatch(openRightModal(<UpdateApporteurAffaire id={id} />)),
              // },
              {
                text: "Supprimer",
                red: true,

                callback: () =>
                  dispatch(
                    openCenteredModal(
                      <ConfirmModal
                        label="l'apporteur d'affaire"
                        id={id}
                        onConfirm={() =>
                          dispatch(deleteAffiliate(id, userId, comissions))
                        }
                      />,
                    ),
                  ),
              },
            ]}
            className="ms-auto"
          />
        </span>
      </div>
    </div>
  );
};

export default IntermediaryCard;
