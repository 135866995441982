import React, { useEffect, useState } from "react";
import GeneralInfoProduct from "./NewProductTabContents/GeneralInfoProduct";
import CaractProduct from "./NewProductTabContents/CaractProduct";
import EquipmentProduct from "./NewProductTabContents/EquipmentProduct";
import LightSensorProduct from "./NewProductTabContents/LightSensorProduct";
import PhotoVideoProduct from "./NewProductTabContents/PhotoVideoProduct";
import DocumentsProduct from "./NewProductTabContents/DocumentsProduct";
import { caractProductForm, productForm } from "./NewProductTabContents/data";

const TabContentUpdateProduct = ({
  activeTab,
  forms,
  setForms,
  setDisabled,
}) => {
  const [invalidFields, setInvalidFields] = useState([]);

  const handleFormsChange = (name, formData) => {
    setForms({ ...forms, [name]: formData });
  };

  // console.log({ forms });

  const checkFormValidation = () => {
    const updatedInvalidFields = [];
    productForm.forEach(({ name, type, required }) => {
      if (required) {
        if (type === "select") {
          if (!forms?.product[name]?.code) {
            updatedInvalidFields.push(name);
          }
        } else if (
          forms?.product[name] === undefined ||
          forms?.product[name] === null ||
          !String(forms?.product[name])?.trim()?.length > 0
        ) {
          updatedInvalidFields.push(name);
        }
      }
    });
    if (forms?.productType?.code !== 1) {
      return;
    }
    caractProductForm.forEach(({ name, required }) => {
      if (required) {
        if (
          forms?.caracteristiques[name] === undefined ||
          forms?.caracteristiques[name] === null ||
          !String(forms?.caracteristiques[name])?.trim()?.length > 0
        ) {
          updatedInvalidFields.push(name);
        }
      }
    });
    if (updatedInvalidFields?.length > 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    setInvalidFields(updatedInvalidFields);
  };

  useEffect(() => {
    checkFormValidation();
  }, [forms]);

  console.log({ forms });

  switch (activeTab) {
    case "Infos générales":
      return (
        <GeneralInfoProduct
          handleFormsChange={handleFormsChange}
          initialValues={forms.product}
        />
      );
    case "Caractéristiques":
      return (
        <CaractProduct
          handleFormsChange={handleFormsChange}
          initialValues={forms.caracteristiques}
          productType={forms.product.productType}
        />
      );
    case "Equipements":
      return (
        <EquipmentProduct
          handleFormsChange={handleFormsChange}
          initialValues={forms.equipement}
        />
      );
    case "LightSensor Serie":
      return (
        <LightSensorProduct
          handleFormsChange={handleFormsChange}
          initialValues={forms.lightSensor}
        />
      );
    case "Photos/Vidéos":
      return (
        <PhotoVideoProduct
          handleFormsChange={handleFormsChange}
          initialValues={forms.media}
        />
      );
    case "Documents":
      return (
        <DocumentsProduct
          handleFormsChange={handleFormsChange}
          initialValues={forms.documents}
        />
      );

    default:
      return (
        <GeneralInfoProduct
          handleFormsChange={handleFormsChange}
          initialValues={forms.product}
        />
      );
  }
};

export default TabContentUpdateProduct;
