import API from "../Api/Api";
import { API_END_POINT } from "../Api/EndPoints";

export const deleteRecord = async (id, collectionName) => {
  try {
    if (!id || !collectionName) return;
    const { data } = await API.delete(
      `${API_END_POINT}api/${collectionName}/${id}`
    );
    return data;
  } catch (error) {
    throw new Error(error);
  }
};
