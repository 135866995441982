import React from "react";

import "./CheckBox.css";

function CheckBox({
  text,
  textClassName = "",
  className = "",
  width,
  height,
  checked,
  callback = () => {},
}) {
  return (
    <div
      className={`checkBoxContainer d-flex align-items-center pointer ${className}`}
      onClick={callback}
    >
      <input
        id="payment-button"
        className="form-check-input me-2 mt-0 b-1-darkGrey pointer"
        type="checkbox"
        checked={checked}
        onChange={callback}
        style={{ height: height, width: width }}
      />
      <p className={`${textClassName} pointer mb-0 `}>{text}</p>
    </div>
  );
}

export default CheckBox;
