import React, { useEffect } from "react";
import RevendeurAvatar from "../../../../Images/Images/avatar.png";
import { useDispatch, useSelector } from "react-redux";
import { getRevendeur } from "../../../../REDUX/actions/user";

const UpdateSAVRevendeur = ({ formData }) => {
  const dispatch = useDispatch();

  const revendeurUser = formData.users.find((user) => user.userRole === 0);
  const { revendeur } = useSelector((state) => state.userReducer);

  useEffect(() => {
    dispatch(getRevendeur({ id: revendeurUser.id }));
  }, []);

  return (
    <div className="px-5 mt-4 h100 ">
      <div className="row mt-5">
        <div className="col-9 d-flex flex-column  b-1-right-dimGrey pe-0">
          <div className="w100">
            <div className="alignH gap-5 justify-content-center b-1-bottom-dimGrey pb-5 w100">
              <img
                src={RevendeurAvatar}
                alt="client avatar"
                className="br50"
                style={{ width: "117px" }}
              />
              <div className="d-flex flex-column gap-2">
                <span className="softBlack normal f16">
                  {revendeur?.firstName} {revendeur?.lastName}
                </span>
                <span className="softBlack normal f16">{revendeur?.email}</span>
              </div>
            </div>
          </div>

          <div className="alignH  align-items-start">
            <div className="d-flex flex-column flex-1 b-1-right-dimGrey py-5">
              <span className="text-softBlack f16 sBold mb-3">Adresse</span>
              <span className="text-softBlack f14 normal">
                {revendeur?.firstName} {revendeur?.lastName}
              </span>
              <span className="text-softBlack f14 normal">
                {revendeur?.profil?.reseller?.deliveryAddress?.street}
              </span>
              <span className="text-softBlack f14 normal">
                {revendeur?.profil?.reseller?.deliveryAddress?.zip}
              </span>
              <span className="text-softBlack f14 normal">
                {revendeur?.profil?.reseller?.deliveryAddress?.country?.name}
              </span>
              <span className="text-lightBlue f14 normal">
                {revendeur?.email}
              </span>
              <span className="text-lightBlue f14 normal">
                {revendeur?.phoneNumber}
              </span>
            </div>

            <div className="d-flex flex-column flex-1  ps-4  py-5">
              <span className="text-softBlack f16 sBold mb-3">Produit</span>
              {/* <span className="text-lightBlue f14 normal">
                SPA - 3 personnes
              </span>
              <span className="text-softBlack f14 normal">
                Commandé le 00.00.0000
              </span> */}
            </div>
          </div>
        </div>
        {/* SAV part */}
        {/* <div className="col-3 d-flex flex-column gap-3 ps-5">
          <span className="softBlack sBold f16">SAV</span>
          {savsList?.map(({ id, savRef, status, spa }) => (
            <div className="d-flex flex-column gap-2" key={id}>
              <div className="alignH gap-3">
                <span className="text-softBlue normal f14">{savRef}</span>
                <span
                  className={`${getStatusStyle(status)?.statusTextStyle} ${
                    getStatusStyle(status)?.statusBgStyle
                  } sBold f13 text-center br8 py-1 px-3`}
                >
                  {status}
                </span>
              </div>
              <span className="softBlack normal f14">{spa}</span>
            </div>
          ))}
        </div> */}
      </div>
    </div>
  );
};

export default UpdateSAVRevendeur;
