import QueryString from "qs";

export const productsListQuery = () =>
  QueryString.stringify(
    {
      fields: [
        "id",
        "name",
        "retailerPriceHT",
        "TVA",
        "publicPriceTTC",
        "productType",
      ],
    },
    { encodeValuesOnly: true }
  );
