import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../Components/Common/FormElements/Button/Button";
import Separator from "../../../Components/Common/Separator/Separator";
import SideBar from "../../../Components/Layout/Sidebar/SideBar";
import {
  getPanier,
  updatePanierItemPacking,
} from "../../../REDUX/actions/panier";
import ShadowLoader from "../../../Utils/ViewWrapper/ShadowLoader/ShadowLoader";
import getProductsByType from "../Panier/getProductsByType";
import PaymentSteps from "../PaymentSteps/PaymentSteps";
import "./PanierDetails.css";
import PanierItem from "./PanierItem";
import PanierItems from "./PanierItems";
import PanierSummary from "./PanierSummary";

function PanierDetails() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userReducer);
  useEffect(() => {
    if (user?.attributes?.panier?.data?.id) {
      dispatch(getPanier({ id: user?.attributes?.panier?.data?.id }));
    }
  }, [dispatch, user?.attributes?.panier?.data?.id]);
  const { panier, panierLoading, panierError } = useSelector(
    (state) => state.panierReducer
  );

  const mainProducts = getProductsByType(panier?.panierItems, 1);
  const accessoryProducts = getProductsByType(panier?.panierItems, 2);
  const marketingProducts = getProductsByType(panier?.panierItems, 3);
  const sparePartProducts = getProductsByType(panier?.panierItems, 4);

  // console.log({
  //   mainProducts,
  //   accessoryProducts,
  //   marketingProducts,
  //   sparePartProducts,
  // });

  // const showSidebar = user?.attributes?.userRole === 0;

  const [packings, setPackings] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [refClientList, setRefClientList] = useState({});
  const [selectedPackings, setSelectedPackings] = useState([]);

  const handleNavigate = () => {
    // dispatch(updateCurrentOrder({ products: allProducts }));
    for (const key of Object.keys(packings)) {
      dispatch(
        updatePanierItemPacking({
          id: key,
          packings: packings[key]?.map((item) => item?.id),
          refClient: refClientList[key],
        })
      );
    }
    navigate("/catalogue/livraison");
  };

  useEffect(() => {
    if (!panier?.panierItems?.length > 0) {
      navigate("/catalogue");
    }
  }, [panier?.panierItems?.length, navigate]);

  useEffect(() => {
    setDisabled(
      !mainProducts?.every(
        (item) => item?.quantity === packings[item?.id]?.length
      )
    );
  }, [mainProducts, packings]);

  return (
    <div className="d-flex justify-content-between">
      <div className="panierDetails mainWithSideBar">
        <Link className="f15 text-customBlack d-block mb-4" to="/catalogue">
          ← Continuer mes achats
        </Link>
        <div>
          <PaymentSteps disabled={disabled} />
          <div className="mt-5">
            <ShadowLoader loading={panierLoading} error={panierError}>
              {mainProducts.length ? (
                <>
                  <p className="f14 text-softBlue sBold">Produits</p>
                  {mainProducts?.map((product) => {
                    // updatePanierIndex(panierItemIndex);
                    return (
                      <PanierItems
                        panierItemIndex={product?.itemIndex}
                        refClientList={refClientList}
                        setRefClientList={setRefClientList}
                        product={product}
                        panier={panier}
                        packings={packings}
                        setPackings={setPackings}
                        selectedPackings={selectedPackings}
                        setSelectedPackings={setSelectedPackings}
                      />
                    );
                  })}
                </>
              ) : null}

              <Separator />
              {accessoryProducts?.length > 0 ? (
                <>
                  <p className="f14 text-softBlue sBold mt-4">Accessoires</p>
                  {accessoryProducts?.map(
                    (accessoryProduct, panierItemIndex) => {
                      // updatePanierIndex(panierItemIndex);
                      return (
                        <PanierItems
                          panierItemIndex={accessoryProduct?.itemIndex}
                          refClientList={refClientList}
                          setRefClientList={setRefClientList}
                          product={accessoryProduct}
                          panier={panier}
                          packings={packings}
                          setPackings={setPackings}
                          selectedPackings={selectedPackings}
                          setSelectedPackings={setSelectedPackings}
                          // showQuantity={true}
                        />
                        // <PanierItem
                        //   panierID={panier?.id}
                        //   key={accessoryProduct.id}
                        //   {...accessoryProduct}
                        //   showQuantity
                        //   selectedPackings={selectedPackings}
                        //   setSelectedPackings={setSelectedPackings}
                        //   panier={panier}
                        //   packings={packings}
                        // />
                      );
                    }
                  )}
                </>
              ) : null}
              {marketingProducts?.length > 0 ? (
                <>
                  <p className="f14 text-softBlue sBold mt-4">
                    Produits marketing
                  </p>
                  {marketingProducts.map(
                    (marketingProduct, panierItemIndex) => {
                      // updatePanierIndex(panierItemIndex);
                      return (
                        <PanierItems
                          panierItemIndex={marketingProduct?.itemIndex}
                          refClientList={refClientList}
                          setRefClientList={setRefClientList}
                          product={marketingProduct}
                          panier={panier}
                          packings={packings}
                          setPackings={setPackings}
                          selectedPackings={selectedPackings}
                          setSelectedPackings={setSelectedPackings}
                        />
                        // <PanierItem
                        //   panierID={panier?.id}
                        //   key={marketingProduct.id}
                        //   {...marketingProduct}
                        //   showQuantity
                        //   selectedPackings={selectedPackings}
                        //   setSelectedPackings={setSelectedPackings}
                        //   panier={panier}
                        //   packings={packings}
                        // />
                      );
                    }
                  )}
                </>
              ) : null}
              {sparePartProducts?.length > 0 ? (
                <>
                  <p className="f14 text-softBlue sBold mt-4">
                    Pièces détachées
                  </p>
                  {sparePartProducts.map(
                    (sparePartProducts, panierItemIndex) => {
                      return (
                        <PanierItems
                          panierItemIndex={sparePartProducts?.itemIndex}
                          refClientList={refClientList}
                          setRefClientList={setRefClientList}
                          product={sparePartProducts}
                          panier={panier}
                          packings={packings}
                          setPackings={setPackings}
                          selectedPackings={selectedPackings}
                          setSelectedPackings={setSelectedPackings}
                        />
                      );
                    }
                  )}
                </>
              ) : null}
            </ShadowLoader>
          </div>
        </div>
      </div>
      <SideBar>
        <PanierSummary>
          <Button
            className="w100 mb-3"
            onClick={handleNavigate}
            disabled={disabled}
          >
            Passer la commande
          </Button>
        </PanierSummary>
      </SideBar>
    </div>
  );
}

export default PanierDetails;
