import React from "react";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VerticalPoints from "../Common/VerticalPoints/VerticalPoints";
import FaqVideo from "./FaqVideo";
import FaqPdf from "./FaqPdf";
import { useSelector } from "react-redux";
import { IMAGE_END_POINT } from "../../Api/EndPoints";
import DeleteGuide from "./DeleteGuide";
import {
  closeCenteredModal,
  openCenteredModal,
} from "../../REDUX/actions/centeredModal";
import { useDispatch } from "react-redux";
import { deleteGuide } from "../../REDUX/actions/guides";
import { openRightModal } from "../../REDUX/actions/rightModal";
import UpdateGuide from "./UpdateGuide";

const FaqCard = ({
  id,
  title,
  description,
  setIsOpenFaq,
  isOpenFaq,
  attachments,
  businessAffiliate,
}) => {
  const { user } = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const toggleOpenFaq = (id) => {
    isOpenFaq === id ? setIsOpenFaq() : setIsOpenFaq(id);
  };
  const handleDelete = () => {
    dispatch(deleteGuide(id));
    dispatch(closeCenteredModal());
  };
  const openDeleteModal = (action, message) => {
    dispatch(
      openCenteredModal(
        <DeleteGuide handleDelete={action} message={message} />,
      ),
    );
  };
  const openUpdateModal = () => {
    dispatch(openRightModal(<UpdateGuide id={id} />));
  };

  return (
    <div
      className={`d-flex flex-column gap-2 px-4 py-3 faqCardContainer  ${
        isOpenFaq === id && "bg-dimGrey"
      }`}
    >
      <div
        className={`d-flex align-items-start justify-content-between gap-5 pointer `}
        onClick={() => toggleOpenFaq(id)}
      >
        <div className="d-flex flex-column gap-3">
          <div className="alignH">
            {isOpenFaq === id ? (
              <span style={{ width: "15px" }}>
                <FontAwesomeIcon icon={faChevronDown} color="#666666" />
              </span>
            ) : (
              <span style={{ width: "15px" }}>
                <FontAwesomeIcon icon={faChevronRight} color="#666666" />
              </span>
            )}
            <span
              className="text-blue bold f18 quickSand"
              style={{ marginLeft: "33px" }}
            >
              {title}
            </span>
          </div>
        </div>
        {(user?.attributes?.userRole === 1 ||
          (user?.attributes?.userRole === 2 &&
            +user?.attributes?.business_affiliate?.data?.id ===
              +businessAffiliate)) && (
          <VerticalPoints
            showLeft
            options={[
              {
                text: "Modifier",
                callback: () => {
                  openRightModal(openUpdateModal());
                },
              },
              {
                text: "Supprimer",
                red: true,
                callback: () =>
                  openDeleteModal(
                    handleDelete,
                    "Êtes-vous sûr(e) de vouloir supprimer ce guide",
                  ),
              },
            ]}
          />
        )}
      </div>
      {/* If open */}
      {isOpenFaq === id && (
        <div className="me-5 row w-100" style={{ marginLeft: "43px" }}>
          <p className="f14 normal text-lightBlack textNoWrap">{description}</p>
        </div>
      )}
      {isOpenFaq === id && (
        <>
          {attachments?.data?.length !== 0 && (
            <hr style={{ borderColor: "#06152b" }} width={"100%"} />
          )}
          <div
            className="alignH gap-5 w90 mb-2 mt-4"
            style={{ marginLeft: "43px" }}
          >
            {attachments?.data?.map(
              ({ attributes: { title, file, videoUrl } }) => {
                return videoUrl ? (
                  <FaqVideo url={videoUrl} />
                ) : (
                  <FaqPdf
                    title={title}
                    url={`${IMAGE_END_POINT}${file?.data?.attributes?.url}`}
                    size={file?.data?.attributes?.size}
                    mime={file?.data?.attributes?.mime}
                  />
                );
              },
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default FaqCard;
