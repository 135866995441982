import React from "react";
import "./FactureItem.css";
import VerticalPoints from "../../Common/VerticalPoints/VerticalPoints";
import CustomCheckBox from "../../Common/CustomCheckBox/CustomCheckBox";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getStatusStyle, isDefined } from "../../../Utils";
import { deleteInvoice } from "../../../REDUX/actions/invoices";
import {
  closeCenteredModal,
  openCenteredModal,
} from "../../../REDUX/actions/centeredModal";
import DeleteInvoicesModal from "../Modal/DeleteInvoicesModal";
import { IMAGE_END_POINT } from "../../../Api/EndPoints";
import { saveAs } from "file-saver";
import CommandeStatusTag from "../../Commandes/CommandeStatusTag";
import { getFileName } from "../../../Utils/fileUtils";
import CustomTooltip from "../../Common/CustomTooltip/CustomTooltip";
import { openRightModal } from "../../../REDUX/actions/rightModal";
import InvoiceComments from "../InvoicesComments";
import adjustPrice from "../../../Utils/AdjustPrice";

function FactureItem({
  id,
  orderID,
  reseller,
  refClient,
  realRefClient,
  createdAt,
  oldCreatedAt,
  attachment,
  totalTTC,
  checked,
  searchInput,
  setCheckedInvoices,
  fileUrl,
  status,
  oldId,
  oldIdOrder,
  slug,
  slugDate,
  orderSlug,
  checkedInvoices,
}) {
  const { user, userRole } = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();

  const openDeleteModal = (action, message) => {
    dispatch(
      openCenteredModal(
        <DeleteInvoicesModal handleDelete={action} message={message} />
      )
    );
  };
  const handleDelete = () => {
    dispatch(deleteInvoice({ id, searchInput }));
    dispatch(closeCenteredModal());
  };
  const handleClick = () => {
    // setChecked(!checked);
    if (checked) {
      setCheckedInvoices((currItems) =>
        currItems?.filter((item) => +item?.id !== +id)
      );
    } else {
      setCheckedInvoices((currItems) => [
        ...currItems,
        {
          id,
          fileUrl,
          orderSlug,
          invoiceSlug: slug,
        },
      ]);
    }
  };

  const detailsOptionsAdmin = [
    {
      text: "Télécharger la facture",
      callback: () =>
        saveAs(
          `${IMAGE_END_POINT}${fileUrl}`,
          getFileName({ url: fileUrl, orderSlug, invoiceSlug: slug })
        ),
      hidden: !fileUrl,
    },
    {
      text: "Commentaire",
      callback: () =>
        dispatch(openRightModal(<InvoiceComments id={id} slug={slug} />)),
    },
    {
      text: "Supprimer la facture",
      red: true,
      callback: () =>
        openDeleteModal(
          handleDelete,
          "Êtes-vous sûr(e) de vouloir supprimer cette facture ?"
        ),
    },
  ];

  const detailsOptions = [
    {
      text: "Télécharger la facture",
      callback: () =>
        saveAs(
          `${IMAGE_END_POINT}${fileUrl}`,
          getFileName({ url: fileUrl, orderSlug, invoiceSlug: slug })
        ),
      hidden: !fileUrl,
    },
    {
      text: "Commentaire",
      callback: () =>
        dispatch(openRightModal(<InvoiceComments id={id} slug={slug} />)),
    },
  ];
  // console.log({ userRole });

  const getOptionsBasedOnUserRole = (userRole) => {
    if (userRole === "BusinessBroker" || userRole === "reseller") {
      return detailsOptions;
    } else if (userRole === "admin") {
      return detailsOptionsAdmin;
    } else return [];
  };

  const factureColWidth = user?.attributes?.userRole === 0 ? "w14" : "w14";
  const factureColStyle = `f14 text-brightGrey mb-0 ${factureColWidth}`;
  const checkedClassName = checked ? "checked" : "";

  return (
    <div
      className={`factureItem d-flex align-items-center b-1-bottom-softGrey p-3 pointer ${checkedClassName}`}
      role="button"
      onClick={handleClick}
    >
      <CustomCheckBox checked={checked} className="me-3" />
      <CustomTooltip title={"#Nouveau/Ancien"}>
        <p className={factureColStyle}>
          {/* {isDefined(oldId) ? `#${slug}/${oldId}` : `#${slug}`} */}
          {`#${slug}`}
        </p>
      </CustomTooltip>
      <CustomTooltip title={"#Nouveau/Ancien"}>
        <p className={factureColStyle}>
          {oldIdOrder || orderSlug
            ? oldIdOrder
              ? `#${orderSlug}/${oldIdOrder}`
              : `#${orderSlug}`
            : "-"}
        </p>
      </CustomTooltip>
      {/* <p className={factureColStyle}>{orderID ? `#${orderID}` : "-"} </p> */}
      {/* <p className={factureColStyle}>{refClient}</p> */}
      {/* <p className={factureColStyle}>{realRefClient ?? "-"}</p> */}
      {user?.attributes?.userRole !== 0 && (
        <p className={`f14 text-softBlue mb-0 ${factureColWidth}`}>
          {reseller ?? "-"}
        </p>
      )}

      <p className={factureColStyle}>{moment(slugDate).format("DD-MM-YYYY")}</p>
      <p className={factureColStyle}>
        {totalTTC ? `${adjustPrice(totalTTC.toFixed(2).toString())} €` : "-"}
      </p>

      <div className="col-1">
        <span className=" d-inline-block w-100 f14 text-brightGrey">
          {status ?? "-"}
          {/* <CommandeStatusTag status={status} /> */}
        </span>
      </div>
      <VerticalPoints
        options={getOptionsBasedOnUserRole(userRole)}
        showLeft
        className="ms-auto"
      />
    </div>
  );
}

export default FactureItem;
