import React from "react";

const ProductAttachementBtns = ({ handleDelete, preview }) => {
  return (
    <div className="buttonsContainer ms-auto">
      {preview && (
        <a href={preview} target="_blank" rel="noopener noreferrer">
          <button className="br50 p-1 me-2 border-0 bg-transparent">
            <i className="bi bi-eye text-blue  f18" />
          </button>
        </a>
      )}
      <button
        className="br50 p-1 border-0 bg-transparent deleteBtn"
        onClick={handleDelete}
      >
        <i className="bi bi-x-lg text-brightRed f18" />
      </button>
    </div>
  );
};

export default ProductAttachementBtns;
