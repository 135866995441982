import React, { useEffect, useState } from "react";
import "./Cart.css";
import { useDispatch, useSelector } from "react-redux";
import { IMAGE_END_POINT } from "../../Api/EndPoints";
import SwitchButton from "../Common/FormElements/SwitchButton/SwitchButton";
import CartPackingItem from "./CartPackingItem";
import adjustPrice from "../../Utils/AdjustPrice";
import Button from "../Common/FormElements/Button/Button";
import { updateCart, validateCommande } from "../../REDUX/actions/catalogue";
import { productTypesModel } from "./utils";
import CheckBox from "../Common/FormElements/CheckBox/CheckBox";
import { Dialog } from "primereact/dialog";
import { ValideSVG } from "../Svgs";
import { useNavigate } from "react-router-dom";
const Cart = ({
  step = 1,
  setStep,
  activeTab,
  searchInput,
  productsactiveTab,
}) => {
  const navigate = useNavigate();

  const { userRole } = useSelector((state) => state.userReducer);
  const { cart, cartLoading } = useSelector((state) => state.catalogueReducer);
  const dispatch = useDispatch();
  const [isIncomplete, setisIncomplete] = useState(true);
  const [conditionsAgreement, setConditionsAgreement] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const handleCloseDialog = () => {
    setShowDialog(false);
    navigate("/commandes");
    localStorage.removeItem("selectedCart");
  };

  useEffect(() => {
    setisIncomplete(
      cart?.particulier
        ? !cart?.addresseDeLivraison ||
            !cart.addresseDeFacturation ||
            !cart.deliveryOptionName ||
            !cart.particulierName
        : !cart?.addresseDeLivraison ||
            !cart.addresseDeFacturation ||
            !cart.deliveryOptionName ||
            !cart.reseller
    );
  }, [cart]);

  return (
    <div className="CartContainer p-3 d-flex flex-column justify-content-between">
      <Dialog
        visible={showDialog}
        style={{ width: "40vw" }}
        onHide={handleCloseDialog}
        onMaskClick={handleCloseDialog}
      >
        <div className="d-flex flex-column align-items-center">
          <ValideSVG width={84} height={84} />
          <p className="f23 sBold text-customBlack mt-3">
            Merci pour votre commande
          </p>
          <p className="f18 text-brightGrey w60 text-center">
            Votre commande a été effectué avec succés
          </p>
        </div>
      </Dialog>
      <div>
        <h2 className="quickSand text-blue bold f22">Panier</h2>

        <div className="f14 sBold text-softBlue mt-3">
          Liste des produits ({cart?.totalNumberOfPackings})
        </div>
        {userRole === "admin" && cart?.id ? (
          <div>
            <div className="alignH gap-2">
              <div>Commande à 0 (SAV)</div>
              <div>
                <SwitchButton
                  small
                  isActive={cart?.free || false}
                  onClick={() =>
                    dispatch(
                      updateCart(cart?.id, {
                        free: !cart?.free,
                        totalDeliveryHT: 0,
                        totalHT: 0,
                        totalTTC: 0,
                        TVA: 0,
                        facture: null,
                        bon_de_commande: null,
                      })
                    )
                  }
                />
              </div>{" "}
            </div>
            <div className="alignH gap-2">
              <div>Commande particulier</div>
              <div>
                <SwitchButton
                  small
                  isActive={cart?.particulier || false}
                  onClick={() =>
                    dispatch(
                      updateCart(cart?.id, {
                        particulier: !cart?.particulier,
                        reseller: null,
                        business_affiliate: null,
                        addresseDeFacturation: null,
                        addresseDeLivraison: null,
                        particulierName: null,
                        particulierEmail: null,
                        particulierPhone: null,
                        particulierCompany: null,
                        facture: null,
                        bon_de_commande: null,
                      })
                    )
                  }
                />
              </div>{" "}
            </div>
          </div>
        ) : null}
      </div>
      {cart?.groupedPackings?.length ? (
        <>
          <div className="cartElementList d-flex flex-column my-3">
            {cart.groupedPackings.map((packingGroup, index) => (
              <div key={index} className="my-3 py-3 border-bottom">
                <div className="d-flex flex-column f14 bold gap-3 mb-3">
                  <img
                    width={"50px"}
                    src={`${IMAGE_END_POINT}${packingGroup.mainImageURL}`}
                    alt=""
                  />
                  <div className="w100">
                    <div className="pb-3">
                      <div>
                        {packingGroup.productName} (
                        {packingGroup.packings.length})
                      </div>
                      <div className="text-softBlue f12">
                        {productTypesModel[packingGroup.productType]}
                      </div>
                    </div>
                    <CartPackingItem
                      packingGroup={packingGroup}
                      activeTab={activeTab}
                      searchInput={searchInput}
                      productsactiveTab={productsactiveTab}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div></div>
      )}
      <div className="bold">
        <div className="text-start">
          <div className="alignH justify-content-between">
            <div>Frais de livraison HT</div>
            <div>
              {cart?.free
                ? 0
                : adjustPrice(
                    (cart?.totalDeliveryHT || 0).toFixed(2).toString()
                  )}{" "}
              €
            </div>
          </div>
          <div className="alignH justify-content-between">
            <div>Total produits HT</div>
            <div>
              {cart?.free
                ? 0
                : adjustPrice(
                    (cart?.totalProductsHT || 0).toFixed(2).toString()
                  )}{" "}
              €
            </div>
          </div>
          <div className="alignH justify-content-between">
            <div>Total HT </div>
            <div>
              {cart?.free
                ? 0
                : adjustPrice(cart?.totalHT?.toFixed(2).toString())}{" "}
              €
            </div>
          </div>
          <div className="alignH justify-content-between">
            <div>
              TVA{" "}
              {cart?.free
                ? 0
                : cart?.addresseDeFacturation?.country?.name === "France"
                ? 20
                : 0}{" "}
              %
            </div>
            <div>
              {cart?.free
                ? 0
                : cart?.addresseDeFacturation?.country?.name === "France"
                ? adjustPrice(cart.TVA.toFixed(2).toString())
                : 0}{" "}
              €
            </div>
          </div>
          <div className="alignH justify-content-between">
            <div>Total TTC </div>
            <div>
              {cart?.free
                ? 0
                : adjustPrice(cart?.totalTTC?.toFixed(2).toString())}{" "}
              €
            </div>
          </div>
        </div>
        <div className="d-flex mt-2">
          <CheckBox
            checked={conditionsAgreement}
            callback={() => setConditionsAgreement(!conditionsAgreement)}
          />
          <p className="f14 mb-0">
            J'accepte les conditions{" "}
            <span className="text-blue">générales de ventes.</span>
          </p>
        </div>
        <div className="d-flex gap-2">
          {step !== 1 && (
            <Button
              className="w100 my-3"
              onClick={() => {
                setStep(step - 1);
              }}
            >
              Précédent
            </Button>
          )}
          {step !== 4 ? (
            <Button
              disabled={!cart?.id}
              className="w100 my-3"
              onClick={() => {
                setStep(step + 1);
              }}
            >
              Suivant
            </Button>
          ) : (
            <Button
              className="w100 my-3"
              disabled={isIncomplete || !conditionsAgreement}
              loading={cartLoading}
              onClick={() => {
                dispatch(validateCommande(setShowDialog, cart));
                localStorage.removeItem("selectedCart");
              }}
            >
              Valider la commande
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Cart;
