import React, { useEffect } from "react";
import ApporteurAffairesHeader from "../Components/ApporteurAffaires/ApporteurAffairesHeader";
import ApporteurAffairesContent from "../Components/ApporteurAffaires/ApporteurAffairesContent";
import RightModal from "../Components/Modals/RightModal/RightModal";
import ViewScroller from "../Components/Common/ViewScroller/ViewScroller";
import { useDispatch } from "react-redux";
import { getAffiliates } from "../REDUX/actions/affiliate";

const ApporteurAffaires = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAffiliates());
  }, [dispatch]);

  return (
    <ViewScroller>
      <div className="w100 d-flex flex-column gap-4 h-100">
        <ApporteurAffairesHeader />
        <ApporteurAffairesContent />
        <RightModal width={60} />
      </div>
    </ViewScroller>
  );
};

export default ApporteurAffaires;
