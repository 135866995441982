import React, { useEffect, useState } from "react";
import TabsNavbar from "../../Common/TabsNavBar/TabsNavbar";
import NewApporteurAffaireContent from "./NewApporteurAffaireContent";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../Common/FormElements/Button/Button";
import { closeRightModal } from "../../../REDUX/actions/rightModal";
import { addressForm, affiliateInfosForm } from "./data";
import { createAffiliateInformations } from "../../../REDUX/actions/affiliate";
import { convertedByType } from "../../../Utils";
import { emailExists } from "../../LoginForm/emailValidation";
import { toast } from "react-toastify";
function NewApporteurAffaire() {
  const dispatch = useDispatch();
  const {
    currentAffiliate,
    currentAddress,
    currentResellers,
    currentComissions,
    currentAffiliateLoading,
  } = useSelector((state) => state?.affiliateReducer);

  const [activeTab, setActiveTab] = useState("Informations");
  const [disabled, setDisabled] = useState(true);
  const [invalidFields, setInvalidFields] = useState([]);
  const [customErrors, setCustomErrors] = useState({});
  const [forms, setForms] = useState({
    informations: {},
    address: {},
    revendeurs: [],
    comissions: [],
  });
  const checkFormValidation = async () => {
    const updatedInvalidFields = [];
    for (const { name, type, required } of affiliateInfosForm) {
      if (required) {
        if (type === "select") {
          if (
            forms.informations[name]?.code === undefined ||
            forms.informations[name]?.code === null
          ) {
            updatedInvalidFields.push(name);
          }
        } else if (
          forms.informations[name] === undefined ||
          forms.informations[name] === null ||
          !String(forms.informations[name])?.trim()?.length > 0
        ) {
          updatedInvalidFields.push(name);
        }
      }
    }
    addressForm.forEach(({ name, type, required }) => {
      if (required) {
        if (type === "select") {
          if (
            forms.address[name]?.code === undefined ||
            forms.address[name]?.code === null
          ) {
            updatedInvalidFields.push(name);
          }
        } else if (
          forms.address[name] === undefined ||
          forms.address[name] === null ||
          !String(forms.address[name])?.trim()?.length > 0
        ) {
          updatedInvalidFields.push(name);
        }
      }
    });
    if (updatedInvalidFields?.length > 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    setInvalidFields(updatedInvalidFields);
  };

  const handleClick = async () => {
    if (invalidFields?.length > 0) {
      return;
    }
    const { email, phoneNumber, password } = forms?.informations;
    const emailExistsResult = await emailExists(email);
    const isValidEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
      email
    );
    const isValidPassword = password.length >= 6;
    const isValidPhoneNumber = /^(\+|\d{1,4})\s?(\d\s?){5,}$/.test(phoneNumber);

    let errors = {
      ...(emailExistsResult ? { email: "exist" } : {}),
      ...(isValidEmail ? {} : { email: "emailError" }),
      ...(isValidPhoneNumber ? {} : { phoneNumber: "phoneNumberError" }),
      ...(isValidPassword ? {} : { password: "passwordError" }),
    };
    setCustomErrors(errors);
    if (Object.keys(errors).length > 0) {
      if (activeTab !== "Informations")
        toast.error(
          "Veuillez valider tous les champs du formulaire, s'il vous plaît"
        );
      return;
    }

    dispatch(
      createAffiliateInformations(
        forms,
        currentAffiliate?.user?.id,
        currentAffiliate?.informations?.id,
        () => dispatch(closeRightModal()),
        errors
      )
    );
  };
  useEffect(() => {
    if (
      currentAffiliate?.user?.id !== null &&
      currentAffiliate?.informations?.id !== null &&
      currentAffiliate?.user?.id !== undefined &&
      currentAffiliate?.informations?.id !== undefined
    ) {
      setForms((currState) => {
        const updatedFormData = { ...currState };
        affiliateInfosForm?.forEach(({ name, type }) => {
          if (type === "password") return;
          if (type === "select") {
            const option =
              currentAffiliate?.user[name] ||
              currentAffiliate?.informations?.attributes[name];
            updatedFormData.informations[name] = {
              name: option,
              code: convertedByType(option),
            };
          } else {
            updatedFormData.informations[name] =
              currentAffiliate?.user[name] ||
              currentAffiliate?.informations?.attributes[name];
          }
        });

        addressForm?.forEach(({ name, type }) => {
          if (type === "select") {
            updatedFormData.address[name] = {
              name: currentAddress?.attributes[name]?.data?.attributes?.name,
              code: convertedByType(currentAddress?.attributes[name]?.data?.id),
            };
          } else {
            updatedFormData.address[name] = currentAddress?.attributes[name];
          }
        });

        updatedFormData.revendeurs = currentResellers?.map((item) => ({
          name: item?.attributes?.name,
          code: item?.id,
        }));

        updatedFormData.comissions = currentComissions?.map((item) => ({
          value: item?.attributes?.value,
          range: {
            name: item?.attributes?.range?.data?.attributes?.name,
            code: convertedByType(item?.attributes?.range?.data?.id),
          },
        }));

        return updatedFormData;
      });
    }
  }, [currentAffiliate]);

  useEffect(() => {
    checkFormValidation();
  }, [forms]);

  useEffect(() => {
    return () => dispatch({ type: "CLEAR_CURRENT_AFFILIATE" });
  }, [dispatch]);

  return (
    <div className="newApporteurAffaire d-flex flex-column justify-content-between h100">
      <div className="h-100 d-flex flex-column">
        <h2 className="sBold f25 quickSand text-blue px-5 mb-5">
          Ajouter un apporteur d'affaire
        </h2>
        <TabsNavbar
          tabs={["Informations", "Adresses", "Revendeurs", "Commission"]}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          className="gap-5"
        />
        <NewApporteurAffaireContent
          activeTab={activeTab}
          forms={forms}
          setForms={setForms}
          customErrors={customErrors}
          setCustomErrors={setCustomErrors}
        />
        <div className="buttonsContainer d-flex justify-content-center mt-5 pb-3 mt-auto">
          <Button
            variant="outline"
            className="me-4"
            onClick={() => dispatch(closeRightModal())}
          >
            Annuler
          </Button>
          <Button
            className="me-4"
            disabled={disabled}
            loading={currentAffiliateLoading}
            onClick={handleClick}
          >
            Enregistrer
          </Button>
          {/* <Button variant="lightBlue">Envoyer les accés</Button> */}
        </div>
      </div>
    </div>
  );
}

export default NewApporteurAffaire;
