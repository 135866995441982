import React from "react";
import { useDispatch } from "react-redux";

import TextInput from "../../Common/FormElements/TextInput/TextInput";
import Button from "../../Common/FormElements/Button/Button";
import DownloadButton from "../../Common/ListHeaderButtons/DownloadButton";
import DeleteButton from "../../Common/ListHeaderButtons/DeleteButton";
import FiltersContainer from "../../Common/Calendar/FiltersContainer/FiltersContainer";
import DepensesFilters from "./DepensesFilters";
import { openRightModal } from "../../../REDUX/actions/rightModal";
import NewDepense from "./NewDepense";
import { deleteExpenses, getExpenses } from "../../../REDUX/actions/expenses";
import { areFilesInList, handleDownload } from "../../../Utils/fileUtils";

const DepensesViewHeader = ({
  checkedItems,
  showFitlers,
  filters,
  setFilters,
  handleShowFilter,
  handleChange,
}) => {
  const dispatch = useDispatch();
  const handleSearch = (e) => {
    if (e) {
      e.preventDefault();
    }
    dispatch(getExpenses(filters));
  };

  const clearSearch = () => {
    const { search, ...otherFilters } = filters;
    dispatch(getExpenses(otherFilters));
    setFilters(otherFilters);
  };

  return (
    <div className="d-flex flex-column">
      <div className="alignH justify-content-between">
        <h2 className="text-blue bold f30 w20 ">Dépenses</h2>
        <div className="alignH w80 justify-content-end gap-3">
          <form onSubmit={handleSearch} className="w40">
            <TextInput
              type="text"
              withSearchBtn
              placeholder="Rechercher"
              className="w-100"
              value={filters?.search ?? ""}
              searchButtonType="submit"
              callback={(e) => {
                const event = {
                  target: { name: "search", value: e.target.value },
                };
                handleChange(event);
              }}
              onSearchBtnClick={handleSearch}
              clearSearch={clearSearch}
            />
          </form>
          <Button
            Icon={() => <i className="bi bi-plus-lg" />}
            onClick={() => dispatch(openRightModal(<NewDepense />))}
          >
            Ajouter une dépense
          </Button>
          <Button
            Icon={() => <i className="bi bi-funnel fa-lg " />}
            onClick={handleShowFilter}
            variant={showFitlers && "outline"}
          >
            Filtres
          </Button>
          <div className="listHeaderButtons d-flex">
            <DownloadButton
              disabled={!areFilesInList(checkedItems)}
              callback={() => handleDownload(checkedItems)}
            />
            <DeleteButton
              disabled={!checkedItems?.length > 0}
              callback={() => dispatch(deleteExpenses(checkedItems))}
            />
          </div>
        </div>
      </div>
      {showFitlers && (
        <FiltersContainer showFitlers={showFitlers} className="mb-4 mt-3">
          <DepensesFilters
            filters={filters}
            handleChange={handleChange}
            handleSearch={handleSearch}
          />
        </FiltersContainer>
      )}
    </div>
  );
};

export default DepensesViewHeader;
