const initialState = {
  affiliates: [],
  loading: false,
  error: null,
  currentAffiliateLoading: false,
  currentAffiliate: null,
  currentComissions: [],
  currentResellers: [],
  currentAddress: null,
  currentAffiliateError: null,
};

const affiliateReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_AFFILIATES_LOADING":
      return { ...state, loading: true, error: null };
    case "FETCH_AFFILIATES_SUCCESS":
      return { ...state, loading: false, affiliates: payload, error: null };
    case "FETCH_AFFILIATES_ERROR":
      return { ...state, loading: false, null: payload };
    case "CREATE_AFFILIATE_INFO_LOADING":
      return {
        ...state,
        currentAffiliateLoading: true,
        currentAffiliateError: null,
      };

    case "CREATE_AFFILIATE_INFO_SUCCESS": {
      let isFound = false;
      const updatedAffiliates = state.affiliates?.map((item) => {
        if (+item?.id === +payload?.informationData?.id) {
          isFound = true;
          return payload?.informationData;
        }
        return item;
      });
      return {
        ...state,
        currentAffiliateLoading: false,
        affiliates: isFound
          ? updatedAffiliates
          : [payload?.informationData, ...state?.affiliates],
        currentAffiliate: {
          user: payload?.userData,
          informations: payload?.informationData,
        },
        currentAffiliateError: null,
      };
    }
    case "CREATE_AFFILIATE_INFO_ERROR":
    case "FETCH_CURRENT_AFFILIATE_ERROR":
      return {
        ...state,
        currentAffiliateLoading: false,
        currentAffiliateError: payload,
      };

    case "CREATE_AFFILIATE_ADDRESS_LOADING":
    case "FETCH_CURRENT_AFFILIATE_LOADING":
      return {
        ...state,
        currentAffiliateLoading: true,
        currentAffiliateError: null,
      };
    case "CREATE_AFFILIATE_ADDRESS_SUCCESS": {
      let isFound = false;
      const updatedAffiliates = state.affiliates?.map((item) => {
        if (+item?.id === +payload?.id) {
          isFound = true;
          return payload;
        }
        return item;
      });
      return {
        ...state,
        affiliates: isFound
          ? updatedAffiliates
          : [payload?.informationData, ...state?.affiliates],
        currentAffiliateLoading: false,
        currentAddress: payload,
        currentAffiliateError: null,
      };
    }
    case "CREATE_AFFILIATE_ADDRESS_ERROR":
      return {
        ...state,
        currentAffiliateLoading: false,
        currentAffiliateError: payload,
      };

    case "CREATE_AFFILIATE_RESELLER_LOADING":
    case "DELETE_AFFILIATE_RESELLER_LOADING":
      return {
        ...state,
        currentAffiliateLoading: true,
        currentAffiliateError: null,
      };
    case "CREATE_AFFILIATE_RESELLER_SUCCESS":
    case "DELETE_AFFILIATE_RESELLER_SUCCESS":
      return {
        ...state,
        currentAffiliateLoading: false,
        currentResellers: payload,
        currentAffiliateError: null,
      };
    case "CREATE_AFFILIATE_RESELLER_ERROR":
    case "DELETE_AFFILIATE_RESELLER_ERROR":
      return {
        ...state,
        currentAffiliateLoading: false,
        currentAffiliateError: payload,
      };

    case "CREATE_AFFILIATE_COMISSION_LOADING":
    case "DELETE_AFFILIATE_COMISSION_LOADING":
      return {
        ...state,
        currentAffiliateLoading: true,
        currentAffiliateError: null,
      };
    case "CREATE_AFFILIATE_COMISSION_SUCCESS": {
      const updatedAffiliates = state.affiliates?.map((item) => {
        if (
          +item?.id === +payload[0]?.attributes?.business_affiliate?.data.id
        ) {
          return {
            ...item,
            attributes: { ...item?.attributes, comissions: { data: payload } },
          };
        }
        return item;
      });
      return {
        ...state,
        affiliates: updatedAffiliates,
        currentAffiliateLoading: false,
        currentComissions: payload,
        currentAffiliateError: null,
      };
    }
    case "CREATE_AFFILIATE_COMISSION_ERROR":
    case "DELETE_AFFILIATE_COMISSION_ERROR":
      return {
        ...state,
        currentAffiliateLoading: false,
        currentAffiliateError: payload,
      };

    case "DELETE_AFFILIATE_COMISSION_SUCCESS":
      return {
        ...state,
        currentAffiliateLoading: false,
        currentComissions:
          state.currentComissions?.filter((item) => +item?.id !== +payload) ??
          [],
        currentAffiliateError: null,
      };

    case "FETCH_CURRENT_AFFILIATE_SUCCESS":
      return {
        ...state,
        currentAffiliateLoading: false,
        currentAffiliate: {
          user: {
            id: payload?.attributes?.user?.data?.id,
            ...payload?.attributes?.user?.data?.attributes,
          },
          informations: payload,
        },
        currentComissions: payload?.attributes?.comissions?.data,
        currentResellers: payload?.attributes?.organizations?.data,
        currentAddress: payload,
        currentAffiliateError: null,
      };
    case "DELETE_AFFILIATE_SUCCESS":
      return {
        ...state,
        affiliates: state?.affiliates?.filter(
          (affiliate) => +affiliate?.id !== +payload
        ),
      };

    case "CLEAR_CURRENT_AFFILIATE":
      return {
        ...state,
        currentAffiliateLoading: false,
        currentAffiliate: null,
        currentComissions: [],
        currentResellers: [],
        currentAddress: null,
        currentAffiliateError: null,
      };

    default:
      return state;
  }
};

export default affiliateReducer;
