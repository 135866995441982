import React from "react";
import commandeImg from "../../../../Images/Images/calendar/commande.jpg";

import "./Commande.css";
// import ProductsTable from "../../../Common/ProductsTable/ProductsTable";
import OrderProductsList from "../../../Common/OrderProductsList/OrderProductsList";

function Commande() {
  return (
    <div className="commandeModal px-5">
      <h1 className="quickSand rightModalTitle mb-5">Détail commande</h1>
      <div className="d-flex bg-dimGrey p-3 mb-4">
        <div className="d-flex me-5">
          <img
            src={commandeImg}
            alt="commande"
            className="br50"
            height={100}
            width={100}
          />
          <div className="ms-3 mt-3">
            <p className="f15 sBold text-customBlack mb-0">Spa Pégase</p>
            <p className="f15 text-brightGrey mb-0">Sublime Line</p>
            <p className="f15 text-brightGrey mb-0">3 pers.</p>
          </div>
        </div>
        <div className="ms-5 mt-3 me-5">
          <p className="f15 sBold text-customBlack mb-0">N° de commande</p>
          <p className="f15 text-brightGrey mb-3">#141147</p>
          <p className="f15 sBold text-customBlack mb-0">Livré le</p>
          <p className="f15 text-brightGrey mb-3">11/07/2022</p>
        </div>
        <div className="promotionTag bg-softGreen br8 d-flex align-items-center mt-4">
          <p className="text-greenWithoutOp mb-0 px-4 py-2 f13 sBold">Soldé</p>
        </div>
      </div>
      {/* <ProductsTable className="mt-3" /> */}
      <OrderProductsList forRightModal />
    </div>
  );
}

export default Commande;
