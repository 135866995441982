import React from "react";

const InProgressTag = () => (
  <span className="sBold f12 br8 py-2 px-3 text-center bg-lightOrange text-orange">
    En cours
  </span>
);
const CompletedTag = () => (
  <span className="sBold f12 br8 py-2 px-3 text-center bg-green text-green">
    Résolu
  </span>
);
const CanceledTag = () => (
  <span className="sBold f12 br8 py-2 px-3 text-center bg-redCalendar text-brightRed">
    Annulé
  </span>
);

function TicketStatusTag({ status }) {
  switch (status) {
    case 1:
      return <InProgressTag />;
    case 2:
      return <CompletedTag />;
    case 3:
      return <CanceledTag />;
    default:
      return <InProgressTag />;
  }
}

export default TicketStatusTag;
