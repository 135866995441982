export const interventionSelectValues = [
  { name: "Diagnostique au téléphone", code: 1 },
  { name: "Diagnostique Intervention", code: 2 },
  { name: "Intervention Réparation", code: 3 },
  { name: "Mise en service du spa", code: 4 },
];

export const getInterventionSelectValue = (status) => {
  const statusOptions = {
    1: "Réparation",
    2: "Entretien",
  };
  return { name: statusOptions[status], code: status };
};
