import React, { useEffect, useState } from "react";
import Informations from "./Informations";
import Adresses from "./Adresses";
import Societe from "./Societe";
import Commission from "./Commission";
import { useDispatch } from "react-redux";
import { getRanges } from "../../../REDUX/actions/range";
import { getOrganizations } from "../../../REDUX/actions/organziation";
import { getCountries } from "../../../REDUX/actions/country";

function NewApporteurAffaireContent({
  activeTab,
  forms,
  setForms,
  customErrors,
  setCustomErrors,
}) {
  const dispatch = useDispatch();

  const handleFormsChange = (name, formData) => {
    setForms({ ...forms, [name]: formData });
  };

  useEffect(() => {
    dispatch(getRanges());
    dispatch(
      getOrganizations({
        business_affiliate: {
          id: { $null: true },
        },
      })
    );
    dispatch(getCountries());
  }, [dispatch]);
  switch (activeTab) {
    case "Informations":
      return (
        <Informations
          handleFormsChange={handleFormsChange}
          initialValues={forms.informations}
          customErrors={customErrors}
          setCustomErrors={setCustomErrors}
        />
      );
    case "Adresses":
      return (
        <Adresses
          handleFormsChange={handleFormsChange}
          initialValues={forms.address}
        />
      );
    case "Revendeurs":
      return (
        <Societe
          handleFormsChange={handleFormsChange}
          initialValues={forms.revendeurs}
        />
      );
    case "Commission":
      return (
        <Commission
          handleFormsChange={handleFormsChange}
          initialValues={forms.comissions}
        />
      );
    default:
      return (
        <Informations
          handleFormsChange={handleFormsChange}
          initialValues={forms.informations}
          customErrors={customErrors}
        />
      );
  }
}

export default NewApporteurAffaireContent;
