import React, { useEffect, useMemo, useState } from "react";
import OrdreProduct from "./OrdreProduct";
import { v4 as uuidv4 } from "uuid";
import adjustPrice from "../../../Utils/AdjustPrice";
import { isDefined } from "../../../Utils";
import TextInput from "../../Common/FormElements/TextInput/TextInput";
import { OrderProductExtractor } from "../../../Utils/DataExtractors/productsExtractor";
import { orderExtractor } from "../../../Utils/DataExtractors/ordersExtractor";

function Commandes({
  formData,
  setFormData,
  withRef,
  setcommandesTabUpdated,
  commandesTabUpdated,
  deletedPackings,
  setdeletedPackings,
}) {
  const [total, setTotal] = useState({
    amountHT: 0,
    TVA: 20,
    amountTTC: 0,
  });
  const [updatedValuesWithQuantity, setupdatedValuesWithQuantity] = useState();
  const [selectedProduct, setselectedProduct] = useState({
    discount: 0,
    itemAmount: null,
    quantity: 1,
    refClient: null,
  });

  const handleAddProduct = async () => {
    setFormData({
      ...formData,
      panierItems: {
        data: [
          ...formData?.panierItems?.data,
          {
            id: uuidv4(),
            attributes: {
              quantity: 1,
              discount: 0,
              itemAmount: null,
              newProduct: true,
              refClient: null,
            },
          },
        ],
      },
    });
  };

  const handleDelete = (id, packing) => {
    const modelID = packing?.attributes?.model?.data?.id;
    const productType =
      packing?.attributes?.model?.data?.attributes?.productType;
    const packingID = packing?.id;

    setFormData((formData) => {
      const updatedProducts = formData?.panierItems?.data?.filter((item) => {
        return productType === 1
          ? +item?.id !== +id
          : item?.attributes?.packings?.data[0]?.attributes?.model?.data?.id !==
              modelID;
      });
      return { ...formData, panierItems: { data: updatedProducts } };
    });
    setdeletedPackings([...deletedPackings, packingID]);
    setcommandesTabUpdated(true);
  };

  useEffect(() => {
    const totalAmountHT = formData?.panierItems?.data
      ?.map(({ attributes }) => {
        return {
          price: isDefined(attributes?.itemAmount)
            ? formData?.createdAt < "2024-03-12" && formData?.oldId
              ? attributes?.itemAmount / 1.2 - attributes?.discount
              : attributes?.itemAmount - attributes?.discount
            : attributes.product?.data?.attributes?.retailerPriceHT -
              attributes?.discount,
          quantity: parseInt(attributes.quantity),
        };
      })
      .reduce((total, { price, quantity = 1 }) => {
        return (total += price);
      }, 0);

    const totalTVA = formData.panierItems?.data
      ?.map(({ attributes }) => {
        return {
          TVA: isDefined(attributes?.itemAmount)
            ? formData?.createdAt < "2024-03-12" && formData?.oldId
              ? attributes?.itemAmount -
                attributes?.discount * 1.2 -
                (attributes?.itemAmount / 1.2 - attributes?.discount)
              : attributes?.itemAmount * 1.2 -
                (attributes?.itemAmount - attributes?.discount)
            : (attributes.product?.data?.attributes?.retailerPriceHT -
                attributes?.discount) *
                1.2 -
              (attributes?.product?.data?.attributes?.retailerPriceHT -
                attributes?.discount),
          priceHT: attributes.retailerPriceHT,
          quantity: attributes.quantity,
        };
      })
      .reduce((total, { TVA, quantity = 1 }) => {
        return (total += TVA);
      }, 0);

    const totalAmountTTC = formData.panierItems?.data
      ?.map(({ attributes }) => {
        return {
          price: isDefined(attributes?.itemAmount)
            ? formData?.createdAt < "2024-03-12" && formData?.oldId
              ? attributes?.itemAmount - attributes?.discount * 1.2
              : (attributes?.itemAmount - attributes?.discount) * 1.2
            : (attributes.product?.data?.attributes?.retailerPriceHT -
                attributes?.discount) *
              1.2,

          quantity: attributes.quantity,
        };
      })
      .reduce((total, { price, quantity = 1 }) => {
        return (total += price);
      }, 0);
    setTotal(() => {
      return {
        amountHT: totalAmountHT,
        TVA: totalTVA,
        amountTTC: totalAmountTTC,
      };
    });

    // setFormData({ ...formData, total: totalAmountTTC });
  }, [formData]);

  const countryCode =
    formData?.delivery?.address?.country?.data?.attributes?.code;

  const hasTax =
    (countryCode === "FR" ||
      formData?.delivery?.billingAddress?.toLowerCase()?.includes("france")) &&
    !formData?.delivery?.billingAddress?.toLowerCase()?.includes("d'outre-mer");

  const handleChangeForm = (e, field, subField = null) => {
    if (subField) {
      setFormData({
        ...formData,
        [field]: {
          ...formData[field],
          [subField]: e.target.value,
        },
      });
    } else {
      setFormData({
        ...formData,
        [field]: e.target.value,
      });
    }
  };

  const updatedItems = useMemo(() => {
    return formData?.panierItems?.data?.map((item, index) => {
      if (item?.attributes?.product?.data?.attributes?.productType === 1)
        return item;

      if (
        index >
        formData?.panierItems?.data?.findIndex(
          (productt) =>
            productt?.attributes?.product?.data?.attributes?.name ===
            item?.attributes?.product?.data?.attributes?.name
        )
      ) {
        return {
          ...item,
          attributes: { ...item?.attributes, hide: true, quantity: 1 },
        };
      }

      const quantity = formData?.panierItems?.data?.reduce((acc, itemm) => {
        if (
          itemm?.attributes?.product?.data?.attributes?.name ===
          item?.attributes?.product?.data?.attributes?.name
        ) {
          return acc + 1;
        }
        return acc;
      }, 0);

      return {
        ...item,
        attributes: { ...item?.attributes, quantity },
      };
    });
  }, [formData?.panierItems?.data]);

  useEffect(() => {
    setupdatedValuesWithQuantity(updatedItems);
  }, [updatedItems]);

  // console.log({ formData });

  return (
    <div className="modalContent px-5 pt-4 ">
      <div className="alignH bg-dimGrey px-4 py-3">
        <p className="w17 mb-0 sBold text-brightGrey f12">Produits</p>
        <p className="w10 mb-0 sBold text-brightGrey f12">Cuve</p>
        <p className="w10 mb-0 sBold text-brightGrey f12">Panneau</p>

        {withRef && (
          <p className="w10 mb-0 sBold text-brightGrey f12">Référence client</p>
        )}
        <p className="w10 mb-0 sBold text-brightGrey f12">Quantité</p>

        <p
          className={`${hasTax ? "w10" : "w20"} mb-0 sBold text-brightGrey f12`}
        >
          Montant HT
        </p>
        {hasTax && <p className="w10 mb-0 sBold text-brightGrey f12">TVA</p>}
        {hasTax && (
          <p className="w10 mb-0 sBold text-brightGrey f12">Montant TTC</p>
        )}
        <p
          className={`${hasTax ? "w10" : "w20"} mb-0 sBold text-brightGrey f12`}
        >
          Remise
        </p>
        <div className="w3"></div>
      </div>
      <div>
        {updatedValuesWithQuantity?.map(({ id, attributes }) => {
          if (!attributes?.hide) {
            return (
              <OrdreProduct
                key={id}
                {...attributes}
                productID={attributes?.product?.data?.id}
                formData={formData}
                setFormData={setFormData}
                panierItemId={id}
                hasTax={hasTax}
                setcommandesTabUpdated={setcommandesTabUpdated}
                commandesTabUpdated={commandesTabUpdated}
                id={id}
                handleDelete={handleDelete}
                packing={(attributes?.packings?.data || [])[0]}
                cuve={attributes?.packings?.data[0]?.attributes?.cuve}
                panel={attributes?.packings?.data[0]?.attributes?.panel}
                selectedProduct={selectedProduct}
                setselectedProduct={setselectedProduct}
              />
            );
          } else return <></>;
        })}
      </div>
      {/* <button
        onClick={handleAddProduct}
        className="addAttachment underline ms-3 mt-3 f12 text-softBlue border-0 bg-transparent"
      >
        + Ajouter un produit
      </button> */}
      <div className="d-flex flex-column  px-4 pb-3 mt-4 b-1-bottom-softGrey">
        <div className="alignH ">
          <p className="f13 sBold mb-0 w57 text-brightGrey">Total Produits</p>
          <p className="w10 f12 mb-0 text-brightGrey">
            {adjustPrice((total.amountHT || 0)?.toFixed(2)?.toString())} €
          </p>
          {hasTax && (
            <>
              <p className="w10 f12 mb-0 text-brightGrey">
                {adjustPrice(total.TVA?.toFixed(2)?.toString())} €
              </p>
              <p className="w10 f12 mb-0 text-brightGrey">
                {adjustPrice(total.amountTTC?.toFixed(2)?.toString())} €
              </p>
            </>
          )}
        </div>
        <div className=" mb-0  mt-4 alignH ">
          <p className="f13 sBold text-brightGrey w57 mb-0">Livraison</p>
          <TextInput
            className={"w14 pe-5 mb-0 text-brightGrey"}
            inputClassName="f12"
            placeholderStyle="f12"
            name="delivery.price"
            value={formData?.delivery?.price}
            callback={(e) => handleChangeForm(e, "delivery", "price")}
            disabled={formData?.paymentStatus || formData?.payments?.length > 0}
          />
        </div>
      </div>

      <div className="d-flex flex-column  px-4 pb-3 mt-4 b-1-bottom-softGrey">
        <div className="alignH ">
          <p className="f13 sBold mb-0 w57 text-brightGrey">Total</p>
          <p className="w10 f12 mb-0 text-brightGrey">
            {adjustPrice(
              (+total.amountHT + +formData?.delivery?.price || 0)
                ?.toFixed(2)
                ?.toString()
            ) ?? "0.00"}{" "}
            €
          </p>
          {hasTax && (
            <>
              <p className="w10 f12 mb-0 text-brightGrey">
                {adjustPrice(
                  ((+total.amountHT + +formData?.delivery?.price || 0) * 0.2)
                    ?.toFixed(2)
                    ?.toString()
                )}{" "}
                €
              </p>
              <p className="w10 f12 mb-0 text-brightGrey">
                {adjustPrice(
                  (+total.amountTTC + +(formData?.delivery?.price * 1.2) || 0)
                    ?.toFixed(2)
                    ?.toString()
                )}{" "}
                €
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Commandes;
