import { gql } from "@apollo/client";

export const GET_PRODUCTS = gql`
  query ($productType: Int, $searchInput: String, $category: ID, $page: Int) {
    products(
      filters: {
        and: [
          { productType: { eq: $productType } }
          { name: { containsi: $searchInput } }
          { category: { id: { eq: $category } } }
        ]
      }
      sort: "createdAt:asc"
      pagination: { page: $page, pageSize: 10 }
    ) {
      meta {
        pagination {
          total
          page
          pageSize
        }
      }
      data {
        id
        attributes {
          name
          buyingPrice
          retailerPriceHT
          publicPriceTTC
          description
          reference
          productType
          packings(pagination: { pageSize: 1000000 }) {
            data {
              id
              attributes {
                sold
                cuve
                panel
                serialNumber
                approvisionnement {
                  data {
                    id
                    attributes {
                      arrivalDate
                      estimatedDate
                    }
                  }
                }
              }
            }
          }
          category {
            data {
              id
              attributes {
                name
              }
            }
          }
          gamme {
            data {
              id
              attributes {
                name
              }
            }
          }
          attachments {
            data {
              id
              attributes {
                file {
                  data {
                    id
                    attributes {
                      url
                      mime
                      name
                    }
                  }
                }
                videoUrl
              }
            }
          }
          caracteristique {
            data {
              id
            }
          }
          light_sensor_serie {
            data {
              id
            }
          }
          equipement {
            data {
              id
            }
          }
        }
      }
    }
  }
`;
export const GET_ONE_PRODUCT = gql`
  query ($id: ID!) {
    product(id: $id) {
      data {
        id
        attributes {
          name
          buyingPrice
          kangourou
          productType
          retailerPriceHT
          publicPriceTTC
          description
          reference
          multipleContainer
          accessoryWeight
          accessoryDimension
          product_size {
            data {
              id
              attributes {
                name
              }
            }
          }
          delivery_types {
            data {
              id
              attributes {
                name
              }
            }
          }
          related_products {
            data {
              id
              attributes {
                name
              }
            }
          }
          accessories {
            data {
              id
              attributes {
                name
                attachments {
                  data {
                    id
                    attributes {
                      title
                      file {
                        data {
                          id
                          attributes {
                            url
                            mime
                            name
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          packings(pagination: { pageSize: 1000000 }) {
            data {
              id
              attributes {
                sold
                cuve
                panel
                serialNumber
                approvisionnement {
                  data {
                    id
                    attributes {
                      arrivalDate
                      estimatedDate
                    }
                  }
                }
              }
            }
          }
          caracteristique {
            data {
              id
              attributes {
                capacity
                productDimensions
                palletizedDimensions
                weight
                hydrotherapyJet
                waterPump
                filtrationPump
                dashboardComputer
                balboaElectricBox
                balboaHeater
                electricalPowerSource
                swimJetPump
                waterVolume
                extraFields
              }
            }
          }
          equipement {
            data {
              id
              attributes {
                acrylicsARISTECH
                systemeBiolok
                desinfection
                filtrationCartouche
                filtrationBasseConsommation
                isolation
                baseABS
                systemeAudio
                hautParleur
                name
                powerController
                easyDrain
                winterCover
                cascade
              }
            }
          }
          light_sensor_serie {
            data {
              id
              attributes {
                sportChromotherapie
                LEDsLigneEau
                jetsRetroEclaires
                backlitCorners
                backlitPanels
                backlitSkimmer
                backlitToggleSwitch
                backlitRegulators
                backlitWaterfall
                backlitRiver
                backlitNeckSupport
              }
            }
          }
          attachments(pagination: { limit: -1 }) {
            data {
              id
              attributes {
                title
                file {
                  data {
                    id
                    attributes {
                      url
                      mime
                      name
                    }
                  }
                }
                videoUrl
                date
                type
                icon
              }
            }
          }
          category {
            data {
              id
              attributes {
                name
              }
            }
          }
          gamme {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_PRODUCTS_ACCESSORIES = gql`
  query ($id: ID) {
    products(
      filters: { related_products: { id: { eq: $id } } }
      sort: "createdAt:asc"
      pagination: { page: 1, pageSize: 100000 }
    ) {
      meta {
        pagination {
          total
          page
          pageSize
        }
      }
      data {
        id
        attributes {
          name
          buyingPrice
          retailerPriceHT
          publicPriceTTC
          description
          reference
          productType
          packings(pagination: { pageSize: 1000000 }) {
            data {
              id
              attributes {
                sold
                cuve
                panel
                serialNumber
                approvisionnement {
                  data {
                    id
                    attributes {
                      arrivalDate
                      estimatedDate
                    }
                  }
                }
              }
            }
          }
          category {
            data {
              id
              attributes {
                name
              }
            }
          }
          gamme {
            data {
              id
              attributes {
                name
              }
            }
          }
          attachments {
            data {
              id
              attributes {
                file {
                  data {
                    id
                    attributes {
                      url
                      mime
                      name
                    }
                  }
                }
                videoUrl
              }
            }
          }
          caracteristique {
            data {
              id
            }
          }
          light_sensor_serie {
            data {
              id
            }
          }
          equipement {
            data {
              id
            }
          }
        }
      }
    }
  }
`;
