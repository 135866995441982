import React from "react";

import adjustPrice from "../../../Utils/AdjustPrice";
import { IMAGE_END_POINT } from "../../../Api/EndPoints";

function OrderProductItem({ packing }) {
  return (
    <>
      <div className="row gx-0 px-5 py-3 alignH">
        <div className={`col-3 px-0 alignH`}>
          <img
            src={`${IMAGE_END_POINT}${packing.mainImageURL}`}
            alt="product"
            style={{ width: "90px" }}
            className="me-3 br10"
          />
          <div className={`normal px-0 f14 text-brightGrey me-3`}>
            {packing.productName ?? "-"}
            {packing.cuve || packing.panel ? (
              <div className="ms-auto pe-5">
                <div className="f12 px-0 text-brightGrey">
                  {packing.cuve} / {packing.panel}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <span className={`col-1 normal f14 px-0 text-brightGrey `}>
          {packing.reference ?? "-"}
        </span>

        <span className={`col-2 normal f14 px-0 text-brightGrey `}>
          {packing.refClient || "-"}
        </span>
        <span className={`col-2 normal px-0 f14 text-brightGrey`}>
          {packing.serialNumber || "-"}
        </span>
        <span className={`col-1 normal f14 px-0 text-brightGrey `}>
          {packing.numberOfProducts}
        </span>
        <span className={` normal col-1 px-0 text-brightGrey textNoWrap`}>
          {packing.discount
            ? adjustPrice(packing.discount?.toString() || 0) + " €"
            : "-"}{" "}
        </span>
        <div className={`my-0 col-1 d-flex flex-column`}>
          {packing.discount !== null && packing.discount !== 0 && (
            <span
              className={`text-decoration-line-through normal f14 px-0 text-brightGrey textNoWrap`}
            >
              {`${packing.unitPrice} €`}
            </span>
          )}
          <span className={`col-1 normal f14 px-0 text-brightGrey textNoWrap`}>
            {adjustPrice(packing.unitPriceAfterDiscount?.toString() || 0)} €
          </span>
        </div>

        <span className={`col-1 normal f14 px-0 text-brightGrey textNoWrap`}>
          {adjustPrice(
            (packing.unitPriceAfterDiscount * packing.numberOfProducts)
              .toFixed(2)
              ?.toString() || 0
          )}{" "}
          €
        </span>
      </div>
      <hr
        style={{
          border: `1px solid white`,
          opacity: 0.8,
          width: "97%",
          margin: "0.5rem auto ",
        }}
      />
    </>
  );
}

export default OrderProductItem;
