import React from "react";
import { useDispatch } from "react-redux";
import { deleteUser } from "../../../../REDUX/actions/user";
import Button from "../../../Common/FormElements/Button/Button";
import { closeCenteredModal } from "../../../../REDUX/actions/centeredModal";
import DeleteBtn from "../../../Common/FormElements/DeleteBtn";
const DeleteMember = ({ id, userRole }) => {
  const dispatch = useDispatch();
  const handleDelete = () => {
    dispatch(deleteUser(id, userRole));
    dispatch(closeCenteredModal());
  };
  return (
    <div className="d-flex align-items-center flex-column gap-2">
      <p>
        Êtes-vous sûr de vouloir supprimer
        {userRole === 1 ? " cet admin" : " ce revendeur"} ?
      </p>
      <div className="d-flex gap-3">
        <DeleteBtn onClick={handleDelete} />
        <Button onClick={() => dispatch(closeCenteredModal())}>Annuler</Button>
      </div>
    </div>
  );
};

export default DeleteMember;
