import React, { useState } from "react";
import RightModal from "../Components/Modals/RightModal/RightModal";
import ProspectsViewContent from "../Components/Prospects/ProspectsViewContent";
import ProspectsViewHeader from "../Components/Prospects/ProspectsViewHeader";
import ViewScroller from "../Components/Common/ViewScroller/ViewScroller";

const ProspectsView = () => {
  const [searchInput, setSearchInput] = useState("");
  return (
    <ViewScroller>
      <div className="w100 d-flex flex-column gap-4 h-100">
        <ProspectsViewHeader
          setSearchInput={setSearchInput}
          searchInput={searchInput}
        />
        <ProspectsViewContent searchInput={searchInput} />
        <RightModal />
      </div>
    </ViewScroller>
  );
};

export default ProspectsView;
