import axios from "axios";
import { API_END_POINT } from "./EndPoints.js";
const API = axios.create({
  baseURL: API_END_POINT,
  headers: {
    "Content-Type": "application/json",
  },
  params: {
    pagination: {
      pageSize: 100000,
    },
  },
});

export default API;
