import React from "react";
import { formatDate } from "../../../Utils/fileUtils";
import { useSelector } from "react-redux";
import adjustPrice from "../../../Utils/AdjustPrice";

const CommandeDetails = ({ commande }) => {
  const { userRole } = useSelector((state) => state.userReducer);

  const contentTab = [
    {
      label: "N° de commande",
      value: commande?.number ? `#${commande?.number}` : "-",
    },
    {
      label: "Date de commande",
      value:
        (commande?.oldCreatedAt
          ? formatDate(commande?.oldCreatedAt, "DD-MM-YYYY")
          : formatDate(commande?.createdAt, "DD-MM-YYYY")) || "-",
    },
    {
      label: "N° de facture",
      value: commande?.facture?.number ? `#${commande?.facture?.number}` : "-",
    },
    {
      label: "Date de facture",
      value: commande?.facture?.date || "-",
    },
    {
      label: "N° bon de commande",
      value: commande?.bon_de_commande?.number
        ? `#${commande?.bon_de_commande?.number}`
        : "-",
    },
    {
      label: "Date bon de commande",
      value: formatDate(commande?.bon_de_commande?.date, "DD-MM-YYYY") || "-",
    },
    {
      label: "Client",
      value: commande?.reseller?.name || commande?.particulierName || "-",
    },
    {
      label:
        userRole === "BusinessBroker" ? "Comission" : "Apporteur d'affaire",
      value:
        userRole === "BusinessBroker"
          ? commande?.comission
            ? `${commande?.comission} €`
            : "-"
          : commande?.business_affiliate?.user?.firstName ||
            commande?.business_affiliate?.user?.lastName
          ? `${commande?.business_affiliate?.user?.firstName} ${commande?.business_affiliate?.user?.lastName}`
          : "-",
    },
    userRole === "admin" && {
      label: "Comission",
      value: commande?.comission ? `${commande?.comission?.toFixed(2)} €` : "-",
    },
    {
      label: "Créé par",
      value: commande?.creator?.firstName || "-",
    },

    {
      label: "Statut",
      value: commande?.status || "-",
    },
    {
      label: "Paiement",
      value: commande?.payStatus || "-",
    },
    {
      label: "Montant HT",
      value: commande?.totalHT
        ? `${adjustPrice(commande?.totalHT.toFixed(2).toString())} €`
        : "-",
    },
    {
      label: "Montant TTC",
      value: commande?.totalTTC
        ? `${adjustPrice(commande?.totalTTC.toFixed(2).toString())} €`
        : "-",
    },
    {
      label: "TVA",
      value: commande?.TVA
        ? `${adjustPrice(commande?.TVA.toFixed(2).toString())} €`
        : "-",
    },
    userRole === "admin" && {
      label: "Marge",
      value: commande?.marge
        ? `${adjustPrice(commande?.marge.toFixed(2).toString())} €`
        : "-",
    },
    userRole === "admin" && {
      label: "Taux de marge",
      value: commande?.txMarge ? `${commande?.txMarge?.toFixed(2)} %` : "-",
    },
    {
      label: "Frais de livraison HT",
      value: commande?.totalDeliveryHT
        ? `${adjustPrice(commande?.totalDeliveryHT.toFixed(2).toString())} €`
        : "-",
    },
    {
      label: "Frais de livraison TTC",
      value: commande?.totalDeliveryTTC
        ? `${adjustPrice(commande?.totalDeliveryTTC.toFixed(2).toString())} €`
        : "-",
    },
    {
      label: "Mode de livraison",

      value: commande?.delivery_type?.name
        ? commande?.delivery_type?.name
        : "-",
    },
    {
      label: "Date livraison estimée",
      value: commande?.estimatedDeliveryDate || "-",
    },
    {
      label: "Date livraison réélle",
      value: commande?.realDeliveryDate || "-",
    },
    {
      label: "Addresse de livraison",
      value: commande?.addresseDeLivraison
        ? `${commande?.addresseDeLivraison?.street}, </br>${commande?.addresseDeLivraison?.zip} ${commande?.addresseDeLivraison?.city?.name},</br> ${commande?.addresseDeLivraison?.country?.name}`
        : "-",
    },
    {
      label: "Addresse de facturation",
      value: commande?.addresseDeFacturation
        ? `${commande?.addresseDeFacturation?.street}, </br>${commande?.addresseDeFacturation?.zip} ${commande?.addresseDeFacturation?.city?.name}, </br>${commande?.addresseDeFacturation?.country?.name}`
        : "-",
    },
    {
      label: "Commande SAV",
      value: commande?.free ? "Oui" : "Non",
    },
    {
      label: "Commande particulier",
      value: commande?.particulier ? "Oui" : "Non",
    },
    commande?.particulier && {
      label: "Nom particulier",
      value: commande?.particulierName || "-",
    },
    commande?.particulier && {
      label: "Email particulier",
      value: commande?.particulierEmail || "-",
    },
    commande?.particulier && {
      label: "Société particulier",
      value: commande?.particulierCompany || "-",
    },
    commande?.particulier && {
      label: "N° tél particulier",
      value: commande?.particulierPhone || "-",
    },
  ];

  return (
    <div className="d-flex  my-3  align-items-start flex-wrap">
      {contentTab
        ?.filter((item) => item)
        ?.map((item, index) => {
          return (
            <div className="my-3 col-3" key={index}>
              <p className="f14 bold text-ultraBlack mb-2">{item?.label}</p>
              <p
                className={`f14 text-ultraBlack mb-2`}
                dangerouslySetInnerHTML={{ __html: item?.value }}
              ></p>
            </div>
          );
        })}
    </div>
  );
};

export default CommandeDetails;
