const oldStagingUrls = {
  api: "https://poseidon-soft-api.labo-linkibe.fr/",
  graphql: "https://poseidon-soft-api.labo-linkibe.fr/graphql/",
  images: "https://poseidon-soft-api.labo-linkibe.fr/",
};

const StagingUrls = {
  api: "https://api-soft.poseidon-spa.com/",
  graphql: "https://api-soft.poseidon-spa.com/graphql/",
  images: "https://api-soft.poseidon-spa.com",
};
const LocalUrls = {
  api: `http://localhost:1337/`,
  graphql: "http://localhost:1337/graphql",
  images: "http://localhost:1337",
};

export const API_END_POINT = StagingUrls.api;
export const GRAPHQL_END_POINT = StagingUrls.graphql;
export const IMAGE_END_POINT = StagingUrls.images;
