import React, { useCallback, useEffect, useState } from "react";
import PanierItem from "./PanierItem";
import getProductsWithSerial from "./getProductsWithSerial";
import { isDefined } from "../../../Utils";

const PanierItems = ({
  panierItemIndex,
  product,
  panier,
  packings,
  setPackings,
  setRefClientList,
  showQuantity,
}) => {
  const [selectedPackings, setSelectedPackings] = useState([]);

  let productsList = getProductsWithSerial(product);

  useEffect(() => {
    if (!product?.id) return;
    setPackings((curr) => ({
      ...curr,
      [product?.id]: selectedPackings?.filter((item) => isDefined(item)),
    }));
  }, [selectedPackings, product?.id, setPackings]);

  const getInitialValues = useCallback(() => {
    const items = panier?.panierItems ?? [];

    const item = {
      ...(items[panierItemIndex]?.packings[0] ?? {}),
      refClient: product?.refClient,
    };
    return item;
  }, [panier?.panierItems, product?.refClient, panierItemIndex]);

  return (
    <div key={product?.id}>
      {productsList?.map((productItem, index) => (
        <PanierItem
          index={index}
          panierID={panier?.id}
          key={productItem?.id}
          panier={panier}
          {...productItem}
          packings={packings}
          selectedPackings={selectedPackings}
          setSelectedPackings={setSelectedPackings}
          setRefClientList={setRefClientList}
          getInitialValues={getInitialValues}
          showQuantity={showQuantity}
        />
      ))}
    </div>
  );
};

export default PanierItems;
