import { v4 as uuidv4 } from "uuid";
import {
  CommandesSvg,
  FactureSvg,
  ProspectsSvg,
} from "../../../Components/Svgs";

export const attachmentsListData = [
  { id: uuidv4(), title: "Loremipsum_spa.jpg", date: "02.04.2023" },
  { id: uuidv4(), title: "Loremipsum_spa.jpg", date: "02.04.2023" },
  { id: uuidv4(), title: "Loremipsum_spa.jpg", date: "02.04.2023" },
];

export const attachmentsListNewProductData = [
  {
    id: uuidv4(),
    title: "Loremipsum_spa.pdf",
    docType: "Fiche technique",
    Icon: () => <FactureSvg width={15} />,
  },
  {
    id: uuidv4(),
    title: "Loremipsum_spa.pdf",
    docType: "Manuel spa touch 2",
    Icon: () => <CommandesSvg width={15} />,
  },
  {
    id: uuidv4(),
    title: "Loremipsum_spa.pdf",
    docType: "Dessin du spa",
    Icon: () => <ProspectsSvg width={15} />,
  },
  {
    id: uuidv4(),
    title: "Loremipsum_spa.pdf",
    docType: "Schema technique",
    Icon: () => <FactureSvg width={15} />,
  },
  {
    id: uuidv4(),
    title: "Loremipsum_spa.pdf",
    docType: "Manuel du spa",
    Icon: () => <FactureSvg width={15} />,
  },
];

export const attachmentsContainerListData = [
  {
    id: uuidv4(),
    title: "Loremipsum_container_facture.pdf",
  },
  {
    id: uuidv4(),
    title: "Loremipsum_container_facture.pdf",
  },
  {
    id: uuidv4(),
    title: "Loremipsum_container_facture.pdf",
  },
];

export const attachmentsDepListData = [
  {
    id: uuidv4(),
  },
];
