import React from "react";
import { IMAGE_END_POINT } from "../../Api/EndPoints";
import "./CatalogueCard.css";
import adjustPrice from "../../Utils/AdjustPrice";

import CatalogueItemCard from "./CatalogueItemCard";

const CatalogCard = ({
  product,
  activeTab,
  searchInput,
  productsactiveTab,
}) => {
  return (
    <div className="newCatalogueCardContainer p-4 border">
      <div className="d-flex flex-column alignC w100 gap-2 flex-1 p-2">
        <img
          className="newCatalogueImg"
          src={`${IMAGE_END_POINT}${product.attributes.mainImage?.data?.attributes?.url}`}
          alt=""
        />

        {product.attributes.totalNumberOfPackings === 0 ? (
          <div className="text-red f14 bold w100">Hors Stock</div>
        ) : (
          <div className="f14 bold w100 d-flex justify-content-between">
            <div className="text-green">
              {product.attributes.totalNumberArrived} En stock
            </div>
            <div className="text-brown">
              {product.attributes.totalNumberUnarrived} en approvisionnement
            </div>
          </div>
        )}
        <div className="f20 bold quickSand ultraBlack w100">
          {product.attributes.name}
        </div>
        <div className="f20 bold quickSand ultraBlack w100 mt-2"></div>
        <div className=" w100">
          <p className="f16 bold text-softBlack mb-0">
            Prix :{" "}
            {product.attributes.retailerPriceHT
              ? adjustPrice(
                  product.attributes.retailerPriceHT.toFixed(2).toString()
                )
              : "0.00"}
            € HT
          </p>

          <p className="f16 bold text-softBlack mb-0">
            Prix de vente conseillé :{" "}
            {product.attributes.publicPriceTTC
              ? adjustPrice(
                  product.attributes.publicPriceTTC.toFixed(2).toString()
                )
              : "0.00"}
            € TTC
          </p>
        </div>
        {/* <div className="d-flex w100 gap-3">
          <Button
            variant="outline"
            className="br30 bold flex-1 my-3"
            onClick={() => navigate(`/catalogue/produit/${product.id}`)}
          >
            Découvrir
          </Button>
        </div> */}
      </div>
      <div className="packingsList d-flex flex-column w100 gap-2 flex-1 p-2 mt-3">
        {product.attributes.groupedPackings.map((packingGroup, index) => (
          <div className="my-3" key={index}>
            <div
              className={`flex-1 f14 bold ${
                packingGroup.arrived ? "text-green" : "text-brown"
              }`}
            >
              {packingGroup.numberOfProducts}x {packingGroup.cuve}
              {",   "}
              {packingGroup.panel}, appro {packingGroup.containerName}{" "}
              {packingGroup.arrived
                ? ""
                : `,Arrive le ${
                    packingGroup.arrivalDate ||
                    packingGroup.estimatedArrivalDate
                  }`}
            </div>
            <CatalogueItemCard
              product={product}
              packingGroup={packingGroup}
              activeTab={activeTab}
              searchInput={searchInput}
              productsactiveTab={productsactiveTab}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CatalogCard;
