import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Creatable from "react-select/creatable";
import Button from "../../../Components/Common/FormElements/Button/Button";
import Select from "../../../Components/Common/FormElements/Select/Select";
import SideBar from "../../../Components/Layout/Sidebar/SideBar";
import { getAffiliates } from "../../../REDUX/actions/affiliate";
import {
  addBAToPanier,
  addResellerToPanier,
  getPanier,
  updateDelivery,
} from "../../../REDUX/actions/panier";
import {
  getCurrentReseller,
  getResellers,
} from "../../../REDUX/actions/reseller";
import ShadowLoader from "../../../Utils/ViewWrapper/ShadowLoader/ShadowLoader";
import PanierSummary from "../PanierDetails/PanierSummary";
import PaymentSteps from "../PaymentSteps/PaymentSteps";
import AddressCard from "./AddressCard";
import DeliveryAdresses from "./DeliveryAdresses";
import "./DeliveryDetails.css";
import DeliveryOptions from "./DeliveryOptions";
import { createDeliveryPanier } from "../../../REDUX/actions/panier";
import TextInput from "../../Common/FormElements/TextInput/TextInput";
import { getOrganizations } from "../../../REDUX/actions/organziation";
import { isDefined } from "../../../Utils";

function DeliveryDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { organizations } = useSelector((state) => state.organizationReducer);
  const { affiliates } = useSelector((state) => state.affiliateReducer);
  const [affiliateOptions, setAffiliateOptions] = useState([]);
  const [resellerOptions, setResellerOptions] = useState([]);
  const [formData, setFormData] = useState({});

  const { panier, panierLoading, panierError, canOrderWithoutPay } =
    useSelector((state) => state.panierReducer);
  const { orderResellerSAV, orderAffiliateSAV } = useSelector(
    (state) => state.ordersReducer
  );

  const [showSaveMessageBtn, setShowSaveMessageBtn] = useState(false);
  const [deliveryMessage, setDeliveryMessage] = useState(
    panier?.delivery?.deliveryMessage
  );

  const handleMessageChange = (e) => {
    setDeliveryMessage(e.target.value);
    if (e.target.value === "") {
      setDeliveryMessage(null);
    }
  };
  // handle select change:
  const handleChange = (event, name) => {
    const value = event.target.value ? event.target.value : event.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    if (name === "businessAffiliate") {
      dispatch(addBAToPanier(panier?.id, value?.code));
      if (isDefined(formData?.reseller?.code)) {
        const isFound = affiliates?.find((item) =>
          item?.attributes?.organizations?.data?.find(
            (org) => +org?.id === +formData?.reseller?.code
          )
        );
        if (isFound) return;
        dispatch(addResellerToPanier(panier?.id, null));
      }
    } else if (name === "reseller") {
      dispatch(addResellerToPanier(panier?.id, value?.code));
    }
  };
  // handle select creatable change:
  // handle Save message
  const handleSaveMessage = () => {
    dispatch(
      updateDelivery({
        panierID: panier.id,
        deliveryID: panier.delivery.id,
        deliveryMessage: deliveryMessage,
      })
    );
  };
  // navigate to paiement on submit
  const handleSubmit = () => {
    dispatch(
      getPanier(
        {
          id: panier?.id,
          realRefClient: formData?.realRefClient,
        },
        () => navigate("/catalogue/paiement")
      )
    );
  };

  const { user, userRole } = useSelector((state) => state.userReducer);

  useEffect(() => {
    if (user?.attributes?.panier?.data?.id) {
      dispatch(
        getPanier({
          id: user?.attributes?.panier?.data?.id,
        })
      );
    }
  }, [dispatch, user?.attributes?.panier?.data?.id]);

  useEffect(() => {
    if (deliveryMessage === panier?.delivery?.deliveryMessage) {
      setShowSaveMessageBtn(false);
    } else {
      setShowSaveMessageBtn(true);
    }
  }, [deliveryMessage, panier]);
  // get Resselers & affiliates
  useEffect(() => {
    let extraFilters = {};
    if (user?.attributes?.userRole === 2) {
      extraFilters = {
        business_affiliate: {
          id: { $eq: user?.attributes?.business_affiliate?.data?.id },
        },
      };
    }
    dispatch(getOrganizations(extraFilters));
    dispatch(getAffiliates());
  }, [
    dispatch,
    user?.attributes?.userRole,
    user?.attributes?.business_affiliate?.data?.id,
  ]);

  // format resellers

  // format affiliates
  const formatAffiliatesOptions = (affiliates = []) => {
    const formatAffiliates = [];
    affiliates?.forEach((affiliateEntry) => {
      formatAffiliates.push({
        code: affiliateEntry?.id,
        name:
          affiliateEntry?.attributes?.user?.data?.attributes?.firstName +
          " " +
          affiliateEntry?.attributes?.user?.data?.attributes?.lastName,
      });
    });
    return formatAffiliates;
  };

  // check if the user is admin or not

  const isAdmin = userRole === "admin";
  const isBusinessBroker = userRole === "BusinessBroker";
  /**
   *  Determine the @constant resellerId based on the user's role and selected reseller (if applicable).
   */
  const resellerId =
    isAdmin || isBusinessBroker
      ? formData?.reseller?.value
      : user?.attributes?.profil?.data?.attributes?.reseller?.data?.id;
  const canSetBuyer = isAdmin || isBusinessBroker;
  /**
   *
   */
  const isExistentReseller =
    resellerOptions?.some(
      (entry) => entry?.value === formData?.reseller?.value
    ) || !isAdmin;

  useEffect(() => {
    if (isExistentReseller) {
      dispatch(getCurrentReseller(resellerId));
    } else if (!panier?.id) {
      dispatch(createDeliveryPanier({ panierId: panier?.id }));
    }
  }, [resellerId, userRole, dispatch, isExistentReseller, panier?.id]);

  useEffect(() => {
    setResellerOptions(() => {
      const updatedResellers = organizations
        .filter((item) => {
          return (
            (+item?.businessAffiliate === +formData?.businessAffiliate?.code ||
              !isDefined(formData?.businessAffiliate?.code)) &&
            !item?.archived
          );
        })
        ?.map(({ name, code }) => ({
          code,
          name,
        }));

      setFormData((currFormData) => ({
        ...currFormData,
        reseller: updatedResellers?.find(
          (item) => +item?.code === +currFormData?.reseller?.code
        ),
      }));

      return updatedResellers;
    });
    setAffiliateOptions(formatAffiliatesOptions(affiliates));
  }, [affiliates, formData?.businessAffiliate?.code, organizations]);

  useEffect(() => {
    if (panierLoading) return;
    setFormData((currFormData) => ({
      ...currFormData,
      reseller: canOrderWithoutPay
        ? {
            code: orderResellerSAV?.id,
            name: orderResellerSAV?.attributes?.name,
          }
        : resellerOptions?.find((item) => +item?.code === +panier?.reseller),
    }));
  }, [
    panier?.reseller,
    resellerOptions,
    panierLoading,
    canOrderWithoutPay,
    orderResellerSAV,
  ]);

  useEffect(() => {
    if (panierLoading) return;
    setFormData((currFormData) => ({
      ...currFormData,
      businessAffiliate: canOrderWithoutPay
        ? {
            code: orderAffiliateSAV?.id,
            name: `${orderAffiliateSAV?.attributes?.user?.data?.attributes?.firstName} ${orderAffiliateSAV?.attributes?.user?.data?.attributes?.lastName}`,
          }
        : affiliateOptions?.find(
            (item) => +item?.code === +panier?.business_affiliate
          ),
    }));
  }, [
    panier?.business_affiliate,
    affiliateOptions,
    panierLoading,
    canOrderWithoutPay,
    orderAffiliateSAV,
  ]);

  /* ******************************** Rendering ******************************* */

  return (
    <ShadowLoader loading={panierLoading} error={panierError}>
      <div className="d-flex justify-content-between">
        <form className="deliveryDetails mainWithSideBar">
          <PaymentSteps activeStep="livraison" />
          <h2 className="quickSand f23 sBold text-whyBotherGrey mt-5 mb-4">
            Choisissez une adresse de livraison
          </h2>

          <div className="row mb-4">
            {isAdmin && (
              <>
                <div className="col-3">
                  <p className="f16 text-ultraBlack mb-2">
                    Apporteur d'affaire
                  </p>
                  <Select
                    showClear
                    resetable
                    placeholder={"Sélectionner"}
                    callback={(e) => handleChange(e, "businessAffiliate")}
                    value={formData?.businessAffiliate}
                    options={affiliateOptions}
                    disabled={canOrderWithoutPay}
                  />
                </div>
              </>
            )}
            {canSetBuyer && (
              <>
                <div className="col-3">
                  <p className="f16 text-ultraBlack mb-2">Magasin</p>
                  <Select
                    showClear
                    resetable
                    placeholder={"Sélectionner"}
                    callback={(e) => handleChange(e, "reseller")}
                    value={formData?.reseller}
                    options={resellerOptions}
                    disabled={canOrderWithoutPay}
                  />
                </div>
              </>
            )}
          </div>
          {!isExistentReseller ? (
            <AddressCard
              title={"adresse de livraison"}
              address={panier?.delivery?.address}
            />
          ) : (
            <DeliveryAdresses />
          )}
          <h2 className="quickSand f23 sBold text-whyBotherGrey my-4">
            Choisissez votre mode de livraison
          </h2>
          <DeliveryOptions />
        </form>
        <SideBar>
          <PanierSummary>
            <Button className="w100 mb-3" onClick={handleSubmit}>
              Paiement
            </Button>
          </PanierSummary>
        </SideBar>
      </div>
    </ShadowLoader>
  );
}

export default DeliveryDetails;
