import React, { useEffect } from "react";
import RevendeurCard from "./RevendeurCard";
import { useDispatch, useSelector } from "react-redux";
import { getResellers } from "../../REDUX/actions/reseller";
import LoaderWrapper from "../../Utils/ViewWrapper/LoaderWrapper/LoaderWrapper";
import { ProgressSpinner } from "primereact/progressspinner";
import useOnScreen from "../../hooks/useOnScreen";

const RevendeurViewContent = ({ isArchived, search }) => {
  const dispatch = useDispatch();
  const { resellers, loading } = useSelector((state) => state.resellerReducer);
  const { measureRef, isIntersecting } = useOnScreen();

  useEffect(() => {
    if (isIntersecting) dispatch(getResellers(search, false, isArchived));
  }, [dispatch, isIntersecting]);

  useEffect(() => {
    dispatch({ type: "RESET_RESELLERS" });
  }, [dispatch]);

  /* ******************************** Rendering ******************************* */
  return (
    <div className="flex-1">
      <div className="row bg-dimGrey py-3 px-4 mx-0 w-100 d-flex justify-content-between align-items-center">
        <span className="f14 col-2 text-brightGrey sBold">Nom</span>
        <span className="f14 col-1 text-brightGrey sBold">Téléphone</span>
        <span className="f14 col-2 text-brightGrey sBold">E-mail</span>
        <span className="f14 col-2 text-brightGrey sBold">Adresse</span>
        <span className="f14 col-2 text-brightGrey sBold">
          Apporteur d'affaire
        </span>
        {/* <span className="f14 col-2 text-brightGrey sBold">
          Apporteur d'affaire
        </span> */}
        <span className="f14 col-2 text-brightGrey sBold">
          Dernière commande
        </span>
      </div>

      <LoaderWrapper loading={loading && resellers?.length < 10}>
        {resellers?.map((revendeur) => (
          <div key={revendeur?.id}>
            <RevendeurCard id={revendeur?.id} {...revendeur?.attributes} />
            <hr style={{ borderColor: "#abb8b2", margin: 0 }} width={"100%"} />
          </div>
        ))}
      </LoaderWrapper>
      {!loading && (
        <div style={{ width: "100%", height: "1px" }} ref={measureRef} />
      )}
      {loading && resellers?.length >= 10 && (
        <ProgressSpinner
          style={{
            display: "flex",
            alignItems: "center",
            width: "100px",
            height: "100px",
            marginTop: "30px",
          }}
          strokeWidth={3}
          className="loadingViewSpinner"
        />
      )}
    </div>
  );
};

export default RevendeurViewContent;
