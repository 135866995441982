import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { closeCenteredModal } from "../../../REDUX/actions/centeredModal";
import DeleteBtn from "../FormElements/DeleteBtn";
import Button from "../FormElements/Button/Button";
const ConfirmModal = ({ label, id, onConfirm }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const handleDelete = async () => {
    try {
      setLoading(true);
      await onConfirm();
      dispatch(closeCenteredModal());
    } catch {
    } finally {
      setLoading(false);
    }
  };
  const closeModal = () => {
    dispatch(closeCenteredModal());
  };
  return (
    <div className="d-flex flex-column align-items-center gap-3">
      <p>
        Etes vous sûr de vouloir supprimer {label ?? ""} #{id} ?{" "}
      </p>
      <div className="d-flex gap-3">
        <DeleteBtn loading={loading} onClick={handleDelete} />
        <Button onClick={closeModal}>Annuler</Button>
      </div>
    </div>
  );
};

export default ConfirmModal;
