import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import TextInput from "../Common/FormElements/TextInput/TextInput";
import Button from "../Common/FormElements/Button/Button";
import FiltersContainer from "../Common/Calendar/FiltersContainer/FiltersContainer";
import SavFilters from "./SavFilters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/fontawesome-free-solid";
import { openRightModal } from "../../REDUX/actions/rightModal";
import NewTicketSAV from "./NewTicketSav/NewTicketSAV";
import {
  adminInitialValues,
  revendeurInitialValues,
} from "../../helpers/formLabels/savFilters";

const SavViewHeader = ({ setSearchInput, filtersInput, setFiltersInput }) => {
  const [showFitlers, setshowFitlers] = useState(false);
  const { userRole } = useSelector((state) => state.userReducer);

  const dispatch = useDispatch();

  const handleShowFilter = () => {
    setshowFitlers(!showFitlers);
    setFiltersInput(
      userRole === "reseller" || userRole === "BusinessBroker"
        ? revendeurInitialValues
        : adminInitialValues
    );
  };
  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };

  return (
    <div>
      <div className="alignH justify-content-between w100 pb-3">
        <h2 className="text-blue bold f30 ">Tickets SAV</h2>
        <TextInput
          withSearchBtn
          placeholder="Rechercher"
          width={"500px"}
          callback={handleSearch}
        />
        <div className="alignH gap-3">
          {
            <Button
              Icon={() => <FontAwesomeIcon icon={faPlus} size={"xs"} />}
              onClick={() => dispatch(openRightModal(<NewTicketSAV />))}
            >
              Nouveau ticket
            </Button>
          }
          <div className="d-flex align-items-center ">
            <Button
              Icon={() => <i className="bi bi-funnel fa-lg" />}
              onClick={handleShowFilter}
              variant={showFitlers && "outline"}
            >
              Filtres
            </Button>
          </div>
        </div>
      </div>

      {showFitlers ? (
        <FiltersContainer
          showFitlers={showFitlers}
          resultsCount={12}
          className="mb-4"
        >
          <SavFilters
            filtersInput={filtersInput}
            setFiltersInput={setFiltersInput}
          />
        </FiltersContainer>
      ) : (
        <p className="f12 text-brightGrey mb-3 mt-3"></p>
      )}
    </div>
  );
};

export default SavViewHeader;
