import qs from "qs";
import { API_END_POINT } from "../../Api/EndPoints";
import API from "../../Api/Api";

export const productsListExtractor = (savProductsData) => {
  return savProductsData?.map((product) => {
    return {
      id: product?.id,
      name: product?.attributes?.name,
      retailerPriceHT: product?.attributes?.retailerPriceHT,
      TVA: product?.attributes?.TVA,
      productType: product?.attributes?.productType,
    };
  });
};

export const OrderProductExtractor = async (productId, quantity = 1) => {
  try {
    // console.log({ productId });
    // return;
    const query = qs.stringify({
      populate: [
        "packings.model.attachments",
        "packings.model.caracteristique",
        "packings.model.category",
        "packings.model.gamme",
        "packings.approvisionnement",
        "caracteristique",
        // "accessories",
        // "attachments",
        // "gamme",
        // "category",
      ],
    });

    const {
      data: { data },
    } = await API.get(`${API_END_POINT}api/products/${productId}?${query}`);

    const unsoldPackings = data?.attributes?.packings?.data?.filter(
      ({ attributes: { sold } }) => !sold
    );

    const sortedPackingsByArrivals = unsoldPackings?.sort((a, b) => {
      const dateA =
        a.attributes?.approvisionnement?.data?.attributes?.arrivalDate ||
        a.attributes?.approvisionnement?.data?.attributes?.estimatedDate;
      const dateB =
        b.attributes?.approvisionnement?.data?.attributes?.arrivalDate ||
        b.attributes?.approvisionnement?.data?.attributes?.estimatedDate;

      if (dateA === dateB) return 0;
      if (dateA === null || dateA === undefined) return 1;
      if (dateB === null || dateB === undefined) return -1;
      return new Date(dateA) - new Date(dateB);
    });
    const product = {
      packings: {
        data: sortedPackingsByArrivals,
        // [0],
        // ?.map(
        //   (packing, index) => index <= quantity
        // ),
      },
      product: {
        data: {
          id: data?.id,
          attributes: {
            TVA: data?.attributes?.TVA,
            accessoryDimension: data?.attributes?.accessoryDimension,
            accessoryWeight: data?.attributes?.accessoryWeight,
            buyingPrice: data?.attributes?.buyingPrice,
            caracteristique: data?.attributes?.caracteristique,
            createdAt: data?.attributes?.createdAt,
            description: data?.attributes?.description,
            kangourou: data?.attributes?.kangourou,
            multipleContainer: data?.attributes?.multipleContainer,
            name: data?.attributes?.name,
            oldId: data?.attributes?.oldId,
            oldRef: data?.attributes?.oldRef,
            productType: data?.attributes?.productType,
            publicPriceTTC: data?.attributes?.publicPriceTTC,
            reference: data?.attributes?.reference,
            retailerPriceHT: data?.attributes?.retailerPriceHT,
            updatedAt: data?.attributes?.updatedAt,
          },
        },
      },
    };

    // console.log({ test });
    console.log({ product });
    return product;
  } catch (error) {
    console.log(error);
  }
};
