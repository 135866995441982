import React from "react";
// import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Button from "../../Common/FormElements/Button/Button";
import Calendar from "./Calendar";
import CalendarFooter from "./CalendarFooter";
import { CalendrierSvg } from "../../Svgs";

const CalendarCard = () => {
  // const { role } = useSelector((state) => state.roleReducer);

  const navigate = useNavigate();

  return (
    <div className="px-4 py-4 w-100 h-100 bg-white br5 shadow14 colAlignC justify-content-between">
      <div className="alignH justify-content-between w-100">
        <div className="alignH gap-2">
          <CalendrierSvg width={21} color="#14748e" />
          <span className="quickSand f22 bold text-blue">Calendrier</span>
        </div>
        <Button onClick={() => navigate("/calendrier")}>Voir plus</Button>
      </div>
      <div className="w-100 my-3">
        <Calendar />
      </div>
      {/* {role === 1 && <CalendarFooter />} */}
      <CalendarFooter />
    </div>
  );
};

export default CalendarCard;
