const initialState = {
  open: false,
};

const questionsPopupReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "OPEN_POPUP":
      return { open: true };
    case "CLOSE_POPUP":
      return { open: false };
    case "TOGGLE_POPUP":
      return { open: !state.open };
    default:
      return state;
  }
};

export default questionsPopupReducer;
