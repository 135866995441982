import React, { useState } from "react";
import PaymentSteps from "../PaymentSteps/PaymentSteps";
import PaymentOption from "./PaymentOption";
import "./PaymentDetails.css";
import IBANinput from "./IBANinput";
import Button from "../../../Components/Common/FormElements/Button/Button";
import SideBar from "../../../Components/Layout/Sidebar/SideBar";
import PanierSummary from "../PanierDetails/PanierSummary";
import { Dialog } from "primereact/dialog";
import { ValideSVG } from "../../../Components/Svgs";
import CheckBox from "../../Common/FormElements/CheckBox/CheckBox";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createOrder } from "../../../REDUX/actions/orders";
import { useEffect } from "react";
import { getPanier } from "../../../REDUX/actions/panier";
import ShadowLoader from "../../../Utils/ViewWrapper/ShadowLoader/ShadowLoader";
import { currentUser } from "../../../REDUX/actions/user";
import defaultImage from "../../../Images/Images/default-image.webp";
import adjustPrice from "../../../Utils/AdjustPrice";
import getPanierSummaryData from "../PanierDetails/getPanierSummaryData";
import { closeRightModal } from "../../../REDUX/actions/rightModal";

function PaymentDetails() {
  const { user } = useSelector((state) => state.userReducer);
  const { panierLoading, panierError, panier, canOrderWithoutPay } =
    useSelector((state) => state.panierReducer);
  const products = panier?.panierItems;
  const { ordersLoading, orderResellerSAV, orderAffiliateSAV, savID } =
    useSelector((state) => state.ordersReducer);

  const [paymenMethod, setPaymenMethod] = useState("bank");
  const [showDialog, setShowDialog] = useState(false);
  const [conditionsAgreement, setConditionsAgreement] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleConfirmation = async () => {
    dispatch(
      createOrder(
        () => {
          setShowDialog(true);
        },
        {
          resellerId: orderResellerSAV?.id,
          affiliateId: orderAffiliateSAV?.id,
          savID,
        }
      )
    );
  };

  const hasTax =
    panier?.delivery?.address?.country?.data?.attributes?.code === "FR" ||
    panier?.delivery?.billingAddress?.toLowerCase()?.includes("france");

  const { taxCost, totalCost } = getPanierSummaryData(
    panier?.total,
    panier?.delivery?.price,
    20,
    hasTax
  );
  const handleCloseDialog = () => {
    setShowDialog(false);
    dispatch(closeRightModal());
    navigate("/commandes");
    dispatch({ type: "CLEAR_PANIER" });
    dispatch(currentUser());
  };

  // const showSidebar = user?.attributes?.userRole === 0;

  useEffect(() => {
    if (user?.attributes?.panier?.data?.id) {
      dispatch(getPanier({ id: user?.attributes?.panier?.data?.id }));
    }
  }, [dispatch, user?.attributes?.panier?.data?.id]);

  return (
    <ShadowLoader loading={panierLoading} error={panierError}>
      <div className="d-flex justify-content-between">
        <div className="paymentDetails mainWithSideBar">
          <PaymentSteps activeStep="payment" />
          <div className="row gx-5">
            <form className="col-4">
              <h2 className="quickSand f23 sBold text-blue mt-5 mb-4">
                Effectuer le virement
              </h2>
              <div>
                <div className="w100">
                  <p className="f17 sBold text-softBlue">
                    Nom et adresse du titulaire
                  </p>
                  <p className="f17 sBold text-customBlack mb-0">
                    S.A.R.L AB WELLNESS
                  </p>
                  <p className="f17 text-brightGrey w70">
                    Zone de comblat le chateau 15800 VIC SUR CER
                  </p>
                </div>
                <div className="w100">
                  <p className="f17 sBold text-softBlue">IBAN</p>
                  <IBANinput />
                </div>
              </div>
              <h2 className="quickSand f23 sBold text-blue mt-5 mb-4">
                Méthode de paiement
              </h2>
              <PaymentOption
                title="Paiement par virement bancaire"
                paymenMethod={paymenMethod}
                setPaymenMethod={setPaymenMethod}
                methodName="bank"
              >
                <p className="f16 text-brightGrey mb-0 ms-4">
                  Dés la réception de votre paiement sur le compte Poséidon
                  Spa,la livraison sera activée sur l'adresse de votre commande,
                  qui sera validée.
                </p>
              </PaymentOption>
            </form>
            <div className="col-7">
              <h2 className="quickSand f26 bold text-blue mt-5 mb-4">
                Récapitulatif
              </h2>
              <div className="d-flex flex-column gap-3">
                {products
                  ?.sort((a, b) => a?.productType - b?.productType)
                  ?.map((product) => {
                    const photo = product?.photo;
                    const currentPacking = product?.packings[0];
                    return (
                      <div
                        key={product?.id}
                        className="alignH justify-content-between"
                      >
                        <div className="col-8 alignH">
                          <div className="col-4">
                            <img
                              src={photo?.length > 0 ? photo : defaultImage}
                              alt=""
                              width="auto"
                              height={74}
                              className={`br5 me-3 ${
                                photo?.length > 0 ? "" : "object-cover"
                              }`}
                            />
                          </div>
                          <div className="col-4">
                            <p className="f12 text-customBlack sBold mb-0">
                              {product?.name}
                            </p>
                            {currentPacking?.attributes?.cuve && (
                              <p className="f12 text-customBlack mb-0">
                                Cuve : {currentPacking?.attributes?.cuve}
                              </p>
                            )}
                            {currentPacking?.attributes?.panel && (
                              <p className="f12 text-customBlack">
                                Jupe : {currentPacking?.attributes?.panel}
                              </p>
                            )}
                          </div>
                        </div>
                        <p className="mb-0 text-customBlack col-2">
                          {product?.quantity}
                        </p>
                        <div className="mb-0 text-customBlack col-2 d-flex flex-column">
                          <p className="align-self-end">
                            {canOrderWithoutPay
                              ? 0
                              : adjustPrice(
                                  (product?.retailerPriceHT ?? 0)
                                    ?.toFixed(2)
                                    .toString()
                                )}{" "}
                            €
                          </p>
                        </div>
                      </div>
                    );
                  })}
              </div>

              <div className="d-flex justify-content-between mt-5">
                <p className="f116 sBold text-ultraBlack">Frais de livraison</p>
                <p className="f116 sBold text-ultraBlack me-2">
                  {canOrderWithoutPay
                    ? 0
                    : adjustPrice(
                        (panier?.delivery?.price ?? 0)?.toFixed(2).toString()
                      )}{" "}
                  €
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="f116 sBold text-ultraBlack">Total HT</p>
                <p className="f116 sBold text-ultraBlack me-2">
                  {canOrderWithoutPay
                    ? 0
                    : adjustPrice(
                        (panier?.total + panier?.delivery?.price ?? 0)
                          ?.toFixed(2)
                          .toString()
                      )}{" "}
                  €
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="f116 sBold text-ultraBlack">TVA (20%)</p>
                <p className="f116 sBold text-ultraBlack me-2">
                  {" "}
                  {adjustPrice(
                    (canOrderWithoutPay ? 0 : +taxCost ?? 0)
                      ?.toFixed(2)
                      ?.toString()
                  )}{" "}
                  €
                </p>
              </div>
              <div className="d-flex justify-content-between mt-2">
                <p className="f116 sBold text-ultraBlack">TOTAL TTC</p>
                <p className="f116 sBold text-ultraBlack me-2">
                  {adjustPrice(
                    (canOrderWithoutPay
                      ? 0
                      : (+totalCost ?? 0).toFixed(2)
                    ).toString()
                  )}{" "}
                  €
                </p>
              </div>
              <div className="paymentPanierFooter d-flex flex-column justify-content-between mt-4">
                <div className="d-flex">
                  <CheckBox
                    checked={conditionsAgreement}
                    callback={() =>
                      setConditionsAgreement(!conditionsAgreement)
                    }
                  />
                  <p className="f14 mb-0">
                    J'accepte les conditions{" "}
                    <span className="text-blue">générales de ventes.</span>
                  </p>
                </div>
                <div>
                  <Button
                    className="mb-3 mt-4"
                    onClick={handleConfirmation}
                    disabled={conditionsAgreement === false ? true : false}
                    loading={ordersLoading}
                  >
                    Valider la commande
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Dialog
          visible={showDialog}
          style={{ width: "40vw" }}
          onHide={handleCloseDialog}
          onMaskClick={handleCloseDialog}
        >
          <div className="d-flex flex-column align-items-center">
            <ValideSVG width={84} height={84} />
            <p className="f23 sBold text-customBlack mt-3">
              Merci pour votre commande
            </p>
            <p className="f18 text-brightGrey w60 text-center">
              Votre commande a été effectué avec succés
            </p>
          </div>
        </Dialog>

        <SideBar>
          <PanierSummary className="paymentPanierSummary">
            <div className="paymentPanierFooter d-flex flex-column justify-content-between mt-4">
              <div className="d-flex">
                <CheckBox
                  checked={conditionsAgreement}
                  callback={() => setConditionsAgreement(!conditionsAgreement)}
                />
                <p className="f14 mb-0">
                  J'accepte les conditions{" "}
                  <span className="text-blue">générales de ventes.</span>
                </p>
              </div>
              <div>
                <Button
                  className="w100 mb-3 mt-4"
                  onClick={handleConfirmation}
                  disabled={conditionsAgreement === false ? true : false}
                  loading={ordersLoading}
                >
                  Valider la commande
                </Button>
              </div>
            </div>
          </PanierSummary>
        </SideBar>
      </div>
    </ShadowLoader>
  );
}

export default PaymentDetails;
