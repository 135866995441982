import React, { useEffect, useState } from "react";
import Button from "../../Common/FormElements/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { closeCenteredModal } from "../../../REDUX/actions/centeredModal";
import { updateAdress } from "../../../REDUX/actions/deliveries";
import TextInput from "../../Common/FormElements/TextInput/TextInput";
import { getCountries } from "../../../REDUX/actions/country";
import Select from "../../Common/FormElements/Select/Select";

function DeliveryAddressModal({ address }) {
  const { countries } = useSelector((state) => state.countryReducer);
  const [formData, setFormData] = useState({
    street: address.attributes?.street,
    city: {
      name: address.attributes?.city?.data?.attributes?.name,
      code: Number(address.attributes?.city?.data?.id),
    },
    country: {
      name: address.attributes?.country?.data?.attributes?.name,
      code: Number(address.attributes?.country?.data?.id),
    },
    zip: address.attributes?.zip,
  });

  const dispatch = useDispatch();
  const handleChange = (event, name) => {
    setFormData({
      ...formData,
      [name]:
        event.target.value || event.target.value === ""
          ? event.target.value
          : event.value,
    });
  };
  const handleSave = () => {
    dispatch(
      updateAdress({
        id: address.id,
        ...formData,
      })
    );
    dispatch(closeCenteredModal());
  };

  useEffect(() => {
    dispatch(getCountries());
  }, [dispatch]);
  return (
    <div>
      <div className="deliveryAddressModal">
        <p className="f16"> Modifier l'adresse : </p>

        <TextInput
          className="mb-3"
          placeholder="Rue"
          callback={(event) => handleChange(event, "street")}
          value={formData?.street}
        />
        <div className="d-flex justify-content-between">
          <Select
            name={"country"}
            placeholder={"Pays"}
            value={formData["country"]}
            options={countries.map((country) => {
              return {
                name: country?.name,
                code: country?.code,
              };
            })}
            label={"Pays"}
            callback={(event) => handleChange(event, "country")}
            className="mb-3 me-3"
          />
          <Select
            name={"city"}
            placeholder={"Ville"}
            value={formData["city"]}
            options={
              countries.find(
                (country) => country?.code === formData?.country?.code
              )?.cities ?? []
            }
            label={"Ville"}
            callback={(event) => handleChange(event, "city")}
            className="mb-3"
            appendToSelf={true}
            filter={true}
          />
        </div>
        <TextInput
          className="mb-3 w49"
          placeholder="Code postal"
          type="number"
          callback={(event) => handleChange(event, "zip")}
          value={formData?.zip}
        />
        <Button className="mt-3" onClick={handleSave}>
          Enregistrer
        </Button>
      </div>
    </div>
  );
}

export default DeliveryAddressModal;
