import eventImage from "../../../Images/Images/events&news.jpg";

export const currentOrdersData = [
  {
    id: "#141148",
    client: "Jean Alfred",
    total: "4 250,00 €",
    date: "11/06/2022",
    status: "En attente",
  },
  {
    id: "#141147",
    client: "Jean Alfred",
    total: "4 250,00 €",
    date: "10/06/2022",
    status: "Livrée",
  },
  {
    id: "#141146",
    client: "Jean Alfred",
    total: "4 250,00 €",
    date: "18/05/2022",
    status: "En attente",
  },
  {
    id: "#141145",
    client: "Jean Alfred",
    total: "4 250,00 €",
    date: "10/05/2022",
    status: "Payée",
  },
];

export const soldProductColors = [
  "#1f77b4", // blue
  "#ff7f0e", // orange
  "#2ca02c", // green
  "#d62728", // red
  "#9467bd", // purple
  "#8c564b", // brown
  "#e377c2", // pink
  "#7f7f7f", // gray
  "#bcbd22", // yellow-green
  "#17becf", // cyan
  "#1a9850", // greenish
  "#66c2a5", // light green
  "#a6d854", // lime green
  "#fc8d62", // salmon
  "#8da0cb", // light purple
  "#e78ac3", // light pink
  "#a6cee3", // light blue
  "#ffd92f", // yellow
  "#e5c494", // beige
  "#ffeda0", // light yellow
  "#f46d43", // orange-red
  "#c7e9b4", // light green
  "#fec44f", // orange-yellow
  "#cab2d6", // light purple
  "#ffff99", // pale yellow
  "#b3de69", // light lime green
  "#fdbf6f", // light orange
  "#f0f9e8", // very light green
  "#f4cae4", // light pink
  "#fff7bc", // very light yellow
  "#f7f7f7", // very light gray
  "#cccccc", // light gray
  "#dd1c77", // magenta
  "#ffff66", // bright yellow
  "#990000", // dark red
  "#0000cc", // dark blue
  "#666699", // grayish purple
  "#99ff99", // light green
  "#ff6666", // bright red
  "#666600", // olive green
  "#660066", // dark purple
  "#999966", // grayish olive
  "#660000", // dark brown
];

export const ticketsSAVValuesData = [
  {
    id: "15221",
    date: "21/06/2018",
    society: { name: "Jacques", company: "Ipsum SPA" },
    fournisseur: "Lorem ipsum",
    dateLivraison: "21/06/2018",
    refClient: "Dupont Jacques",
    statut: "En cours",
    probleme: "Panne",
    dateRelance: "21/06/2018",
    technicien: "done",
  },
  {
    id: "15220",
    date: "21/06/2018",
    society: { name: "Jacques", company: "Ipsum SPA" },
    fournisseur: "Lorem ipsum",
    dateLivraison: "21/06/2018",
    refClient: "Dupont Jacques",
    statut: "Résolus",
    probleme: "Panne",
    dateRelance: "21/06/2018",
    technicien: "done",
  },
  {
    id: "15219",
    date: "21/06/2018",
    society: { name: "Jacques", company: "Ipsum SPA" },
    fournisseur: "Lorem ipsum",
    dateLivraison: "21/06/2018",
    refClient: "Dupont Jacques",
    statut: "En cours",
    probleme: "Panne",
    dateRelance: "21/06/2018",
    technicien: "undone",
  },
  {
    id: "15218",
    date: "21/06/2018",
    society: { name: "Jacques", company: "Ipsum SPA" },
    fournisseur: "Lorem ipsum",
    dateLivraison: "21/06/2018",
    refClient: "Dupont Jacques",
    statut: "En cours",
    probleme: "Panne",
    dateRelance: "21/06/2018",
    technicien: "done",
  },
];
export const ticketsSAVValuesRevData = [
  {
    id: "15221",
    date: "21/06/2018",
    nom: "Jacques",
    fournisseur: "Lorem ipsum",
    dateLivraison: "21/06/2018",
    refClient: "Dupont Jacques",
    statut: "En cours",
    probleme: "Panne",
    dateRelance: "21/06/2018",
    technicien: "done",
  },
  {
    id: "15220",
    date: "21/06/2018",
    nom: "Jacques",
    fournisseur: "Lorem ipsum",
    dateLivraison: "21/06/2018",
    refClient: "Dupont Jacques",
    statut: "Résolus",
    probleme: "Panne",
    dateRelance: "21/06/2018",
    technicien: "done",
  },
  {
    id: "15219",
    date: "21/06/2018",
    nom: "Jacques",
    fournisseur: "Lorem ipsum",
    dateLivraison: "21/06/2018",
    refClient: "Dupont Jacques",
    statut: "En cours",
    probleme: "Panne",
    dateRelance: "21/06/2018",
    technicien: "undone",
  },
  {
    id: "15218",
    date: "21/06/2018",
    nom: "Jacques",
    fournisseur: "Lorem ipsum",
    dateLivraison: "21/06/2018",
    refClient: "Dupont Jacques",
    statut: "En cours",
    probleme: "Panne",
    dateRelance: "21/06/2018",
    technicien: "done",
  },
];

export const eventsAndNews = [
  { id: 1, photo: eventImage, title: "Poséidon Spa Golf Cup 2019 !" },
  { id: 2, photo: eventImage, title: "Poséidon Spa Golf Cup 2019 !" },
  { id: 3, photo: eventImage, title: "Poséidon Spa Golf Cup 2019 !" },
  { id: 4, photo: eventImage, title: "Poséidon Spa Golf Cup 2019 !" },
  { id: 5, photo: eventImage, title: "Poséidon Spa Golf Cup 2019 !" },
  { id: 6, photo: eventImage, title: "Poséidon Spa Golf Cup 2019 !" },
];

export const notifsListData = [
  {
    id: 1,
    date: "11/07/2022 16h00",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit consectetur adipiscing elit ",
  },
  {
    id: 2,
    date: "11/07/2022 16h00",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit consectetur adipiscing elit ",
  },
  {
    id: 3,
    date: "11/07/2022 16h00",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit consectetur adipiscing elit ",
  },
  {
    id: 4,
    date: "11/07/2022 16h00",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit consectetur adipiscing elit ",
  },
  {
    id: 5,
    date: "11/07/2022 16h00",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit consectetur adipiscing elit ",
  },
  {
    id: 6,
    date: "11/07/2022 16h00",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit consectetur adipiscing elit ",
  },
  {
    id: 7,
    date: "11/07/2022 16h00",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit consectetur adipiscing elit ",
  },
];
