import React from "react";

import fileUploadIcon from "../../../../Images/Icons/sky-upload.png";
import imageUploadIcon from "../../../../Images/Icons/camera-upload.png";
import videoUploadIcon from "../../../../Images/Icons/video-upload.png";

import "./UploadButton.css";

const UploadButton = ({ type, text, callback, className = "" }) => {
  let iconSource = fileUploadIcon;
  let iconWidth = 40;
  let acceptTypes = "/*";

  switch (type) {
    case "file":
      iconSource = fileUploadIcon;
      iconWidth = 40;
      acceptTypes = "/*";
      break;
    case "image":
      iconSource = imageUploadIcon;
      iconWidth = 32;
      acceptTypes = "image/*";
      break;
    case "video":
      iconSource = videoUploadIcon;
      iconWidth = 42;
      acceptTypes = "video/mp4,video/x-m4v,video/*";
      break;
    default:
      break;
  }

  return (
    <div className={`bg-white colAlignC ${className}`}>
      <label
        htmlFor={type}
        className="pointer upload-btn-container colAlignC br5 d-flex flex-column justify-content-center align-items-center"
      >
        <img
          src={iconSource}
          alt={type}
          width={iconWidth}
          height={28}
          className="upload-btn-icon"
        />
        {text !== "undefined" ? (
          <p className="f14 text-brightGrey mb-0 mt-3 w70 text-center">
            {text ?? ""}
          </p>
        ) : (
          ""
        )}
      </label>
      <input
        hidden
        id={type}
        accept={acceptTypes}
        type="file"
        onChange={callback}
        multiple
      />
    </div>
  );
};

export default UploadButton;
