import React from "react";
import { useSelector } from "react-redux";

const Step4MissingData = () => {
  const { cart } = useSelector((state) => state.catalogueReducer);
  const { userRole } = useSelector((state) => state.userReducer);

  return !cart.addresseDeLivraison ||
    !cart.addresseDeFacturation ||
    !cart.reseller ||
    !cart.deliveryOptionName ||
    !cart.business_affiliate ||
    !cart.facture ||
    !cart.bon_de_commande ? (
    <div className="mt-3 bold">
      <div className="f12 text-red">Détails manquants</div>
      {!cart.addresseDeLivraison ? (
        <div className="text-red f10">Adresse de livraison (obligatoire)</div>
      ) : null}
      {!cart.addresseDeFacturation ? (
        <div className="text-red f10">Adresse de facturation (obligatoire)</div>
      ) : null}
      {!cart.deliveryOptionName ? (
        <div className="text-red f10">Mode de livraison (obligatoire)</div>
      ) : null}
      {!cart.reseller ? (
        <div className="text-red f10">Revendeur (obligatoire)</div>
      ) : null}
      {!cart.business_affiliate && userRole !== "reseller" ? (
        <div className="text-red f10">Apporteur d'affaire</div>
      ) : null}
      {!cart.facture ? <div className="text-red f10">Facture</div> : null}
      {!cart.bon_de_commande ? (
        <div className="text-red f10">Bon de commande</div>
      ) : null}
    </div>
  ) : null;
};

export default Step4MissingData;
