import React from "react";
import VerticalPoints from "../../Common/VerticalPoints/VerticalPoints";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleXmark,
  faCircleCheck,
} from "@fortawesome/free-regular-svg-icons";
import TicketStatusTag from "../../Sav/NewTicketSav/TicketStatusTag";
import moment from "moment";
import { TicketProblemPicker } from "../../Sav/NewTicketSav/TicketProblemPicker";
import { useDispatch, useSelector } from "react-redux";
import { generateOptionsBasedOnRole } from "../../../Utils/savUtils";

const TicketSAVPreview = ({
  id,
  date,
  deliveryDate,
  refClient,
  problem,
  status,
  reseller,
  ticketSAV,
  technicien = true,
}) => {
  const dispatch = useDispatch();
  const { userRole } = useSelector((state) => state.userReducer);
  // const columnStyle = "col-1 textStart  text-brightGrey f15 normal py-4";
  const columnStyle = "textStart text-brightGrey f15 normal py-4 w10 pe-3";
  const options = generateOptionsBasedOnRole(userRole, id, dispatch);

  return (
    <div className="w-100 alignH px-4">
      <span className={`text-lightBlue ${columnStyle}`}>{id}</span>
      <span
        className={`textStart text-brightGrey ellipsis-text f15 normal py-4 w20 pe-3`}
      >
        {reseller?.attributes?.name ?? "-"}
      </span>
      <span className="textStart text-brightGrey f15 normal py-4 w20 pe-3">
        {moment(date).format("DD-MM-YYYY")}
      </span>
      {/* {role === 0 && (
        <>
          <span className={columnStyle}>{nom}</span>
          <span className={columnStyle}>{fournisseur}</span>
        </>
      )} */}
      {/* {role !== 0 && (
        <>
          <span className={`${columnStyle} d-flex flex-column`}>
            <span className="text-softBlue">{society?.name}</span>
            <span className="text-softBlue">{society?.company}</span>
          </span>
          <span className={`${columnStyle}`}>{fournisseur}</span>
        </>
      )} */}

      <span className="textStart text-brightGrey f15 normal py-4 w20 pe-3">
        {moment(deliveryDate).format("DD-MM-YYYY")}
      </span>
      {/* <span className="textStart text-brightGrey f15 normal py-4 w15 pe-3">
        {refClient}
      </span> */}
      <div className={`alignH pe-0 w15`}>
        <TicketStatusTag status={status} />
      </div>
      <span className="textStart text-brightGrey f15 normal py-4 w15 pe-3">
        {TicketProblemPicker(problem)}
      </span>
      {/* <span className={columnStyle}>{dateRelance}</span> */}
      <span className="textStart text-brightGrey f15 normal py-4 w15 pe-3">
        {technicien === "done" ? (
          <FontAwesomeIcon icon={faCircleCheck} size="xl" color={"#1ad598"} />
        ) : (
          <FontAwesomeIcon icon={faCircleXmark} size="xl" color={"#ff0d0d"} />
        )}
      </span>
      <span className="textStart text-brightGrey f15 normal py-4 w3">
        <VerticalPoints showLeft options={options} className="ms-auto" />
      </span>
    </div>
  );
};

export default TicketSAVPreview;
