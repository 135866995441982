import React, { useState } from "react";

import "./LongSwitch.css";

function LongSwitch() {
  const [option, setOption] = useState("month");
  const weekClassName = option === "week" ? "active" : "";
  const monthClassName = option === "month" ? "active" : "";

  const handleClick = (value) => {
    setOption(value);
  };
  return (
    <div className="longSwitch d-flex justify-content-between align-items-center br6 position-relative">
      <button
        className="w50 d-flex justify-content-center bg-transparent border-0 zIndex-2"
        onClick={() => handleClick("week")}
      >
        <p className={`btnText mb-0 f15 text-lightGreen ${weekClassName}`}>
          Semaine
        </p>
      </button>
      <button
        className="w50 d-flex justify-content-center bg-transparent border-0 zIndex-2"
        onClick={() => handleClick("month")}
      >
        <p className={`btnText mb-0 f15 text-lightGreen ${monthClassName}`}>
          Mois
        </p>
      </button>
      <div
        className={`highlighter zIndex-1 br6 bg-blue ${monthClassName}`}
      ></div>
    </div>
  );
}

export default LongSwitch;
