const initialState = {
  profiles: [],
  loading: false,
  error: null,
  profile: null,
  profileLoading: false,
  profileError: null,
  createProfile: null,
  createLoading: false,
  createError: null,
  deleteLoading: false,
  deleteError: null,
};

const profileReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_PROFILES_LOADING":
      return { ...state, loading: true, error: null };
    case "FETCH_PROFILES_SUCCESS":
      return { ...state, loading: false, error: null, profiles: payload };
    case "FETCH_PROFILES_ERROR":
      return { ...state, loading: false, error: payload };

    case "FETCH_PROFILE_LOADING":
      return { ...state, profileLoading: true, profileError: null };
    case "FETCH_PROFILE_SUCCESS":
      return {
        ...state,
        profileLoading: false,
        profileError: null,
        profile: payload,
      };
    case "FETCH_PROFILE_ERROR":
      return { ...state, profileLoading: false, profileError: payload };

    case "CREATE_PROFILE_LOADING":
      return { ...state, createLoading: true, createError: null };
    case "CREATE_PROFILE_SUCCESS": {
      let isFound = false;
      const updatedProfiles = state.profiles?.map((item) => {
        if (+item?.id === +payload.id) {
          isFound = true;
          return payload;
        }
        return item;
      });
      return {
        ...state,
        createLoading: false,
        createError: null,
        createProfile: payload,
        profiles: isFound ? updatedProfiles : [payload, ...state.profiles],
      };
    }
    case "CREATE_PROFILE_ERROR":
      return { ...state, createLoading: false, createError: payload };

    case "DELETE_PROFILE_LOADING":
      return {
        ...state,
        deleteLoading: true,
        deleteError: null,
      };
    case "DELETE_PROFILE_SUCCESS":
      return {
        ...state,
        deleteLoading: false,
        deleteError: null,
        profiles: state.profiles?.filter((item) => +item?.id !== +payload),
      };

    case "CLEAR_CURRENT_PROFILE":
      return {
        ...state,
        profile: null,
        createProfile: null,
        createLoading: false,
        createError: null,
      };
    default:
      return state;
  }
};

export default profileReducer;
