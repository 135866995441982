import React from "react";
import { Link } from "react-router-dom";

function ProductAttachment({ icon, name, link }) {
  return (
    <Link
      target="_blank"
      to={link}
      className="productAttachmentLink d-flex flex-column align-items-center pointer"
    >
      <div className="productAttachmentIcon br50 bg-softBlue mb-3 d-flex align-items-center justify-content-center">
        {icon}
      </div>
      <p className="f13 text-brightGreyy">{name}</p>
    </Link>
  );
}

export default ProductAttachment;
