import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextInput from "../../Common/FormElements/TextInput/TextInput";
import { lightSensorForm } from "./data";
import { Col, Row } from "react-bootstrap";
import SwitchButton from "../../Common/FormElements/SwitchButton/SwitchButton";

const LightSensorProduct = ({ handleFormsChange, initialValues }) => {
  const dispatch = useDispatch();
  const { currentLightSensor, currentProductLoading: loading } = useSelector(
    (state) => state.productsReducer
  );
  const [formData, setFormData] = useState(initialValues ?? {});
  const [invalidFields, setInvalidFields] = useState([]);
  const [disabled, setDisabled] = useState(true);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
    checkFormValidation();
  };

  const checkFormValidation = () => {
    const updatedInvalidFields = [];
    lightSensorForm.forEach(({ name, type, required }) => {
      if (required) {
        if (type === "select") {
          if (
            formData[name]?.code === undefined ||
            formData[name]?.code === null
          ) {
            updatedInvalidFields.push(name);
          }
        } else if (
          formData[name] === undefined ||
          formData[name] === null ||
          !String(formData[name])?.trim()?.length > 0
        ) {
          updatedInvalidFields.push(name);
        }
      }
    });
    setInvalidFields(updatedInvalidFields);
  };

  useEffect(() => {
    checkFormValidation();
    handleFormsChange("lightSensor", formData);
  }, [formData]);

  useEffect(() => {
    setDisabled(invalidFields?.length > 0);
  }, [invalidFields]);

  useEffect(() => {
    if (currentLightSensor?.id) {
      setFormData((currState) => {
        const updatedFormData = { ...currState };
        lightSensorForm?.forEach(({ name, type }) => {
          if (type === "switch") {
            updatedFormData[name] = currentLightSensor?.attributes[name];
          } else {
            updatedFormData[name] = currentLightSensor?.attributes[name];
          }
        });
        return updatedFormData;
      });
    }
  }, [currentLightSensor]);

  return (
    <>
      <div className="d-flex flex-column gap-3">
        <Row>
          {lightSensorForm.map((el) => {
            if (el?.type === "switch") {
              return (
                <Col
                  lg={3}
                  key={el.name}
                  className="mb-3 d-flex flex-column gap-2"
                >
                  <span className="text-softBlack normalf16">{el.label}</span>
                  <SwitchButton
                    isActive={formData[el.name]}
                    onClick={() => {
                      setFormData({
                        ...formData,
                        [el?.name]: !formData[el.name],
                      });
                    }}
                    filterSwitch
                  />
                </Col>
              );
            }
            return (
              <Col
                lg={3}
                key={el.name}
                className="mb-3 d-flex flex-column gap-2"
              >
                <span className="text-softBlack normalf16">{el.label}</span>
                <TextInput
                  className={"w100"}
                  type={el.type}
                  name={el.name}
                  value={formData[el.name]}
                  placeholder={el.placeholder}
                  euro={el.euro}
                  callback={handleChange}
                />
              </Col>
            );
          })}
        </Row>
      </div>
      {/* <div className="w-100 alignC gap-3 px-5 pt-3 mt-auto">
        <Button
          variant={"outline"}
          className="px-5"
          onClick={() => dispatch(closeRightModal())}
        >
          Annuler
        </Button>
        <Button
          className="px-5"
          disabled={disabled}
          loading={loading}
          onClick={loading ? undefined : handleClick}
        >
          Enregistrer
        </Button>
      </div> */}
    </>
  );
};

export default LightSensorProduct;
