import React, { useState } from "react";
import TabsNavbar from "../../Common/TabsNavBar/TabsNavbar";
import LineChartHeader from "../../Dashboard/Syntheses/LineChartHeader";
import CustomLineChart from "../../Common/CustomLineChart/CustomLineChart";

const SynthesesRecettesSection = () => {
  const [activeTab, setActiveTab] = useState("CA Objectif");

  return (
    <div className="w100">
      <TabsNavbar
        tabs={[
          "CA Objectif",
          "CA Actuel",
          "Marge Brut",
          "Marge Net",
          "Tx Marge Brut",
          "Tx Marge net",
          "Résultat",
        ]}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabClassName="f14 normal me-4 text-start"
      />

      <LineChartHeader colors={["yellowOrange", "aquaGreen"]} />
      <CustomLineChart colors={["#FCB03E", "#1AD5AF"]} />
    </div>
  );
};

export default SynthesesRecettesSection;
