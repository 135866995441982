import React from "react";

function DeleteButton({ callback, disabled = false, styles = "" }) {
  return (
    <button
      onClick={callback}
      className={`deleteButton br5 d-flex justify-content-center align-items-center border-0 ${styles}`}
      disabled={disabled}
    >
      <i className="bi bi-trash" />
    </button>
  );
}

export default DeleteButton;
