import React, { useEffect, useState } from "react";
import Select from "../Common/FormElements/Select/Select";
import SwitchButton from "../Common/FormElements/SwitchButton/SwitchButton";
import Button from "../Common/FormElements/Button/Button";
import CalendarInput from "../Common/FormElements/CalendarInput/CalendarInput";
import { useDispatch, useSelector } from "react-redux";
import { getTicketSAVFiltersData } from "../../REDUX/actions/ticketSAV";
import moment from "moment";
import { adminInitialValues } from "../../helpers/formLabels/savFilters";
import {
  problemOptions,
  statusOptions,
} from "../../helpers/formLabels/savRevendeurFilters";

const SavFiltersAdmin = ({ filtersInput, setFiltersInput }) => {
  const [formData, setFormData] = useState(adminInitialValues);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTicketSAVFiltersData());
  }, [dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSpecialChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value?.code });
  };

  const handleInterventionChange = () => {
    setFormData({ ...formData, intervention: !formData.intervention });
  };

  const handleSubmit = () => {
    setFiltersInput(formData);
  };

  const { filtersLists } = useSelector((state) => state.ticketSAVReducer);

  return (
    <>
      {/* 1st line */}
      <div className="d-flex justify-content-between">
        <div className="w20">
          <label className="f16 text-softBlack mb-2 sBold">Statut</label>
          <Select
            showClear
            placeholder="Status"
            name="status"
            options={statusOptions}
            callback={handleSpecialChange}
          />
        </div>
        <div className="w20">
          <label className="f16 text-softBlack mb-2 sBold">Fournisseur</label>
          <Select
            options={[
              { name: "Fournisseur", code: "Fournisseur" },
              { name: "Option", code: "Option" },
            ]}
            disabled={true}
          />
        </div>

        <div className="w20">
          <label className="f16 text-softBlack mb-2 sBold">Créé le</label>
          <Select
            showClear
            placeholder={"Créé le"}
            options={filtersLists?.createdAtList?.map((date) => {
              return {
                name: moment(date).format("DD-MM-YYYY à hh:mm"),
                code: date,
              };
            })}
            name="createdAt"
            callback={handleChange}
          />
        </div>
        <div className="w20">
          <label className="f16 text-softBlack mb-2 sBold">
            Date de relance
          </label>
          <Select
            showClear
            placeholder="date de relance"
            options={filtersLists?.relaunchList?.map((date) => {
              return {
                name: date,
                code: date,
              };
            })}
            name="relaunch"
            callback={handleChange}
          />
        </div>
      </div>

      {/* 2nd line */}

      <div className="d-flex justify-content-between mt-4">
        <>
          <div className="w20">
            <label className="f16 text-softBlack mb-2 sBold">Revendeur</label>
            <Select
              showClear
              placeholder={"Revendeur"}
              options={filtersLists?.resellersList?.map((reseller) => {
                return {
                  name: reseller.lastName + " " + reseller.firstName,
                  code: reseller.id,
                };
              })}
              name="revendeur"
              callback={handleChange}
            />
          </div>

          <div className="w20">
            <label className="f16 text-softBlack mb-2 sBold">Responsable</label>
            <Select
              showClear
              placeholder="Responsable"
              options={filtersLists?.responsablesList?.map((responsable) => {
                return {
                  name: responsable.lastName + " " + responsable.firstName,
                  code: responsable.id,
                };
              })}
              name="responsable"
              callback={handleChange}
            />
          </div>
        </>

        <div className="w20">
          <label className="f16 text-softBlack mb-2 sBold">Problème</label>
          <Select
            showClear
            placeholder="Problème"
            options={problemOptions}
            name="problem"
            callback={handleSpecialChange}
          />
        </div>

        <div className="w20 alignH justify-content-between">
          <div className="d-flex flex-column ">
            <label className="f16 text-softBlack mb-2 sBold">
              Interventions
            </label>
            <span className="mb-1">
              <SwitchButton filterSwitch onClick={handleInterventionChange} />
            </span>
          </div>
        </div>
      </div>

      {/* 3rd line */}

      <div className="d-flex justify-content-between mt-4">
        <div className="w20 d-flex flex-column">
          <label className="f16 text-softBlack mb-2 sBold">Depuis le</label>
          <CalendarInput
            placeholder={"Depuis le"}
            name="sinceDate"
            callback={handleChange}
            withMinDate={false}
          />
        </div>
        <div className="w20 d-flex flex-column">
          <label className="f16 text-softBlack mb-2 sBold">Jusqu'au</label>
          <CalendarInput
            placeholder={"Jusqu'au"}
            name="untilDate"
            callback={handleChange}
            withMinDate={false}
          />
        </div>
        <div className="w20"></div>
        <div className="w20 align-self-end">
          <Button className="filterSubmitBtn" onClick={handleSubmit}>
            Filtrer
          </Button>
        </div>
      </div>
    </>
  );
};

export default SavFiltersAdmin;
