import React from "react";
import VerticalPoints from "../Common/VerticalPoints/VerticalPoints";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openRightModal } from "../../REDUX/actions/rightModal";
import NewRevendeur from "./NewRevendeur/NewRevendeur";
import {
  archiveReseller,
  deleteReseller,
  unarchiveReseller,
} from "../../REDUX/actions/reseller";
import { openCenteredModal } from "../../REDUX/actions/centeredModal";
import ConfirmModal from "../Common/Modal/ConfirmModal";
import { isDefined } from "../../Utils";

const RevendeurCard = ({
  id,
  name,
  phoneNumber,
  email,
  responsible,
  business_affiliate: businessAffiliate,
  delivery_addresses,
  billingAddress,
  lastOrderDate,
  archived,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSeeProfiles = () => {
    navigate(`/revendeur/${id}/profils`);
  };

  const addressAttributes = delivery_addresses?.data[0]?.attributes;
  const city = addressAttributes?.city?.data?.attributes?.name;
  const country = addressAttributes?.country?.data?.attributes?.name;
  const street = addressAttributes?.street;

  const addressData = [country, city, street].filter((item) => isDefined(item));

  const address = addressData?.length > 0 ? addressData.join(", ") : "-";

  const affiliateName = `${
    businessAffiliate?.data?.attributes?.user?.data?.attributes?.firstName ?? ""
  } ${
    businessAffiliate?.data?.attributes?.user?.data?.attributes?.lastName ?? ""
  }`;
  const handleUpdate = () => {
    dispatch(openRightModal(<NewRevendeur id={id} />));
  };
  return (
    <div>
      <div
        className={`row px-4 py-4 d-flex justify-content-between align-items-center w-100 orderCardContainer pointer`}
        onClick={handleUpdate}
      >
        <span className={`f14  overflow-auto regular col-2 text-black`}>
          {name ?? "-"}
        </span>
        <span className={`f14  overflow-auto normal col-1 text-lightBlack`}>
          {phoneNumber ?? "-"}
        </span>
        <span className={`f14  overflow-auto normal col-2 text-lightBlack`}>
          {email ?? "-"}
        </span>
        <span className={`f14  overflow-auto normal col-2 text-lightBlack`}>
          {address ?? "-"}
        </span>
        <span className={`f14  overflow-auto normal col-2 text-blue`}>
          {affiliateName?.trim()?.length > 0 ? affiliateName : "-"}
        </span>
        {/* <span className={`f14  overflow-auto normal col-2 text-blue`}>
          {affiliateName}
        </span> */}
        <span className={`f14   normal col-2 text-lightBlack alignH`}>
          {lastOrderDate ?? "-"}
          <VerticalPoints
            showLeft
            options={[
              { text: "Voir les profils", callback: handleSeeProfiles },
              {
                text: "Modifier",
                callback: () =>
                  dispatch(openRightModal(<NewRevendeur id={id} />)),
              },
              {
                text: archived ? "Désarchiver" : "Archiver",
                callback: archived
                  ? () => dispatch(unarchiveReseller(id))
                  : () => dispatch(archiveReseller(id)),
              },
              {
                text: "Supprimer",
                callback: () =>
                  dispatch(
                    openCenteredModal(
                      <ConfirmModal
                        label="le revendeur"
                        id={id}
                        onConfirm={() =>
                          dispatch(
                            deleteReseller(
                              id,
                              delivery_addresses?.data[0]?.id,
                              billingAddress?.data?.id
                            )
                          )
                        }
                      />
                    )
                  ),
                red: true,
              },
            ]}
            className="ms-auto"
          />
        </span>
      </div>
    </div>
  );
};

export default RevendeurCard;
