import { useState } from "react";
import { Carousel } from "react-bootstrap";
import defaultImage from "../../../Images/Images/default-image.webp";
import { openCenteredModal } from "../../../REDUX/actions/centeredModal";
import { useDispatch } from "react-redux";
import ImageModal from "../../Common/ImageModal";

function ProductCarousel({ photos = [] }) {
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(0);
  const handleChange = (activeIndex) => {
    setActiveIndex(activeIndex);
  };

  const openModal = (image) => () => {
    dispatch(openCenteredModal(<ImageModal image={image} />));
  };
  return (
    <div className="productCarousel w40 ">
      {photos?.length > 0 ? (
        <>
          <Carousel
            controls={photos?.length > 1}
            indicators={false}
            interval={null}
            onSelect={handleChange}
            nextIcon={<i className="bi bi-chevron-right f31 carouselIcon" />}
            prevIcon={<i className="bi bi-chevron-left f31 carouselIcon" />}
          >
            {photos.map((image, index) => {
              return (
                <Carousel.Item key={index}>
                  <button
                    className="w-100 h-100 alignC p-5 border-0 outline-none bg-transparent"
                    onClick={openModal(image)}
                  >
                    <img
                      className="d-block w-auto h-100 ob-contain"
                      src={image}
                      alt="produit"
                    />
                  </button>
                </Carousel.Item>
              );
            })}
          </Carousel>
          <div className="d-flex mt-3 overflow-x-scroll">
            {photos.map((image, index) => (
              <button
                key={index}
                className="alignC border-0 outline-none p-2 bg-transparent"
                onClick={openModal(image)}
              >
                <img
                  src={image}
                  alt="produit"
                  height={120}
                  width={120}
                  className={activeIndex === index ? "active" : ""}
                  style={{ objectFit: "contain" }}
                />
              </button>
            ))}
          </div>
        </>
      ) : (
        <img
          src={defaultImage}
          alt="produit"
          height="auto"
          width="100%"
          style={{ objectFit: "contain" }}
        />
      )}
    </div>
  );
}

export default ProductCarousel;
