/* -------------------------------------------------------------------------- */
/*                                dependencies                                */
/* -------------------------------------------------------------------------- */
// Pacakges
import React from "react";
import { openCenteredModal } from "../../../REDUX/actions/centeredModal";
import { useDispatch } from "react-redux";
import FormAdress from "./FormAdress";
/* -------------------------------------------------------------------------- */
/*                             Local Ui component                             */
/* -------------------------------------------------------------------------- */
const AddAdress = ({
  resellerId,
  type = "addresseDeLivraison",
  callBack = () => {},
}) => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(
      openCenteredModal(
        <FormAdress resellerId={resellerId} type={type} callBack={callBack} />
      )
    );
  };
  return (
    <div
      style={{ minHeight: "200px" }}
      className="br5 w300 cursor-pointer shadow alignC p-2"
      aria-description="add adress"
      onClick={handleClick}
    >
      <div className="w300 alignC flex-column h100 bold text-center">
        <i className="bi bi-plus f50 text-brightGrey"></i>
        <span className="f14 text-brightGrey">
          Ajouter une nouvelle adresse de{" "}
          {type === "addresseDeLivraison" ? "livraison" : "facturation"}
        </span>
      </div>
    </div>
  );
};

export default AddAdress;
