const getPanierSummaryData = (
  productsCost,
  deliveryCost = 0,
  VATPercentage = 20,
  hasTax
) => {
  let taxCost = hasTax
    ? (((productsCost + deliveryCost) / 100) * VATPercentage).toFixed(2)
    : 0;
  let totalCost = (productsCost + deliveryCost).toFixed(2);
  if (isNaN(productsCost)) {
    return {
      taxCost: 0,
      totalCost: 0,
    };
  } else {
    taxCost = hasTax
      ? (((productsCost + deliveryCost) / 100) * VATPercentage).toFixed(2)
      : 0;
    totalCost = ((productsCost + deliveryCost) * (hasTax ? 1.2 : 1)).toFixed(2);
  }
  return {
    taxCost,
    totalCost,
  };
};
export default getPanierSummaryData;

// const getPanierSummaryData = (products, deliveryOption = undefined) => {
//   let totalCount = 0;
//   let productsCost = 0;
//   let deliveryCost = 0;
//   let taxCost = 0;
//   let totalCost = 0;
//   switch (deliveryOption) {
//     case "on site":
//       deliveryCost = 0;
//       break;
//     case "truck 19T LH":
//       deliveryCost = 250;
//       break;
//     case "truck 3,5T LH":
//       deliveryCost = 350;
//       break;
//     case "truck charter":
//       deliveryCost = 390;
//       break;

//     default:
//       break;
//   }
//   for (let i = 0; i < products.length; i++) {
//     totalCount += products[i].quantity;
//     productsCost += products[i].quantity * products[i].publicPriceTTC;
//   }
//   taxCost = (productsCost / 100) * 20;
//   totalCost = productsCost + deliveryCost + taxCost;

//   return { totalCount, deliveryCost, taxCost, productsCost, totalCost };
// };
