import React from "react";
import TextInput from "../../../Common/FormElements/TextInput/TextInput";

function CompanyInfo() {
  return (
    <div className="p-5">
      <div className="d-flex justify-content-between">
        <div className="mb-3 w49">
          <p className="f16 text-ultraBlack mb-2">Nom de la société</p>
          <TextInput placeholder="Nom de la société" />
        </div>
        <div className="mb-3 w49">
          <p className="f16 text-ultraBlack mb-2">Siret</p>
          <TextInput placeholder="Siret" />
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <div className="mb-3 w49">
          <p className="f16 text-ultraBlack mb-2">E-mail</p>
          <TextInput placeholder="E-mail" />
        </div>
        <div className="mb-3 w49">
          <p className="f16 text-ultraBlack mb-2">Téléphone</p>
          <TextInput placeholder="Code postal" />
        </div>
      </div>
      <div className="mb-3">
        <p className="f16 text-ultraBlack mb-2">Site Internet</p>
        <TextInput placeholder="Site Internet" />
      </div>
    </div>
  );
}

export default CompanyInfo;
