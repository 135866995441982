import React, { useEffect } from "react";
import IntermediaryCard from "./IntermediaryCard";
import { useDispatch, useSelector } from "react-redux";
import { getProfiles } from "../../../REDUX/actions/profile";
import { useParams } from "react-router-dom";
import LoaderWrapper from "../../../Utils/ViewWrapper/LoaderWrapper/LoaderWrapper";

function ViewContent() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { profiles, loading } = useSelector((state) => state.profileReducer);

  useEffect(() => {
    dispatch(getProfiles(id));
  }, [dispatch, id]);
  return (
    <div className="flex-1">
      <div className="row bg-dimGrey py-3 px-4 mx-0 w-100 d-flex justify-content-between align-items-center">
        <span className="col-2 text-brightGrey sBold f14">Nom</span>
        <span className="col-2 text-brightGrey sBold f14">Prénom</span>
        <span className="col-2 text-brightGrey sBold f14">Téléphone</span>
        <span className="col-3 text-brightGrey sBold f14">E-mail</span>
        <span className="col-2 text-brightGrey sBold f14">Poste</span>
        <span className="col-1 text-brightGrey sBold f14"></span>
      </div>

      <LoaderWrapper loading={loading}>
        {profiles?.map((profile) => (
          <div key={profile?.id}>
            <IntermediaryCard
              id={profile?.id}
              {...profile?.attributes}
              userId={profile?.attributes?.user?.data?.id}
              {...profile?.attributes?.user?.data?.attributes}
            />
            <hr style={{ borderColor: "#abb8b2", margin: 0 }} width={"100%"} />
          </div>
        ))}
      </LoaderWrapper>
    </div>
  );
}

export default ViewContent;
