const initialState = {
  expenses: [],
  loading: false,
  error: null,
  currentExpensesItem: null,
  currentExpensesItemLoading: false,
  createError: null,
  currentComments: [],
  currentCommentsLoading: false,
  itemLoading: false,
  commentsLoading: false,
  vendors: [],
};

const expensesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_EXPENSES_LOADING":
      return { ...state, loading: true };
    case "FETCH_EXPENSES_SUCCESS":
      return { ...state, loading: false, expenses: payload, error: null };
    case "FETCH_EXPENSES_ERROR":
      return { ...state, loading: false, error: payload };

    case "CREATE_UPDATE_EXPENSES_LOADING":
      return { ...state, currentExpensesItemLoading: true };
    case "CREATE_EXPENSES_SUCCESS":
      return {
        ...state,
        expenses: [payload, ...state?.expenses],
        currentExpensesItemLoading: false,
        createError: null,
        currentExpensesItem: payload,
      };
    case "UPDATE_EXPENSES_SUCCESS": {
      const updatedExpenses = state.expenses?.map((item) => {
        if (+item?.id === +payload?.id) {
          return payload;
        } else {
          return item;
        }
      });
      return {
        ...state,
        expenses: updatedExpenses,
        currentExpensesItemLoading: false,
        createError: null,
        currentExpensesItem: payload,
      };
    }
    case "CREATE_UPDATE_EXPENSES_ERROR":
      return {
        ...state,
        currentExpensesItemLoading: false,
        createError: payload,
      };
    case "CREATE_COMMENTS_LOADING":
      return { ...state, currentCommentsLoading: true };
    case "CREATE_COMMENTS_SUCCESS":
      return {
        ...state,
        currentCommentsLoading: false,
        currentComments: [payload, ...state.currentComments],
        expenses: state.expenses?.map((item) => {
          if (+item?.id === +payload?.attributes?.expense?.data?.id) {
            return {
              ...item,
              attributes: {
                ...item?.attributes,
                comments: {
                  data: [payload, ...item?.attributes?.comments?.data],
                },
              },
            };
          }
          return item;
        }),
      };

    case "FETCH_COMMENTS_LOADING":
      return { ...state, commentsLoading: true };

    case "FETCH_COMMENTS_SUCCESS":
      return { ...state, currentComments: payload, commentsLoading: false };

    case "FETCH_CURRENT_EXPENSES_LOADING":
      return { ...state, itemLoading: true };
    case "FETCH_CURRENT_EXPENSES_SUCCESS":
      return { ...state, itemLoading: false, currentExpensesItem: payload };

    case "DELETE_EXPENSES_ITEM_LOADING":
      return { ...state, loading: false, error: null };
    case "DELETE_EXPENSES_ITEM_SUCCESS":
      return {
        ...state,
        expenses: state.expenses?.filter((item) => +item?.id !== +payload),
        loading: false,
      };
    case "DELETE_EXPENSES_ITEM_ERROR":
      return { ...state, error: payload, loading: false };

    case "DELETE_EXPENSES_SUCCESS":
      return {
        ...state,
        expenses: state.expenses?.filter(
          (item) => !payload?.find((p) => +p?.id === +item?.id)
        ),
        loading: false,
      };

    case "FETCH_EXPENSE_VENDORS":
      return { ...state, vendors: payload };
    case "CLEAR_CURRENT_EXPENSES":
      return {
        ...state,
        currentExpensesItemLoading: false,
        createError: null,
        currentExpensesItem: null,
        currentComments: [],
        currentCommentsLoading: false,
      };
    default:
      return state;
  }
};

export default expensesReducer;
