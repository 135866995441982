import React, { useEffect, useState } from "react";

import TabsNavbar from "../../Common/TabsNavBar/TabsNavbar";
import Button from "../../Common/FormElements/Button/Button";
import ShadowLoader from "../../../Utils/ViewWrapper/ShadowLoader/ShadowLoader";
import UpdateTicketSAVContent from "./UpdateTicketSAVContent";
import { useDispatch, useSelector } from "react-redux";
import {
  createCartSAV,
  getOneTicketSAV,
  updateTicketSAV,
} from "../../../REDUX/actions/ticketSAV";
import "./UpdateTicketSav.css";
import {
  getInterventionSelectValue,
  interventionSelectValues,
} from "./UpdateSavTabContent/getInterventionType";
import { useNavigate } from "react-router-dom";

const UpdateTicketSav = ({ id }) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("Infos générales");
  const [formData, setFormData] = useState({});

  const { ticketSAV, ticketSAVLoading, ticketSAVError, ticketSAVReload } =
    useSelector((state) => state.ticketSAVReducer);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    dispatch(
      updateTicketSAV({
        id,
        ...formData,
      })
    );
  };

  useEffect(() => {
    dispatch(getOneTicketSAV({ id }));
  }, [dispatch, id, ticketSAVReload]);

  useEffect(() => {
    const intervention = ticketSAV?.intervention;
    const responsable = ticketSAV?.users.find((user) => user.userRole === 1);

    setFormData({
      ...ticketSAV,
      intervention: intervention
        ? {
            ...ticketSAV?.intervention,
            type: interventionSelectValues?.find((el) => {
              return +el?.code === +intervention?.type;
            }),
          }
        : undefined,
      responsable: responsable
        ? {
            name: responsable.firstName + " " + responsable.lastName,
            code: responsable.id,
          }
        : undefined,
    });
  }, [ticketSAV]);

  return (
    <div className="newTicketSAV d-flex flex-column justify-content-between h100">
      <div>
        <h2 className="bold rightModalTitle px-5 mb-4">Ticket SAV #{id}</h2>
        <TabsNavbar
          tabs={[
            "Infos générales",
            // "Revendeur",
            "Pièces jointes",
            "Échanges",
            // "Commande SAV",
            // "Résumé",
          ]}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabClassName={"me-5"}
        />
        <ShadowLoader loading={ticketSAVLoading} error={ticketSAVError}>
          <UpdateTicketSAVContent
            activeTab={activeTab}
            formData={formData}
            setFormData={setFormData}
          />
        </ShadowLoader>
      </div>
      {activeTab === "Infos générales" && (
        <div className="d-flex justify-content-center mt-5 gap-3">
          <Button className="px-5" onClick={handleSubmit}>
            Enregistrer
          </Button>

          <Button
            variant="outline"
            className="px-5"
            onClick={() =>
              dispatch(
                createCartSAV(navigate, {
                  reseller: ticketSAV?.reseller?.id,
                  business_affiliate:
                    ticketSAV?.reseller?.attributes?.business_affiliate?.data
                      ?.id,
                  ticket_sav: ticketSAV?.id,
                })
              )
            }
          >
            Créer une commande
          </Button>
        </div>
      )}
    </div>
  );
};

export default UpdateTicketSav;
