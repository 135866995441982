import React, { useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";

import CalendarEvent from "../../../../Common/Calendar/CalendarEvent/CalendarEvent";
import Evenement from "../../../Modals/Evenement/Evenement";
import { openRightModal } from "../../../../../REDUX/actions/rightModal";
import Prospect from "../../../Modals/Prospect/Prospect";
import Commande from "../../../Modals/Commande/Commande";

import "./CalendarSlot.css";
import { eventTypeFr, formatTimeToHHMM } from "../../../../../Utils/fileUtils";
import Livraison from "../../../Modals/Livraison/Livraison";

function CalendarSlot({
  date,
  day,
  index,
  indexOfOne,
  events = [],
  orders = [],
  selectedOption,
}) {
  const [showDropdown, setShowDropdown] = useState(false);
  const lastInLineClassName = (index + 1) % 7 === 0 ? "lastInLine" : "";
  const dispatch = useDispatch();

  const numberColor =
    index < indexOfOne || (index > 27 && day < 10)
      ? "text-softGrey"
      : "text-fadedGrey";

  const isCurrentDate =
    moment().format("YYYY/M/D") === date ? "isCurrentDate" : "";

  const handleEventClick = (type, eventId) => {
    switch (type) {
      case "Événement":
        dispatch(openRightModal(<Evenement modalTitle={type} id={eventId} />));
        break;
      case "Commande":
        dispatch(openRightModal(<Commande />));
        break;
      case "Salon":
        dispatch(
          openRightModal(
            <Evenement modalTitle={type} id={eventId} eventType={"Salon"} />
          )
        );
        break;
      case "Livraison":
        setShowDropdown(true);
        break;
      case "Prospect":
        dispatch(openRightModal(<Prospect />));
        break;

      default:
        break;
    }
  };

  let filteredEvents = events || [];
  let filteredOrders = orders || [];
  if (selectedOption.code === "Salons") {
    filteredEvents =
      events?.filter((event) => event?.attributes?.eventType === "salon") || [];
    filteredOrders = [];
  } else if (selectedOption.code === "Événements") {
    filteredEvents =
      events?.filter((event) => event?.attributes?.eventType === "event") || [];
    filteredOrders = [];
  } else if (selectedOption.code === "Livraisons") {
    filteredEvents = [];
    filteredOrders = orders || [];
  }

  return (
    <div
      className={`calendarSlot pt-1 pb-3 d-flex flex-column ${lastInLineClassName}`}
    >
      <p
        className={`slotDate mb-1 text-center f14 sBold br50 align-self-end ${numberColor} ${isCurrentDate}`}
      >
        {day}
      </p>
      {filteredEvents?.map((event) => {
        const startDate = moment(event?.attributes?.startDate);
        const endDate = moment(event?.attributes?.endDate);
        const today = moment(date);

        if (startDate.format("YYYY/M/D") === date) {
          const { id, attributes } = event;
          const startTime = formatTimeToHHMM(attributes?.startTime);
          const endTime = formatTimeToHHMM(attributes?.endTime);
          return (
            <CalendarEvent
              key={id}
              id={id}
              title={attributes?.title ?? eventTypeFr[attributes?.eventType]}
              type={eventTypeFr[attributes?.eventType]}
              startTime={startTime}
              endTime={endTime}
              isStartDate
              multipleDays={endDate.diff(startDate, "days") > 0}
              callback={() =>
                handleEventClick(eventTypeFr[attributes?.eventType], id)
              }
            />
          );
        }
        if (endDate.format("YYYY/M/D") === date) {
          const { id, attributes } = event;
          const startTime = formatTimeToHHMM(attributes?.startTime);
          const endTime = formatTimeToHHMM(attributes?.endTime);
          return (
            <CalendarEvent
              key={id}
              id={id}
              title={attributes?.title ?? eventTypeFr[attributes?.eventType]}
              type={eventTypeFr[attributes?.eventType]}
              startTime={startTime}
              endTime={endTime}
              isEndDate
              callback={() =>
                handleEventClick(eventTypeFr[attributes?.eventType], id)
              }
            />
          );
        }
        if (today.isBetween(startDate, endDate)) {
          const { id, attributes } = event;
          const startTime = formatTimeToHHMM(attributes?.startTime);
          const endTime = formatTimeToHHMM(attributes?.endTime);
          return (
            <CalendarEvent
              key={id}
              id={id}
              title={attributes?.title ?? eventTypeFr[attributes?.eventType]}
              type={eventTypeFr[attributes?.eventType]}
              startTime={startTime}
              endTime={endTime}
              isBetween
              callback={() =>
                handleEventClick(eventTypeFr[attributes?.eventType], id)
              }
            />
          );
        }
        return null;
      })}
      {filteredOrders?.map((order) => {
        const delivaryDate = moment(order?.delivery?.date).format("YYYY/M/D");
        const resellerName = order?.reseller?.name;

        if (delivaryDate === date) {
          const { id } = order;
          return (
            <CalendarEvent
              key={id}
              id={id}
              type={"Livraisons"}
              resellerName={resellerName}
              {...order}
              callback={() =>
                dispatch(openRightModal(<Livraison order={order} />))
              }
            />
          );
        }
        return null;
      })}
    </div>
  );
}

export default CalendarSlot;
