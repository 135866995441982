import { isDefined } from "../../../Utils";

const getDetailsTableData = (attributes, activeTab) => {
  const caracteristique = attributes?.caracteristique?.data?.attributes;
  const extraFields = caracteristique?.extraFields;

  const extraFieldsDetails = extraFields?.map((extraField) => {
    return {
      name: `${extraField?.inputName
        ?.charAt(0)
        ?.toUpperCase()}${extraField?.inputName?.slice(1)}`,
      value: extraField?.valueName ?? "-",
    };
  });
  // const caracteristique = attributes?.caracteristique?.data?.attributes;
  const lightSensor = attributes?.light_sensor_serie?.data?.attributes;
  const equipement = attributes?.equipement?.data?.attributes;
  // console.log(attributes);
  // console.log(attributes);
  // function Helpers
  const getBoolFr = (bool) => {
    return bool && bool !== null ? "oui" : "non";
  };
  if (activeTab === "Caractéristiques") {
    return [
      // {
      //   name: "En stock:",
      //   value: attributes?.availableQuantity
      //     ? "Livraison Immédiate"
      //     : attributes?.replenishmentDate
      //     ? `en réapro le ${attributes?.replenishmentDate}`
      //     : "hors stock",
      // },
      {
        name: "Capacité:",
        value: caracteristique?.capacity
          ? `${caracteristique?.capacity} personnes`
          : "-",
        hide: !isDefined(caracteristique?.capacity),
      },
      {
        name: "Dimensions:",
        value: caracteristique?.productDimensions ?? "-",
        hide: !isDefined(caracteristique?.productDimensions),
      },
      {
        name: "Poids net:",
        value: isDefined(caracteristique?.weight)
          ? `${caracteristique?.weight} kg`
          : "-",
        hide: !isDefined(caracteristique?.weight),
      },
      {
        name: "Volume d'eau:",
        value: isDefined(caracteristique?.waterVolume)
          ? `${caracteristique?.waterVolume} L`
          : "-",
        hide: !isDefined(caracteristique?.waterVolume),
      },
      {
        name: "Jets Hydrothérapie:",
        value: caracteristique?.hydrotherapyJet ?? "-",
        hide: !isDefined(caracteristique?.hydrotherapyJet),
      },
      {
        name: "Pompe à eau:",
        value: caracteristique?.waterPump ?? "-",
        hide: !isDefined(caracteristique?.waterPump),
      },
      {
        name: "Pompe de filtration:",
        value: caracteristique?.filtrationPump
          ? `${caracteristique?.filtrationPump} HP`
          : "-",
        hide: !isDefined(caracteristique?.filtrationPump),
      },
      {
        name: "Ordinateur de bord:",
        value: caracteristique?.dashboardComputer ?? "-",
        hide: !isDefined(caracteristique?.dashboardComputer),
      },
      {
        name: "Boitier Eléctrique Balboa:",
        value: caracteristique?.balboaElectricBox ?? "-",
        hide: !isDefined(caracteristique?.balboaElectricBox),
      },
      {
        name: "Chauffage Balboa:",
        value: caracteristique?.balboaHeater
          ? `${caracteristique?.balboaHeater} kw`
          : "-",
        hide: !isDefined(caracteristique?.balboaHeater),
      },
      {
        name: "Alimentation électrique:",
        value: caracteristique?.electricalPowerSource ?? "-",
        hide: !isDefined(caracteristique?.electricalPowerSource),
      },
      ...(extraFieldsDetails ?? []),
    ];
  } else if (activeTab === "Equipement") {
    return [
      {
        name: "Acrylics ARISTECH :",
        value: getBoolFr(equipement?.acrylicsARISTECH),
      },
      {
        name: "Système BioLok :",
        value: getBoolFr(equipement?.systemeBiolok),
      },
      {
        name: "Désinfection :",
        value: equipement?.desinfection ?? "-",
      },
      {
        name: "Filtration à cartouche :",
        value: getBoolFr(equipement?.filtrationCartouche),
      },
      {
        name: "Isolation :",
        value: equipement?.isolation ?? "-",
      },
      {
        name: "couverture d'hiver",
        value: getBoolFr(equipement?.winterCover),
      },
      {
        name: "Haut parleur",
        value: getBoolFr(equipement?.hautParleur),
      },
      {
        name: "Régulateur de puissance :",
        value: equipement?.powerController ?? "-",
      },
      {
        name: "Filtration basse consomation :",
        value: getBoolFr(equipement?.filtrationBasseConsomation),
      },
      {
        name: "Cascade :",
        value: getBoolFr(equipement?.cascade),
      },
      {
        name: "Easy Drain:",
        value: getBoolFr(equipement?.easyDrain),
      },
      {
        name: "Base Abs",
        value: getBoolFr(equipement?.baseABS),
      },
    ];
  } else {
    return [
      {
        name: "Spot de chromothérapie :",
        value: lightSensor?.sportChromotherapie,
      },
      {
        name: "LEDs ligne d'eau :",
        value: lightSensor?.LEDsLigneEau,
        show: isDefined(lightSensor?.LEDsLigneEau),
      },
      {
        name: "Jets Rétro-éclairés :",
        value: getBoolFr(lightSensor?.jetsRetroEclaires),
      },
      {
        name: "Cascade rétro-éclairée :",
        value: lightSensor?.backlitWaterfall ?? "Non",
      },
      {
        name: "Rivière rétro-éclairée :",
        value: lightSensor?.backlitRiver ?? "Non",
      },
      {
        name: "Appui-tête rétro-éclairé :",
        value: lightSensor?.backlitNeckSupport ?? "Non",
      },
      {
        name: "Angles rétro-éclairés :",
        value: lightSensor?.backlitNeckSupport ?? "Non",
      },
      {
        name: "Panneaux rétro-éclairés :",
        value: getBoolFr(lightSensor?.backlitPanels),
      },

      {
        name: "Skimmer rétro-éclairé :",
        value: getBoolFr(lightSensor?.backlitSkimmer),
      },
      {
        name: "ON/OFF rétroéclairés :",
        value: getBoolFr(lightSensor?.backlitToggleSwitch),
      },
      // {
      //   name: "Régulateurs rétroéclairés :",
      //   value: ,
      // },
    ];
  }
};

export default getDetailsTableData;
