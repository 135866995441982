import React from "react";
import { useSelector } from "react-redux";

const NewAdressCardParticulier = ({
  address,
  type = "addresseDeLivraison",
}) => {
  const { cart } = useSelector((state) => state.catalogueReducer);

  return (
    <div
      style={{ minHeight: "200px" }}
      className={`adressCardContainer br10 p-3 cursor-pointer border ${
        address.id === cart[type]?.id ? "bg-fadedBlue  bold" : "bg-dimGrey"
      }`}
    >
      <div>
        {/* <p>
      <i class="bi bi-trash3 text-lameRed pointer"></i>
    </p> */}
        <p className="f16 text-brightGrey mb-1 text-start">{address?.name} </p>
        <p className="f16 text-brightGrey mb-1 text-start">
          {address?.street}{" "}
        </p>
        <p className="f16 text-brightGrey mb-1 text-start">
          {address?.city?.name}
        </p>
        <p className="f16 text-brightGrey mb-2 text-start">{address?.zip}</p>
        <p className="f16 text-brightGrey mb-1 text-start">
          {address?.country?.name}
        </p>
      </div>
    </div>
  );
};

export default NewAdressCardParticulier;
