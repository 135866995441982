import moment from "moment";

const initialState = {
  date: moment().format("YYYY-MM-DD"),
};

const calendarReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "UPDATE_DATE":
      return { ...state, date: payload };
    default:
      return state;
  }
};

export default calendarReducer;
