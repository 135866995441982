import React from "react";
import "./AdressCard.css";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../Common/FormElements/Button/Button";
import { openCenteredModal } from "../../../REDUX/actions/centeredModal";
import { updateCart } from "../../../REDUX/actions/catalogue";
import FormAdress from "../../Catalogue/DeliveryDetails/FormAdress";

const NewAdressCard = ({
  address,
  type = "addresseDeLivraison",
  withSelectOption = true,
  resellerId,
  callBack = () => {},
}) => {
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => state.catalogueReducer);

  return (
    <div
      className={`adressCardContainer br10 p-3 cursor-pointer border ${
        address.id === cart[type]?.id ? "bg-fadedBlue  bold" : "bg-dimGrey"
      }`}
    >
      <div>
        {/* <p>
          <i class="bi bi-trash3 text-lameRed pointer"></i>
        </p> */}
        <p className="f16 text-brightGrey mb-1">{address.attributes?.name} </p>
        <p className="f16 text-brightGrey mb-1">
          {address.attributes?.street}{" "}
        </p>
        <p className="f16 text-brightGrey mb-1">
          {address.attributes?.city?.data?.attributes?.name}
        </p>
        <p className="f16 text-brightGrey mb-2">{address.attributes?.zip}</p>
        <p className="f16 text-brightGrey mb-1">
          {address.attributes?.country?.data?.attributes?.name}
        </p>
      </div>
      <div className="d-flex gap-2">
        <Button
          className="mt-2 w50"
          onClick={() =>
            dispatch(
              openCenteredModal(
                <FormAdress
                  resellerId={resellerId ? resellerId : cart?.reseller?.id}
                  type={type}
                  address={address}
                  callBack={callBack}
                />
              )
            )
          }
        >
          Modifier
        </Button>
        {withSelectOption && (
          <Button
            className="mt-2 w50"
            onClick={() =>
              dispatch(
                updateCart(
                  cart.id,
                  type === "addresseDeFacturation"
                    ? {
                        addresseDeFacturation:
                          address.id === cart[type]?.id ? null : address.id,
                        facture: null,
                        bon_de_commande: null,
                      }
                    : {
                        addresseDeLivraison:
                          address.id === cart[type]?.id ? null : address.id,
                        facture: null,
                        bon_de_commande: null,
                      }
                )
              )
            }
          >
            {address.id === cart[type]?.id ? "Désélectionner" : "Sélectionner"}
          </Button>
        )}
      </div>
    </div>
  );
};

export default NewAdressCard;
