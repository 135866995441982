import React from "react";

import "./Thumbnail.css";

function Thumbnail({ image, status }) {
  // let indicatorColor;
  // if (status === "online") {
  //   indicatorColor = "greenPist";
  // } else if (status === "sleep") {
  //   indicatorColor = "orange";
  // } else {
  //   indicatorColor = "grey";
  // }
  return (
    <div className="thumbnailContainer position-relative">
      <img
        src={image}
        alt="thumbnail"
        className="thumbnail br50 b-3-white object-fit-cover"
        width={50}
        height={50}
      />
      {/* <div
        className={`statusIndicator br50 b-2-white position-absolute bg-${indicatorColor}`}
      ></div> */}
    </div>
  );
}

export default Thumbnail;
