import moment from "moment";

export const dates = [
  29, 30, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 1, 2, 3,
];

export const days = [
  "Dimanche",
  "Lundi",
  "Mardi",
  "Mercredi",
  "Jeudi",
  "Vendredi",
  "Samedi",
];
export const months = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];
export const shortDays = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"];
export const shortMonths = [
  "Janv",
  "Févr",
  "Mars",
  "Avr",
  "Mai",
  "Juin",
  "Juil",
  "Août",
  "Sept",
  "Oct",
  "Nov",
  "Déc",
];

// export const getPointColor = (day) => {
//   switch (day) {
//     case 1:
//     case 18:
//       return "var(--bs-wildStrawberry)";
//     case 2:
//       return "var(--bs-aquaGreen)";
//     case 4:
//     case 22:
//       return "var(--bs-yellowOrange)";
//     case 10:
//       return "var(--bs-jasminePurple)";
//     case 13:
//       return "var(--bs-yellowGreen)";
//     case 16:
//       return "#3ABAEA";
//     default:
//       return "";
//   }
// };
export const getPointColor = (day, dates) => {
  const formattedDate = moment(day).format("DD/MM/YY");
  const matchingDate = dates.find((date) => date.startDate === formattedDate);

  if (matchingDate) {
    switch (matchingDate.key) {
      case "event":
        return "var(--bs-wildStrawberry)";
      case "order":
        return "var(--bs-aquaGreen)";
      // Add more cases as needed
      default:
        return "";
    }
  } else {
    return "";
  }
};
const colorPicker = {
  event: "",
};
