import React from "react";
import { Spinner } from "react-bootstrap";

const DeleteBtn = ({ onClick = () => {}, type = "button", loading }) => {
  return (
    <button
      type={type}
      className="deleteButton bg-redWithoutOp text-white border-0 px-4 br5"
      onClick={onClick}
    >
      {loading ? (
        <Spinner animation="border" variant="light" className="small-spinner" />
      ) : (
        "Supprimer"
      )}
    </button>
  );
};

export default DeleteBtn;
