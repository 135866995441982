const initialState = {
  resellers: [],
  businessAffiliates: [],
  generalDataLoading: false,
  generalDataError: null,
};
const generalReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "GENERAL_DATA_LOADING":
      return { ...state, generalDataLoading: true, generalDataError: null };
    case "REFETCH_RESELLER_ITEM":
      const updatedResellers = state.resellers.map((reseller) =>
        reseller.id === payload.id ? payload : reseller
      );
      return { ...state, resellers: updatedResellers };
    case "GENERAL_DATA_SUCCESS":
      return {
        ...state,
        generalDataLoading: false,
        generalDataError: null,
        resellers: payload.resellers,
        businessAffiliates: payload.business,
      };
    default:
      return state;
  }
};
export default generalReducer;
