import { userRoles } from "../../Utils/fileUtils";
const initialState = {
  user: undefined,
  selectedUser: undefined,
  selectedUserLoading: false,
  selectedUserError: null,
  userLoading: false,
  userCreateLoad: false,
  userUpdateLoad: false,
  error: null,
  allUsers: [],
  redirect: null,
  changePasswordLoading: false,
  changePasswordError: null,
  responsables: [],
  responsablesLoading: false,
  responsablesError: null,
  revendeur: [],
  revendeurLoading: false,
  revendeurError: null,
  userRole: undefined,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        user: action.payload,
        userLoading: false,
        userRole: userRoles[action.payload?.attributes.userRole],
      };
    case "LOGIN_ERROR":
      return { ...state, userLoading: false, error: action.payload };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        userLoading: false,
        user: action.payload,
        userRole: userRoles[action.payload?.attributes?.userRole],
      };
    case "LOADING_USER":
      return { ...state, userLoading: true, error: null };
    case "UPDATE_USER_LOADING":
      return { ...state, userUpdateLoad: true };
    case "UPDATE_USER_SUCC":
    case "UPDATE_USER_FAIL":
      return { ...state, userUpdateLoad: false };

    case "CREATE_USER_LOADING":
      return { ...state, userCreateLoad: true };
    case "CREATE_USER_SUCC":
    case "CREATE_USER_FAIL":
      return { ...state, userCreateLoad: false };
    case "AUTH_SUCCESS":
      return {
        ...state,
        userLoading: false,
        user: action.payload,
        userRole: userRoles[action.payload?.attributes?.userRole],
      };

    case "UPDATE_PROFILE_LOAD":
      return { ...state, userUpdateLoad: true };
    case "UPDATE_PROFILE_SUCC":
    case "UPDATE_PROFILE_FAIL":
      return { ...state, userUpdateLoad: false };

    case "CHANGE_PASSWORD_LOAD":
      return { ...state, changePasswordLoading: true };
    case "CHANGE_PASSWORD_SUCC":
      return {
        ...state,
        changePasswordLoading: false,
      };
    case "CHANGE_PASSWORD_FAIL":
      return {
        ...state,
        changePasswordLoading: false,
        changePasswordError: action.payload,
      };

    case "UNAUTHORIZED":
      return {
        ...state,
        userLoading: false,
        error: action.payload,
        user: undefined,
      };
    case "FETCH_USERS":
      return {
        ...state,
        allUsers: action.payload,
      };
    case "FETCH_SELECTED_USER":
      return {
        ...state,
        selectedUser: action.payload,
        selectedUserLoading: false,
      };
    case "SELECTED_USER_LOADING":
      return { ...state, selectedUserLoading: true };
    case "SELECTED_USER_ERROR":
      return {
        ...state,
        selectedUserLoading: false,
        selectedUserError: action.payload,
      };
    case "RESPONSABLES_LOADING":
      return {
        ...state,
        responsablesLoading: true,
      };
    case "RESPONSABLES_FETCHED":
      return {
        ...state,
        responsablesLoading: false,
        responsables: action.payload,
      };
    case "RESPONSABLES_ERROR":
      return {
        ...state,
        responsablesLoading: false,
        responsablesError: action.payload,
      };
    case "REVENDEUR_LOADING":
      return {
        ...state,
        revendeurLoading: true,
      };
    case "REVENDEUR_FETCHED":
      return {
        ...state,
        revendeurLoading: false,
        revendeur: action.payload,
      };
    case "REVENDEUR_ERROR":
      return {
        ...state,
        revendeurLoading: false,
        revendeurError: action.payload,
      };
    case "LOGOUT":
      return { ...state, userLoading: false, user: undefined, error: null };
    default:
      return state;
  }
};
