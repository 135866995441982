/* -------------------------------------------------------------------------- */
/*                                dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCountriesCitiesNew,
  getCountriesNew,
} from "../../../REDUX/actions/country";
import TextInput from "../../Common/FormElements/TextInput/TextInput";
import Select from "../../Common/FormElements/Select/Select";
import Button from "../../Common/FormElements/Button/Button";
import {
  createNewDeliveryAddress,
  updateAdress,
} from "../../../REDUX/actions/deliveries";
import { AutoComplete } from "primereact/autocomplete";
/* -------------------------------------------------------------------------- */
/*                             Local UI component                             */
/* -------------------------------------------------------------------------- */
const FormAdress = ({ resellerId, type, address = null, callBack }) => {
  // consts

  const dispatch = useDispatch();

  const { countries, cities } = useSelector((state) => state.countryReducer);
  const { cart } = useSelector((state) => state.catalogueReducer);

  // hooks
  const [formData, setFormData] = useState(
    address
      ? {
          name: address?.attributes?.name,
          city: {
            name: address?.attributes?.city?.data?.attributes?.name,
            code: address?.attributes?.city?.data?.id,
          },
          country: {
            name: address?.attributes?.country?.data?.attributes?.name,
            code: address?.attributes?.country?.data?.id,
          },
          street: address?.attributes?.street,
          zip: address?.attributes?.zip,
          code: address?.attributes?.city?.data?.attributes?.code,
        }
      : {}
  );

  useEffect(() => {
    dispatch(getCountriesNew());
  }, [dispatch]);
  // available countries:
  const countryOptions = countries?.map((country) => {
    return {
      name: country?.name,
      code: country?.code,
    };
  });
  // available cities:

  const loadCities = (e) => {
    if (formData.country?.code) {
      dispatch(getCountriesCitiesNew(formData.country.code, e.query));
    }
  };

  /* *************************** callbacks & methods ************************** */

  // handle changes in a formInput field or select:
  const handleChange = (event, name) => {
    setFormData({
      ...formData,
      [name]:
        event.target.value || event.target.value === ""
          ? event.target.value
          : event.value,
    });
  };

  const handleCountryChange = (event) => {
    setFormData({
      ...formData,
      city: null,
      zip: null,
      code: null,
      country: event.target.value,
    });
    dispatch(getCountriesCitiesNew(event.target.value.code));
  };
  const handleCityChange = (event) => {
    setFormData({
      ...formData,
      city: event.target.value,
      zip: event.target.value.zipCode || null,
      code: event.target.value.cityCode || null,
    });
  };

  // create new address on submit
  const handleSubmit = () => {
    if (address?.id) {
      dispatch(updateAdress(address.id, formData, resellerId, callBack));
    } else {
      dispatch(
        createNewDeliveryAddress(formData, resellerId, type, cart, callBack)
      );
    }
  };
  let formDataIncomplete =
    !formData?.city ||
    !formData?.country ||
    !formData?.street?.trim() ||
    !formData?.zip ||
    !formData?.code;

  console.log({ formData });

  return (
    <form className="deliveryAddressModalContainer" autoComplete="off">
      <div className="deliveryAddressModal pt-5">
        <p className="f16 bold">Nouvelle adresse : </p>

        <TextInput
          className="mb-3"
          placeholder="Adresse"
          callback={(event) => handleChange(event, "name")}
          value={formData?.name}
        />
        <TextInput
          className="mb-3"
          placeholder={"Rue"}
          callback={(event) => handleChange(event, "street")}
          value={formData?.street}
        />
      </div>
      <div className="d-flex justify-content-between gap-1">
        <Select
          name={"country"}
          placeholder={"Pays"}
          value={formData?.country}
          options={countryOptions}
          label={"Pays"}
          callback={(event) => handleCountryChange(event, "country")}
          className="mb-3 w30"
          appendToSelf={true}
          filter={true}
        />
        <AutoComplete
          className="mb-3 customAutocomplete br6"
          field="name"
          value={formData?.city}
          suggestions={cities}
          completeMethod={(e) => loadCities(e)}
          onChange={(e) => handleCityChange(e, "city")}
          style={{
            zIndex: 1400,
            height: "40px",
            border: "none",
            outline: "none",
          }}
          disabled={!formData?.country}
          placeholder="Ville"
        />
        <TextInput
          className={"mb-3"}
          placeholder={"code postal"}
          type="text"
          callback={(event) => handleChange(event, "zip")}
          value={formData?.zip}
        ></TextInput>
        <TextInput
          className={"mb-3"}
          placeholder={"Code département"}
          type="number"
          callback={(event) => handleChange(event, "code")}
          value={formData?.code}
        ></TextInput>
      </div>
      <div className="d-flex align-items-center justify-content-center mt-3 mb-5">
        <Button onClick={handleSubmit} disabled={formDataIncomplete}>
          Enregistrer
        </Button>
      </div>
    </form>
  );
};

export default FormAdress;
