import React, { useState } from "react";
import DeliveryCalendar from "./DeliveryCalendar/DeliveryCalendar";
import CheckBox from "../../Common/FormElements/CheckBox/CheckBox";
import Button from "../../Common/FormElements/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { closeCenteredModal } from "../../../REDUX/actions/centeredModal";
import { updateDelivery } from "../../../REDUX/actions/panier";

function DeliveryDialog({ submitText, mode, price }) {
  const dispatch = useDispatch();
  const { panier } = useSelector((state) => state.panierReducer);

  const [deliveryDate, setDeliveryDate] = useState(panier?.delivery?.date);

  const handleCancel = () => {
    dispatch(closeCenteredModal());
  };
  const handleSubmit = () => {
    dispatch(
      updateDelivery({
        panierID: panier.id,
        deliveryID: panier.delivery.id,
        mode,
        price,
        date: deliveryDate,
      })
    );
    dispatch(closeCenteredModal());
  };
  return (
    <div className="deliveryDialog">
      <h2 className="f23 sBold text-brightGrey mb-4">Livraison souhaitée le</h2>
      <DeliveryCalendar
        setSelectedDate={setDeliveryDate}
        selectedDate={deliveryDate}
      />
      <CheckBox
        text="Je ne sais pas"
        className="mt-3"
        textClassName="text-brightGrey f15"
        callback={() => setDeliveryDate(null)}
      />
      <div className="deliveryDialogFooter mt-3 d-flex justify-content-center">
        <Button variant="outline" className="me-3" onClick={handleCancel}>
          Ignorer
        </Button>
        <Button onClick={handleSubmit} disabled={deliveryDate === undefined}>
          {submitText}
        </Button>
      </div>
    </div>
  );
}

export default DeliveryDialog;
