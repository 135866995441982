import React, { Fragment, useEffect, useState } from "react";
import TextInput from "../../Common/FormElements/TextInput/TextInput";
import Select from "../../Common/FormElements/Select/Select";
import TextArea from "../../Common/FormElements/TextArea/TextArea";
import { useDispatch, useSelector } from "react-redux";
import { productForm } from "./data";
import { Col, Row } from "react-bootstrap";
import { isDefined } from "../../../Utils";
import { getAccessoryRelatedProducts } from "../../../REDUX/actions/products";
import MultiSelectCheckbox from "../../Common/FormElements/MultiSelectCheckbox";

const GeneralInfoProduct = ({ handleFormsChange, initialValues }) => {
  const dispatch = useDispatch();
  const optionsTypes = [
    {
      name: "Produits",
      code: 1,
    },
    {
      name: "Accessoires",
      code: 2,
    },
    {
      name: "Produits Marketing",
      code: 3,
    },
    {
      name: "Pièce Détachée",
      code: 4,
    },
  ];
  const { categories } = useSelector((state) => state.categoryReducer);
  const { productSizes } = useSelector((state) => state.productSizeReducer);
  const { deliveryTypes } = useSelector((state) => state.deliveryTypeReducer);
  const { relatedProducts } = useSelector((state) => state.productsReducer);
  const [formData, setFormData] = useState(initialValues ?? {});

  const [fetchedData, setFetchedData] = useState({
    productType: optionsTypes,
    kangourou: [
      { code: 0, name: "Sans poche kangourou" },
      { code: 1, name: "Avec poche kangourou" },
    ],
  });
  const [renderedProductsForms, setRenderedProductsForms] = useState(
    productForm?.filter((el) => el?.name !== "related_products")
  );
  const handleChange = (e) => {
    const { value, name } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    handleFormsChange("product", formData);
  }, [formData]);
  useEffect(() => {
    if (!isDefined(formData?.productType?.code)) {
      setRenderedProductsForms(
        productForm?.filter(
          (el) =>
            ![
              "related_products",
              "accessoryWeight",
              "accessoryDimension",
              "category",
              "gamme",
              "product_size",
              "multipleContainer",
              "delivery_types",
              "kangourou",
            ]?.includes(el?.name)
        )
      );
    } else if (formData?.productType?.code === 1) {
      setRenderedProductsForms(
        productForm?.filter(
          (el) =>
            ![
              "related_products",
              "accessoryWeight",
              "accessoryDimension",
              "kangourou",
            ]?.includes(el?.name)
        )
      );
      setFetchedData((fetchedData) => ({
        ...fetchedData,
        category: categories.map((item) => ({
          name: item?.name,
          code: item?.code,
        })),
      }));
    } else {
      setFetchedData((fetchedData) => ({
        ...fetchedData,
        related_products: relatedProducts,
      }));
      setRenderedProductsForms(
        productForm.filter(
          (formElement) =>
            ![
              "category",
              "gamme",
              "product_size",
              "multipleContainer",
            ]?.includes(formElement?.name)
        )
      );
    }
  }, [formData?.productType?.code, relatedProducts]);

  useEffect(() => {
    if (formData?.productType?.code !== 1) {
      dispatch(getAccessoryRelatedProducts());
    }
  }, [formData?.productType?.code, dispatch]);

  useEffect(() => {
    if (isDefined(formData?.category?.code)) {
      setFetchedData((fetchedData) => ({
        ...fetchedData,
        gamme: categories?.find(
          (item) => +formData?.category?.code === +item?.code
        )?.ranges,
      }));
    }
  }, [categories, formData?.category?.code]);

  useEffect(() => {
    if (isDefined(formData?.category?.code)) {
      handleFormsChange("product", {
        ...formData,
        gamme: !!fetchedData?.gamme?.find(
          (item) => +item?.code === +formData?.gamme?.code
        )
          ? formData?.gamme
          : {},
      });
    }
  }, [formData.category?.code, fetchedData?.gamme]);

  useEffect(() => {
    setFetchedData((fetchedData) => ({
      ...fetchedData,
      delivery_types: deliveryTypes,
      product_size: productSizes,
    }));
  }, [deliveryTypes, productSizes]);

  return (
    <>
      <div className="d-flex flex-stretch">
        <Row className="gx-5">
          <Col lg={8}>
            <Row>
              {renderedProductsForms.map((el, index) => {
                if (el.type === "textarea") return null;
                if (el.type === "multiSelectCheckbox")
                  return (
                    <Fragment key={el?.name}>
                      <Col
                        lg={6}
                        key={el.name}
                        className="mb-3 d-flex flex-column gap-2"
                      >
                        <div>
                          <span className="text-softBlack normalf16">
                            {el.label}
                          </span>
                          {/* {el.required && (
                            <span className="text-lameRed normalf16"> *</span>
                          )} */}
                        </div>
                        <MultiSelectCheckbox
                          filter={el?.filter}
                          name={el.name}
                          placeholder={el.label}
                          value={formData[el.name]}
                          options={
                            el.name === "delivery_types"
                              ? formData.productType?.code === 1
                                ? fetchedData[el.name]?.filter(
                                    (el) => el.value <= 3 || el.value >= 6
                                  )
                                : fetchedData[el.name]?.filter(
                                    (el) => el.value >= 4
                                  )
                              : fetchedData[el.name]
                          }
                          disabled={
                            el?.disabledByOptions &&
                            !fetchedData[el.name]?.length > 0
                          }
                          label={el.label}
                          callback={handleChange}
                        />
                      </Col>
                      {(index === 3 || el?.name === "publicPriceTTC") && (
                        <Col
                          lg={12}
                          className="mb-3 mt-0 d-flex flex-column gap-2"
                        >
                          <hr
                            style={{ borderColor: "#abb8b2", marginBottom: 0 }}
                            width={"100%"}
                          />
                        </Col>
                      )}
                    </Fragment>
                  );
                if (el.type === "select") {
                  return (
                    <Fragment key={el?.name}>
                      <Col
                        lg={6}
                        key={el.name}
                        className="mb-3 d-flex flex-column gap-2"
                      >
                        <div>
                          <span className="text-softBlack normalf16">
                            {el.label}
                          </span>
                          {/* {el.required && (
                            <span className="text-lameRed normalf16"> *</span>
                          )} */}
                        </div>
                        <Select
                          multipe
                          name={el.name}
                          placeholder={el.label}
                          value={
                            formData[el.name] === "kangourou"
                              ? formData[el.name]
                              : formData[el.name]
                          }
                          options={fetchedData[el.name]}
                          disabled={
                            el?.disabledByOptions &&
                            !fetchedData[el.name]?.length > 0
                          }
                          label={el.label}
                          callback={handleChange}
                        />
                      </Col>
                      {(index === 3 || el?.name === "publicPriceTTC") && (
                        <Col
                          lg={12}
                          className="mb-3 mt-0 d-flex flex-column gap-2"
                        >
                          <hr
                            style={{ borderColor: "#abb8b2", marginBottom: 0 }}
                            width={"100%"}
                          />
                        </Col>
                      )}
                    </Fragment>
                  );
                }
                return (
                  <Fragment key={el.name}>
                    <Col lg={6} className="mb-3 d-flex flex-column gap-2">
                      <div>
                        <span className="text-softBlack normalf16">
                          {el.label}
                        </span>
                        {/* {el.required && (
                          <span className="text-lameRed normalf16"> *</span>
                        )} */}
                      </div>
                      <TextInput
                        className={"w100"}
                        type={el.type}
                        name={el.name}
                        value={formData[el.name]}
                        placeholder={el.placeholder}
                        euro={el.euro}
                        callback={handleChange}
                      />
                    </Col>
                    {(index === 3 || el?.name === "publicPriceTTC") && (
                      <Col
                        lg={12}
                        className="mb-3 mt-0 d-flex flex-column gap-2"
                      >
                        <hr
                          style={{ borderColor: "#abb8b2", marginBottom: 0 }}
                          width={"100%"}
                        />
                      </Col>
                    )}
                  </Fragment>
                );
              })}
            </Row>
          </Col>
          <Col
            lg={4}
            className="d-flex flex-column gap-2 border-start border-darkGrey"
          >
            <span className="text-softBlack normalf16">Description</span>
            <TextArea
              name="description"
              value={formData.description}
              placeholder="description"
              className={"w-100 b-2-softGrey h100"}
              rows={10}
              callback={handleChange}
            />
          </Col>
        </Row>
      </div>
      {/* <div className="w-100 alignC gap-3 px-5 pt-3 mt-auto">
        <Button
          variant={"outline"}
          className="px-5"
          onClick={() => dispatch(closeRightModal())}
        >
          Annuler
        </Button>
        <Button
          className="px-5"
          disabled={disabled}
          loading={loading}
          onClick={loading ? undefined : handleClick}
        >
          Enregistrer
        </Button>
      </div> */}
    </>
  );
};

export default GeneralInfoProduct;
