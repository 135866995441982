import React, { useRef } from "react";

import TextInput from "../../FormElements/TextInput/TextInput";
import CalendarInput from "../../FormElements/CalendarInput/CalendarInput";
import userPhoto from "../../../../Images/Images/avatar.png";
import { IMAGE_END_POINT } from "../../../../Api/EndPoints";

import "./GeneralInfo.css";

const ProfileGeneralInfo = ({
  newUser,
  setNewUser,
  user,
  handleChangeUpdateUser = () => {},
  handleImageChange = () => {},
  defaultImgUser,
  userImage = null,
  invalidFields,
}) => {
  const fileInputRef = useRef(null);
  console.log(invalidFields);
  const handleCameraIconClick = () => {
    fileInputRef.current.click();
  };
  const userWithoutImg = defaultImgUser
    ? ` ${IMAGE_END_POINT}${defaultImgUser}`
    : userPhoto;
  return (
    <div className="generalInfo d-flex flex-column justify-content-between">
      <div className="d-flex px-5 py-5">
        <div className="memberImageContainer me-4 d-flex justify-content-center align-self-lg-start position-relative pointer">
          <label htmlFor="imageInput" className="imageInput">
            <img
              src={userImage ? URL.createObjectURL(userImage) : userWithoutImg}
              alt="member"
              className="br50 ob-cover w100 h100 pointer"
              onClick={handleCameraIconClick}
            />
          </label>
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            hidden
          />
          <div className="iconContainer d-flex align-items-center justify-content-center bg-softBlue br50">
            <i
              className="bi bi-camera text-white f18"
              onClick={handleCameraIconClick}
            />
          </div>
        </div>
        <div className="me-3 w40">
          <div className="mb-3">
            <p className="f16 text-ultraBlack mb-2">Nom</p>
            <TextInput
              placeholder="Nom"
              value={newUser?.lastName}
              callback={handleChangeUpdateUser}
              name={"lastName"}
            />
          </div>
          <div className="mb-3">
            <p className="f16 text-ultraBlack mb-2">Poste</p>
            <TextInput
              placeholder="Poste"
              value={newUser?.occupation}
              callback={handleChangeUpdateUser}
              name={"occupation"}
            />
          </div>
          <div className="mb-3">
            <p className="f16 text-ultraBlack mb-2">Téléphone</p>
            <TextInput
              placeholder="Téléphone"
              value={newUser?.phoneNumber}
              callback={handleChangeUpdateUser}
              name={"phoneNumber"}
            />
            {invalidFields.phoneNumber === "phoneNumberError" && (
              <span className="f14 text-red">
                Veuillez entrer un numéro de téléphone valide.
              </span>
            )}
          </div>
        </div>
        <div className="w40">
          <div className="mb-3">
            <p className="f16 text-ultraBlack mb-2">Prénom</p>
            <TextInput
              placeholder="Prénom"
              value={newUser?.firstName}
              callback={handleChangeUpdateUser}
              name={"firstName"}
            />
          </div>
          <div className="mb-3 ">
            <p className="f16 text-ultraBlack mb-2">Date de naissance</p>
            <CalendarInput
              name="birthdate"
              className="birthdayInput b-2-softGrey w100"
              placeholder="Date de naissance"
              // defaultValue={moment(newUser?.birthdate).toDate()}
              withMinDate={false}
              value={new Date(newUser?.birthdate)}
              callback={handleChangeUpdateUser}
            />
          </div>
          <div className="mb-3">
            <p className="f16 text-ultraBlack mb-2">E-mail</p>
            <TextInput
              placeholder="E-mail"
              value={newUser?.email}
              callback={handleChangeUpdateUser}
              name={"email"}
            />
            {invalidFields?.email === "emailError" && (
              <span className="f14 text-red">
                Veuillez entrer un email valide.
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileGeneralInfo;
