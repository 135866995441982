import React from "react";
// import { productTableData } from "../../../helpers/fakeData/Catalogue/ProductTable";
import Separator from "../../../Components/Common/Separator/Separator";
import getDetailsTableData from "./getDetailsTableData";

function TableItem({ name, value }) {
  const displayValue =
    value !== undefined && value !== "[object Undefined]" ? value : "-";
  return (
    <div>
      <div className="d-flex p-3 justify-content-between">
        <p className="mb-0 f15 bold text-softBlack">{name}</p>
        <p className="mb-0 f15 text-start text-softBlack text-capitalize">
          {displayValue}
        </p>
      </div>
      <Separator />
    </div>
  );
}
function ProductTableBody({ productDetails, activeTab }) {
  return (
    <div className="p-4">
      {getDetailsTableData(productDetails, activeTab).map(
        ({ name, value, hide }, index) => {
          if (hide) return null;
          return <TableItem key={index} name={name} value={value} />;
        },
      )}
    </div>
  );
}

export default ProductTableBody;
