import React, { useState } from "react";
import FaqCard from "./FaqCard";

import "./FaqViewContent.css";

const FaqViewContent = ({ guides = [] }) => {
  const [isOpenFaq, setIsOpenFaq] = useState();

  return (
    <div className="d-flex flex-column p-3 mt-5 flex-1">
      {guides?.data?.map((guide) => (
        <FaqCard
          key={guide?.id}
          id={guide?.id}
          title={guide?.attributes?.title}
          description={guide?.attributes?.description}
          attachments={guide?.attributes?.attachments}
          businessAffiliate={guide?.attributes?.business_affiliate?.data?.id}
          setIsOpenFaq={setIsOpenFaq}
          isOpenFaq={isOpenFaq}
        />
      ))}
    </div>
  );
};

export default FaqViewContent;
