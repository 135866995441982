import React from "react";
import ApprovDetailsItem from "./ApprovDetailsItem";
import approvDetailsLabels from "./ApprovDetailsLabels";
import "./ApprovDetails.css";

const ApprovDetailsList = ({ packingList }) => {
  return (
    <div className="w-100">
      <div className={`approvDetailsHeader row gx-0 bg-smoothBlue`}>
        {approvDetailsLabels?.map(({ label, className }, index) => (
          <p
            className={`${className} d-block mb-0 f14 py-3 alignH text-brightGrey sBold textNoWrap`}
            key={index}
          >
            {label}
          </p>
        ))}
      </div>
      {/* Values */}
      <div className={`approvDetailsList bg-skyBlue`}>
        {packingList?.map((details, index) => {
          return <ApprovDetailsItem key={details.id} {...details} />;
        })}
      </div>
    </div>
  );
};

export default ApprovDetailsList;
