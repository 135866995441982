import React, { useEffect, useState } from "react";

import MessageInput from "../../Sav/NewTicketSav/ChatContainer/MessageInput";
import { useDispatch, useSelector } from "react-redux";
import {
  createContainerComment,
  getContainerComments,
} from "../../../REDUX/actions/approvisionnement";
import MessageItem from "../../Sav/NewTicketSav/ChatContainer/MessageItem";

const ContainerCommentaireTabContent = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userReducer);
  const {
    approv,
    currentComments,
    currentCommentsLoading: loading,
  } = useSelector((state) => state.approvReducer);

  const [newMessage, setNewMessage] = useState({ text: "", attachments: [] });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "attachments") {
      setNewMessage({
        ...newMessage,
        [name]:
          newMessage?.attachments?.length > 0
            ? [...newMessage?.attachments, ...value]
            : value,
      });
    } else {
      setNewMessage({ ...newMessage, [name]: value });
    }
  };

  const handleSubmit = (e, callback) => {
    e.preventDefault();
    if (
      newMessage?.text?.trim()?.length > 0 ||
      newMessage?.attachments?.length > 0
    ) {
      dispatch(
        createContainerComment(newMessage, approv?.id, user?.id, () => {
          setNewMessage({ text: "", attachments: [] });
          callback();
        })
      );
    }
  };

  useEffect(() => {
    if (approv?.id) {
      dispatch(getContainerComments(approv?.id));
    }
  }, [dispatch, approv?.id]);

  return (
    <div className="ps-5 pe-3 pt-4 newSavComment d-flex flex-column justify-content-end">
      <div className="d-flex pe-4 flex-column overflow-auto flex-column-reverse scroll-snap-Ymandatory flex-1">
        {currentComments.map((comment, index) => (
          <MessageItem
            key={comment?.id}
            index={index}
            message={comment}
            isOwner={+user?.id === +comment?.attributes?.user?.data?.id}
          />
        ))}
      </div>
      <div className={"mt-5 pe-4"}>
        <MessageInput
          loading={loading}
          handleChange={handleChange}
          newMessage={newMessage}
          setNewMessage={setNewMessage}
          handleSubmit={handleSubmit}
        />
      </div>
    </div>
  );
};

export default ContainerCommentaireTabContent;
