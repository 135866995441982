import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";

import TabsNavbar from "../../Common/TabsNavBar/TabsNavbar";
import ModifyOrderContent from "./ModifyOrderContent";
import Button from "../../Common/FormElements/Button/Button";
import ShadowLoader from "../../../Utils/ViewWrapper/ShadowLoader/ShadowLoader";
import {
  closeRightModal,
  openRightModal,
} from "../../../REDUX/actions/rightModal";
import {
  getOneOrder,
  updateDeliveryPrice,
  updateOrder,
  updateOrderItems,
} from "../../../REDUX/actions/orders";
import UpdateTicketSav from "../../Sav/UpdateTicketSavAdmin/UpdateTicketSav";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import { fetchCommande } from "../../../REDUX/actions/commandes";

import "./ModifyOrder.css";

function ModifyOrder({ id, initialTab = "Infos générales", savID }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userReducer);
  const [disabled, setdisabled] = useState(false);
  const [activeTab, setActiveTab] = useState(initialTab);
  const [formData, setFormData] = useState({});
  const [commandesTabUpdated, setcommandesTabUpdated] = useState();
  const [deletedPackings, setdeletedPackings] = useState([]);
  const { invoicesLoading } = useSelector((state) => state.invoicesReducer);

  // const { order, orderLoading, orderError, orderReload } = useSelector(
  //   (state) => state.ordersReducer
  // );

  const {
    commande: order,
    commandeLoading,
    commandeRefetchLoading,
    commandeError,
  } = useSelector((state) => state.commandeReducer);

  const location = useLocation();

  const isAdmin =
    user?.attributes?.userRole === 1 || user?.attributes?.userRole === 2;
  const tabs = isAdmin
    ? [
        "Infos générales",
        // "Commandes",
        "Commentaires",
        "Paiements",
      ]
    : ["Commentaires"];

  const handleSubmit = () => {
    let error = false;

    if (formData?.payments) {
      for (const payment of formData?.payments) {
        if (payment?.amount === 0 || payment?.date === undefined) {
          toast.error("Veuillez verifier les payments!");
          error = true;
          activeTab === "Paiements" && setdisabled(true);
        }
      }
    }
    if (!error) {
      if (commandesTabUpdated) {
        dispatch(
          updateOrder({
            id,
            updatedOrder: formData,
          })
        );
        dispatch(updateOrderItems(formData, deletedPackings));
      } else {
        dispatch(
          updateOrder(
            {
              id,
              updatedOrder: formData,
            },
            activeTab === "Paiements"
          )
        );
      }
    }
    if (+order?.delivery?.price !== +formData?.delivery?.price) {
      dispatch(updateDeliveryPrice(formData));
    }
  };

  useEffect(() => {
    dispatch(fetchCommande(id));
  }, [id, dispatch]);

  // useEffect(() => {
  //   dispatch(getOneOrder({ id }));
  // }, [dispatch, id, orderReload]);

  useEffect(() => {
    setFormData({
      ...order,
      payments: order?.payments || [],
    });
  }, [order, commandeRefetchLoading, activeTab]);

  useEffect(() => {
    return () => {
      dispatch({ type: "CLEAR_ORDER" });
    };
  }, [dispatch]);

  useEffect(() => {
    if (formData?.payments) {
      for (const payment of formData?.payments) {
        if (payment?.amount === 0 || payment?.date === undefined) {
          activeTab === "Paiements" && setdisabled(true);
        }
      }
    }
    if (!formData?.number) setdisabled(true);

    return () => {
      setdisabled(false);
    };
  }, [formData, activeTab]);

  return (
    <div className="modifyOrder d-flex flex-column justify-content-between h100">
      <div>
        <div className="alignH align-items-end">
          {location.pathname === "/sav" && (
            <FontAwesomeIcon
              icon={faArrowLeftLong}
              size={"xl"}
              className="ps-5 mb-5 pointer text-blue"
              onClick={() =>
                dispatch(openRightModal(<UpdateTicketSav id={savID} />))
              }
            />
          )}

          <h2
            className={`sBold f25 quickSand text-blue ${
              location.pathname === "/sav" ? "px-3" : "px-5"
            } mb-5`}
          >
            {isAdmin
              ? `Modifier la commande #${order?.number}`
              : `La commande #${order?.number}`}
          </h2>
        </div>
        <TabsNavbar
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          className="gap-5"
        />
        <ShadowLoader
          loading={commandeLoading || invoicesLoading}
          error={commandeError}
        >
          <ModifyOrderContent
            activeTab={activeTab}
            formData={formData}
            setFormData={setFormData}
            setcommandesTabUpdated={setcommandesTabUpdated}
            commandesTabUpdated={commandesTabUpdated}
            deletedPackings={deletedPackings}
            setdeletedPackings={setdeletedPackings}
            order={order}
          />
        </ShadowLoader>
      </div>
      {/* {activeTab !== "Commentaires" && (
        <div className="buttonsContainer d-flex justify-content-center mt-5">
          <Button
            variant="outline"
            className="me-4"
            onClick={() => dispatch(closeRightModal())}
          >
            Annuler
          </Button>
          <Button onClick={handleSubmit} disabled={disabled}>
            Enregistrer
          </Button>
        </div>
      )} */}
    </div>
  );
}

export default ModifyOrder;
