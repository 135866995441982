import React, { useEffect, useState } from "react";

import TabsNavbar from "../../Common/TabsNavBar/TabsNavbar";
import NewDepenseTabContent from "./NewDepenseTabContent";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentExpensesItem } from "../../../REDUX/actions/expenses";

const NewDepense = ({ id, initialTab = "Infos générales" }) => {
  const dispatch = useDispatch();
  const { currentExpensesItem } = useSelector((state) => state.expensesReducer);
  const [activeTab, setActiveTab] = useState(initialTab);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (currentExpensesItem) {
      setDisabled(false);
    }
  }, [currentExpensesItem]);

  useEffect(() => {
    if (id) {
      dispatch(getCurrentExpensesItem(id));
    }
  }, [id, dispatch]);

  return (
    <div className="profileModal d-flex flex-column justify-content-between h100 ">
      <div>
        <h1 className="quickSand f25 sBold text-blue mb-5 px-5">
          {id ? "Modifier la dépense" : "Ajouter une dépense"}
        </h1>
        <TabsNavbar
          tabs={[
            "Infos générales",
            // "Paiements",
            "Commentaire",
          ]}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          className="gap-5"
          disabled={disabled}
        />
        <NewDepenseTabContent activeTab={activeTab} setDisabled={setDisabled} />
      </div>
      {/* <div className="d-flex justify-content-center ">
        <Button variant="outline" className="me-3">
          Annuler
        </Button>
        <Button>Sauvegarder</Button>
      </div> */}
    </div>
  );
};

export default NewDepense;
