import { toast } from "react-toastify";
import API from "../../Api/Api";
import { buildGuidesQuery } from "../apiQueries/guides";
import {
  GUIDES_ERROR,
  GUIDES_FETCHED,
  GUIDES_LOADING,
  GUIDE_LOADING,
  GUIDE_FETCHED,
  GUIDE_ERROR,
  GUIDE_UPDATED,
  GUIDE_CREATED,
} from "../reducer/guides";
import { closeRightModal } from "./rightModal";
import { uploadAttachmentToStrapi } from "../../Utils/uploadAttachmentToStrapi";
import store from "../store";
import qs from "qs";
import { isDefined, uploadFile } from "../../Utils";
import { API_END_POINT } from "../../Api/EndPoints";
import moment from "moment";

export const getGuides = (filters) => async (dispatch) => {
  // const { user } = getState().userReducer;
  // const userRole = user?.attributes?.userRole;
  // let BaFilters = {};
  // if (userRole === 2) {
  //   BaFilters = {
  //     business_affiliate: {
  //       id: { $eq: user?.attributes?.business_affiliate?.data?.id },
  //     },
  //   };
  // }
  dispatch({ type: GUIDES_LOADING });
  const guidesQuery = buildGuidesQuery(
    ["title", "videoUrl", "date"], // Fields for attachments
    ["url", "size", "mime"], // Fields for file in attachments
    filters, // Pass the filters to the function thi can
    // BaFilters,
  );
  try {
    const { data: allGuides } = await API.get(`api/guides?${guidesQuery}`);
    dispatch({ type: GUIDES_FETCHED, payload: allGuides });
  } catch (error) {
    console.log(error);
  }
};

export const addGuide =
  (newGuide, attachments) => async (dispatch, getState) => {
    try {
      const { user } = getState().userReducer;
      const userRole = user?.attributes?.userRole;
      let businessAffiliate =
        userRole === 2 ? user?.attributes?.business_affiliate?.data?.id : null;
      dispatch({ type: GUIDE_LOADING });
      const { data: createdGuide } = await API.post("/api/guides", {
        data: {
          ...newGuide,
          business_affiliate: businessAffiliate,
        },
      });
      if (attachments.length) {
        for (const attachment of attachments) {
          if (attachment.file) {
            const { data: createdAttachment } = await API.post(
              "api/attachments",
              {
                data: {
                  title: attachment.title,
                  guides: createdGuide.data.id,
                  date: moment(attachment?.date),
                },
              },
            );
            await uploadAttachmentToStrapi(
              attachment.file,
              "guide fichier",
              createdAttachment.data.id,
              "api::attachment.attachment",
              "file",
            );
          }
        }
      }
      dispatch({ type: GUIDE_CREATED });
      dispatch(getGuides());
      dispatch(closeRightModal());
      toast.success("Guide créé avec succès !");
    } catch (error) {
      toast.error("error");
      console.log(error);
    }
  };

export const deleteGuide = (guideId) => async (dispatch) => {
  try {
    await API.delete(`/api/guides/${guideId}`);
    dispatch(getGuides());
    toast.success("Guide supprimé avec succès !");
  } catch (error) {
    console.log(error);
    toast.error("Une erreur s'est produite lors de la suppression du membre");
  }
};

export const getSelectedGuide = (payload) => async (dispatch) => {
  const queryObject = qs.stringify(
    {
      populate: {
        attachments: {
          fields: ["id", "title", "createdAt", "date"],
          populate: {
            file: { fields: "*" },
          },
        },
        category: true,
        gamme: true,
        model: true,
      },
    },
    { encodeValuesOnly: true },
  );

  const { id } = payload;
  dispatch({ type: GUIDE_LOADING });
  try {
    const { data: guide } = await API.get(`api/guides/${id}?${queryObject}`);
    dispatch({ type: GUIDE_FETCHED, payload: guide });
  } catch (err) {
    toast.error(err);
    dispatch({ type: GUIDES_ERROR, payload: err });
  }
};

export const updateGuide = (payload) => async (dispatch) => {
  const { id, updatedGuide, attachments } = payload;
  dispatch({ type: "GUIDE_LOADING" });

  try {
    delete updatedGuide.attachments;
    await API.put(`api/guides/${id}`, {
      data: {
        ...updatedGuide,
        attachments: attachments
          ?.map((item) => item?.attachmentId)
          .filter((item) => isDefined(item)),
      },
    });
    if (attachments) {
      const oldAttachments =
        store.getState().guidesReducer.guide.data.attributes.attachments.data;
      for (const attachement of payload.attachments) {
        // add or update attachment
        const existantFile = oldAttachments.find(
          (oldAttach) => oldAttach.id === attachement.id,
        );

        if (!existantFile && attachement.file) {
          const fileId = await uploadFile(attachement?.file);
          await API.post(`${API_END_POINT}api/attachments?populate=*`, {
            data: {
              title: attachement?.title,
              file: fileId,
              date: moment(attachement?.date),
              guides: [id],
            },
          });
        } else if (existantFile && attachement.title !== existantFile?.title) {
          await API.put(
            `${API_END_POINT}api/attachments/${attachement?.attachmentId}?populate=*`,
            {
              data: {
                title: attachement?.title,
              },
            },
          );
        }
      }
    }
    dispatch({ type: GUIDE_UPDATED });
    dispatch(closeRightModal());
    dispatch(getGuides());
    dispatch(toast.success("Guide a été mis à jour avec succès !"));
  } catch (error) {
    toast.error(error);
  }
};
