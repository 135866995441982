import React from "react";
import Button from "../../../Common/FormElements/Button/Button";
import { formatTimeToHHMM } from "../../../../Utils/fileUtils";
import { IMAGE_END_POINT } from "../../../../Api/EndPoints";
import moment from "moment";

const EventDetails = ({ event }) => {
  const getClickableLink = (link) => {
    return link.startsWith("http://") || link.startsWith("https://")
      ? link
      : `http://${link}`;
  };

  return (
    <div className="px-5">
      {event?.data?.attributes?.Image?.data?.attributes?.url && (
        <div style={{ height: "250px" }}>
          <img
            src={
              IMAGE_END_POINT +
              event?.data?.attributes?.Image?.data?.attributes?.url
            }
            alt="evenement"
            className="w100 br5 ob-cover h100"
          />
        </div>
      )}
      <h2 className="quickSand rightModalTitle mb-3 mt-5">
        {event?.data?.attributes?.title}
      </h2>
      <p className="f17 text-brightGrey mb-4">
        <pre>{event?.data?.attributes?.description}</pre>
      </p>
      <div className="d-flex">
        <div className="me-5">
          <p className="f16 bold text-softBlack">Date de début</p>
          <p className="f16 text-softBlack">
            {moment(event?.data?.attributes?.startDate)?.format("DD-MM-YYYY")}
          </p>
        </div>
        <div className="mx-5">
          <p className="f16 bold text-softBlack">Date de fin</p>
          <p className="f16 text-softBlack">
            {moment(event?.data?.attributes?.endDate)?.format("DD-MM-YYYY")}
          </p>
        </div>
        {event?.data?.attributes?.resellers?.data?.length > 0 && (
          <div className="ms-5">
            <p className="f16 bold text-softBlack">Revendeurs</p>
            {event?.data?.attributes?.resellers?.data?.map((item) => {
              return (
                <p className="f16 text-softBlack">
                  {item?.attributes?.name ?? ""}
                </p>
              );
            })}
          </div>
        )}
      </div>
      <div>
        <p className="f16 bold text-softBlack">Heure</p>
        <p className="f16 text-softBlack">
          {formatTimeToHHMM(event?.data?.attributes?.startTime)} -{" "}
          {formatTimeToHHMM(event?.data?.attributes?.endTime)}
        </p>
      </div>
      {event?.data?.attributes?.visitLink?.length > 0 && (
        <a
          href={getClickableLink(event?.data?.attributes?.visitLink)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button>Visiter le lien</Button>
        </a>
      )}
    </div>
  );
};

export default EventDetails;
