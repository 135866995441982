import { API_END_POINT } from "../../Api/EndPoints";
import API from "../../Api/Api";
import qs from "qs";

export const getOrganizations =
  (extraFilters = {}) =>
  async (dispatch) => {
    dispatch({ type: "FETCH_ORGANIZATIONS_LOADING" });
    try {
      const queryString = qs.stringify({
        populate: {
          business_affiliate: true,
        },
        filters: {
          $or: [
            {
              archived: { $eq: false },
            },
            {
              archived: { $null: true },
            },
          ],
          ...extraFilters,
        },
      });
      const { data } = await API.get(
        `${API_END_POINT}api/organizations?${queryString}`
      );

      const payload = data?.data
        ?.filter((item) => {
          return (
            item?.attributes?.archived === false ||
            item?.attributes?.archived === null
          );
        })
        ?.map((item) => {
          return {
            name: item?.attributes?.name,
            code: item?.id,
            businessAffiliate: item?.attributes?.business_affiliate?.data?.id,
          };
        });
      dispatch({ type: "FETCH_ORGANIZATIONS_SUCCESS", payload });
    } catch (error) {
      dispatch({ type: "FETCH_ORGANIZATIONS_ERROR" });
    }
  };
