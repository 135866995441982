import QueryString from "qs";
import NEW_API from "../../Api/NewApi";

export const fetchProfiles = () => async (dispatch, getState) => {
  const user = getState().userReducer.user?.attributes;

  if (!user) {
    return;
  }
  const businessQueries = QueryString.stringify({
    populate: {
      organizations: {
        fields: ["name"],
      },
    },
  });
  const resellersQueries = QueryString.stringify({
    populate: {
      billingAddress: {
        fields: ["*"],
        populate: {
          country: { fields: ["name"] },
          city: { fields: ["name", "code", "zipCode"] },
        },
      },
      delivery_addresses: {
        fields: ["*"],
        populate: {
          country: { fields: ["name"] },
          city: { fields: ["name", "code", "zipCode"] },
        },
      },
      business_affiliate: {
        fields: ["id", "company"],
      },
    },
  });
  try {
    if (user.userRole === 1) {
      dispatch({ type: "GENERAL_DATA_LOADING" });
      const business = await NEW_API.get(
        `/api/business-affiliates?${businessQueries}`
      );
      const resellers = await NEW_API.get(
        `/api/organizations?${resellersQueries}`
      );
      dispatch({
        type: "GENERAL_DATA_SUCCESS",
        payload: {
          business: business.data.data,
          resellers: resellers.data.data,
        },
      });
    } else if (user.userRole === 2) {
      const filters = QueryString.stringify({
        filters: {
          business_affiliate: { id: { $eq: user.business_affiliate.data.id } },
        },
      });
      const resellers = await NEW_API.get(
        `/api/organizations?${resellersQueries}&${filters}`
      );
      dispatch({
        type: "GENERAL_DATA_SUCCESS",
        payload: {
          business: [],
          resellers: resellers.data.data,
        },
      });
    } else {
      const resellerId = user.profil.data.attributes.reseller.data.id;
      const reseller = await NEW_API.get(
        `/api/organizations/${resellerId}?${resellersQueries}`
      );
      dispatch({
        type: "GENERAL_DATA_SUCCESS",
        payload: {
          business: [],
          resellers: [reseller.data.data],
        },
      });
    }
  } catch (error) {
    dispatch({ type: "CART_ERROR" });
  }
};
export const refetchReseller = (resellerId) => async (dispatch) => {
  const resellersQueries = QueryString.stringify({
    populate: {
      billingAddress: {
        fields: ["*"],
        populate: {
          country: { fields: ["name"] },
          city: { fields: ["name", "code", "zipCode"] },
        },
      },
      delivery_addresses: {
        fields: ["*"],
        populate: {
          country: { fields: ["name"] },
          city: { fields: ["name", "code", "zipCode"] },
        },
      },
      business_affiliate: {
        fields: ["id", "company"],
      },
    },
  });
  try {
    const reseller = await NEW_API.get(
      `/api/organizations/${resellerId}?${resellersQueries}`
    );

    dispatch({ type: "REFETCH_RESELLER_ITEM", payload: reseller.data.data });
  } catch (error) {
    dispatch({ type: "CART_ERROR" });
  }
};
