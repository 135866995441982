import React from "react";

const TicketsSAVPreview = () => {
  const ticketsSAVPreviewTitles = [
    { id: 1, label: "#", className: "w10" },
    { id: 1, label: "Revendeur", className: "w20" },
    { id: 2, label: "Date du ticket", className: "w20" },
    { id: 3, label: "Date de livraison", className: "w20" },
    // { id: 4, label: "Ref Client", className: "w15" },
    { id: 5, label: "Statut", className: "w15" },
    { id: 6, label: "Problème", className: "w15" },
    { id: 7, label: "Technicien", className: "w15" },
  ];

  return (
    <div className="bg-dimGrey orderListTable py-3 px-4 alignH ">
      {ticketsSAVPreviewTitles?.map(({ id, label, className }) => (
        <span
          className={` f14 text-brightGrey sBold pe-3 ${className}`}
          style={{ textAlign: "left" }}
          key={id}
        >
          {label}
        </span>
      ))}
      <span className="w3"></span>
    </div>
  );
};

export default TicketsSAVPreview;
