import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import TextInput from "../Common/FormElements/TextInput/TextInput";
import Select from "../Common/FormElements/Select/Select";
import Button from "../Common/FormElements/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/fontawesome-free-solid";
import { openRightModal } from "../../REDUX/actions/rightModal";
import NewGuide from "./NewGuide";
import { getGuides } from "../../REDUX/actions/guides";
import { getRanges } from "../../REDUX/actions/range";
import { getCategories } from "../../REDUX/actions/category";
import API from "../../Api/Api";
import { API_END_POINT } from "../../Api/EndPoints";
import { isDefined } from "../../Utils";

const productTypes = [
  {
    name: "Produits",
    code: 1,
  },
  {
    name: "Accessoires",
    code: 2,
  },
  {
    name: "Communication",
    code: 3,
  },
  {
    name: "SAV",
    code: 4,
  },
];

const FaqViewHeader = ({ setSearchQuery, searchQuery }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userReducer);
  const { ranges } = useSelector((state) => state.rangeReducer);
  const { categories } = useSelector((state) => state.categoryReducer);

  const [data, setData] = useState({
    types: productTypes,
    categories: [],
    ranges: [],
    models: [],
  });

  const [filters, setFilters] = useState({});

  const getModels = async () => {
    try {
      if (isDefined(filters?.type)) {
        const { data } = await API.get(
          `${API_END_POINT}api/products?filters[productType][$eq]=${filters?.type?.code}`,
        );

        setData((currentData) => ({
          ...currentData,
          categories: [],
          ranges: [],
          models: data?.data?.map((item) => ({
            name: item?.attributes?.name,
            code: item?.id,
          })),
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchQuery(value);
  };

  const clearSearch = () => {
    setSearchQuery("");
    dispatch(
      getGuides({
        gamme: {
          id: { $eq: filters?.range?.code },
        },
        model: {
          id: { $eq: filters?.model?.code },
          productType: { $eq: filters?.type?.code },
        },
        category: {
          id: { $eq: filters?.category?.code },
        },
      }),
    );
  };
  const handleSearchBtnClick = (e) => {
    if (e) {
      e.preventDefault();
    }
    dispatch(
      getGuides({
        $or: [
          {
            title: {
              $containsi: searchQuery?.trim(),
            },
          },
          {
            description: {
              $containsi: searchQuery?.trim(),
            },
          },
        ],
        gamme: {
          id: { $eq: filters?.range?.code },
        },
        model: {
          id: { $eq: filters?.model?.code },
          productType: { $eq: filters?.type?.code },
        },
        category: {
          id: { $eq: filters?.category?.code },
        },
      }),
    );
  };
  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setFilters((currFilters) => ({ ...currFilters, [name]: value }));
  };

  useEffect(() => {
    dispatch(
      getGuides({
        $or: [
          {
            title: {
              $containsi: searchQuery?.trim(),
            },
          },
          {
            description: {
              $containsi: searchQuery?.trim(),
            },
          },
        ],
        gamme: {
          id: { $eq: filters?.range?.code },
        },
        model: {
          id: { $eq: filters?.model?.code },
          productType: { $eq: filters?.type?.code },
        },
        category: {
          id: { $eq: filters?.category?.code },
        },
      }),
    );
  }, [filters, dispatch]);

  useEffect(() => {
    dispatch(getRanges());
    dispatch(getCategories());
  }, [dispatch]);

  useEffect(() => {
    setData((currData) => ({
      ...currData,
      categories,
      ranges: [],
      models: [],
    }));
    setFilters((filters) => ({ type: filters?.type }));
  }, [filters?.type]);

  useEffect(() => {
    setData((currData) => ({
      ...currData,
      categories,
      ranges: [],
      models: [],
    }));
    setFilters((filters) => ({ type: filters?.type }));
  }, [categories]);

  useEffect(() => {
    setData((data) => ({
      ...data,
      ranges: filters?.category?.ranges,
      models: [],
    }));
    setFilters((filters) => ({
      ...filters,
      range: undefined,
      model: undefined,
    }));
  }, [filters?.category, categories]);

  useEffect(() => {
    setData((data) => ({
      ...data,
      models: ranges?.find((range) => +range?.code === +filters?.range?.code)
        ?.models,
    }));
    setFilters((filters) => ({
      ...filters,
      model: undefined,
    }));
  }, [filters?.range, ranges]);

  useEffect(() => {
    if ([2, 4, 3]?.includes(filters?.type?.code)) {
      getModels();
      setFilters((filters) => ({ type: filters?.type }));
    }
  }, [filters?.type?.code]);

  return (
    <div className="d-flex justify-content-between align-items-start w-100 ">
      <h2 className="text-blue bold f30 flex-1">Guides</h2>
      <div
        className={`row alignH align-items-end justify-content-between ${
          user?.attributes?.userRole === 0 ? "flex-3" : "flex-4"
        }`}
      >
        <div className={`${filters?.type?.code === 1 ? "flex-4" : "flex-3"}`}>
          <form onSubmit={handleSearchBtnClick}>
            <TextInput
              withSearchBtn
              placeholder="Rechercher"
              callback={handleSearch}
              value={searchQuery}
              onSearchBtnClick={handleSearchBtnClick}
              searchButtonType="submit"
              clearSearch={clearSearch}
            />
          </form>
        </div>
        <div className="flex-2 d-flex flex-column align-items-start gap-2">
          <span className="normal f16 text-softBlack">Type</span>
          <Select
            name="type"
            value={filters?.type}
            options={data.types}
            placeholder="Sélectionner"
            callback={(e) => handleSelectChange(e)}
            showClear
            resetable
          />
        </div>
        {filters?.type?.code === 1 && (
          <div className="flex-2 d-flex flex-column align-items-start gap-2">
            <span className="normal f16 text-softBlack">Catégorie</span>
            <Select
              name="category"
              value={filters?.category}
              options={data.categories}
              placeholder="Sélectionner"
              callback={(e) => handleSelectChange(e)}
              showClear
              resetable
            />
          </div>
        )}
        {filters?.type?.code === 1 && (
          <div className={`flex-2 d-flex flex-column align-items-start gap-2`}>
            <span className="normal f16 text-softBlack">Gamme</span>
            <Select
              name="range"
              value={filters?.range}
              options={data.ranges}
              placeholder="Sélectionner"
              callback={(e) => handleSelectChange(e)}
              showClear
              resetable
            />
          </div>
        )}
        <div className="flex-2 d-flex flex-column align-items-start gap-2">
          <span className="normal f16 text-softBlack">Modèle</span>
          <Select
            name="model"
            value={filters?.model}
            options={data.models}
            placeholder="Sélectionner"
            callback={(e) => handleSelectChange(e)}
            showClear
            resetable
          />
        </div>
        {[1, 2]?.includes(user?.attributes?.userRole) && (
          <div className="flex-2 d-flex flex-column align-items-start gap-2">
            <span></span>
            <Button
              Icon={() => <FontAwesomeIcon icon={faPlus} size={"xs"} />}
              className="w100"
              onClick={() => dispatch(openRightModal(<NewGuide />))}
            >
              Ajouter un guide
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FaqViewHeader;
