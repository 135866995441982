export const resellerForm = [
  {
    type: "text",
    label: "Nom de la société",
    name: "name",
    required: true,
  },
  {
    type: "number",
    label: "N°siret",
    name: "siret",
    required: true,
  },
  {
    type: "string",
    label: "Code TVA",
    name: "codeTVA",
    required: true,
  },
  {
    type: "text",
    label: "Téléphone",
    name: "phoneNumber",
    required: true,
  },
  {
    type: "email",
    label: "E-mail",
    name: "email",
    required: true,
  },
];

export const deliveryAddressForm = [
  {
    type: "text",
    label: "Adresse de livraison",
    name: "street",
    required: true,
  },
  {
    type: "text",
    label: "Code postal",
    name: "zip",
    required: true,
  },
  {
    type: "select",
    label: "Pays",
    placeholder: "Sélectionner",
    name: "country",
    required: true,
  },
  {
    type: "select",
    label: "Ville",
    placeholder: "Sélectionner",
    name: "city",
    subOption: "country",
    optionsName: "cities",
    required: true,
  },
];

export const billingAddressForm = [
  {
    type: "text",
    label: "Adresse de facturation",
    name: "street",
    required: true,
  },
  {
    type: "text",
    label: "Code postal",
    name: "zip",
    required: true,
  },
  {
    type: "select",
    label: "Pays",
    placeholder: "Sélectionner",
    name: "country",
    required: true,
  },
  {
    type: "select",
    label: "Ville",
    placeholder: "Sélectionner",
    name: "city",
    subOption: "country",
    optionsName: "cities",
    required: true,
  },
];
