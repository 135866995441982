import { API_END_POINT } from "../../Api/EndPoints";
import API from "../../Api/Api";

export const getCategories = () => async (dispatch) => {
  dispatch({ type: "FETCH_CATEGORIES_LOADING" });
  try {
    const { data } = await API.get(
      `${API_END_POINT}api/categories?populate=gammes&sort[0]=id`,
    );
    const payload = data?.data?.map((item) => ({
      name: item?.attributes?.name,
      code: item?.id,
      ranges: item?.attributes?.gammes?.data?.map((rangeItem) => ({
        name: rangeItem?.attributes?.name,
        code: rangeItem?.id,
      })),
    }));

    dispatch({ type: "FETCH_CATEGORIES_SUCCESS", payload });
    // console.log(payload);
  } catch (error) {
    dispatch({ type: "FETCH_CATEGORIES_ERROR" });
  }
};
