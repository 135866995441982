import axios from "axios";
import { API_END_POINT } from "./EndPoints.js";
const NEW_API = axios.create({
  baseURL: API_END_POINT,
  headers: {
    "Content-Type": "application/json",
  },
});

export default NEW_API;
