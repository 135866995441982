import { API_END_POINT } from "../../Api/EndPoints";
import API from "../../Api/Api";

export const getRanges = () => async (dispatch) => {
  dispatch({ type: "FETCH_RANGES_LOADING" });
  try {
    const { data } = await API.get(
      `${API_END_POINT}api/ranges?populate=products&sort[0]=id`,
    );
    const payload = data?.data?.map((item) => ({
      name: item?.attributes?.name,
      code: item?.id,
      models: item?.attributes?.products?.data?.map((rangeItem) => ({
        name: rangeItem?.attributes?.name,
        code: rangeItem?.id,
      })),
    }));

    dispatch({ type: "FETCH_RANGES_SUCCESS", payload });
  } catch (error) {
    dispatch({ type: "FETCH_RANGES_ERROR" });
  }
};
