import React, { useState } from "react";
import CalendarHeader from "./CalendarHeader/CalendarHeader";
import CalendarContent from "./CalendarContent/CalendarContent";
import Select from "../../Common/FormElements/Select/Select";

function CalendarMain({ selectedOption }) {
  return (
    <div className="calendarMain mt-4">
      <CalendarHeader />
      <CalendarContent selectedOption={selectedOption} />
    </div>
  );
}

export default CalendarMain;
