import QueryString from "qs";

export const invoicesQuery = () =>
  QueryString.stringify(
    {
      fields: ["oldId", "createdAt", "createdAt", "number", "date"],
      populate: {
        media: {
          fields: ["url"],
        },
        commande: {
          fields: [
            "id",
            // "refClient",
            "oldId",
            "status",
            "totalTTC",
            "totalHT",
            "createdAt",
            "oldCreatedAt",
            // "realRefClient",
            "number",
          ],
          populate: {
            reseller: {
              fields: ["name"],
            },
          },
        },
      },
    },
    { encodeValuesOnly: true }
  );
