const getOrderDetailsLabel = (forRightModal, withoutStatus) => {
  return !withoutStatus
    ? [
        { label: "Nom du produit", className: "col-3", alignement: "start" },
        {
          label: "Référence",
          className: "col-1",
          alignement: "start",
        },
        {
          label: "Référence Client",
          // label: forRightModal ? "Réf Client." : "Créée par",
          className: "col-2",
          alignement: "start",
        },

        {
          label: "N° de série",
          // label: forRightModal ? "Réf Client." : "Créée par",
          className: "col-2",
          alignement: "start",
        },
        {
          label: forRightModal ? "Quant." : "Quantité",
          className: "col-1",
          alignement: "start",
        },
        { label: "Remise", className: "col-1", alignement: "start" },
        // {
        //   label: forRightModal ? "Acce." : "Accessoires",
        //   className: "col-1",
        //   alignement: "start",
        // },
        { label: "Prix unitaire HT", className: "col-1", alignement: "start" },
        { label: "Prix HT", className: "col-1", alignement: "start" },
      ]
    : [
        { label: "Nom du produit", className: "col-4", alignement: "start" },
        {
          label: "Référence",
          className: "col-2",
          alignement: "start",
        },
        {
          label: "Créée par",
          // label: forRightModal ? "Réf Client." : "Référence client",
          className: "col-2",
          alignement: "start",
        },
        {
          label: forRightModal ? "Quant." : "Quantité",
          className: "col-1",
          alignement: "start",
        },
        // {
        //   label: forRightModal ? "Acce." : "Accessoires",
        //   className: "col-1",
        //   alignement: "start",
        // },
        { label: "Prix unitaire HT", className: "col-1", alignement: "start" },
        { label: "Prix HT", className: "col-1", alignement: "start" },
      ];
};

export default getOrderDetailsLabel;
