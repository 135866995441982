import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import TextInput from "../../../Common/FormElements/TextInput/TextInput";
import CalendarInput from "../../../Common/FormElements/CalendarInput/CalendarInput";
import SwitchButton from "../../../Common/FormElements/SwitchButton/SwitchButton";
import Button from "../../../Common/FormElements/Button/Button";
import { closeRightModal } from "../../../../REDUX/actions/rightModal";
import UploadButton from "../../../Common/FormElements/UploadButton/UploadButton";
import { createEvent } from "../../../../REDUX/actions/events";
import TextArea from "../../../Common/FormElements/TextArea/TextArea";
import { getOrganizations } from "../../../../REDUX/actions/organziation";
import CustomMultiSelect from "../../../Common/FormElements/MultiSelect";

const NewEvent = () => {
  /* ***************************** consts && hooks **************************** */
  const dispatch = useDispatch();
  const { organizations } = useSelector((state) => state.organizationReducer);
  const { user } = useSelector((state) => state.userReducer);
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const [eventBoolean, setEventBoolean] = useState(false);
  const [eventFormData, setEventFormData] = useState({
    eventType: !eventBoolean ? "event" : "salon",
  });
  const modalTitle = eventBoolean ? "Salon" : "Événement";
  const { createdEventLoading } = useSelector((state) => state.eventsReducer);
  /* ******************************** callBacks ******************************* */
  // handle switch event type
  const handleSwitchEvent = () => {
    setEventBoolean(!eventBoolean);
    setEventFormData((prevFormData) => ({
      ...prevFormData,
      eventType: eventBoolean ? "event" : "salon",
    }));
  };
  // handle change normal inputs :
  const handleChange = (field, value) => {
    setEventFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };
  // handle change => calendar / time :
  const handleCalendarInput = (e, name) => {
    const { value } = e;
    let formattedValue = value;
    if (name === "startTime" || name === "endTime") {
      const momentValue = moment(value, "HH:mm:ss.SSS");
      if (momentValue.isValid()) {
        formattedValue = momentValue.format("HH:mm:ss.SSS");
      }
    }
    setEventFormData((prevFormData) => ({
      ...prevFormData,
      [name]: formattedValue,
    }));
  };
  // handle the image change
  const handleImageChange = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      setEventFormData((prevFormData) => ({
        ...prevFormData,
        photo: imageFile,
      }));
    }
  };
  // handle submit event form
  const handleSubmit = () => {
    dispatch(
      createEvent({
        newEvent: {
          ...eventFormData,
          startDate: moment(eventFormData.startDate)?.utc(true).toDate(),
          endDate: moment(eventFormData.endDate)?.utc(true).toDate(),
        },
      })
    );
  };

  useEffect(() => {
    dispatch(getOrganizations());
  }, [dispatch]);

  useEffect(() => {
    setOrganizationOptions(
      organizations?.map((item) => ({ label: item?.name, value: item?.code }))
    );
  }, [organizations]);

  let formDataIncomplete =
    !eventFormData.title ||
    !eventFormData.description ||
    !eventFormData.startDate ||
    !eventFormData.endDate ||
    !eventFormData.startTime ||
    !eventFormData.endTime ||
    (user?.attributes?.userRole === 2 && !eventFormData.resellers);

  // If eventBoolean is false (i.e., not a "Salon" event), also check for photo
  if (!eventBoolean) {
    formDataIncomplete = formDataIncomplete || !eventFormData.photo;
  }
  /* ******************************** Rendering ******************************* */
  return (
    <div className="evenementModal px-5 d-flex flex-column justify-content-between h100 ">
      <div>
        <h1 className="quickSand f25 sBold text-blue mb-5">
          Ajouter un {modalTitle}
        </h1>
        <div className="d-flex flex-column gap-4">
          <div className="d-flex flex-column gap-2">
            <span className="text-softBlack normal f16">Revendeur</span>
            <CustomMultiSelect
              filter
              name="resellers"
              placeholder=""
              value={eventFormData?.resellers}
              options={organizationOptions}
              callback={(e) => handleChange("resellers", e.target.value)}
            />
          </div>
          <div className="d-flex flex-column gap-2">
            <span className="text-softBlack normal f16">Titre</span>
            <TextInput
              className="w100"
              value={eventFormData?.title || ""}
              callback={(e) => handleChange("title", e.target.value)}
            />
          </div>
          <div className="w100 alignH gap-4">
            <div className="w50 d-flex flex-column gap-2">
              <span className="text-softBlack normal f16">
                Date de début de l'événement
              </span>
              <CalendarInput
                className="w100"
                value={
                  moment(eventFormData?.startDate)?.format("DD-MM-YYYY") || null
                }
                callback={(event) => handleCalendarInput(event, "startDate")}
              />
            </div>
            <div className="w50 d-flex flex-column gap-2">
              <span className="text-softBlack normal f16">
                Date de fin de l'événement
              </span>
              <CalendarInput
                className="w100"
                value={
                  moment(eventFormData?.endDate)?.format("DD-MM-YYYY") || null
                }
                callback={(event) => handleCalendarInput(event, "endDate")}
              />
            </div>
          </div>

          <div className="w100 alignH gap-4">
            <div className="w50 d-flex flex-column gap-2">
              <span className="text-softBlack normal f16">Heure de début</span>
              <CalendarInput
                className="w100"
                callback={(event) => handleCalendarInput(event, "startTime")}
                timeOnly
                minDate={false}
              />
            </div>
            <div className="w50 d-flex flex-column gap-2">
              <span className="text-softBlack normal f16">Heure de fin</span>
              <CalendarInput
                className="w100"
                callback={(event) => handleCalendarInput(event, "endTime")}
                timeOnly
                minDate={false}
              />
            </div>
          </div>

          <div className="w100 alignH gap-4">
            <div className="w50 d-flex flex-column gap-2">
              <span className="text-softBlack normal f16">
                Type d'événement
              </span>
              <div className="alignH">
                <span className="f16 normal">Événement</span>
                <span className="switchButtonContainer mx-3">
                  <SwitchButton
                    isActive={eventBoolean}
                    filterSwitch
                    onClick={handleSwitchEvent}
                  />
                </span>
                <span className="f16 normal">Salon</span>
              </div>
            </div>
            <div className="w50 d-flex flex-column gap-2">
              <span className="text-softBlack normal f16">
                Lien à consulter
              </span>
              <TextInput
                className="w100"
                value={eventFormData?.visitLink || ""}
                callback={(e) => handleChange("visitLink", e.target.value)}
              />
            </div>
          </div>
          <div className="w100 gap-4 d-flex justify-content-between">
            <div
              className={`w50 d-${
                eventBoolean ? "none" : "flex"
              } justify-content-start flex-column`}
            >
              <span className="text-softBlack normal f16">
                Image couverture
              </span>
              <UploadButton
                type={"file"}
                text={`${
                  eventFormData?.photo?.name ||
                  "Ajouter pièce jointes (max. 15 MB)"
                }`}
                callback={handleImageChange}
                className="coverImg mt-2"
              />
            </div>
            <div className={`${eventBoolean ? "w100" : "w50"}`}>
              <span className="text-softBlack normal f16 ">description</span>
              <TextArea
                className="w100 mt-2"
                value={eventFormData?.description || ""}
                callback={(e) => handleChange("description", e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Buttons */}
      <div className="align-self-center alignH gap-3 mt-5">
        <Button
          variant={"outline"}
          className="px-5"
          onClick={() => dispatch(closeRightModal())}
        >
          Annuler
        </Button>
        <Button
          className="px-5"
          onClick={handleSubmit}
          formDataIncomplete={formDataIncomplete}
          loading={createdEventLoading}
        >
          Enregistrer
        </Button>
      </div>
    </div>
  );
};

export default NewEvent;
