import React from "react";
import TextInput from "../../../Common/FormElements/TextInput/TextInput";
import Select from "../../../Common/FormElements/Select/Select";
import Separator from "../../../Common/Separator/Separator";

function AddressInfo() {
  const villeOptions = [
    { name: "Paris", code: "Paris" },
    { name: "London", code: "London" },
  ];
  return (
    <div className="p-5">
      <div className="mb-3">
        <p className="f16 text-ultraBlack mb-2">Adresse de livraison</p>
        <TextInput placeholder="Adresse de livraison" />
      </div>
      <div className="d-flex justify-content-between">
        <div className="mb-3 w49">
          <p className="f16 text-ultraBlack mb-2">Ville</p>
          <Select placeholder="Sélectionner" options={villeOptions} />
        </div>
        <div className="mb-3 w49">
          <p className="f16 text-ultraBlack mb-2">Code postal</p>
          <TextInput placeholder="Code postal" />
        </div>
      </div>
      <div className="mb-4 w49">
        <p className="f16 text-ultraBlack mb-2">Pays</p>
        <Select placeholder="Sélectionner" options={villeOptions} />
      </div>
      <Separator />
      <div className="my-3">
        <p className="f16 text-ultraBlack mb-2">Adresse de facturation</p>
        <TextInput placeholder="Adresse de facturation" />
      </div>
      <div className="d-flex justify-content-between">
        <div className="mb-3 w49">
          <p className="f16 text-ultraBlack mb-2">Ville</p>
          <Select placeholder="Sélectionner" options={villeOptions} />
        </div>
        <div className="mb-3 w49">
          <p className="f16 text-ultraBlack mb-2">Code postal</p>
          <TextInput placeholder="Code postal" />
        </div>
      </div>
      <div className="mb-4 w49">
        <p className="f16 text-ultraBlack mb-2">Pays</p>
        <Select placeholder="Sélectionner" options={villeOptions} />
      </div>
    </div>
  );
}

export default AddressInfo;
