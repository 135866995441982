import React from "react";

import "./CurrentOrders.css";

const CurrentOrdersList = () => {
  const orderTableTitles = [
    { id: 1, label: "N° de commande" },
    { id: 1, label: "Revendeur" },
    { id: 3, label: "Montant TTC" },
    { id: 4, label: "Date de commande" },
    { id: 5, label: "Statut" },
  ];
  return (
    <div className="currOrdersList">
      <div className="row bg-dimGrey orderListTable py-3 ps-5 alignH justify-content-between">
        {orderTableTitles?.map(({ id, label }) => (
          <span
            className="col-2 f14 text-brightGrey sBold"
            style={{ textAlign: "left" }}
            key={id}
          >
            {label}
          </span>
        ))}
        <span className="col-1"></span>
      </div>
    </div>
  );
};

export default CurrentOrdersList;
