import React, { useEffect, useState } from "react";
import CheckBox from "../../../FormElements/CheckBox/CheckBox";
import TextInput from "../../../FormElements/TextInput/TextInput";
import moment from "moment";
import { saveAs } from "file-saver";

import "./AttachmentItem.css";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAttachements,
  updateCurrentProduct,
  updateMainImage,
} from "../../../../../REDUX/actions/products";
import MediaPreview from "../../../MediaPreview";
import { IMAGE_END_POINT } from "../../../../../Api/EndPoints";
import SwitchButton from "../../../FormElements/SwitchButton/SwitchButton";
import { uploadFile } from "../../../../../Utils";
import { uploadImageToStrapi } from "../../../../../Utils/uploadImageToStrapi";

function AttachmentItem({
  id,
  checkAll,
  title,
  date,
  fileName,
  file,
  fileUrl,
  mediaType,
  attachments,
  setAttachments,
  selectedItems,
  setSelectedItems,
  fromContainer,
  fromDepense,
  acceptTypes,
  fromNewProduct,
  activeSwitch,
  setActiveSwitch,
}) {
  const { user } = useSelector((state) => state.userReducer);
  const { currentProduct } = useSelector((state) => state.productsReducer);

  const dispatch = useDispatch();
  const [checked, setChecked] = useState(checkAll);
  const [previewMedia, setPreviewMedia] = useState();

  const handleSwitchChange = () => {
    setActiveSwitch(id);
    ((!activeSwitch && !currentProduct?.attributes?.mainImage) ||
      (activeSwitch && currentProduct?.attributes?.mainImage?.data?.id)) &&
      dispatch(
        updateMainImage({
          productId: currentProduct?.id,
          fileId: file?.data?.id,
        })
      );
  };

  const handleSelect = () => {
    setChecked(!checked);
    if (checked === true) {
      setSelectedItems((items) => items?.filter((item) => item !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };
  const handleUpload = (e) => {
    const currentFile = e.target.files[0];
    const modifiedAttachments = attachments.map((attach) =>
      attach.id === id
        ? {
            ...attach,
            title: currentFile.name,
            file: currentFile,
            date: new Date(),
            mediaType: currentFile?.type?.includes("image")
              ? "image"
              : currentFile?.type?.includes("video")
              ? "video"
              : null,
          }
        : attach
    );
    setAttachments(modifiedAttachments);
  };
  const handleDelete = () => {
    if (fromDepense) {
      setAttachments([{ id: attachments[0]?.id }]);
    } else {
      const modifiedAttachments = attachments.filter((attach) => {
        if (attach.id === id) {
          return false;
        } else {
          return true;
        }
      });
      setAttachments(modifiedAttachments);
    }
  };

  const deleteAddedAttachements = () => {
    const attachementsToDelete = attachments?.filter(
      (attach) => attach?.id === id
    );

    dispatch(deleteAttachements(attachementsToDelete, () => handleDelete()));
  };

  const handleNameChange = (e) => {
    const modifiedAttachments = attachments.map((attach) =>
      attach.id === id
        ? {
            ...attach,
            title: e.target.value,
          }
        : attach
    );
    setAttachments(modifiedAttachments);
  };

  useEffect(() => {
    setChecked(checkAll);
  }, [checkAll]);
  const checkedClassName = checked ? "checked" : "";

  return (
    <div
      className={`attachmentItem custom d-flex align-items-center p-3 ${checkedClassName}`}
    >
      {!fromContainer && !fromDepense && (
        <CheckBox
          className="me-2"
          width={17}
          height={17}
          checked={checked}
          callback={handleSelect}
        />
      )}
      {user?.attributes?.userRole !== 0 && (
        <TextInput
          className="w30 pe-4"
          value={title}
          inputClassName={"f12"}
          placeholderStyle={"f12"}
          placeholder={
            fromContainer
              ? "Facture container"
              : fromDepense
              ? "Facture"
              : "SPA"
          }
          callback={handleNameChange}
        />
      )}

      <div
        className={`${
          // fromNewProduct ? "w20" :
          "w30"
        } d-flex align-items-center`}
      >
        {fileName || fileUrl ? (
          <span
            onClick={() => saveAs(IMAGE_END_POINT + fileUrl, fileName)}
            className="documentLink f12 text-softBlue mb-0 w100 pointer"
          >
            {fileName}
          </span>
        ) : (
          <div>
            <label>
              <div className="bg-softBlue pointer d-flex text-white align-items-center px-4 py-2 br5">
                <i className="bi bi-upload" />
                <p className="f12 mb-0 ms-2">
                  {fromContainer
                    ? "Importer packing list"
                    : "Importer un document"}
                </p>
              </div>
              <input
                hidden
                accept={
                  acceptTypes
                    ? acceptTypes
                    : fromContainer
                    ? ".pdf"
                    : ".jpg, .jpeg, .png, .pdf, .csv"
                }
                type="file"
                onChange={handleUpload}
              />
            </label>
          </div>
        )}
      </div>

      {!fromContainer && (
        <p className="f12 text-brightGrey mb-0 w20">
          {date ? moment(date).format("DD.MM.YYYY") : "-"}
        </p>
      )}
      {/* {fromNewProduct && (
        <div className="w20">
          <SwitchButton
            isActive={activeSwitch === id}
            small
            onClick={() => handleSwitchChange()}
          />
        </div>
      )} */}
      <div className="buttonsContainer ms-auto">
        {(file || fileUrl) && mediaType ? (
          <>
            <button
              className="br50 p-1 me-2 border-0 bg-transparent"
              onClick={() => {
                setPreviewMedia(fileUrl);
              }}
            >
              <i className="bi bi-eye text-blue f18" />
            </button>
            {previewMedia && (
              <MediaPreview
                mediaType={mediaType}
                isUrl={!!fileUrl && !file}
                file={previewMedia}
                setPreviewMedia={setPreviewMedia}
              />
            )}
          </>
        ) : null}
        <button
          className="br50 p-1 border-0 bg-transparent deleteBtn"
          onClick={deleteAddedAttachements}
        >
          <i className="bi bi-x-lg text-brightRed f18" />
        </button>
      </div>
    </div>
  );
}

export default AttachmentItem;
