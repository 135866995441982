// initial state
const initialState = {
  guidesLoading: false,
  guidesError: null,
  guides: [],
  guide: null,
  guideLoading: false,
};

// Action Types
export const GUIDES_LOADING = "GUIDES_LOADING";
export const GUIDES_ERROR = "GUIDES_ERROR";
export const GUIDES_FETCHED = "GUIDES_FETCHED";
export const GUIDE_LOADING = "GUIDE_LOADING";
export const GUIDE_FETCHED = "GUIDE_FETCHED";
export const GUIDE_ERROR = "GUIDE_ERROR";
export const GUIDE_UPDATED = "GUIDE_UPDATED";
export const GUIDE_CREATED = "GUIDE_CREATED";

// Reducer
const guidesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GUIDES_LOADING:
      return { ...state, guidesLoading: true };
    case GUIDES_ERROR:
      return { ...state, guidesLoading: false, guidesError: action.payload };
    case GUIDES_FETCHED:
      return { ...state, guidesLoading: false, guides: action.payload };
    case GUIDE_LOADING:
      return { ...state, guideLoading: true };
    case GUIDE_FETCHED:
      return { ...state, guideLoading: false, guide: action.payload };
    case GUIDE_ERROR:
      return { ...state, guideLoading: false, guideError: action.payload };
    case GUIDE_UPDATED:
    case GUIDE_CREATED:
      return { ...state, guideLoading: false };
    default:
      return state;
  }
};

export default guidesReducer;
