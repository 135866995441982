import React, { useState } from "react";
import TabsNavbar from "../../Common/TabsNavBar/TabsNavbar";
import LineChartHeader from "../../Dashboard/Syntheses/LineChartHeader";
import CustomLineChart from "../../Common/CustomLineChart/CustomLineChart";

const SynthesesDepensesSection = () => {
  const [activeTab, setActiveTab] = useState("Fournisseur");

  return (
    <div className="w100 mb-5">
      <TabsNavbar
        tabs={["Fournisseur", "Fixe", "Variable", "Perte SAV"]}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabClassName="f14 normal me-4 text-start"
      />
      <LineChartHeader colors={["wildStrawberry", "turquoise"]} />
      <CustomLineChart colors={["#FC43AC", "#1eb1d8"]} />
    </div>
  );
};

export default SynthesesDepensesSection;
