import React, { useEffect, useState } from "react";
import { PdfIcon, PhotoSvg, VideoSvg } from "../Svgs";
import { saveAs } from "file-saver";
import DownloadButton from "../Common/ListHeaderButtons/DownloadButton";
import { getFileName } from "../../Utils/fileUtils";

const FaqPdf = ({ title, size, url, mime }) => {
  const [fileSize, setFileSize] = useState();
  const type = mime?.includes("image")
    ? "IMAGE"
    : mime?.includes("video")
      ? "VIDEO"
      : mime?.includes("pdf")
        ? "PDF"
        : "FICHIER";

  useEffect(() => {
    (async function getFileSize() {
      try {
        const response = await fetch(url);
        if (response.ok) {
          const contentLength = response.headers.get("content-length");
          const sizeInBytes = parseInt(contentLength);
          const sizeInKb = sizeInBytes / 1024; // Convert to KB
          if (sizeInKb > 1024) {
            const sizeInMb = sizeInKb / 1024; // Convert to KB
            setFileSize(sizeInMb.toFixed(2) + " MB");
          } else {
            setFileSize(sizeInKb.toFixed(2) + " KB");
          }
        } else {
          setFileSize("-");
        }
      } catch (error) {
        setFileSize("-");
      }
    })();
  }, [size, url]);

  return (
    <div className="alignH gap-4">
      {type === "IMAGE" ? (
        <PhotoSvg />
      ) : type === "VIDEO" ? (
        <VideoSvg />
      ) : type === "PDF" ? (
        <PdfIcon />
      ) : null}
      {/* <PdfIcon /> */}
      <div>
        <span className="f14 normal text-lightBlack">{title}</span>
        <p className="f12 normal text-lightBlack mb-0">
          {type} | {fileSize}
        </p>
      </div>
      {/* <a href={url} target="_blank" rel="noopener noreferrer">
        <DownloadPdfSvg />
      </a> */}

      <div className="listHeaderButtons d-flex">
        <DownloadButton
          disabled={!url}
          callback={() => saveAs(`${url}`, getFileName(url))}
        />
      </div>
    </div>
  );
};

export default FaqPdf;
