export const getColumnslist = (role) => {
  return [
    { title: "N°", width: "col-1", sort: "number" },
    { title: "Date", width: "col-1", sort: "date" },
    { title: "Facture", width: "col-1", sort: "facture.number" },
    { title: "Client", width: "col-1", sort: "reseller.name" },
    {
      title: "Bon de Commande",
      width: "col-1",
      sort: "bon_de_commande.number",
    },
    // {
    //   title: "Livraison Souhaité",
    //   width: "col-1",
    //   sort: "estimatedDeliveryDate",
    // },
    { title: "Livraison", width: "col-1", sort: "realDeliveryDate" },
    role === "BusinessBroker"
      ? { title: "Comission", width: "col-1", sort: "comission" }
      : {
          title: "AA",
          width: "col-1",
          sort: "business_affiliate.user.firstName",
        },
    role === "admin" && { title: "TX Marge", width: "col-1", sort: "txMarge" },
    { title: "Montant TTC", width: "col-1", sort: "totalTTC" },
    { title: "Statut", width: "col-1", sort: "status" },
    { title: "Paiement", width: "col-1", sort: "payStatus" },
    { title: "Créé par", width: "col-1", sort: "creator.firstName" },
    // { title: "", width: "col-1" },
  ];
  // const roleMap = {
  //   reseller: [
  //     { title: "N°", width: "col-1", sort: "number" },
  //     { title: "Date", width: "col-1" },
  //     { title: "Facture", width: "col-1" },
  //     { title: "Client", width: "col-1" },
  //     { title: "Livraison Souhaité", width: "col-1" },
  //     { title: "Livraison", width: "col-1" },
  //     { title: "AA", width: "col-1" },
  //     // { title: "Commission", width: "col-1" },
  //     // { title: "Marge Net", width: "col-1" },
  //     // { title: "Tx Marge", width: "col-1" },
  //     // { title: "Montant HT", width: "col-1" },
  //     // { title: "TVA", width: "col-1" },
  //     { title: "Montant TTC", width: "col-1" },
  //     { title: "Statut", width: "col-1" },
  //     { title: "Paiement", width: "col-1" },
  //     { title: "Créé par", width: "col-1" },
  //     { title: "", width: "col-1" },
  //     // { title: "N° de commande", width: "col-2" },
  //     // { title: "Date de commande", width: "col-2" },
  //     // { title: "Réf client", width: "col-1" },
  //     // { title: "Montant", width: "col-1" },
  //     // // { title: "Modèles", width: "col-1" },
  //     // { title: "Livraison souhaitée", width: "col-2" },
  //     // { title: "Livrée le", width: "col-1" },
  //     // { title: "Statut", width: "col-1" },
  //     // { title: "", width: "col-1" },
  //   ],
  //   admin: [
  //     { title: "N°", width: "col-1", sort: "number" },
  //     { title: "Date", width: "col-1", sort: "date" },
  //     { title: "Facture", width: "col-1", sort: "facture.number" },
  //     { title: "Client", width: "col-1", sort: "reseller.name" },
  //     {
  //       title: "Livraison Souhaitée",
  //       width: "col-1",
  //       sort: "estimatedDeliveryDate",
  //     },
  //     { title: "Livraison", width: "col-1", sort: "realDeliveryDate" },
  //     { title: "AA", width: "col-1" },
  //     // { title: "Commission", width: "col-1" },
  //     // { title: "Marge Net", width: "col-1" },
  //     // { title: "Tx Marge", width: "col-1" },
  //     // { title: "Montant HT", width: "col-1" },
  //     // { title: "TVA", width: "col-1" },
  //     { title: "Montant TTC", width: "col-1" },
  //     { title: "Statut", width: "col-1" },
  //     { title: "Paiement", width: "col-1" },
  //     { title: "Créé par", width: "col-1" },
  //     { title: "", width: "col-1" },
  //   ],
  //   BusinessBroker: [
  //     { title: "N°", width: "col-1", sort: "number" },
  //     { title: "Date", width: "col-1" },
  //     { title: "Facture", width: "col-1" },
  //     { title: "Client", width: "col-1" },
  //     { title: "Livraison Souhaitée", width: "col-1" },
  //     { title: "Livraison", width: "col-1" },
  //     { title: "AA", width: "col-1" },
  //     // { title: "Commission", width: "col-1" },
  //     // { title: "Marge Net", width: "col-1" },
  //     // { title: "Tx Marge", width: "col-1" },
  //     // { title: "Montant HT", width: "col-1" },
  //     // { title: "TVA", width: "col-1" },
  //     { title: "Montant TTC", width: "col-1" },
  //     { title: "Statut", width: "col-1" },
  //     { title: "Paiement", width: "col-1" },
  //     { title: "Créé par", width: "col-1" },
  //     { title: "", width: "col-1" },
  //     // { title: "N°", width: "col-1" },
  //     // { title: "Date de commande", width: "col-2" },
  //     // { title: "N° Fact", width: "col-1" },
  //     // { title: "Société", width: "col-1" },
  //     // // { title: "Référence client", width: "col-2" },
  //     // // { title: "AA", width: "col-1" },
  //     // // { title: "Créée par", width: "col-1" },
  //     // { title: "Livraison souhaitée", width: "col-2" },
  //     // { title: "Livrée le", width: "col-1" },
  //     // { title: "Montant", width: "col-1" },
  //     // { title: "Commission", width: "col-1" },
  //     // { title: "Paiement", width: "col-2" },
  //   ],
  // };
  // return roleMap[role] || [];
};

export const approvColumnsList = [
  { title: "Nom du container", className: "w10" },
  { title: "Usine", className: "w10" },
  { title: "Date order", className: "w13" },
  { title: "Date estimative", className: "w13" },
  { title: "Date arrivée", className: "w10" },
  { title: "Tx €/$", className: "w10" },
  { title: "QC", className: "w10" },
  { title: "Livraison", className: "w10" },
  { title: "Coût container", className: "w10" },
];

export const commandesHeaderTable = [
  { title: "N°", width: "col-1" },
  { title: "N° Fact", width: "col-1" },
  { title: "Société", width: "col-1" },
  { title: "Date livraison", width: "col-1" },
  { title: "AA", width: "col-1" },
  // { title: "Commission", width: "col-1" },
  { title: "Marge Net", width: "col-1" },
  { title: "Tx Marge", width: "col-1" },
  { title: "Montant HT", width: "col-1" },
  { title: "TVA", width: "col-1" },
  { title: "Montant TTC", width: "col-1" },
  { title: "Statut", width: "col-1" },
  { title: "Paiement", width: "col-1" },
];
