import React, { useEffect, useMemo, useState } from "react";
import { Chart } from "primereact/chart";
import { externalTooltipHandler } from "./externalTooltipHandler";

import "./CustomLineChart.css";

const CustomLineChart = ({ colors, data, years, unity = "k" }) => {
  const monthLabelList = useMemo(
    () => [
      "Jan",
      "Fev",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juil",
      "Août",
      "Sept",
      "Oct",
      "Nov",
      "Déc",
    ],
    []
  );
  const [stats, setStats] = useState();

  useEffect(() => {
    if (!data) return;
    setStats({
      labels: monthLabelList,
      datasets: years
        ?.map((year, index) => {
          if (!data[year]?.length > 0) return null;
          return {
            label: String(year),
            data: data[year]?.map((value) =>
              unity === "%" ? value ?? 0 : (value ?? 0) / 1000
            ),
            fill: false,
            borderColor: colors[index],
            tension: 0.4,
            pointBorderColor: colors[index],
            pointBackgroundColor: colors[index],
            pointBorderWidth: 3,
            unity,
          };
        })
        .filter((item) => item !== null),
    });
  }, [data, colors, monthLabelList, years, unity]);

  const getLightTheme = () => {
    let basicOptions = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          position: "none", // to hide the default squares legend of reactPrime
          labels: {
            color: "#495057",
          },
        },
        tooltip: {
          enabled: false,
          position: "nearest",
          external: (context) => externalTooltipHandler(context, unity),
        },
      },
      scales: {
        x: {
          ticks: {
            color: "#666666",
          },
          grid: {
            display: false,
          },
        },
        y: {
          ticks: {
            color: "#666666",
            stepSize: 20,
            callback: (value) => value + unity,
            fontWeight: "bold",
          },
          grid: {
            color: "#ebedef",
          },
        },
      },
    };

    let multiAxisOptions = {
      stacked: false,
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: {
            color: "#495057",
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: "#495057",
            stepSize: 4,
          },
          grid: {
            color: "#ebedef",
            display: false,
          },
        },
        y: {
          type: "linear",
          display: true,
          position: "right",
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
        },
        y1: {
          type: "linear",
          display: true,
          position: "right",
          ticks: {
            color: "#495057",
          },
          grid: {
            drawOnChartArea: false,
            color: "#ebedef",
          },
        },
      },
    };

    return {
      basicOptions,
      multiAxisOptions,
    };
  };

  const { basicOptions } = getLightTheme();

  return (
    <div>
      <div className="card customLineChart">
        {stats && <Chart type="line" data={stats} options={basicOptions} />}
      </div>
    </div>
  );
};

export default CustomLineChart;
